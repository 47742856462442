// Social Media
.social-media {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include max(lg) {
      padding-bottom: rem(50);
    }

    @include max(md) {
      padding-bottom: rem(30);
    }

    @include min(1300) {
      padding-left: clamp-rem(30, 100);
    }

    .wp-block-buttons {
      margin-top: rem(24);
    }

    section.has-campeasy-white-background-color & {
      .social-media__content {
        @include max(md) {
          padding-bottom: 0;
        }
      }
    }
  }
}
