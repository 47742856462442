.notice__wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(10);
  position: fixed;
  top: rem(30);
  right: rem(16);
  z-index: 120;
}

.notice {
  @include border;
  width: rem(370);
  max-width: calc(100vw - var(--wp--style--root--padding-right) * 2);
  display: flex;
  align-items: flex-start;
  gap: rem(8);
  background: var(--wp--preset--color--white);
  padding: rem(8 16);
  margin: 0;
  opacity: 0;
  animation: fadeInRight 0.6s forwards;

  &__icon {
    color: var(--wp--preset--color--error);
    margin: rem(3 0 0);
  }

  &__message {
    font-size: rem(14);
    line-height: rem(22);
  }

  &__close {
    display: flex;
    margin: rem(3) 0 0 auto;
    cursor: pointer;

    @include hover-responsive {
      color: var(--wp--preset--color--primary);
    }
  }

  &.dynamic {
    position: absolute;
    top: rem(30);
    right: rem(16);
    z-index: 110;

    @include max(md) {
      left: auto !important;
      right: var(--wp--style--root--padding-right) !important;
    }
  }

  &.leaving {
    transition: 0.6s;
    animation: fadeOut 0.6s forwards;
  }

  &--success {
    .notice__icon {
      width: rem(16);
      height: rem(16);
      font-size: rem(12);
      color: var(--wp--preset--color--white-static);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 rem(16);
      background: var(--wp--preset--color--success);
      border-radius: 50%;

      &::before {
        content: $icon--io-check;
      }
    }
  }

  &--error {
    border-color: var(--wp--preset--color--error);

    .notice__icon {
      &::before {
        content: $icon--io-warning;
      }
    }
  }

  &--warning {
    .notice__icon {
      color: var(--wp--preset--color--warning);

      &::before {
        content: $icon--io-warning;
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(rem(16));
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: rem(-50);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
