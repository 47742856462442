.modal-change-camper {
  .modal__content {
    width: auto;
    padding: 0;

    &-inner {
      padding: 0;
    }
  }
}

.change-camper {
  &__wrapper {
    border-radius: $radius;
    overflow: hidden;

    @include min(576) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    max-width: rem(430);
    background-color: var(--wp--preset--color--white);
    padding: rem(35 30);

    @include min(lg) {
      padding: rem(50 40);
    }

    &:only-child {
      grid-column: span 2;
    }

    &:nth-child(2) {
      background-color: var(--wp--preset--color--light-bg);
      border-left: 1px solid var(--wp--preset--color--stroke);
    }
  }

  &__header {
    flex-shrink: 0;
    display: grid;
    grid-row-gap: rem(12);
    margin-bottom: clamp-rem(16, 24, 575, 1024);

    @include min(lg) {
      grid-template-columns: auto 1fr;
      margin-bottom: rem(40);
    }
  }

  &__image {
    width: rem(117);
    aspect-ratio: 117/80;
    object-fit: contain;
  }

  &__dates {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    &-item {
      padding: rem(0 16);

      @include min(lg) {
        padding: rem(0 20);
      }

      &:first-child {
        @include max(lg) {
          padding-left: 0;
        }
      }

      &:not(:first-child) {
        border-left: 1px solid var(--wp--preset--color--stroke);
      }

      label {
        @extend %copy-md;
        display: block;
        color: var(--wp--preset--color--gray-one);
        margin-bottom: rem(6);
      }

      span {
        @extend %copy-sm;
        font-weight: 500;
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: clamp-rem(16, 20, 575, 1024);
  }

  &__title {
    margin: 0;
  }

  &__text {
    font-size: clamp-rem(14, 16, 575, 1024);

    @include min(md) {
      margin-bottom: rem(10);
    }
  }

  &__btn {
    margin-top: auto;
  }
}
