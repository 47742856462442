$extra-container-md: 400px;
$extra-container-sm: 250px;

.extra-card {
  width: 100%;
  container: extra-card / inline-size;
  position: relative;
  perspective: 3000px;

  &.is-animating {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  &.is-flipped {
    .extra-card__inner {
      transform: rotateY(180deg);
    }
  }

  &.is-hovered {
    @include hover-responsive {
      .extra-card__front-view,
      .extra-card__back-view {
        border-color: var(--wp--preset--color--primary);
      }
    }
  }

  &.in-cart {
    .extra-card__front-view,
    .extra-card__back-view {
      border-color: var(--wp--preset--color--primary);
    }
  }

  &__inner {
    width: 100%;
    height: rem(205);
    display: flex;
    flex-direction: column-reverse;
    border-radius: $radius;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1), border-color $tr;
    position: relative;

    @include min(lg) {
      flex-direction: row;
    }
  }

  &__front-view,
  &__back-view {
    @include border;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    position: absolute;
    transition: border-color $tr;
  }

  &__front-view {
    display: flex;
  }

  &__back-view {
    transform: rotateY(180deg);
  }

  &__info {
    width: rem(168);
    background-color: var(--wp--preset--color--light-bg);
    border-radius: $radius 0 0 $radius;
    flex-shrink: 0;

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(13 15 17);
    }
  }

  &__thumbnail {
    width: 100%;
    margin: 0;
	  padding: 8%;

    img {
      width: 100%;
      aspect-ratio: 170/150;
      object-fit: contain;
    }
  }

  &__brand {
    width: rem(96);
	  height: rem(96);
    position: absolute;
    bottom: rem(16);
    left: rem(16);
	  display: flex;
	  align-items: end;

    @include max(md) {
      display: none;
    }

	  > img {
		  max-height: 100%;
		  width: 100%;
		  object-fit: contain;
		  object-position: bottom center;
		  padding: rem(8);
		  border-radius: $radius;
	  }

	  .checkout-extras__grid &{
		  width: rem(72);
		  height: rem(72);
	  }
  }

  &__favorite {
    width: rem(28);
    height: rem(28);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--wp--preset--color--gray-one);
    font-size: rem(28);
    position: absolute;
    bottom: rem(16);
    left: rem(124);

    @include hover-responsive {
      .icon {
        transform: scale(1.1);
      }
    }

    .icon {
      display: inline-block;
      transition: $tr;
    }

    &.is-checked {
      .icon {
        &:after {
          @extend .icon;
          content: $icon--io-check;
          width: rem(14);
          height: rem(14);
          font-size: rem(13);
          background-color: var(--wp--preset--color--success);
          color: $w;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--wp--preset--color--white);
          border-radius: 50%;
          position: absolute;
          bottom: 2px;
          right: 0;
          z-index: 2;
        }
      }
    }
  }

  &__main {
    flex-grow: 1;
    background-color: var(--wp--preset--color--white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0 $radius $radius 0;
    padding: rem(18 16);

    &-heading {
      width: 100%;
      padding-right: rem(55);
      border-top-left-radius: $radius;
    }
  }

  &__title {
    @include limit-text(2);
    margin: rem(0 0 4);
  }

  &__subtitle {
    @extend %copy-md;
    @include limit-text(2);
    color: var(--wp--preset--color--gray-one);
  }

  &__discount {
    @extend %copy-md;
    background-color: var(--wp--preset--color--success);
    color: $w;
    display: inline-block;
    border-radius: rem(4);
    padding: rem(0 12);
    margin-top: rem(12);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(16);
    margin-top: auto;
  }

  &__price {
    display: grid;
    grid-template-areas: ". sale"
  "prefix value";
    grid-template-columns: auto auto;
    grid-gap: rem(2 5);
    align-items: baseline;
    margin: 0;

    &-sale {
      @extend %copy-md;
      grid-area: sale;
      color: var(--wp--preset--color--gray-two);
    }

    &-prefix {
      grid-area: prefix;
      font-size: rem(16);
      font-weight: 400;
    }

    &-value {
      grid-area: value;
      margin: 0;
    }
  }

  &__more,
  &__back {
    background-color: var(--wp--preset--color--light-bg);
    z-index: 10;
    top: rem(-1);
    right: rem(-1);
    .checkin & {
      background-color: var(--wp--preset--color--white);
    }
    &:after {
      background-color: var(--wp--preset--color--light-bg);
      .checkin & {
        background-color: var(--wp--preset--color--white);
      }
    }

    .icon {
      color: var(--wp--preset--color--primary);
    }

    &:hover {
      transform: scale(1.1) translate(-2px, 2px);
    }
  }

  &__back {
    left: 0;
    transform-origin: left;
    @supports (-webkit-touch-callout: none) {
      backface-visibility: visible;
    }
  }

  .js-view-toggle {
    cursor: pointer;
  }

  &__overlay {
    overflow-y: auto;
    height: calc(100% - rem(4));
    width: calc(100% - rem(1));
    background-color: var(--wp--preset--color--light-bg);
    border-radius: $radius;
    padding: rem(0 30 18);
    margin-top: rem(2);
    .checkin & {
      background-color: var(--wp--preset--color--white);
    }

    &-content {
      @extend %copy-sm;

      @include min(md) {
        font-size: rem(14);
      }

      font, span {
        color: var(--wp--preset--color--base) !important;
      }

      .editor {
        &:not(:last-of-type) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .extra-card__title {
      min-height: rem(46);
      background-color: var(--wp--preset--color--light-bg);
      padding: rem(18 0 6 48);
      margin: rem(0 0 20);
      position: sticky;
      top: 0;
      .checkin & {
        background-color: var(--wp--preset--color--white);
      }
    }

    .extra-card__actions-wrapper {
      width: 100%;
      height: rem(45);
      margin-top: 1.5rem;
      .extra-card__actions {
        max-width: rem(118);
      }
    }
  }

  // view: Details
  &:not([data-view="details"]) {
    .extra-card__overlay--details {
      display: none;
    }
  }

  @container extra-card (max-width: #{$extra-container-md}) {
    &__inner {
      height: 100%;
    }

    &__front-view {
      flex-direction: column;
      position: relative;
      padding-top: rem(126);
    }

    &__main-heading {
      width: 100%;
      height: rem(126);
      background-color: var(--wp--preset--color--white);
      padding: rem(16 75 16 16);
      position: absolute;
      top: 0;
      left: 0;
    }

    &__title {
      font-size: rem(14);
      font-weight: 600;
    }

    &__discount {
      margin: 0;
      position: absolute;
      bottom: 0;
      left: rem(16);
      transform: translateY(-50%);
      z-index: 2;
    }

    &__favorite {
      top: rem(112);
      right: rem(16);
      left: auto;
      z-index: 2;
    }

    &__info {
      width: 100%;
      position: relative;
      @include min(md) {
        flex-grow: 1;
        height: rem(124);
      }
      @include max(md) {
        flex-grow: 1;
        height: rem(102);
      }
      &-bottom {
        padding: 0;
      }
    }

    &__thumbnail {
      width: rem(120);
      margin: rem(12) auto;
    }

    &__brand {
      bottom: rem(10);
      left: rem(10);
    }

    &__main {
      border-radius: 0 0 $radius $radius;
    }

    &__actions {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    .input-controls__btn {
      width: rem(25);
      height: rem(25);

      svg {
        width: rem(10);
        height: rem(10);
      }
    }

    &__price {
      &:not(:has(.extra-card__price-prefix)) { // affects only 'Free' price value
        display: inline-block;
        font-size: rem(18);
        font-weight: 500;
      }
    }
  }

  @container extra-card (max-width: #{$extra-container-sm}) {
    &__front-view {
      padding-top: rem(80);
    }

    &__main-heading {
      width: 100%;
      height: rem(80);
      background-color: var(--wp--preset--color--white);
      padding: rem(12 50 6 8);
      position: absolute;
      top: 0;
      left: 0;
    }

    &__subtitle {
      @include limit-text(1);
      font-size: rem(12);
    }

    &__discount {
      left: rem(8);
      transform: translateY(50%);
    }

    &__thumbnail {
      width: rem(96);
      margin: rem(10) auto;
    }

    &__favorite {
      right: rem(5);
      top: rem(66);
    }

    &__main {
      padding: rem(10 12 12);
    }

    .card-toggle-right,
    .card-toggle-left {
      width: rem(41);
      height: rem(41);

      .icon {
        font-size: rem(41);

        &:before {
          font-size: rem(41);
        }

        &:after {
          width: rem(41);
          height: rem(41);
          top: 0;
          left: 0;
        }
      }
    }

    &__overlay {
      padding: rem(0 16 18);

      .extra-card__title {
        min-height: rem(40);
        padding: rem(18 0 0 34);
      }
    }
  }
}

// Mini card (for bottom cart)
.extra-card__mini,
.camper-card__mini {
  @extend %copy-md;
  @include border;
  width: 30%;
  height: rem(44);
  display: flex;
  align-items: center;
  background-color: var(--wp--preset--color--light-bg);
  position: absolute;
  top: calc(50% - rem(30));
  left: calc(50% - rem(120));
  opacity: 0;
  visibility: hidden;
  transition: opacity $tr;

  @include min(lg) {
    height: rem(60);
  }

  .cart-extras &,
  .cart-comparison & {
    position: static;
    opacity: 1;
    visibility: visible;
  }

  &-count {
    flex-shrink: 0;
    width: rem(36);
    text-align: center;

    &:before {
      content: 'x';
    }
  }

  &-thumbnail {
    flex-shrink: 0;
    width: rem(43);
    height: 100%;
    margin: 0;

    .cart-comparison & {
      width: rem(50);
      background: var(--wp--preset--color--white);
      border-right: 1px solid var(--wp--preset--color--stroke);
      border-radius: $radius 0 0 $radius;
      padding: rem(0 4);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-title {
    @include limit-text(1);
    flex-grow: 1;
    padding: rem(0 10);

    @include min(lg) {
      @include limit-text(2);
    }
  }

  &-remove {
    width: rem(40);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-left: 1px solid var(--wp--preset--color--stroke);

    .icon {
      color: var(--wp--preset--color--gray-two);
      font-size: rem(20);
      transition: color $tr;
    }

    @include hover-responsive {
      .icon {
        color: var(--wp--preset--color--error);
      }
    }
  }
}

// Small Card (on Extras page)
.extra-card-small {
  width: 100%;
  position: relative;
  perspective: 3000px;

  &.is-animating {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  &.is-hovered {
    @include hover-responsive {
      .extra-card-small__content {
        &:after {
          opacity: 1;
        }
      }

      .extra-card-small__inner {
        border-color: var(--wp--preset--color--primary);
      }
    }
  }

  &.is-flipped {
    .extra-card-small__inner {
      transform: rotateY(180deg);
    }
  }

  &__inner {
    height: 100%;
    border: 1px solid var(--wp--preset--color--stroke);
    border-radius: 0.5625rem;
    display: flex;
    flex-direction: column;
    background-color: var(--wp--preset--color--campeasy-white);
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1), border-color $tr;
    transform-style: preserve-3d;

    @include min(lg) {
      flex-direction: row;
    }
  }

  &__front {
    width: 100%;
    backface-visibility: hidden;
    border-radius: 0.5625rem;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__header {
    border-radius: 0.5625rem 0.5625rem 0 0;
    min-height: 8.875rem;
    background-color: var(--wp--preset--color--light-bg);
    margin: 0;
    padding: 3.75rem 1.875rem 1.25rem;
  }

  &__title {
    color: var(--wp--preset--color--primary);
    margin: 0;

    a {
      @include animated-underline;
    }
  }

  &__subtitle {
    @extend %copy-sm;
  }

  &__image {
    background-color: var(--wp--preset--color--white);
    margin: 0;
    position: relative;

    > img {
      width: 100%;
      height: rem(232);
      object-fit: contain;
	    padding: 8%;
    }
  }

  &__more {
    color: var(--wp--preset--color--primary) !important;
    z-index: 10;

    .back-icon {
      display: none;
    }
  }

  .camper__more {
    top: -1px;
    right: -1px;
  }

	&__actions {
		margin-bottom: 0;

		.camper__dates {
			margin-bottom: 0;
		}

		.camper__buttons {
			margin-top: 1rem;
		}
	}

  .camper-small__back {
    background-color: var(--wp--preset--color--light-bg);
  }

  .camper__back {
    color: var(--wp--preset--color--primary) !important;
    top: -1px;
    left: -1px;
    transform-style: preserve-3d;
    z-index: 2;
  }

  &__overlay {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-header {
      margin: 0;
      padding: rem(80 30 20);

      @include min(md) {
        min-height: rem(150);
        padding-top: rem(65);
      }
    }

    &-container {
      overflow-y: auto;
    }

    &-content {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: rem(0 30 35);
    }

    &-text {
      @extend %copy-md;
    }

    .camper__buttons {
      margin-top: rem(30);
    }
  }

  &__block {
    + .extra-card-small__block {
      margin-top: rem(30);
    }
  }

  &__block-title {
    margin-bottom: rem(24);
  }

  // view: Details
  &:not([data-view="details"]) {
    .camper-small__overlay--details {
      display: none;
    }
  }
}
