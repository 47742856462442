// My Account
.account {
  &-wrapper {
    border-top: 1px solid var(--wp--preset--color--stroke);
    margin: rem(80 0);

    @include min(1024) {
      display: flex;
      column-gap: rem(30);

      @include min(lg) {
        column-gap: clamp-rem(50, 125);
      }
    }
  }

  &-nav {
    padding-top: clamp-rem(30, 80, 782, 1440);

    @include min(md) {
      width: rem(200);
      flex-shrink: 0;
      border-right: 1px solid var(--wp--preset--color--stroke);
    }

    @include min(lg) {
      width: rem(305);
    }

    &__greeting {
      @extend %subtitle;
      color: var(--wp--preset--color--black);
      text-transform: uppercase;
      margin-bottom: rem(30);

      @include max(md) {
        padding: rem(0 20);
        padding-left: 0;
      }
    }

    &__menu {
      @include unstyled-list;

      &-wrapper {
        position: sticky;
        top: rem(65);
      }

      > li {
        border-top: 1px solid var(--wp--preset--color--stroke);
        padding: rem(18 0 18 20);
        @include max(md) {
          padding-left: 0;
        }

        &:last-child {
          @include max(md) {
            border-bottom: 1px solid var(--wp--preset--color--stroke);
          }
        }

        @include min(md) {
          padding: rem(30 0);
        }

        &:has(.menu-toggle) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          > a {
            max-width: calc(100% - rem(36));
          }
        }

        &.is-open {
          > .menu-toggle {
            transform: rotate(180deg);
          }
        }

        &:not(.is-open) {
          .sub-menu {
            max-height: 0;
            padding-top: 0;
            opacity: 0;
            overflow: hidden;
          }
        }

        &.is-active {
          > a {

            &:before {
              display: none;
            }
          }

          &:not(:has(.has-sub-menu)) {
            > a {
              font-weight: 700;
            }
          }
        }

        .menu-toggle {
          flex-shrink: 0;
          width: rem(36);
          font-size: rem(16);
          line-height: rem(24);
          text-align: center;
          cursor: pointer;
          transition: $tr;

          @include hover-responsive {
            font-size: rem(20);
          }
        }

        > a {
          @extend %h6;
          color: var(--wp--preset--color--base);

          > span {
            @include animated-underline(2px, 0);
          }
        }
      }

      .sub-menu {
        @include unstyled-list;
        width: 100%;
        max-height: rem(200);
        display: block;
        padding-top: rem(15);
        padding-left: rem(20);
        transition: 0.4s;

        @include min(md) {
          padding-top: rem(24);
        }

        > li {
          &:not(:last-child) {
            margin-bottom: rem(15);

            @include min(md) {
              margin-bottom: rem(26);
            }
          }

          &.is-active {
            > a {
              color: var(--wp--preset--color--base);
              font-weight: 500;

              &:before {
                display: none;
              }
            }
          }

          > a {
            @extend %copy-md;
            color: var(--wp--preset--color--base);

            > span {
              @include animated-underline;
            }
          }
        }
      }
      li {
        &.is-active {
          > a {
            > span {
              &::before {
                opacity: 1;
                width: 100%;
              }
              &:hover {
                &::before {
                  opacity: 0;
                  width: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &-content {
    flex-grow: 1;
    padding-top: rem(50);

    @include min(1024) {
      padding-top: clamp-rem(30, 80, 782, 1440);
    }

    &__header {
      margin-bottom: clamp-rem(40, 50);

      &:has(.account-message) {
        margin-bottom: rem(40);
      }

      &:has(.btn) {
        margin-bottom: clamp-rem(40, 60);
      }

      .btn {
        margin-top: clamp-rem(16, 34);
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(16);
      color: var(--wp--preset--color--black);
      letter-spacing: 0.05em;
      margin-bottom: clamp-rem(18, 30);
    }

    &__subtitle {
      @extend %copy2;
      color: var(--wp--preset--color--black);
    }

    &:has(.cards-grid-simple) {
      .account-content__header {
        margin-bottom: rem(40);
      }
    }
	.modal__content-inner {
	  max-height: fit-content;
	  .campeasy-user-form {
		width: 22rem;
		margin: auto;
	  }
	}
  }

  &-booking {
    &__section {
      + .account-booking__section {
        margin-top: clamp-rem(50, 80);
      }

      &-title {
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        margin-bottom: rem(50);
        padding-bottom: rem(20);
      }
    }
  }

  &-message {
    @extend %copy2;
    @include border;
    color: var(--wp--preset--color--link);
    padding: rem(14 30);

    .icon {
      font-size: rem(20);
      margin-right: rem(5);
      position: relative;
      top: rem(4);

      &--io-warning {
        color: var(--wp--preset--color--warning);
      }
    }
  }
}

.booking-item {
  &:not(:first-child) {
    border-top: 1px solid var(--wp--preset--color--stroke);
    margin-top: rem(30);
    padding-top: rem(50);
    .dashboard & {
      margin-top: rem(50);
    }
  }

  &__subtitle {
    @extend %copy2;
    margin-bottom: rem(20);
  }

  &__extras {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: rem(20);

  }
}

// sub-page: Booking Details
.account-booking {
  &__order-number {
    margin-bottom: rem(30);

	  > a {
		  color: var(--wp--preset--color--white-static);
	  }
  }

  &__details {
    @include border;
    overflow: hidden;

    &-main {
      padding: rem(30 30 20);
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: rem(16);
      margin-bottom: rem(20);

      .account-booking__details-subtitle {
        margin: 0;
      }
    }

    &-subtitle {
      margin: rem(0 0 20);
    }
  }

  &__dates {
    border-top: 1px solid var(--wp--preset--color--stroke);
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    margin: rem(30 0);
    padding: rem(45 0 30);
    display: grid;
    gap: rem(40 20);

    @include min(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__date {
    @extend %copy-md;
    background-color: var(--wp--preset--color--light-bg);
    border-radius: $radius;
    padding: clamp-rem(32, 40) clamp-rem(24, 30) clamp-rem(24, 30);
    text-align: center;
    position: relative;

    &-label {
      width: 100%;
      max-width: rem(155);
      background-color: #B5A6F1;
      color: $w;
      font-size: rem(14);
      border-radius: rem(14);
      letter-spacing: 0.05em;
      line-height: rem(28);
      text-align: center;
      padding: rem(0 20);
      white-space: nowrap;
      position: absolute;
      top: rem(-14);
      left: 50%;
      transform: translateX(-50%);
    }

    &-title {
      margin: rem(0 0 10);
    }

    &-location,
    &-option {
      font-weight: 500;
      margin-bottom: rem(24);
    }
  }

  &__table {
    @extend %copy-md;
    padding: rem(14 30);

    table {
      width: 100%;
      //max-width: rem(580);  fix from https://tsks.me/projects/826?modal=Task-19980-826
      table-layout: fixed;

      td {
        padding: rem(6 0);
        h4 {
          @include max(md) {
            white-space: nowrap;
          }
        }
        text-align: right;

        &:not(:last-child) {
          padding-right: rem(12);
          text-align: left;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    &--total {
      background-color: #F8F6FF;
      margin-top: rem(10);

      @include theme-dark {
        background-color: var(--wp--preset--color--primary);
      }

      td {
        vertical-align: bottom;
      }

      h4 {
        margin: 0;
      }
    }
  }

  &__add {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: rem(16);
    background-color: $dark-link;
    padding: rem(14 25 14 30);

    @include min(md) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    &-title {
      color: $w;
      font-weight: 700;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__checkbox {
    padding: rem(30);

    label {
      display: inline-flex;
      gap: clamp-rem(8, 12);
    }

    input[type="checkbox"] {
      position: relative;
      top: rem(3);
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: rem(20);
    margin-top: clamp-rem(30, 40);
    padding: rem(0 30);

    @include min(xxl) {
      justify-content: flex-start;
    }
  }

  &__grid {
    &.dashboard {
      .booking-extra {
        background-color: var(--wp--preset--color--light-bg);
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        padding: rem(24);
        padding-bottom: rem(32);

        @include min(md) {
          padding: rem(0 30 35 30);
        }
      }
    }
  }
}

// sub-page: Personal Information
.account-profile {
  .driver-licence-heading {
    display: none;
  }
  .checkin & {
    max-width: rem(725);
    margin-top: rem(60);
    [type="submit"] {
      background-color: var(--wp--preset--color--gray-two);
      &:hover {
        background-color: var(--wp--preset--color--primary);
      }
    }
    h4 {
      margin-top: 0;
      margin-bottom: rem(30);
      &.driver-licence-heading {
        display: block;
      }
    }
  }
  &.loading {
    opacity: .3;
    pointer-events: none;
    cursor: not-allowed;
  }
  .form-row {
    row-gap: 0;
    margin-bottom: 0;
    .form-field {
      &:not(:has(textarea)) {
        margin-bottom: rem(30);
      }
    }
  }
  &__section {
    max-width: rem(650);

    + .account-profile__section {
      margin-top: clamp-rem(40, 60);
    }

    .account-content__header {
      margin-bottom: clamp-rem(20, 30);
    }

    &:has(.account-profile.is-open) {
      .account-profile__toggle {
        display: none;
      }
    }

    &.view-mode {
      .form-row:has(.form-field--consent),
      .form-field--file:has(.form-field--file__name) label,
      .form-field--file label:after,
      .btn {
        display: none !important;
      }

	  .iti__country-container {
		display: none;
	  }

      .form-field__birthday-wrapper {
        @include min(md) {
          justify-content: flex-end;
        }
      }

      .form-field__birthday {
        pointer-events: none;
        input {
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(24);
          letter-spacing: -0.01em;
          color: var(--wp--preset--color--gray-one);
          @include min(md) {
            max-width: rem(100);
            text-align: right;
            justify-content: flex-end;
          }
        }
      }
    }

    &:not(.view-mode) {
      .js-account-edit {
        display: none;
      }
    }
  }

  &__edit {
    @extend %h5;
    color: var(--wp--preset--color--link);
    cursor: pointer;
    @include max(md) {
      min-width: 12%;
    }
  }

  &__toggle {
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .form-field {
    &:has(input[type="checkbox"]) {
      grid-column: span 2;
    }

    &:has(textarea) {
      grid-column: span 2;

      .view-mode & {
        display: block;
      }
    }

    .view-mode & {
      display: flex;
      @include max(md) {
        flex-wrap: wrap;
      }
    }

    label {
      .view-mode & {
        font-size: rem(16);
        gap: 0;
        margin: 0;
        @include max(md) {
          display: block;
          width: 100%;
        }

        &:after {
          content: ':';
          margin-right: rem(5);
        }
      }
    }

    .view-mode & {
      &.form-field--photo {
        label {
          @include max(md) {
            width: auto;
          }
        }
      }
      textarea {
        &:empty {
          display: none;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
	select,
    textarea {
      .view-mode & {
        height: auto;
        border: none;
        padding: 0 !important;
        pointer-events: none;
        background: none;
      }
    }

    input[type="number"] {
      .view-mode & {
        -moz-appearance: textfield;
      }

      .view-mode &::-webkit-inner-spin-button,
      .view-mode &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    textarea {
      min-height: rem(190) !important;

      .view-mode & {
        display: none;
      }
    }

    .about-me-viewmode {
      display: none;
      margin: 0;
      .view-mode & {
        display: block;
      }
    }

    &--photo {
      label {
        width: rem(100);
        height: rem(100);
        border-radius: 50%;
        cursor: pointer;
        line-height: 0;
        margin: 0;
        overflow: hidden;
        position: relative;

        .view-mode & {
          pointer-events: none;
        }

        @include hover-responsive {
          .icon--io-edit {
            transform: scale(1.2);
          }
        }

        input[type="file"] {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba($b, 0.5);
          position: absolute;
          top: 0;
          left: 0;

          .view-mode & {
            display: none;
          }
        }

        .icon--io-profile-user {
          font-size: rem(100);
          transform: scale(1.2);
        }

        .icon--io-edit {
          color: $w;
          font-size: rem(18);
          position: absolute;
          top: calc(50% - rem(9));
          left: calc(50% - rem(9));
          z-index: 2;
          transition: transform $tr;

          .view-mode & {
            display: none;
          }
        }
      }
    }

    &--file {
      grid-column: span 2;

      .checkin & {
        grid-column: auto;
      }

      &__name {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr 1fr;
        gap: rem(10);
        font-size: rem(16);
        margin-bottom: rem(24);
        @include max(md) {
          display: flex;
          flex-wrap: wrap;
        }
        &.changed {
          i, .form-field--file__date, .format {
            display: none;
          }
          a {
            pointer-events: none;
          }
          .form-field--file__name-value {
            text-decoration: none;
          }
        }

        a {
          color: var(--wp--preset--color--base);
        }

        // .view-mode & {
        //   margin-bottom: 0;
        // }

        .icon {
          color: var(--wp--preset--color--gray-two);
          font-size: rem(24);

          .view-mode & {
            font-size: rem(32);
          }
        }

        &-value {
          text-decoration: underline;
        }
      }

      &__remove {
        cursor: pointer;

        @include hover-responsive {
          .icon {
            color: var(--wp--preset--color--error);
          }
        }
      }

      .btn {
        flex-shrink: 0;
      }

      label {
        .view-mode & {
          align-items: center;
          gap: rem(10);
        }
      }

      input[type="file"] {
        display: none;
      }

      &__date {
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(24);
        letter-spacing: -0.01em;
        color: var(--wp--preset--color--gray-one);
        @include min(md) {
          text-align: right;
        }
        @include max(md) {
          display: block;
          width: 100%;
          margin-top: rem(15);
        }
      }
    }

    &--consent {
      label {
        align-items: flex-start;
      }
    }
  }
}

#account-password {
  display: none;

  .checkin & {
    display: block;
  }
}

.account-widget {
  display: flex;
  flex-direction: column;
  gap: rem(30);
  border-top: 1px solid var(--wp--preset--color--stroke);

  &__item {
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(15);

    @include hover-responsive {
      img {
        opacity: 0.85;
      }
    }

    &-image {
      display: block;
      line-height: 0;
      border-radius: $radius;
      margin-bottom: rem(16);
      overflow: hidden;

      img {
        aspect-ratio: 269/197;
        object-fit: cover;
        transition: $tr;
      }
    }

    &-excerpt {
      @extend %copy-md;
      margin-bottom: rem(16);
    }
  }
}

// sub-page: Favorites
.account-favorites-nav {
  @include unstyled-list;
  overflow-x: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--wp--preset--color--stroke);
  margin-bottom: clamp-rem(30, 50);
  padding-bottom: rem(30);

  @include max(lg) {
    gap: rem(16 30);
  }

  li {
    flex-shrink: 0;
    padding: rem(0 20);
    white-space: nowrap;

    @include max(lg) {
      height: auto;
      border: none;
      border-radius: 0;
      padding: 0;
    }

    &.is-active {
      pointer-events: none;
      color: var(--wp--preset--color--link);
    }

    &:not(.is-active) {
      border: none;
    }

    &:hover {
      border-color: transparent;
      background-color: transparent;
      color: var(--wp--preset--color--link);

      &:before {
        display: none;
      }
    }
  }
}

.form-field__birthday {
  position: relative;
  input[type="date"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }
}

.form-field {
  .iti {
	  display : inline-flex;
    width: 100%;
    &__country {
      background-color: var(--wp--preset--color--white);
      &.iti__highlight {
        background-color: var(--wp--preset--color--white);
      }
    }
	}
}

.iti__search-input {
	border-radius: 0;
}

[data-value="about_me"] {
  display: none;
  margin: 0;
  .view-mode & {
    display: block;
  }
}

.tapfiliatebox {
	position: relative;
	margin: rem(16) 0;
	&__btn {
		position: absolute;
		right: rem(5);
		top: rem(5);
		border: 0;
		height: rem(35);
	}
}
