.rating {
  display: inline-flex;
  align-items: center;
  gap: rem(8);
  cursor: pointer;

  .stars {
    flex-shrink: 0;
  }

  &__reviews {
    font-size: rem(12);
    line-height: 1rem;
    position: relative;
    bottom: -1px;

    &.has-reviews {
      @include animated-underline;
      color: var(--wp--preset--color--link);
    }
  }
}
