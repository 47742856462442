.itm-extras {
	padding-top: rem(24);

	.campers-filters {
		margin-bottom: rem(48);

		&__inner {
			@include max(md) {
				border-bottom: 1px solid var(--wp--preset--color--stroke);
				margin-bottom: rem(45);
				padding-bottom: rem(20);
			}

			@include min(md) {
				@include max(lg) {
					grid-template-columns: auto 1fr auto auto;
				}
			}

			@include min(lg) {
				grid-template-columns: 1fr 3fr 1fr;
			}
		}

		&__features-group {
			@include max(md) {
				gap: 0;
			}
		}

		&__sorting {
			@include min(md) {
				grid-column: 4/5;
			}

			@include min(lg) {
				height: rem(65);
			}

			.custom-select {
				@include min(lg) {
					top: rem(10);
				}
			}
		}
	}

	&__group {
		&:not(:last-child) {
			margin-bottom: var(--wp--preset--spacing--xl);
		}

		&-heading {
			max-width: rem(850);
			text-align: center;
			margin: 0 auto rem(50);
		}

		&-subtitle {
			@extend %copy2;
			color: var(--wp--preset--color--gray-one);
		}

		&-items {
			display: grid;
			grid-gap: rem(40 20);

			@include min(xs) {
				grid-template-columns: repeat(auto-fill, minmax(rem(350), 1fr));
			}
		}
	}
}
