// Mixins for Media Queries
// example usage: @include min(lg) || @include min(720)
// --- min-width
@mixin min($bp) {
  @if map-has-key($grid-breakpoints, $bp) {
    @media only screen and (min-width: map-get($grid-breakpoints, $bp)) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$bp + 'px'}) {
      @content;
    }
  }
}

// --- max-width
@mixin max($bp) {
  @if map-has-key($grid-breakpoints, $bp) {
    $bp_max: map-get($grid-breakpoints, $bp) - 1;
    @media only screen and (max-width: $bp_max) {
      @content;
    }
  } @else {
    @media only screen and (max-width: #{$bp + 'px'}) {
      @content;
    }
  }
}

@mixin min-max($bpMin, $bpMax) {
  $bp_max: map-get($grid-breakpoints, $bpMax) - 1;
  @media only screen and (min-width: map-get($grid-breakpoints, $bpMin)) and (max-width: $bp_max) {
    @content;
  }
}

// --- min-width (container query)
// parent element should have at least: container-type: inline-size;
// or parent element can be a named container: container: container-name / inline-size;
// example usage:  @include container-min(lg) || @include container-min(lg, container-name)
@mixin container-min($bp, $container-name: '') {
  @if map-has-key($grid-breakpoints, $bp) {
    @container #{$container-name} (min-width: #{map-get($grid-breakpoints, $bp)}) {
      @content;
    }
  } @else {
    @container #{$container-name} (min-width: #{$bp + 'px'}) {
      @content;
    }
  }
}

// --- max-width (container query)
@mixin container-max($bp, $container-name: '') {
  @if map-has-key($grid-breakpoints, $bp) {
    $bp_max: map-get($grid-breakpoints, $bp) - 1;
    @container #{$container-name} (max-width: #{$bp_max}) {
      @content;
    }
  } @else {
    @container #{$container-name} (max-width: #{$bp + 'px'}) {
      @content;
    }
  }
}

@mixin nonselect {
  user-select: none;
  -webkit-user-select: none;
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin animated-underline($height: 1px, $position: 1px, $color: currentColor) {
  display: inline-block;
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: $position;
    left: 0;
    width: 0;
    opacity: 0;
    height: $height;
    background: $color;
    transition: width $tr, opacity .1s linear .2s;
  }

  &:hover {
    text-decoration: none !important;

    &:before {
      width: 100%;
      opacity: 1;
      transition: width $tr, opacity .1s linear;
    }
  }
}

@mixin animated-underline-with-no-hover($height: 1px, $position: 1px, $color: currentColor) {
  display: inline-block;
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: $position;
    left: 0;
    width: 0;
    opacity: 0;
    height: $height;
    background: $color;
    transition: width $tr, opacity .1s linear .2s;
  }
}

@mixin ie-fix {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin moz-fix {
  @supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
    @content;
  }
}

@mixin safari-fix {
  @supports (-webkit-hyphens: none) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin transition( $option: all, $duration: 0.3s ) {
  transition: $option $duration;
}

// Clear default styling for list elements
@mixin unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin unstyled-button {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
}

// All headings
@mixin headings {
  h1, h2, h3, h4, h5, h6,
  .content-type-h1,
  .content-type-h2,
  .content-type-h3,
  .content-type-h4,
  .content-type-h5,
  .content-type-h6 {
    @content;
  }
}

// Custom scrollbar styling
@mixin scrollbar($width: 6px, $color-border: var(--wp--preset--color--primary), $color-track: var(--wp--preset--color--stroke)) {

  @include min(md) {

    &::-webkit-scrollbar {
      width: $width;
    }
    &::-webkit-scrollbar-track {
      background: $color-track;
      border-radius: calc($width / 2);
    }
    &::-webkit-scrollbar-thumb {
      width: $width;
      height: 60px;
      border-radius: calc($width / 2);
      background: $color-border;
    }
  }
}

@mixin scrollbar-x($width: 6px, $color-border: var(--wp--preset--color--primary), $color-track: var(--wp--preset--color--stroke)) {

  @include min(md) {
    &::-webkit-scrollbar:horizontal {
      background: $color-border;
      height: $width;
    }
    &::-webkit-scrollbar-track:horizontal {
      background: $color-track;
      border-radius: calc($width / 2);
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background: $color-border;
      border-radius: calc($width / 2);
    }
  }
}

// Button hover
@mixin hover($color: rgba($primary-darker,.5)) {
  @include min(xl) {
    position: relative;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: var(--x);
      top: var(--y);
      width: calc(var(--wp--preset--font-size--medium) * 8);
      height: calc(var(--wp--preset--font-size--medium) * 8);
      background: radial-gradient(circle closest-side, $color, transparent);
      transform: translate(-50%, -50%) scale(0);
      will-change: transform;
      transition: transform .2s ease;
      display: block;
    }

    &:hover {

      &:before {
        transform: translate(-50%, -50%) scale(1);
      }

      [tabindex] & {

        &:before {
          transform: translate(-50%, -50%) scale(0);
        }
      }
    }

    span {
      position: relative;
      z-index: 1;
      pointer-events: none !important;
    }
  }
}

// Responsive hover
@mixin hover-responsive($bp: lg) {
  @include min($bp) {
    &:hover {
      @content
    }
  }
}

// Default border
@mixin border($border-radius: $radius,  $color: var(--wp--preset--color--stroke)) {
  border: 1px solid $color;
  border-radius: $border-radius;
}

// Limit text to N lines
@mixin limit-text($num: 4) { // number of lines to show
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  line-clamp: $num;
  -webkit-box-orient: vertical;
}

// Dark theme
@mixin theme-dark {
  .dark & {
    @content;
  }
}
