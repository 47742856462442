.info-posts {

    &__title {
        margin: 0 0 rem(30) !important;
    }

    &__grid {
        display: grid;
        grid-auto-rows: rem(16);
        grid-template-columns: 1fr 1fr;
        gap: rem(20);
        margin: 0 0 rem(80);

        @include max(md) {
            grid-template-columns: 1fr;
        }
    }
}

.info-post {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: rem(10);
    padding: rem(20);
    position: relative;
    overflow: hidden;

    &:nth-child(odd) {
        grid-row: span 11;
    }

    &:nth-child(even) {
        grid-row: span 9;
    }

    &:hover {

        .info-post {

            &__cover {
                transform: scale(1.2);
                transform-origin: bottom;
            }

            &__subtitle {
                height: rem(96);
                -webkit-line-clamp: 4;
            }
        }
    }

    &__cover {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 70%);
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s;
    }

    &__title {
        font-size: rem(22);
        color: var(--wp--preset--color--white-static);
        font-weight: 500;
        line-height: rem(30);
        margin: 0 0 rem(10);
        position: relative;
        z-index: 1;
    }

    &__subtitle {
        height: rem(48);
        font-size: rem(14);
        color: var(--wp--preset--color--white-static);
        line-height: rem(24);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transition: 0.3s;
    }

    .wp-post-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.info-post-container {

    @include max(md) {
        gap: rem(80);
    }
}