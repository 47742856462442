.itm-insurance-additional{

  .checkout-title {
    text-align: center;
  }

  .extra-coverage__items {
    display: flex;
    justify-content: center;

    .extra-coverage {
      width: 33.3%;
    }

    @include max(xl) {
      .extra-coverage {
        width: 50%;
      }
    }

    @include max(md) {
      flex-wrap: wrap;

      .extra-coverage {
        width: 100%;
      }
    }
  }
}

.extra-coverage__items {
  display: grid;
  grid-gap: rem(20);
  grid-template-columns: 1fr;

  @include min(md) {
    grid-template-columns: repeat(auto-fill, minmax(rem(470), 1fr));
  }
}

.extra-coverage {
  @include border;
  background-color: var(--wp--preset--color--white);
  padding: rem(20 16);

  @include min(lg) {
    padding: rem(30);
  }

  &__header {
    margin-bottom: rem(10);

    @include min(md) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: rem(0 12);
      margin-bottom: rem(17);
    }
  }

  &__title {
    letter-spacing: 0.02em;
    margin: rem(0 0 4);
  }

  &__subtitle {
    @extend %copy-md;
    color: var(--wp--preset--color--gray-one);

    @include max(md) {
      margin-bottom: rem(10);
    }
  }

  &__price {
    @include max(md) {
      display: flex;
      align-items: baseline;
      gap: rem(5);
    }

    span {
      @extend .h5;
      color: var(--wp--preset--color--link);
      display: block;
      line-height: 1.2;
    }

    small {
      @extend %copy-sm;
      color: var(--wp--preset--color--gray-one);

      @include max(md) {
        font-size: rem(14);
        letter-spacing: 0.05em;
      }
    }
  }

  .insurance__status {
    margin-bottom: rem(24);
    display: inline-block;
  }

  &__text {
    @extend %copy-md;

    ul {
      @include unstyled-list;
      display: flex;
      flex-direction: column;
      gap: rem(6);

      @include min(lg) {
        gap: rem(16);
      }

      li {
        padding-left: rem(34);
        min-height: rem(24);
        position: relative;

        &:before {
          @extend .icon;
          content: $icon--io-check;
          font-size: rem(20);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__disclaimer {
    @extend %copy-md;
    @include border;
    font-weight: 500;
    margin-top: rem(16);
    padding: rem(20);
    box-shadow: rem(0 0 10) rgba($b, 0.05);

    @include min(lg) {
      margin-top: rem(30);
    }
  }
}
