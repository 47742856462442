// Author archive
.author {
  main {
    overflow: visible;
  }
  &-container {
    margin-top: var(--wp--preset--spacing--xxl);
    margin-bottom: var(--wp--preset--spacing--xxl);
  }

  &-sidebar {
    @include min(lg) {
      padding-right: clamp-rem(50, 100, 1200, 1440);
    }
  }

  &-toc {
    @include min(lg) {
      position: sticky;
      top: var(--header-height);
    }

    &__title {
      font-size: clamp(1rem, calc(1rem + (1vw - 6.4px) * 0.25), 1.125rem);
      color: var(--wp--preset--color--base);
      font-weight: 500;
      border-bottom: 1px solid var(--wp--preset--color--stroke);
      padding-bottom: 0.75rem;
      margin-bottom: 1.5rem;
    }
  }

  &-date-first {
    @extend %copy2;
    color: var(--wp--preset--color--gray-one);
    line-height: rem(24);
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding-bottom: rem(14);
    text-align: right;
  }

  &-section {
    &:not(:last-child) {
      margin-bottom: clamp-rem(32, 40);
    }

    &__title {
      margin-bottom: rem(20);
    }

    &__text {
      color: var(--wp--preset--color--gray-one);
    }
  }

  &-bio {
    @extend %copy2;
    margin-bottom: rem(40);
  }

  .post-related {
    background-color: var(--wp--preset--color--light-bg);
    padding-top: var(--wp--preset--spacing--xxl);
    padding-bottom: var(--wp--preset--spacing--xxl);

    + .itm-cta-hover {
      margin-top: 0;
    }
  }
}
