.itm-ratings-global {
  h2 {
    max-width: unset;
  }

  .global-ratings__items {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }

  .global-ratings__item {
    color: var(--wp--preset--color--gray-one) !important;
  }
}
