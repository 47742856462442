.gallery-grid {
  display: grid;
  grid-gap: rem(16);
  grid-template-columns: repeat(2, 1fr);

  @include min(md) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: clamp-rem(16, 20, 1200, 1440);
  }

  &__item {
    border-radius: rem(4);
    line-height: 0;
    overflow: hidden;
    position: relative;

    @include hover-responsive {
      img {
        opacity: 0.85;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 164 / 200;
      border-radius: rem(4);
      object-fit: cover;
      transition: opacity $tr;

      @include min(md) {
        aspect-ratio: 142 / 110;
      }
    }

    @include max(md) {
      &:nth-child(3) {
        grid-column: span 2;

        img {
          aspect-ratio: 343/144;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        img {
          aspect-ratio: 164/110;
        }
      }
    }

    @include min(md) {
      &:nth-child(1),
      &:nth-child(8) {
        grid-column: span 2;
        grid-row: span 2;

        img {
          aspect-ratio: 305 / 273;
        }
      }

      &:nth-child(2),
      &:nth-child(9) {
        grid-column: span 2;

        img {
          aspect-ratio: 305 / 144;
        }
      }

      &:nth-child(5) {
        grid-column: span 2;

        img {
          aspect-ratio: 305 / 200;
        }
      }

      &:nth-child(6),
      &:nth-child(7) {
        img {
          aspect-ratio: 142 / 200;
        }
      }
    }
  }

  [data-more],
  [data-more-mobile] {
    position: relative;

    &:after {
      width: 100%;
      height: 100%;
      background-color: rgba($b, 0.5);
      color: $color-white;
      font-size: rem(16);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  [data-more][data-more-mobile] {
    &:after {
      content: attr(data-more-mobile);

      @include min(md) {
        content: attr(data-more);
      }
    }
  }

  [data-more]:not([data-more-mobile]) {
    &:after {
      content: attr(data-more);

      @include max(md) {
        display: none;
      }
    }
  }

  [data-more-mobile]:not([data-more]) {
    &:after {
      content: attr(data-more-mobile);

      @include min(md) {
        display: none;
      }
    }
  }
}

.mobile-event-gallery {
  @include max(lg) {
    margin-bottom: rem(36);
  }
}