// Grid breakpoints -------------------------------------------
// for min-width = values, for max-width = value - 1px.
// example: @include min(lg) = @media (min-width: 1025px)
// example: @include max(lg) = @media (max-width: 1024px)
$grid-breakpoints: (
  xxs: 0,
  xs: 480px,
  md: 782px,
  lg: 1025px,
  xl: 1440px,
  xxl: 1920px,
  xxxl: 2560px
);

$radius: 0.5625rem; // border-radius (9px)
$radius-lg: 1.4475rem; // border-radius for form inputs (23px)
$tr: .3s;
$header-tr: .2s linear;

// Icons
$icon--io-check: "\e012";
$icon--io-chevron-down: "\e016";
$icon--io-chevron-right: "\e018";
$icon--io-close: "\e01e";
$icon--io-warning-mark: "\e076";
$icon--io-warning: "\e077";
$icon-arrow-next: "\e003";
