// Tooltip
.tooltip {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:not(:has(.icon-info)) {
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-bottom: 1px dashed;
    }
  }

  &:hover {
    .tooltip__content {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__content {
    display: block;
    width: rem(250);
    padding-bottom: rem(10);
    cursor: initial;
    position: absolute;
    left: calc(50% - rem(90));
    bottom: 100%;
    z-index: 120;
    transition: opacity $tr, transform $tr;
    opacity: 0;
    transform: translateY(rem(7));
    visibility: hidden;

    &-inner {
      display: block;
      width: 100%;
      background-color: var(--wp--preset--color--gray-one);
      color: var(--wp--preset--color--campeasy-white);
      font-size: rem(12);
      border-radius: rem(5);
      padding: rem(26 24 26 16);
      position: relative;
      white-space: normal;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem(6 4 0 4);
        border-color: var(--wp--preset--color--gray-one) transparent transparent transparent;
        position: absolute;
        top: 100%;
        left: rem(18);
      }

      strong {
        display: block;
        font-size: rem(16);
        font-weight: 500;
        margin-bottom: rem(10);
      }

      a {
        color: var(--wp--preset--color--campeasy-white) !important;
        text-decoration: underline;
      }

      p {
        margin-top: 0;
        margin-bottom: rem(5);
      }

      *:last-child {
        margin-bottom: 0;
      }

      .tooltip {
        display: initial;
        border: none;
        cursor: initial;
        line-height: inherit;

        &:after,
        &__content {
          display: none;
        }
      }
    }
  }

  &__more {
    display: block;
    font-weight: 600;
    text-align: right;
    margin-top: rem(5);
    transition: text-shadow $tr;

    &:hover {
      text-shadow: -0.015em 0 0.015em var(--wp--preset--color--campeasy-white), 0.015em 0 0.015em var(--wp--preset--color--campeasy-white);
    }
  }

  &.to-right {
    .tooltip__content {
      left: auto;
      right: rem(-10);

      &-inner:after {
        left: auto;
        right: rem(25);
      }
    }
  }

  &.to-left {
    .tooltip__content {
      left: rem(-10);

      &-inner:after {
        left: rem(25);
      }
    }
  }

  &.to-bottom {
    .tooltip__content {
      padding: rem(10 0 0);
      bottom: auto;
      top: 100%;

      &-inner {
        &:after {
          border-width: rem(0 4 6 4);
          border-color: transparent transparent var(--wp--preset--color--gray-one) transparent;
          top: auto;
          bottom: 100%;
          left: calc(50% - #{rem(4)});
        }
      }
    }
  }

  &:has(.icon-info:first-child) { // simplify styles, when it's only a single icon
    gap: rem(6);

    .tooltip__content {
      left: 50%;
      transform: translateX(-50%);

      &-inner {
        &:after {
          left: calc(50% - #{rem(4)});
        }
      }
    }
  }
}
