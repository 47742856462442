.archive-wrapper {
  margin-top: var(--wp--preset--spacing--xxl) !important;
  margin-bottom: var(--wp--preset--spacing--xxl) !important;
}

.tags-nav,
.archive-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: rem(16);
  margin-bottom: rem(30);

  @include min(md) {
    flex-wrap: nowrap;
    gap: rem(20);
  }

  &__select {
    flex-basis: calc(50% - rem(8));
    justify-content: flex-end;
    border-radius: rem(23) !important;
    box-shadow: rem(0 0 10 0) rgba($b, 0.1) !important;
    text-overflow: ellipsis;

    @include min(md) {
      flex-basis: calc(33.33% - rem(14));
    }

    @include min(lg) {
      flex: 0 0 rem(225);
    }

    @include min(xxl) {
      flex-basis: rem(275);
    }
  }

  .search-form {
    flex-basis: 100%;

    @include max(md) {
      order: -2;
    }

    @include min(md) {
      flex-basis: calc(33.33% - rem(14));
    }

    @include min(lg) {
      flex: 0 0 rem(225);
    }

    @include min(xxl) {
      flex-basis: rem(275);
    }

    &__input {
      border-radius: rem(23) !important;
      margin-left: auto;

      @include min(lg) {
        width: rem(125) !important;
      }

      &:focus {
        width: 100% !important;
      }
    }
  }
}

.categories-nav {
  flex-basis: calc(50% - rem(8));

  @include max(md) {
    order: -1;
  }

  @include min(md) {
    flex-basis: calc(33.33% - rem(14));
  }

  @include min(lg) {
    flex-basis: auto;
    flex-grow: 1;
  }

  ul {
    @include unstyled-list;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    @extend %subtitle;
    text-transform: uppercase;

    &:not(:last-child) {
      &:after {
        content: '|';
        margin: 0 rem(5);
      }
    }
  }

  &__select {
    border-radius: rem(23) !important;
    box-shadow: rem(0 0 10 0) rgba($b, 0.1) !important;
    text-overflow: ellipsis;
  }

  &__link {
    display: inline-block;
    color: inherit;
    position: relative;
    padding: 0 2px;

    @include hover-responsive {
      i {
        height: 100%;
      }
    }

    &.is-active {
      pointer-events: none;

      i {
        height: 100%;
      }
    }

    i {
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: height 0.2s ease-in-out;
    }

    span {
      position: relative;
    }
  }
}

.posts-grid {
  display: grid;
  grid-gap: rem(40 20);
  min-height: rem(500);

  @include min(700) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include min(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include min(2000) {
    grid-template-columns: repeat(4, 1fr);
  }
}
