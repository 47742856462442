// Core WordPress components:
//
// # Body
// # Adminbar
//

// Adminbar -------------------------------------------
#wpadminbar {
	--wp-blue: #2271b1;
	transition: transform 0.3s !important;
	transform: translateY(-100%);

	&:hover {
		#wp-admin-bar-site-name {
			background-color: transparent !important;
		}
	}

	&:not(.is-expanded) {
		&:hover {
			transform: translateY(0);

			#wp-admin-bar-site-name {
				transform: translateY(0);
			}
		}
	}

	&.is-expanded {
		transform: translateY(0);

		#wp-admin-bar-site-name {
			transform: translateY(0);

			&:before {
				background-color: var(--wp-blue);
			}

			&:after {
				transform: rotate(-45deg);
				font-size: 20px !important;
			}
		}
	}

	.ab-top-menu>.menupop>.ab-sub-wrapper {
		top: 100%;
	}

	.ab-top-secondary {
		 @include max(md) {
			position: absolute;
			top: 0;
			right: 0;

			>.menupop>.ab-sub-wrapper {
				right: 0;
				left: auto;
			}
		}
	}

	#wp-admin-bar-root-default {
    @include max(640) {
			display: flex;

			> li {
				flex-shrink: 0;
			}
		}
	}

	// hide some menu items to prevent horizontal overflow
	ul#wp-admin-bar-root-default>li {
		&#wp-admin-bar-wpseo-menu,
		&#wp-admin-bar-gform-forms {
			 @include max(md) {
				display: none !important;
			}
		}
	}
}

#wp-admin-bar-site-name {
	display: inline-flex !important;
	background-color: #000000 !important;
	position: relative;
	transform: translateY(46px);
	transition: transform 0.3s !important;

	 @include min(md) {
		transform: translateY(32px);
	}

	&:before,
	&:after {
		width: 46px;
		height: 46px;

		 @include min(md) {
			width: 32px;
			height: 32px;
		}
	}

	&:before {
		content: '' !important;
		display: block;
		background-color: #000000;
		position: absolute;
		top: 0;
		right: 0;
		transition: background-color 0.2s !important;
	}

	&:after {
		content: "\f109" !important;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-family: dashicons;
		font-size: 18px;
		transition: all 0.2s !important;
		cursor: pointer;
		position: relative;
	}

	&:hover {
		&:before {
			background-color: var(--wp-blue);
		}

		&:after {
			font-size: 22px;
		}
	}
}

// Toolbox -------------------------------------------
#wu-toolbox {
	position: relative;
	z-index: 9;

	 @include max(md) {
		display: none;
	}
}

.wp-core-ui {
	.button {
		&.button-large {
			width: 100%;
			height: rem(45);
			font-size: rem(14);
			font-weight: 500;
			line-height: rem(17);
			letter-spacing: 0.05em;
			text-align: center;
			margin-top: rem(24);
		}
	}
}