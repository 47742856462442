.cart-empty-checkout {
    header {
        &::before {
            background-color: transparent !important;
        }
        .site-logo {
            svg {
                transform: scale(1) !important;
                top: 0 !important;
            }
        }
        * {
            &:not(.wp-block-button__link):not(.wp-block-button__link span)  {
                color: $color-base !important;
                fill: $color-base !important;
			  a {
				color: var(--wp--preset--color--base) !important;
			  }
            }
        }
    }
    .cart-empty {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-image: url(../../assets/img/checkout/cart-empty.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        h2, .h2 {
            margin-bottom: rem(30);
            color: $color-base !important;
        }
    }
    footer {
        display: none;
    }
}
