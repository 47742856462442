.not-found {
    width: 100%;
    height: 100dvh;
    background-image: url(../../assets/img/sea-texture.jpg);
    background-repeat: no-repeat;
    background-position: top 50% right 0;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;

    @media (orientation: portrait) {
        align-items: flex-start;
        padding-top: rem(100);
        padding-bottom: rem(80);
    }
    .container {
        display: flex;
        flex-direction: column;

        @media (orientation: portrait) {
            align-items: center;
        }
    }

    h1 {
        max-width: rem(440);
        font-size: rem(42);
        color: var(--wp--preset--color--white-static);
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.05em;
        margin: 0 0 rem(30);

        @media (min-width: 2048px) {
            max-width: rem(580);
            font-size: rem(64);
        }

        @media (orientation: portrait) {
            max-width: rem(360);
            font-size: rem(32);
            text-align: center;
        }

        @media (orientation: landscape) {
            max-width: 40vw;
            font-size: 4vw;
        }
    }

    &__pier {
        width: 70%;
        height: 100vh;
        object-fit: cover;
        object-position: left 50%;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        transform: translateY(-50%);
        pointer-events: none;

        @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 3.2/2) {
            object-fit: contain;
        }

        @media (orientation: portrait) {
            width: calc(100dvh - 17.5rem);
            max-width: 100%;
            height: 100vw;
            object-fit: cover;
            object-position: 0% 100%;
            transform: rotate(90deg) translate(-100%, 0%);
            transform-origin: left bottom;
            top: auto;
            left: 0;
            bottom: 0;
            z-index: 2;

            @include max(md) {
                width: calc(100dvh - 20rem);
            }
        }
    }

    &__error {
        font-size: 13.2vw;
        color: var(--wp--preset--color--white-static);
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.05em;
        white-space: nowrap;
        position: absolute;
        top: 48vh;
        right: min(3vw, 40px);
        transform: translateY(-50%);
        z-index: 1;

        @media (orientation: portrait) {
            width: 100%;
            font-size: 12vh;
            text-align: center;
            left: 50%;
            right: auto;
            top: rem(300);
            bottom: auto;
            transform: translateX(-50%);
        }
    }

    .wp-block-button {
        position: relative;
        z-index: 3;
    }

    .wp-block-button__link {
        font-weight: 500;
        text-transform: uppercase;
    }
}

.error404 {

    section.exclusives {
        display: none !important;
    }

    footer {
        display: none !important;
    }
}
