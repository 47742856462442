.single-info-post,
.single-post {

  .itm-intro .yoast-breadcrumbs {
    padding-right: 0;
  }

  .post-author {
    padding-bottom: rem(50);

    @include max(md) {
      padding-top: rem(20);
      padding-bottom: rem(32);
    }
  }

  .itm-cta-hover {
    margin-top: 0;

    + .exclusives {
      margin-top: 0;
    }
  }
}

.post {
  &-container {
    color: var(--wp--preset--color--gray-one);
    font-size: rem(16);
    margin: 0;
    padding-top: var(--wp--preset--spacing--xxl);
    padding-bottom: var(--wp--preset--spacing--xxl);

    @include headings {
      &:not(:first-child) {
        margin-block-start: clamp-rem(32, 40);
      }

      &:not(:last-child) {
        margin-block-end: rem(20);
      }
    }
  }

  &-sidebar {
    @include min(lg) {
      padding-right: clamp-rem(50, 100, 1200, 1440);
    }

    .wp-block-single-navigation {

      @include min(lg) {
        position: sticky;
        top: var(--header-height);
        margin: 0;
      }
    }
  }

  &-content {

    .content-type-p2 {
      color: var(--wp--preset--color--base);
    }

    .wp-block-gallery {

      &.has-nested-images {
        margin: clamp-rem(32, 40) 0;

        @include max(480) {
          gap: rem(20) !important;
        }

        figure.wp-block-image {

          @include max(480) {
            width: 100% !important;
          }

          img {
            aspect-ratio: 414 / 300;
            object-fit: cover;
          }

          figcaption {
            @extend %figcaption;
          }
        }
      }
    }

    .wp-block-image {
      line-height: 0;

      img {
        width: 100%;
      }

      figcaption {
        @extend %figcaption;
      }
    }

    a:where(:not(.wp-element-button)) {
      @include animated-underline();
    }
  }

  &-toc {
    @include min(lg) {
      position: sticky;
      top: rem(20);
    }
  }

  &-meta {
    @extend %copy2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(20);
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding-bottom: rem(14);
    margin-bottom: clamp-rem(16, 50);

    a {
      color: inherit;

      @include hover-responsive {
        color: var(--wp--preset--color--base);
      }
    }
  }

  &-related {
    background-color: var(--wp--preset--color--light-bg);
    padding-top: var(--wp--preset--spacing--xxl);
    padding-bottom: var(--wp--preset--spacing--xxl);

    &__heading {
      max-width: rem(850);
      margin: 0 auto rem(50);
      text-align: center;
    }

    &__title {
      margin-bottom: clamp-rem(8, 20);
    }

    &__subtitle {
      @extend %copy2;
      color: var(--wp--preset--color--gray-one);
    }

    &__grid {
      display: grid;
      gap: rem(32);

      @include min(md) {
        gap: rem(20);
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .wp-block-buttons {
      gap: rem(16);
      margin-top: clamp-rem(32, 50);
    }
  }
}
