.site-header {
  height: var(--header-height);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: linear-gradient(to bottom, rgba(0, 0, 0, .3), transparent 70%);
  backdrop-filter: blur(2px);
  transition: height $header-tr, padding $header-tr;

  body:has(.countdown) & {
    top: var(--countdown-height);
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--wp--preset--color--white);
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    transition: opacity $header-tr, border-bottom-color $header-tr;
  }

  &:not(.alternative-view) {
    &::before {
      border-bottom: 1px solid var(--wp--preset--color--stroke);
    }
  }

  .locked:not(.megamenu-is-open) & {
    z-index: 0;
  }

  .locked:not(.megamenu-is-open) .checkout-steps-nav {
    z-index:0
  }

  > .container {
    display: flex;
    max-width: 100vw;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    gap: 1.875rem;
  }

  .site-logo {
    display: inline-block;
    align-self: stretch;
    position: relative;
    flex-shrink: 0;

    svg {
      top: 0;
      position: absolute;
      transform-origin: left top;
      transition: transform $header-tr .05s, top $header-tr .05s;

      path {
        transition: fill $header-tr;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: rem(30);
    justify-content: flex-end;

    .currency-switcher {
      cursor: pointer;
      flex-shrink: 0;
      position: relative;

      input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
      }

      &__active {
        color: var(--wp--preset--color--white-static);
        transition: color $header-tr;
      }

      &__list {
        @include unstyled-list;
        max-height: 12.75rem;
        background: var(--wp--preset--color--white);
        border-radius: 0.625rem;
        padding: 0.5rem 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: calc(100% + 0.625rem);
        left: 50%;
        z-index: 3;
        opacity: 0;
        width: max-content;
        transform: translateX(-50%);
        pointer-events: none;

        li {
          font-size: 0.875rem;

          &:hover {
            background: var(--wp--preset--color--light-bg);
          }

          label {
            width: 100%;
            padding: 0.25rem 1rem;
            display: inline-block;
          }
        }

        &.active {
          pointer-events: auto;
          animation: fadeInCenter 0.3s forwards;
        }
      }
    }

    .favorites-link {
      flex-shrink: 0;
      position: relative;

      svg {
        width: rem(32);
        height: rem(28);
      }

      &__count {
        position: absolute;
        bottom: rem(3);
        right: 7%;
        font-size: rem(8);
        font-weight: 500;
        color: #000;
        width: rem(12);
        text-align: center;
        transition: color $header-tr;
      }
    }

    .my-account-link {
      position: relative;

      svg {
        width: rem(30);
        height: rem(28);
        cursor: pointer;
      }

      &__actions {
        @include unstyled-list;
        max-height: 12.75rem;
        background: var(--wp--preset--color--white);
        border-radius: 0.625rem;
        padding: 0.5rem 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: calc(100% + 0.625rem);
        right: -1rem;
        z-index: 3;
        opacity: 0;
        width: max-content;
        pointer-events: none;

        li {
          font-size: 0.875rem;

          &:hover {
            background: var(--wp--preset--color--light-bg);
            .light & {
              background: var(--wp--preset--color--gray-three);
            }
          }

          a {
            width: 100%;
            padding: 0.25rem 1rem;
            display: inline-block;
            color: var(--wp--preset--color--base);
          }
        }

        &.active {
          pointer-events: auto;
          animation: fadeIn 0.3s forwards;
        }
      }
    }

    .theme-switcher {
      @include unstyled-button;
      cursor: pointer;
      flex-shrink: 0;

      &__icon {
        width: rem(26);
        height: rem(26);
        color: $color-white;

        &--light {
          @include theme-dark {
            display: none;
          }
        }

        &--dark {
          display: none;

          @include theme-dark {
            display: inline;
          }
        }
      }
    }

    .wp-block-button {
      font-weight: 500;
      font-size: rem(14);
      flex-shrink: 0;
    }

    .icon-burger {
      flex-shrink: 0;
      display: inline-block;
      width: rem(25);
      height: rem(24);
      position: relative;

      &.active {
        i {
          background-color: transparent;
          height: 0;

          &:before {
            transform: rotate(-45deg) translateY(-1px);
          }

          &:after {
            transform: rotate(45deg) translateY(1px);
          }
        }
      }

      i {
        display: block;
        width: 100%;
        height: rem(2);
        background-color: var(--wp--preset--color--white-static);
        position: absolute;
        top: calc(50% - rem(1));
        left: 0;
        transition: transform $header-tr, background-color $header-tr;
        border-radius: rem(2);

        &:before,
        &:after {
          transition: all $header-tr;
          content: '';
          display: block;
          width: 100%;
          height: rem(2);
          background-color: var(--wp--preset--color--white-static);
          transform-origin: right center;
          position: absolute;
          border-radius: rem(2);
          right: 0;
        }

        &:before {
          top: rem(-8);
        }

        &:after {
          top: rem(8);
        }
      }
    }
  }

  @include min(lg) {
    z-index: 11;
  }

  @include max(xl) {

    .site-logo {
      width: 4.25rem !important;
      align-self: auto;

      svg {
        position: relative;
        width: 100% !important;
        height: auto !important;
      }
    }

    .site-header__actions {

      .wp-block-button {
        order: -1;
        position: absolute;
        width: 36vw;
        left: 50%;
        transform: translateX(-50%);

        .wp-block-button__link {
          width: 100%;
          background-color: var(--wp--preset--color--white-static);
          color: var(--wp--preset--color--base-static);
          border: 1px solid var(--wp--preset--color--stroke);
          transition: color $header-tr, background $header-tr, border $header-tr;
        }
      }
    }
  }
  &.alternative-view {
    .wp-block-button__link {
      border: 2px solid var(--wp--preset--color--primary);
      color: var(--wp--preset--color--base);
      background-color: transparent;
    }
  }
  @include max(lg) {

    .site-header__actions {

      .wp-block-button {
        position: relative;
        left: auto;
        transform: none;
      }
    }
  }

  @include max(md) {
    .site-logo {
      width: rem(60) !important;
    }

    .site-header__actions {
      width: 100%;

      .favorites-link,
      .theme-switcher {
        display: none;
      }

      .wp-block-button {
        width: inherit;
        flex-shrink: 2;

        .wp-block-button__link {
          padding: rem(10);
        }
      }
    }
  }

  @include max(599) {

    > .container {
      gap: 0.75rem;
    }

    .site-logo {
      width: rem(54) !important;
    }

    .site-header__actions {
      gap: 0.75rem;

      .favorites-link,
      .theme-switcher {
        display: none;
      }
    }
  }

  @include max(385) {
    > .container {
      gap: 0.6125rem;
    }

    .site-logo {
      width: rem(50) !important;
    }

    .site-header__actions {
      gap: 0.6125rem;

      .wp-block-button {

        .wp-block-button__link {
          padding: 0.625rem 0.075rem;
        }
      }
    }
  }
}

.site-header {

  &.not-transparent:not(.scrolled-out):not(.alternative-view) {

    .site-logo {

      @include min(xl) {

        svg {
          transform: scale(0.6) translateY(-50%);
          top: 50%;
          transition: transform $header-tr, top $header-tr;
        }
      }
    }
  }

  &.scrolled-out {
    height: var(--sticky-header-height);

    .site-logo {

      @include min(xl) {

        svg {
          transform: scale(0.45) translateY(-50%);
          top: 50%;
          transition: transform $header-tr, top $header-tr;
        }
      }
    }

    + .itm-megamenu .itm-megamenu__main-nav{
      margin: rem(10) 0;
    }
  }

  .megamenu-is-open &,
  &.not-transparent,
  &.scrolled-out {
    background: transparent;

    ~ svg {

      #favorites-header,
      #my-account,
      #my-account-count,
      #theme-dark,
      #theme-light {

        path {
          fill: var(--wp--preset--color--base);
        }
      }

      #my-account-count {
        path:last-child {
          fill: var(--wp--preset--color--white);
        }
      }
    }

    &:before {
      opacity: 1;
    }

    .site-logo {

      svg {

        path {
          fill: var(--wp--preset--color--base);
        }
      }
    }

    .currency-switcher__active {
      color: var(--wp--preset--color--base);
    }

    .favorites-link {

      &__count {
        color: var(--wp--preset--color--white);
      }
    }

    .icon-burger {

      i {
        background-color: var(--wp--preset--color--base);

        &:before,
        &:after {
          background-color: var(--wp--preset--color--base);
        }
      }
    }
  }

  .page-checkout &,
  .page-checkout-event & {
    @include min(lg) {
      &:before {
        border-bottom: none !important;
      }
    }
  }

  @include min-max(md, lg) {

    + .itm-megamenu {
      top: var(--header-height);
      height: calc( 100vh - var(--header-height) );
    }

    &.scrolled-out {
      + .itm-megamenu {
        top: var(--sticky-header-height);
        height: calc( 100vh - var(--sticky-header-height) );
      }
    }

    &:not(.scrolled-out) {

      transition: none;

      .site-header:before,
      .site-logo svg,
      .site-logo svg path,
      .site-header__actions .currency-switcher__active,
      .site-header__actions .favorites-link__count,
      .site-header__actions .wp-block-button,
      .site-header__actions .wp-block-button__link{
        transition: none;
      }

      .site-header__actions {

        .icon-burger {

          i{
            transition: transform $header-tr;

            &:before,
            &:after{
              transition: transform $header-tr, top $header-tr, width $header-tr, height $header-tr;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInCenter {
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.page-checkout {
  .site-header {
    @include min(lg) {
      z-index: 91;
    }
  }
}

//fix for old safari
.site-header {
  &:not(.not-transparen) {
    svg {
      fill: $w;
      color: $w;
    }
    &.scrolled-out, .megamenu-is-open & {
      svg {
        fill: $b;
        color: $b;
      }
    }
  }
}
