// Modal
.modal {
  // local variables:
  --modal-margin: #{rem(24)};
  --modal-max-width: #{rem(1350)};

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  opacity: 0;

  &__overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba($w, 0.8);
    position: fixed;
    top: 0;
    left: 0;

    @include theme-dark {
      background-color: rgba($b, 0.8);
    }
  }

  &.is-open {
    opacity: 1;
    transition: opacity $tr;
    visibility: visible;
    z-index: 110;

    .modal__inner {
      transform: scale(1);
      transition: transform $tr;
    }
  }

  &__inner {
    width: 100%;
    max-width: var(--modal-max-width);
    max-height: calc(100dvh - var(--modal-margin) * 2);
    height: calc(100% - rem(20));
    display: flex;
    align-items: center;
    padding: rem(10);
    margin: auto;
    position: relative;
    transform: scale(0.95);
    pointer-events: none;

    @include min(md) {
      padding: 0 var(--modal-margin);
    }
  }

  &__content {
    @include border;
    width: 100%;
    max-height: 100%;
    background-color: var(--wp--preset--color--light-bg);
    color: var(--wp--preset--color--base);
    box-shadow: rem(0 0 15 0) rgba(#000, 0.15);
    margin: auto;
    padding: rem(50 0 0);
    position: relative;
    pointer-events: auto;
	  overflow: hidden;

	  &-header {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
      padding: clamp-rem(16, 24) clamp-rem(16, 24) clamp-rem(16, 24) clamp-rem(16, 50);
		  margin-top: rem(-50);
		  border-bottom: 1px solid var(--wp--preset--color--stroke);

		  h4 {
			  margin: 0;
		  }

		  .modal__close {
			  position: static;
		  }
	  }

    &-inner {
      overflow-y: auto;
      max-height: 80dvh;
      padding: 0 clamp-rem(16, 50) rem(50);
    }
  }

	&.modal-self-service {

		.modal__inner {
			max-width: 40rem;
		}

		.modal__content-header {
			padding-left: clamp-rem(16, 40);
		}

		.modal__content-inner {
			padding: clamp-rem(16, 24) clamp-rem(16, 40);
		}
	}

	&__close {
    @extend %btn-close;
    position: absolute;
    top: rem(12);
    right: rem(12);
    z-index: 2;

	  .modal-datepicker & {

		  @include max(md) {
			  right: rem(12);
			  top: rem(12);
		  }
	  }

    .icon {
      transition: 0.15s;
    }
  }
}

.js-modal-open {
  cursor: pointer;
}

// Contact Form
.modal-contact {
  width: 100%;
  max-width: rem(370);
  height: auto;
  top: auto;
  right: 0;
  bottom: var(--modal-margin);
  left: auto;

  @include max(md) {
    bottom: 0;
  }

  .modal__inner {
    @include max(md) {
      margin: 0;
    }
  }

  .modal__content {
    box-shadow: none;
    margin: 0;
    padding-top: rem(32);

    &-inner {
      padding: rem(0 16 16);
    }
  }

  &__header {
    text-align: center;
  }

  &__icon {
    width: rem(40);
    height: rem(40);
    color: var(--wp--preset--color--campeasy-white);

    img {
      width: rem(20);
    }
  }
}

// Single Camper
.modal-camper-features {
  .modal__content {
    max-width: rem(740);
    margin: auto;
  }
}

// Personal Information form on Account page
.modal-form-personal-info {
  .modal__content {
    max-width: rem(800);
  }

  .form-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: rem(20);
    padding: clamp-rem(20, 125) 0;

    .icon {
      font-size: clamp-rem(70, 100);
    }

    &__title {
      color: var(--wp--preset--color--black);
      margin: 0;
    }

    &__text {
      @extend %copy2;
      color: var(--wp--preset--color--gray-one);
    }
  }
}
