.itm-intro-for-funnel {
	text-align: center;
	letter-spacing: 0.05em;
	padding-top: calc( var(--wp--preset--spacing--xxl) + 40px);
	padding-bottom: var(--wp--preset--spacing--xl);

	h1 {
		font-size: rem(42);
		font-weight: 700;
		line-height: 1.2;
		margin: 0 0 rem(10);
	}
}

.intro-funnel {
  &__dayoff-message {
	padding-top: rem(10) ;
  }

	&__subtitle {
		font-size: rem(16);
		text-transform: uppercase;
		text-align: center;
		display: flex;
		flex-direction: column;
		width: max-content;
		margin-left: auto;
		margin-right: auto;

		> p {
			margin: 0;
			display: inline-block;

			&:not(:first-child) {
				@include animated-underline(1px, 1px, var(--wp--preset--color--primary));
			}
		}

		@include max(md){
			flex-wrap: wrap;
		}
	}

	&__bold {
		color: var(--wp--preset--color--link);
		font-weight: 700;
		position: relative;
		cursor: pointer;
	}
}
