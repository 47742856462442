// Insurance comparison table
// (should be complied after _insurance.scss)
.insurance-comparison {
  overflow-x: auto;

  &__table {
    @include border;
    min-width: rem(750);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: var(--wp--preset--color--white);
    overflow: hidden;
    border-bottom: 0;
  }

  .insurance {
    &__item {
      background-color: transparent;
      border: none;
      border-radius: 0 !important;

      &--wide {
        grid-column: span 5;

        + .insurance__item--wide {
          border-top: 1px solid var(--wp--preset--color--stroke);
        }

        &.d-none {
          + .insurance__item--wide {
            border-top: 0;
          }
        }

        &:last-child {
          border-bottom: 1px solid var(--wp--preset--color--stroke);
        }

        h6 {
          display: flex;
          align-items: center;
          gap: rem(12);
          letter-spacing: 0.02em;
        }
      }

      &--cdw,
      &--exclusive,
      &--not-covered {
        border-right: 1px solid var(--wp--preset--color--stroke) !important;

        &.last-item {
          border-right: none !important;
        }

      }

      &--cdw {
        &:nth-child(5) {
          .insurance__title {
            color: var(--wp--preset--color--gray-one);
            font-size: rem(14);
            font-weight: 500;
            letter-spacing: 0.03em;
          }
        }

        .insurance__feature {
          &:nth-child(2n+1) {
            background-color: var(--wp--preset--color--light-bg);
          }
        }

        + .insurance__item--wide {
          background-color: var(--wp--preset--color--light-bg);
        }
      }

      &--exclusive {
        .insurance__feature {
          &:nth-child(2n) {
            background-color: var(--wp--preset--color--light-bg);
          }
        }
      }

      &--not-covered {
        .insurance__feature {
          &:nth-child(2n+1) {
            background-color: var(--wp--preset--color--light-bg);
          }
        }
      }
    }

    &__head {
      border-bottom: none;
      padding-right: rem(16) !important;
    }

    &__title {
      color: var(--wp--preset--color--base);
    }

    &__features {
      min-height: unset;
    }

    &__feature {
      height: rem(68);
      font-size: rem(12);
      padding: rem(3 16);
      overflow: hidden;

      @include min(lg) {
        padding: rem(3 20);

        label {
          font-size: rem(14);
        }
      }
    }
  }

  &__download {
    margin-top: clamp-rem(24, 40);

    @include max(md) {
      height: rem(36);
      font-size: rem(12);
      padding: rem(0 22);
    }
  }

  &__text-bottom {
    @extend %copy1;
    margin-top: rem(30);
  }
}

.page-checkout {
  .insurance {
    &-comparison {
      &__table {
        background-color: $color-light-bg;
      }
    }
    &__item {
      background-color: var(--wp--preset--color--white);
    }
  }
}
