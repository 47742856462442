.socials {
  display: flex;
  align-items: center;
  gap: rem(24);

  &__item {
    width: rem(32);
    height: rem(32);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    @include max(md) {
      width: rem(24);
      height: rem(24);
      padding-top: 0 !important;
      text-align: center;
    }

    &:hover {
      color: var(--wp--preset--color--primary);
    }

    svg {
      width: rem(24);
      height: rem(24);
      fill: currentColor;

      &.icon-facebook {
        width: rem(13);
      }

      &.icon-linkedin {
        height: rem(27);
      }

      &.icon-twitter {
        width: rem(26);
        height: rem(26);
        @include max(md) {
          width: rem(22);
          height: rem(22);
        }
      }

      &.icon-youtube {
        height: rem(20);
        @include max(md) {
          height: rem(24);
        }
      }
    }
  }
}
