/* Additional styles for core/columns block. */
.wp-block-column.wp-block-column {

	&.is-style-sticky {

		@include min(md) {
			position: sticky;
			top: 5rem;
			height: max-content;

			.has-anchor-nav & {
				top: 11.375rem;
				margin-bottom: 6.5rem;
			}
		}
	}
}
