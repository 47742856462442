.main-nav{

	.not-transparent &,
	.scrolled-out &{

		&__menu{

			.menu-item{

				a{
					color: var(--wp--preset--color--base);
				}
			}
		}
	}

	&.not-calculated{
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
	}

	&__menu{
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.menu-item{
			margin: 0 rem(24);

			a{
				font-weight: 600;
				font-size: rem(14);
				text-transform: uppercase;
				color: $color-white;
				transition: color $header-tr linear;
			}
		}
	}

	@include max(xl) {
		display: none;
	}
}
