.article {
  @include border;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  &.category-tips {
    .article__category {
      &:before {
        background: radial-gradient(circle closest-side, rgba(#ba6e27, 0.5), transparent);
      }
    }
  }

  &.category-ambassadors {
    .article__category {
      &:before {
        background: radial-gradient(circle closest-side, rgba(#4a634d, 0.5), transparent);
      }
    }
  }

  &__category {
    height: rem(37);
    border: none;
    border-radius: 0 0 $radius 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform $tr;
    z-index: 2;
  }

  &__thumbnail {
    width: 100%;

    img,
    .img-placeholder {
      width: 100%;
      aspect-ratio: 345 / 280;
      object-fit: cover;
      transition: opacity $tr;

      @include min(md) {
        aspect-ratio: 415 / 280;
      }
    }

    &:hover {
      text-decoration: none;

      img {
        opacity: 0.85;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(24 16 16);
  }

  .posted-on {
    @extend %copy-sm;
    color: var(--wp--preset--color--gray-one);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem(5);
  }

  &__title {
    margin: 0 0 rem(16);
  }

  &__excerpt {
    @extend %copy1;
    color: var(--wp--preset--color--gray-one);
    margin-bottom: rem(16);

    .post-related & {
      margin-bottom: rem(50);
    }
  }

  .post-author {
    margin-bottom: rem(50);
  }

  &__more {
    color: var(--wp--preset--color--base);
    margin-top: auto;

    svg {
      position: relative;
      top: -1px;
    }
  }

  // layput: Related - used for related posts and on author archive
  &--related {
    .article__excerpt {
      margin-bottom: rem(50);
    }
  }
}

// Author
.post-author {
  --author-img-size: #{rem(48)};
  --author-gap: #{rem(16)};
  display: flex;
  align-items: center;
  gap: var(--author-gap);
  color: var(--wp--preset--color--base);

  &__photo {
    flex-shrink: 0;
    color: var(--wp--preset--color--gray-three);
    position: relative;

    &-img {
      width: var(--author-img-size);
      height: var(--author-img-size);
      object-fit: cover;
      background-color: var(--wp--preset--color--gray-three);
      border-radius: 50%;
    }

    &-verify {
      width: rem(30);
      height: rem(30);
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__bio {
    @extend %copy-md;
    display: block;
    color: var(--wp--preset--color--gray-one);
  }

  // is used on single.php
  &--full {
    --author-img-size: #{rem(100)};
    --author-gap: #{rem(30)};

    .post-author__name {
      --bg-height1: #{calc(88% - rem(6))};
      --bg-height2: 88%;
      @extend %subtitle;
      background: linear-gradient(180deg, transparent 0%, transparent var(--bg-height1), var(--bg-decor) var(--bg-height1), var(--bg-decor) var(--bg-height2), transparent var(--bg-height2));
      text-transform: uppercase;

      @include min(md) {
        --bg-height1: #{calc(91% - rem(10))};
        --bg-height2: 91%;
      }
    }

    .post-author__bio {
      @extend %copy1;
      margin-top: rem(5);
    }
  }
}


// No results
.article-no-results {
  .article__content {
    align-items: center;
  }

  .article__title {
    text-align: center;
    margin-bottom: 0;
  }
}

.entry-date.published:not(.updated) {
  display: none;
}
