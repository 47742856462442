// Booking card
.booking-card {
  background-color: var(--wp--preset--color--light-bg);
  border-radius: $radius;
  margin-bottom: rem(30);
  padding: rem(32 24);
  text-align: center;
  position: relative;

  .account-booking__grid & {
    margin-bottom: clamp-rem(24, 55);
  }

  .dashboard & {
    margin-bottom: 0;
    padding-bottom: clamp-rem(24, 55);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include min(md) {
    padding: rem(35 30 20);
    text-align: left;
  }

  @include min(1300) {
    padding-top: rem(50);
  }

  &__order-number {
    background-color: var(--wp--preset--color--primary);
    color: $w;
    font-size: rem(16);
    line-height: 1.5;
    border-radius: rem(18);
    padding: rem(6 16);
    white-space: nowrap;
    position: absolute;
    top: rem(-18);
    left: rem(24);
    max-width: fit-content;
    @include hover();
    @include min(lg) {
      position: absolute;
    }

	  a {
		  color: var(--wp--preset--color--white-static);
	  }

    @include min(1300) {
      left: rem(46);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: rem(10 20);

    @include min(md) {
      display: grid;
      grid-template-columns: auto 1fr;
    }

    @include min(1300) {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  &__image {
    width: 100%;
    flex-shrink: 0;
    margin: 0;

    @include max(md) {
      order: -2;
    }

    @include min(md) {
      width: rem(150);

      @include max(1299) {
        grid-row: 1/3;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 150/112;
      object-fit: contain;
    }
  }

  &__heading {
    flex-grow: 1;

    @include max(md) {
      margin-bottom: rem(6);
    }
  }

  &__title {
    margin-bottom: rem(8);

    @include min(md) {
      margin-bottom: rem(6);
    }
  }

  &__pricing {
    display: flex;
    align-items: center;
    gap: rem(12);

    @include max(md) {
      justify-content: center;
    }

    @include min(md) {
      gap: rem(20);

      @include max(1300) {
        grid-column: 2/3;
        justify-content: flex-end;
      }
    }

  }

  &__price {
    flex-shrink: 0;
    @extend %h4;
    white-space: nowrap;
  }

  &__actions {
    flex-shrink: 0;
    position: relative;

    .btn.is-open {
      background-color: var(--wp--preset--color--primary);
      color: $w;
    }

    &-list {
      display: none;
      background-color: var(--wp--preset--color--white);
      border-radius: $radius;
      padding: rem(16);
      box-shadow: rem(2 2 10 0) rgba($b, 0.15);
      position: absolute;
      top: calc(100% + rem(16));
      right: 0;
      z-index: 2;

      a {
        @include animated-underline;
        color: var(--wp--preset--color--base);
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: rem(5);
        }
      }
    }
  }

  &__footer {
    @include max(md) {
      order: -1;
      margin-bottom: rem(26);
    }

    @include min(md) {
      width: 100%;
      grid-column: 2/4;
      text-align: right;
    }
  }

  &__info {
    @extend %copy2;
	@include max(xs) {
	  font-size: rem(14);
	}

	display: inline-flex;
    align-items: center;
    gap: rem(10);
    color: var(--wp--preset--color--gray-one);

    &[data-checkin="available"] {
      color: var(--wp--preset--color--link);
    }

    .icon--io-warning {
      color: var(--wp--preset--color--warning);
    }

    .icon--io-star {
      color: var(--wp--preset--color--gray-two);
    }
  }
}

// Booking Additional card
.booking-extra-card {
  display: block;
  border-radius: $radius;
  overflow: hidden;
  position: relative;

  &__bg {
    width: 100%;
  }

  .btn {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include max(575) {
      height: rem(34);
      font-size: rem(12);
      padding: rem(0 22);
    }
  }
}
