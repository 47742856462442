// Thank You (successful order)
.page-thank-you,
.page-thank-you-event {
  background-color: var(--wp--preset--color--light-bg);

  main {
    padding: clamp-rem(40, 80) 0 clamp-rem(80, 120);
  }

  .booking-extra {
    margin-top: clamp-rem(50, 80);

    @include max(lg) {
      display: none;
    }
  }
}

.thankyou {
  &-wrapper {
    margin-bottom: clamp-rem(80, 120);

    @include min(lg) {
      display: flex;
      gap: clamp-rem(24, 38);
    }
  }

  &-title {
    @extend %h3;
    margin-bottom: clamp-rem(24, 40);
    margin-top: rem(80) !important;
    @include max(md) {
      margin-top: rem(40) !important;
    }
  }

  // Sidebar: Summary
  &-sidebar {
    @include min(md) {
      width: 100%;
      flex-shrink: 0;
    }

    @include min(lg) {
      width: rem(305);
    }

    &__inner {
      @include min(lg) {
        position: sticky;
        top: var(--sticky-header-height);
      }
    }
  }

  &-complete-profile {
    @include border;
    display: flex;
    gap: clamp-rem(10, 16);
    background-color: var(--wp--preset--color--white);
    padding: rem(30 20);
    margin-bottom: clamp-rem(30, 60);

    .btn-outline {
      @include max(md) {
        height: rem(36);
        font-size: rem(12);
        padding: rem(0 22);
        white-space: nowrap;
      }
    }
    .btn-icon {
      &::before {
        display: none !important; 
      }
    }
  }

  &-order-number {
    margin: rem(0 0 30);

    strong {
      font-weight: 700;
    }
  }

  &-to-account {
    @include max(lg) {
      text-align: center;
      margin-top: rem(30);
    }
  }
}

.order-details {
  @include border;
  background-color: var(--wp--preset--color--white);
  padding: rem(16);
  margin-bottom: rem(40);

  @include min(md) {
    padding: rem(20);
  }

  .summary-camper {
    min-height: rem(136);
    background-color: transparent;
    padding: rem(0 0 0 163);
    position: relative;

    @include max(md) {
      width: calc(100% + #{rem(32)});
      background-color: var(--wp--preset--color--light-bg);
      margin: rem(0 -16);
    }

    @include min(md) {
      max-width: rem(525);
    }

    &__image {
      width: rem(136);
      position: absolute;
      top: 0;
      left: 0;
    }

    &__content {
      display: block;
      position: static;
    }

    &__title {
      color: var(--wp--preset--color--base);
    }

    &__details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: rem(8 16);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(16);
    margin-bottom: rem(20);
  }

  &__shuttle {
    @extend %copy-md;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    margin-bottom: rem(20);
    padding: rem(20 0);

    @include min(md) {
      border-top: 1px solid var(--wp--preset--color--stroke);
      flex-direction: row;
      margin-top: rem(20);
    }

    &-divider {
      @include max(md) {
        margin: rem(20 0);
        border-top: 1px solid var(--wp--preset--color--stroke);
      }

      @include min(md) {
        margin: 0 clamp-rem(20, 50);
        border-right: 1px solid var(--wp--preset--color--stroke);
      }
    }
  }

  &__list {
    @include unstyled-list;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: rem(6);

    &-title {
      color: var(--wp--preset--color--gray-one);
    }

    strong {
      font-weight: 500;
    }
  }

  &__customer {
    &-title {
      margin-bottom: rem(20);
    }

    &-list {
      @extend %copy-md;
      @include unstyled-list;
      display: flex;
      row-gap: rem(6);
      flex-wrap: wrap;

      li {
        width: 100%;

        @include min(md) {
          width: 50%;
          padding-right: rem(20);
        }

        strong {
          font-weight: 500;
        }
      }
    }
  }
}

// Self Checkin Thank You
.thankyou-checkin {
  text-align: center;
  margin: calc(var(--header-height) + #{rem(150)}) 0 rem(150) !important;

  &__title {
    margin-bottom: rem(20); 
  }

  &__subtitle {
    color: var(--wp--preset--color--gray-one);
    margin-bottom: clamp-rem(24, 40);
  }
}
