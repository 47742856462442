.print-confirmation-page {
	padding: rem(40 0);
	.site-header, .site-footer, .exclusives, .cart-comparison {
		display: none;
	}
	.thankyou-wrapper {
		margin: 0 auto;
		display: block;
	}
	.thankyou-sidebar {
		width: 100%;
	}
}
