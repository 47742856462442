/* Additional styles for core/button block. */
.wp-block-button {
  display: flex;
  letter-spacing: 0.05em;

  .wp-block-button__link {
    @include hover();
    color: $color-white;

    &.disabled {
      background-color: var(--wp--preset--color--gray-two) !important;
      border-color: var(--wp--preset--color--gray-two) !important;
      pointer-events: none !important;

      @include theme-dark {
        background-color: var(--wp--preset--color--gray-three) !important;
        border-color: var(--wp--preset--color--gray-three) !important;
      }
    }
  }

  &.is-style-icon {

    svg {
      overflow: visible !important;
    }
  }

  &.is-style-outline {

    .wp-block-cover & {

      .wp-block-button__link {
        color: var(--wp--preset--color--white-static);
      }
    }

    .wp-block-button__link {
      color: var(--wp--preset--color--base);
      background: transparent;
    }
  }

  &.is-style-tertiary {

    .wp-block-button__link {
      color: var(--wp--preset--color--base);
      background: none;
      position: relative;
      transition: 0.3s;

      &:hover {
        color: var(--wp--preset--color--primary);

        &::after {
          color: var(--wp--preset--color--primary);
          right: rem(4);
        }
      }

      &::after {
        content: $icon--io-chevron-right;
        font-family: "campeasy";
        color: var(--wp--preset--color--base);
        position: absolute;
        top: 50%;
        right: rem(8);
        transform: translateY(-52%);
        transition: 0.3s;
        transition-delay: 0s;
      }

      &::before {
        display: none;
      }
    }
  }

  &.has-small-font-size {

    .wp-block-button__link {
      padding: rem(10 22);
      line-height: 1.2;
    }

    &.is-style-outline {

      .wp-block-button__link {
        padding: rem(9 20);
      }
    }
  }

  &.has-medium-font-size {

    .wp-block-button__link {
      padding: rem(12 28);
    }

    &.is-style-outline {
      .wp-block-button__link {
        padding: rem(10 26);
      }
    }
  }

  &.has-large-font-size {

    .wp-block-button__link {
      padding: rem(18 36);
    }

    &.is-style-outline {
      .wp-block-button__link {
        padding: rem(16 34);
      }
    }
  }

  &.has-extra-large-font-size {

    .wp-block-button__link {
      padding: 1em 2em;
    }

    &.is-style-outline {
      .wp-block-button__link {
        padding: calc(1em - 0.125rem) calc(2em - 0.125rem);
      }
    }
  }

  &.is-style-outline .wp-block-button__link,
  & .wp-block-button__link.has-campeasy-white-background-color {
    @include hover;
  }

  .wp-block-button__link.is-style-outline,
  &.is-style-outline > .wp-block-button__link {
    border: 2px solid var(--wp--preset--color--primary);
    transition: $tr;

    @include hover-responsive {
      background-color: var(--wp--preset--color--primary);
      color: $color-white;
    }
  }
}

// Fix empty spacing below button on frontend.
.is-front .wp-block-button {
  line-height: 0;

  .wp-block-button__link {
    line-height: 1.5;
  }
}

.wp-block-file {
	&__button {
		@include hover();
    color: $w;
	}
}
