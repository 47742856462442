// Steps
.checkout-steps {
  padding-top: var(--header-height);

  &__head {
    padding: rem(80) 0 rem(40) 0;
    text-align: center;
    background-color: var(--wp--preset--color--white);
    p {
      margin: rem(20) 0 0 0;
    }
  }

  &__inner {
    .checkin & {
      background-color: var(--wp--preset--color--light-bg);
    }
  }

  .checkout & {

    &[data-current="1"] {
      .checkout-steps-nav__mobile-prev,
      .js-checkout-prev {
        opacity: 0;
        pointer-events: none;
      }
    }

    &:not([data-current="1"]) {
      .checkout-step[data-step="1"] {
        display: none;
      }
    }

    &:not([data-current="2"]) {
      .checkout-step[data-step="2"] {
        display: none;
      }
    }

    &:not([data-current="3"]) {
      .checkout-step[data-step="3"] {
        display: none;
      }
    }

    &:not([data-current="4"]) {
      .checkout-step[data-step="4"] {
        display: none;
      }
    }

    &:not([data-current="5"]) {
      .checkout-step[data-step="5"] {
        display: none;
      }
    }

    &:not([data-current="6"]) {
      .checkout-step[data-step="6"] {
        display: none;
      }
    }

    &[data-current-step-key="payment"] {
      .js-checkout-submit {
        display: inline-flex;
      }
      .js-checkout-next {
        display: none;
      }
    }
  }

  .checkin & {
    &[data-current="1"] {
      .checkout-steps-nav__mobile-prev,
      .js-checkout-prev {
        display: none;
      }
    }

    &[data-current="6"] {
      .js-checkout-next {
        display: none;
      }
    }

    &:not([data-current="1"]) {
      .checkout-step[data-step="1"] {
        display: none;
      }
    }

    &:not([data-current="2"]) {
      .checkout-step[data-step="2"] {
        display: none;
      }
    }

    &:not([data-current="3"]) {
      .checkout-step[data-step="3"] {
        display: none;
      }
    }

    &:not([data-current="4"]) {
      .checkout-step[data-step="4"] {
        display: none;
      }
    }

    &:not([data-current="5"]) {
      .checkout-step[data-step="5"] {
        display: none;
      }
    }

    &:not([data-current="6"]) {
      .checkout-step[data-step="6"],
      .js-checkout-submit {
        display: none;
      }
    }
  }

  .page-checkout-event & {
    &[data-current="1"] {
      .checkout-steps-nav__mobile-prev,
      .js-checkout-prev {
        display: none;
      }
    }

    &:not([data-current="1"]) {
      .checkout-step[data-step="1"] {
        display: none;
      }
    }

    &[data-current="2"] {
      .js-checkout-next {
        display: none;
      }
    }

    &:not([data-current="2"]) {
      .checkout-step[data-step="2"],
      .js-checkout-submit {
        display: none;
      }
    }
  }
}

// Steps navigation
.checkout-steps-nav {
  @include min(lg) {
    border-top: 1px solid var(--wp--preset--color--stroke);
    border-bottom: 1px solid transparent;
    // position: sticky;
    top: calc( var(--sticky-header-height) - 1px);
    transition: transform 0.2s linear;
    z-index: 90;

    body:has(.site-header.scrolled-out) .checkout &,
    body:has(.site-header.scrolled-out) .checkout-event & {
      border-bottom-color: var(--wp--preset--color--stroke);
    }

    body:has(.site-header.scrolled-out) .checkin & {
      border-bottom-color: transparent;
    }

    .checkin & {
      background-color: var(--wp--preset--color--campeasy-white);
      border-top: none;
    }
  }

  .checkin & {
    @include min(lg) {
      padding-left: var(--wp--style--root--padding-left);
      padding-right: var(--wp--style--root--padding-right);
    }
  }

  &__list {
    @include unstyled-list;
    background-color: var(--wp--preset--color--white);
    display: flex;
    align-items: center;
    justify-content: center;
    counter-reset: steps;
    padding: rem(20);

    .checkin & {
      padding: rem(24 0);
      max-width: rem(1120);
      width: 100%;
      margin: auto;
      &.with-scroll {
        overflow-x: auto;
        scroll-behavior: smooth;
        white-space: nowrap;
        justify-content: flex-start;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
          border-radius: rem(3);
        }
      }

      @include min(lg) {
        @include max(1280) {
          flex-wrap: wrap;
        }
      }
    }

	  &:has(li:nth-child(6)) {
		  li {
			  font-size: clamp-rem(14, 16, 1024, 1440);

			  &:before {
				  width: clamp-rem(30, 40, 1024, 1440);
				  height: clamp-rem(30, 40, 1024, 1440);
				  font-size: clamp-rem(16, 22, 1024, 1440);
				  margin-right: clamp-rem(8, 14, 1024, 1440);
			  }

			  &:not(:last-child) {
				  &:after {
					  width: clamp-rem(30, 60, 1024, 1440);
				  }
			  }
		  }
	  }

    li {
      display: inline-flex;
      align-items: center;
      counter-increment: steps;
      white-space: nowrap;
      cursor: pointer;
      pointer-events: none;

      &:hover {
        .link-animated {
          &:before {
            width: 100%;
            opacity: 1;
            transition: width $tr, opacity .1s linear;
          }
        }
      }

      .checkin & {
        font-size: rem(14);
      }

      &:before {
        content: counter(steps);
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);
        color: var(--wp--preset--color--gray-one);
        font-size: rem(22);
        font-weight: 500;
        line-height: 1;
        border: 2px solid var(--wp--preset--color--stroke);
        border-radius: 50%;
        transition: $tr;
        margin-right: rem(14);
      }

      &:not(:last-child):after {
        content: '';
        display: block;
        width: clamp-rem(30, 120, 780, 1440);
        border-bottom: 2px solid var(--wp--preset--color--stroke);
        margin-left: rem(7);
        transition: border-color $tr;

        .checkin & {
          width: clamp-rem(35, 80, 1280, 1440);
        }
      }

      &.is-current {
        pointer-events: none;

        + li {
          pointer-events: initial;
        }

        &:before {
          background-color: var(--wp--preset--color--primary);
          border-color: var(--wp--preset--color--primary);
          color: $w;
        }
      }

      &.is-available {
        pointer-events: initial;
      }

      &.is-done {
        pointer-events: initial;

        &:before,
        &:after {
          border-color: var(--wp--preset--color--primary);
        }

        &:before {
          @extend .icon;
          content: $icon--io-check;
          color: $primary;
        }
      }

      &.is-animating {
        &:before {
          font-size: 0;
        }
      }
    }
  }

  &__mobile {
    width: 100%;
    max-width: var(--wp--style--global--wide-size);
    display: flex;
    justify-content: space-between;
    gap: rem(16);
    padding: rem(30) var(--wp--style--root--padding-left) 0;
    margin-right: auto;
    margin-left: auto;

    &-current {
      font-size: rem(16);
      letter-spacing: 0.02em;
      margin: 0 0 rem(6);
    }

    &-next,
    &-prev {
      @extend %copy-md;
      display: inline-block;
      cursor: pointer;

      &:after {
        content: attr(data-prefix) ': ';
        float: left;
        margin-right: rem(4);
      }

      &.disabled {
        pointer-events: none !important;
      }
    }

    &-next {
      margin-bottom: rem(4);
    }

    &-prev {
      color: var(--wp--preset--color--gray-one);
    }

    &-progress {
      width: rem(55);
      height: rem(55);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      line-height: 0;
      background: radial-gradient(closest-side, var(--wp--preset--color--white) 79%, transparent 80% 100%),
      conic-gradient($primary calc(var(--progress-current) * 100 / var(--progress-total) * 1%), var(--wp--preset--color--gray-three) 0);
    }

    &-value {
      display: inline-block;
      font-size: rem(12);
      font-weight: 600;
      letter-spacing: 0.02em;

      &:before {
        counter-reset: variable var(--progress-current);
        content: counter(variable) ' ';
      }
    }
  }

  @property --progress-value {
    syntax: '<integer>';
    inherits: false;
    initial-value: 0;
  }
}

// Action buttons
.step-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(16);
  margin-top: rem(60);

  .js-checkout-next {
    margin-left: auto;
    .checkin & {
      margin: auto;
    }
  }

  .js-checkout-submit {
    display: none;

    body:has(.checkout-payment__options-list .is-loading) & {
      @extend .disabled;
    }
  }

  .btn {
    cursor: pointer;
    .checkin & {
      &:not(.js-checkout-next) {
        display: none;
      }
    }
  }
}

// Action buttons (mobile sticky)
.step-actions-mobile {
  height: rem(72);
  width: 100%;
  // display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-template-areas: "prev next"
// "overview overview";
  grid-column-gap: rem(8);
  align-items: center;
  background-color: var(--wp--preset--color--white);
  box-shadow: rem(0 1 1) var(--wp--preset--color--stroke);
  padding: rem(9 16);
  position: fixed;
  top: var(--sticky-header-height);
  z-index: 90;

  display: flex;
  flex-wrap: wrap;
  .checkin & {
    display: none;
  }

  @include max(md) {
    border-radius: 0 0 $radius $radius;

    body:has(.step-actions-mobile.is-visible.is-open) & {
      border-radius: 0;
    }
  }

  @include min(md) {
    // height: rem(40);
    grid-template-columns: rem(100) 1fr rem(100);
    grid-template-areas: "prev overview next";
    padding: rem(9) var(--wp--style--root--padding-left);
  }

  &:not(.is-visible) {
    display: none;
  }
  .js-checkout-submit {
    display: none;
  }

  .step-actions-mobile__prev, .step-actions-mobile__next {
    width: calc(50% - rem(4));
  }

  .step-actions-mobile__overview {
    width: calc(100% + 32px);
    justify-content: space-between;
  }

  &.is-open {
    .step-actions-mobile__toggle {
      &:before {
        content: attr(data-hide);
      }

      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__overview {
    grid-area: overview;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: rem(24);

    @include max(md) {
      justify-content: space-between;
      border-top: 1px solid var(--wp--preset--color--stroke);
      margin: rem(9 -16 0);
      padding: rem(9 16 0);
    }
  }

  &__price {
    display: inline-flex;
    align-items: center;
    gap: rem(12);
    font-size: rem(16);
    margin: 0;

    &:before {
      content: attr(data-prefix);
    }
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    gap: rem(12);
    cursor: pointer;

    &:before {
      content: attr(data-show);
    }

    .icon {
      transition: transform $tr;
    }
  }

  &__prev {
    grid-area: prev;
    line-height: 0;
  }

  &__next {
    grid-area: next;
    line-height: 0;
    text-align: right;
  }

  .btn-link {
    font-size: rem(12);
    white-space: nowrap;
  }
}
