body:not(.wp-admin) {

	%input {
		@include border;
		width: 100%;
		height: rem(44);
		display: inline-flex;
		align-items: center;
		background-color: var(--wp--preset--color--white);
		color: var(--wp--preset--color--base);
		font-size: rem(16);
		font-weight: 400;
		text-align: left;
		line-height: rem(16);
		box-shadow: none;
		padding: rem(13 20);
		-webkit-appearance: none;
		-moz-appearance: none;
		transition: color $tr, border-color $tr;

		&:not(.without-important) {
			padding: rem(13 20) !important;
		}

		&.disabled {
			pointer-events: none;
			background-color: var(--wp--preset--color--light-bg);
		}

		&:hover,
		&:focus,
		&:active {
			border-color: var(--wp--preset--color--gray-two);
		}

		&[aria-invalid="true"],
		&.has-error {
			border-color: var(--wp--preset--color--error) !important;
		}
	}

	.account-comment {
		.form-field {
			margin-bottom: rem(30);

		}
	}

	label.review-terms {
		font-size: 16px;
		@include max(md) {
			font-size: 14px;
		}

	}

	.input-upload-container {
		.upload-text {
			color: var(--wp--preset--color--base);
			@include min(lg) {
				font-size: 14px;
			}

		}

		.upload-label {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.preview-container {
			margin-top: 10px;
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}

		.preview-container .image-wrapper {
			position: relative;

		}

		.preview-container img {
			max-width: 100px;
			max-height: 100px;
			object-fit: cover;
			border-radius: 3px;
			position: relative;
		}

		.preview-container .close-btn {
			position: absolute;
			top: -8px;
			right: -9px;
			background-color: var(--wp--preset--color--link);
			color: white;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}

	form.loading {
		opacity: .3;
		pointer-events: none;
		cursor: not-allowed;
	}

	.input-rating-form-group {
		content: '';
		display: table;
		clear: both;

		.rating {
			gap: 0;
			border: none;
			float: left;
		}

		.rating > label {
			color: var(--wp--preset--color--accent);
			padding: 0 0.25rem;
			float: right;
			font-family: "campeasy";
			font-size: rem(26);
		}

		.rating > label:before {
			content: '\E065';
			display: inline-block;
		}

		.rating > input {
			display: none !important;
		}


		.rating:hover label {
			color: var(--wp--preset--color--accent);
		}

		.rating label:hover ~ label {
			color: var(--wp--preset--color--gray-two);
		}

		input:checked + label ~ label {
			color: var(--wp--preset--color--gray-two);
		}

	}

	.input,
	input[type="text"],
	input[type="search"],
	input[type="email"],
	input[type="number"],
	input[type="tel"],
	input[type="url"],
	input[type="password"],
	textarea {

		@extend %input;

		&:focus {
			@include placeholder {
				opacity: 0;
			}
		}

		@include placeholder {
			@include nonselect;
			opacity: 0.6;
			transition: opacity $tr;
		}
	}

	select {
		@extend %input;
		height: rem(44);
		display: block;
		// icon: angle down (black)
		background: var(--wp--preset--color--white) url("data:image/svg+xml,%3Csvg width='14' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.712.525a.937.937 0 0 1 1.326 0L7 5.487 11.962.525a.937.937 0 1 1 1.326 1.325L7.663 7.475a.937.937 0 0 1-1.326 0L.712 1.85a.937.937 0 0 1 0-1.325Z' fill='%2321201F'/%3E%3C/svg%3E") no-repeat right rem(20) top 50%;
		background-size: rem(14 8);
		cursor: pointer;
		line-height: rem(42);
		padding: rem(0 40 0 20);

		&:not(.without-important) {
			padding: rem(0 40 0 20) !important;
		}


		@include theme-dark {
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.712.525a.937.937 0 0 1 1.326 0L7 5.487 11.962.525a.937.937 0 1 1 1.326 1.325L7.663 7.475a.937.937 0 0 1-1.326 0L.712 1.85a.937.937 0 0 1 0-1.325Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
		}

		&[multiple] {
			background: none;
			min-height: 4.625rem;
		}
	}

	%field-select {
		width: auto;
		height: rem(46);
		display: inline-flex;
		align-items: center;
		gap: rem(7);
		font-size: rem(16);
		font-weight: 400;
		line-height: rem(42);
		border: 1px solid var(--wp--preset--color--stroke);
		border-radius: rem(25);
		cursor: pointer;
		box-shadow: 0 0 rem(10) 0 rgba($b, 0.1);
		padding: rem(0 20);
		white-space: nowrap;
		transition: border-color $tr;

		.icon {
			font-size: rem(20);
		}

		@include hover-responsive {
			border-color: var(--wp--preset--color--primary);
		}
	}

	input[type="radio"] {
		flex-shrink: 0;
		display: inline-block;
		width: rem(18);
		height: rem(18);
		border: 2px solid var(--wp--preset--color--base);
		border-radius: 50%;
		appearance: none;
		cursor: pointer;
		transition: $tr;

		&:checked {
			background-color: var(--wp--preset--color--base);
			background: radial-gradient(circle, var(--wp--preset--color--base) 0%, var(--wp--preset--color--base) rem(5), var(--wp--preset--color--campeasy-white) rem(5));
			pointer-events: none;
		}
	}

	input[type="checkbox"] {
		flex-shrink: 0;
		display: inline-block;
		width: rem(18);
		height: rem(18);
		background: transparent no-repeat 50% / rem(13 13);
		border: 2px solid var(--wp--preset--color--base);
		border-radius: 2px;
		appearance: none;
		cursor: pointer;
		transition: $tr;

		&:checked {
			// icon: check mark black
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.388 10.164.038 5.899 1.816 4.07l2.57 2.524 5.795-5.74 1.78 1.815-7.574 7.492v.002Z' fill='%2321201F'/%3E%3C/svg%3E");
			pointer-events: none;

			@include theme-dark {
				// icon: check mark black
				background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.388 10.164.038 5.899 1.816 4.07l2.57 2.524 5.795-5.74 1.78 1.815-7.574 7.492v.002Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
			}
		}
	}

	textarea {
		min-height: rem(120) !important;
		line-height: 1.5;
	}

	button[type="submit"],
	input[type="submit"] {
		background-color: var(--wp--preset--color--primary);
		border-radius: rem(25);
		border-color: var(--wp--preset--color--primary);
		border-width: 0 !important;
		border-style: solid;
		color: var(--wp--preset--color--white);
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		padding: calc(0.667em + 2px) calc(1.333em + 2px);
		text-decoration: none;
	}

	.campeasy-user-form__form, .account-profile__section {
		button[type="submit"],
		input[type="submit"] {
			color: $color-white;
		}
	}

	.itm-button-black_wrapper {

		button[type="submit"],
		input[type="submit"] {
			color: var(--wp--preset--color--itm-black);
		}
	}

	button[type="submit"] {
		@include hover();

		&.btn-outline {
			background-color: transparent;
			color: var(--wp--preset--color--base);
			border: 2px solid var(--wp--preset--color--primary) !important;

			&:before {
				background-color: transparent;
			}

			&:hover {
				background-color: var(--wp--preset--color--primary);
				color: $w;
			}
		}
	}

	label {
		cursor: pointer;

		&.required {
			gap: 0;

			&:after {
				content: '*';
				color: var(--wp--preset--color--error);
			}
		}
	}

	%label-radio { // for label with radio or checkbox inside
		display: inline-flex;
		align-items: center;
		gap: rem(7);
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	// Switcher (toggle)
	.field-switch {
		display: inline-flex;
		align-items: center;
		gap: rem(7);
		flex-shrink: 0;

		&__toggle {
			position: relative;
		}

		input {
			display: none;

			&:checked + .field-switch__slider {
				&:before {
					transform: translateX(rem(14));
				}
			}
		}

		&__slider {
			min-width: rem(37);
			width: rem(37);
			height: rem(22);
			border: 1px solid var(--wp--preset--color--gray-one);
			border-radius: rem(12);
			cursor: pointer;
			position: relative;
			transition: .4s;

			&:before {
				content: "";
				background-color: var(--wp--preset--color--base);
				border-radius: 50%;
				position: absolute;
				height: rem(16);
				width: rem(16);
				left: rem(3);
				transition: .4s;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
		}
	}

	.field-checkbox {
		@extend %copy1;
		display: flex;
		align-items: center;
		gap: rem(10);
		color: var(--wp--preset--color--base);

		input[type="checkbox"] {
			width: rem(22);
			height: rem(22);
		}
	}

	// Custom Select (can be with inputs and icons)
	.custom-select {
		filter: drop-shadow(0 0 rem(10) rgba($b, 0.1));
		position: relative;
		user-select: none;
		transition: min-width $tr;

		@include hover-responsive {
			.custom-select__current {
				border-color: var(--wp--preset--color--primary);
			}
		}

		> .icon {
			pointer-events: none;
		}

		&.active {
			z-index: 90;

			.custom-select__current {
				border-color: var(--wp--preset--color--primary);
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			.custom-select__list {
				opacity: 1;
				visibility: visible;
			}
		}

		&__current {
			background: var(--wp--preset--color--white) !important;
			border: 1px solid var(--wp--preset--color--stroke);
			border-radius: rem(24);
			text-overflow: ellipsis;
			white-space: nowrap;
			position: relative;
			padding: rem(8 20);
			min-height: rem(48);

			label {
				padding: 0 !important;
				min-height: 0 !important;
			}

			* {
				pointer-events: none;
			}
		}

		&__list {
			@include unstyled-list;
			display: block;
			width: 100%;
			background: var(--wp--preset--color--white);
			border: 1px solid var(--wp--preset--color--primary);
			border-radius: rem(0 0 24 24);
			overflow: hidden;
			position: absolute;
			top: calc(100% - 1px);
			left: 0;
			transition: opacity 0.2s;
			opacity: 0;
			visibility: hidden;

			.option {
				&:not(:first-child) {
					border-top: 1px solid var(--wp--preset--color--stroke);
				}
			}
		}

		.option {
			width: 100%;
			display: flex;
			align-items: center;
			gap: rem(7);
			justify-content: flex-start;
			cursor: pointer;
			text-overflow: ellipsis;
			white-space: nowrap;
			transition: background-color $tr, border-color $tr, min-width $tr;

			@include hover-responsive {
				background-color: var(--wp--preset--color--light-bg);
			}

			label {
				width: 100%;
				display: flex;
				align-items: center;
				gap: rem(7);
				padding: rem(8 20);
				min-height: rem(48);
			}

			.icon {
				font-size: rem(20);
			}

			input[type="radio"] {
				display: none;
			}
		}

		.selected {
			background-color: var(--wp--preset--color--primary);
			color: $color-white;
			pointer-events: none;
		}
	}

}

// Form layout
.form-row {
	display: grid;
	grid-template-columns: 1fr;
	gap: rem(30);
	margin-bottom: rem(30);

	@include min(md) {
		grid-template-columns: 1fr 1fr;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:has(.field-switch) {
		row-gap: rem(20);
	}

	&-fullwidth {
		display: block;
	}

	.form-row {
		margin-bottom: 0;
	}
}

.form-field {
	label {
		@extend %copy-md;
		display: flex;
		align-items: center;
		gap: rem(10);
		color: var(--wp--preset--color--gray-one);
		margin-bottom: rem(2);

		@include min(md) {
			white-space: nowrap;
		}

		input {
			flex-shrink: 0;
		}

		a {
			@include animated-underline;
		}
	}

	&:has(.field-switch) {
		label {
			@extend %copy-md;
			color: var(--wp--preset--color--base);
		}
	}

	> small {
		@extend %copy-sm;
		display: block;
		color: var(--wp--preset--color--gray-one);

		&:last-child {
			margin-top: rem(2);
		}
	}

	textarea {
		min-height: rem(190) !important;
	}

	&--phone {
		.phone__group {
			display: flex;

			> * {
				height: rem(44);
				line-height: rem(42);
			}

			> select {
				width: rem(63);
				background-size: rem(10 6);
				background-position-x: calc(100% - rem(10));
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				padding: rem(0 24 0 16) !important;

				&:not(:hover) {
					border-right-color: transparent;
				}
			}

			> input[type="text"] {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				padding: rem(0 20 0 6) !important;
				transition: border-color $tr;

				@include min(md) {
					padding-left: rem(16) !important;
				}

				&:hover {
					border-left-color: var(--wp--preset--color--base);
				}
			}
		}
	}

	&--wide {
		@include min(640) {
			grid-column: span 2;
		}
	}

	&:has(.field-switch) {
		align-self: center;
	}

	&--radio-boxes {
		input[type=radio] {
			display: none !important;
		}

		label {
			&:not(:has(input[type=radio])) {
				margin-bottom: rem(14);
			}

			&:has(input[type=radio]) {
				width: rem(30);
				height: rem(30);
				display: inline-flex;
				align-self: center;
				justify-content: center;
				background-color: var(--wp--preset--color--white);
				color: var(--wp--preset--color--base);
				font-size: rem(16);
				border-radius: 2px;
				border: 1px solid var(--wp--preset--color--stroke);
				margin: rem(0 4 0 0);
				transition: $tr;

				@include hover-responsive {
					border-color: var(--wp--preset--color--primary);
				}
			}

			&:has(input[type=radio]:checked) {
				background-color: #B5A6F1;
				border-color: var(--wp--preset--color--primary);
			}
		}
	}

	&--consent {
		align-self: center;

		label {
			align-items: flex-start;
		}
	}
}

/**
	Disable Edge input custom controls
 */
input::-ms-reveal,
input::-ms-clear {
	display: none;
}
