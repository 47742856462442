// sass-lint:disable no-important

// Visibility
@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @media (min-width: map-get($grid-breakpoints, $bp)) {
      display: none !important;
    }
  }

  .visible-#{$bp}-up {
    $bp_max: map-get($grid-breakpoints, $bp) - 1;
    @media (max-width: $bp_max) {
      display: none !important;
    }
  }
}

.d-none {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

// Text alignment
.text-left   { text-align: left !important; }
.text-right  { text-align: right !important; }
.text-center { text-align: center !important; }

// Colors
$color-array: (
        primary: var(--wp--preset--color--primary),
        link: var(--wp--preset--color--link),
        light: var(--wp--preset--color--light-bg),
        stroke: var(--wp--preset--color--stroke),
        error: var(--wp--preset--color--error),
        white: var(--wp--preset--color--white),
        black: var(--wp--preset--color--base),
        green: var(--wp--preset--color--success),
        yellow: var(--wp--preset--color--warning),
        red: var(--wp--preset--color--error),
        gray: var(--wp--preset--color--gray-two)
);
@each $color-name, $color-value in $color-array {
  #{'.bg-' + $color-name} {
    background-color: $color-value !important;
  }
  #{'.color-' + $color-name} {
    color: $color-value !important;
  }
}

// Text only for screen readers.
%screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; // Many screen reader and browser combinations announce broken words as they would appear visually.

  &:focus {
    font-size: rem(14);
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: #000;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: rem(15 23 14);
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; // Above WP toolbar.
  }
}

.screen-reader-text {
  @extend %screen-reader-text;
}
