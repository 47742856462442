.single-events {
  &:has(.single-dates) {
    .single-overview {
      padding-top: rem(70);
    }
  }

  .itm-intro .wp-block-heading {
    max-width: rem(1100);
  }

  &:has(.single-summary.category-campeasy) {
    .itm-intro {
      .wp-block-heading {
        padding-bottom: rem(120);
        position: relative;

        &:after {
          content: '';
          display: block;
          width: rem(88);
          height: rem(88);
          background: $w url('../../assets/img/logo-black.svg') no-repeat 50% / rem(63 45);
          border: 2px solid var(--wp--preset--color--primary);
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: calc(50% - #{rem(44)});
        }
      }
    }
  }

  .wp-block-itm-video-gallery__video {
    @include max(md) {
      height: rem(450);
    }
  }

  .fancybox__content {
    padding: 0;
	  width: 80vw;
	  max-width: 1440px;

    > iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    .is-close-btn {
      position: absolute;
      top: rem(-40);
      right: rem(-40);
      visibility: hidden;
      cursor: pointer;
      transition: 0.15s;

      &::before {
        content: "\e01d";
        display: block;
        line-height: 1;
        font-family: "campeasy";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        text-decoration: none;
        text-transform: none;
        color: $color-gray-two;
        visibility: visible;
      }
      &:hover {

        &::before {
          color: $primary;
        }
      }
    }
  }
}

.single-summary {
  --event-color: var(--wp--preset--color--primary);
}

.single-event {
  &-host {
    display: flex;
    gap: rem(16);
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding-bottom: rem(40);
    margin-bottom: clamp-rem(36, 40);

    &__photo {
      flex-shrink: 0;

      img {
        width: rem(48);
        border: 2px solid var(--event-color);
        border-radius: 50%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    &__position {
      @extend %copy-md;
      color: var(--wp--preset--color--gray-one);
    }
  }

  &-features {
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding-bottom: rem(20);
    margin-bottom: clamp-rem(36, 40);

    @include min(xs) {
      display: flex;
      gap: rem(16);
      padding-bottom: rem(40);
    }

    &__list {
      @include unstyled-list;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: clamp-rem(20, 27);

      li {
        display: flex;
      }

      .icon {
        font-size: rem(24);
        margin-right: rem(10);
      }

      &-languages {
        span {
          text-transform: uppercase;

          &:not(:last-child):after {
            content: ', ';
          }
        }
      }
    }

    &__map {
      flex-shrink: 0;
      width: 43%;
      text-align: center;

      @include max(xs) {
        width: 100%;
        border-top: 1px solid var(--wp--preset--color--stroke);
        margin-top: rem(30);
        padding-top: rem(20);
        text-align: left;
      }

      img {
        width: 100%;
        background-color: var(--wp--preset--color--white-static);
        margin-bottom: rem(30);

        @include max(xs) {
          max-width: rem(315);
        }
      }
    }
  }

  &-booking {
    &__title {
      margin-bottom: rem(32);
    }

    .custom-select {
      filter: none !important;
      margin-bottom: rem(30);

      @include max(md) {
      max-width: calc(100vw - #{rem(64)});
      }

      @include min(md) {
        max-width: rem(335);
      }

      &__current {
        padding-right: rem(40) !important;
      }

      > .icon {
        font-size: rem(20);
        position: absolute;
        top: calc(50% - #{rem(10)});
        right: rem(18);
      }

      .option {
        @extend %copy-md;

        label {
          overflow: hidden;
        }

        span {
          color: var(--wp--preset--color--gray-one);
        }

        &.selected {
          span {
            color: $color-white;
          }
        }
      }
    }

    &__guests {
      display: flex;
      align-items: center;
      gap: rem(16);
      margin-bottom: rem(18);

      &-label {
        font-size: clamp-rem(16, 18);
        font-weight: 500;
        letter-spacing: 0.02em;
      }
    }

    table {
      margin-bottom: rem(10);

      td {
        padding: rem(4 0);
        margin: 0;

        &:first-child {
          padding-right: rem(26);
        }
      }
    }

    &__info {
      @extend %copy-md;
      color: var(--wp--preset--color--gray-one);
    }

    .btn {
      margin-top: clamp-rem(32, 42);
    }
  }
}
