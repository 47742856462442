.itm-megamenu {
  height: calc( 100% - var(--header-height) );
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: var(--header-height);
  left: 0;
  background: var(--wp--preset--color--white);
  opacity: 0;
  pointer-events: none;
  transition: top $header-tr;
  display: grid;
  grid-template-rows: auto 1fr;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &__back {
    background: var(--wp--preset--color--gray-one);
    padding: 1.25rem var(--wp--style--root--padding-right) 1.25rem var(--wp--style--root--padding-left);
    color: var(--wp--preset--color--white);
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: rem(67);
    z-index: 1;

    &.active {

      > * {
        display: inline-block;
      }
    }

    i {
      display: none;
      margin-right: rem(10);
      color: var(--wp--preset--color--primary);
      pointer-events: none;
      line-height: 1.33;
    }

    span {
      display: none;
      text-transform: uppercase;
      pointer-events: none;
    }
  }

  &__sidebar {
    background: var(--wp--preset--color--gray-one);
    position: relative;
    top: -1px;
    height: calc(100% + 1px);
    display: grid;
    grid-template-rows: 1fr auto;
    transition: left .35s ease;
    overflow: hidden;
    width: 100vw;
    z-index: 99;

    @media (min-width: 1025px) and (max-width: 1380px){
      padding-bottom: rem(60);
    }

    &__logo {
      display: none;
    }

    &__menu {
      top: 0;
      margin: 0;
      padding: 0;
      left: 100%;
      width: calc(100% - ( var(--wp--style--root--padding-right) / 2 ));
      max-height: calc(100% - 6rem);
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      transition: left .35s ease;

      &.no-transition{
        transition: none;
      }

      &__inner {
        padding: 0;
      }

      &__item {
        position: relative;
        padding: rem(20) 0;
        margin-left: var(--wp--style--root--padding-left);
        margin-right: calc( var(--wp--style--root--padding-right) / 2);
        border-bottom: 1px solid var(--wp--preset--color--stroke);

        &:first-child {
          padding-top: rem(10);
        }

        &:last-child {
          border-bottom: 0;
        }

        &.cloned{
          margin-top: rem(12);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          color: var(--wp--preset--color--white);
        }
      }
    }

    > .wp-block-button {
      display: none;
    }

    &__socials {
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: center;
      margin: rem(64) 0 rem(30);

      a {
        padding: 0.5rem 0.5rem 0;
        max-width: 1.5rem;
        max-height: 1.5rem;
        display: inline-block;
        box-sizing: content-box;
        color: var(--wp--preset--color--white);
        transition: transform .2s ease;

        &:hover {
          color: var(--wp--preset--color--primary);

          svg {
            transform: translateY(rem(-4));
          }
        }

        svg {
          max-width: inherit;
          max-height: inherit;
          transition: transform .2s ease;

          use,
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &__main-nav {
    display: none;
  }

  &__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.25rem calc(var(--wp--style--root--padding-right) / 2) 0 calc(var(--wp--style--root--padding-left) / 2);
    position: absolute;
    left: 100%;
    top: rem(67);
    background: var(--wp--preset--color--white);
    transition: left .35s ease;
    z-index: 1;
    margin: 2rem calc(var(--wp--style--root--padding-right) / 2) 1rem;
    width: calc(100% - var(--wp--style--root--padding-right));

    @include max(1381) {
      @supports (height: 100dvh) {
        height: calc(100dvh - var(--header-height) - rem(67) ); // top & vertical margin.
      }

      @supports not (height: 100dvh) {
        height: calc(100% - var(--header-height) - rem(67) ); // top & vertical margin.
      }
    }

    @include max(lg) {
      @supports (height: 100dvh) {
        height: calc(100dvh - var(--header-height) - rem(67) - 3rem); // top & vertical margin.
      }

      @supports not (height: 100dvh) {
        height: calc(100% - var(--header-height) - rem(67) - 3rem); // top & vertical margin.
      }
    }

    @include max(md) {
      //margin-bottom: rem(80);
    }

    &__page {
      height: 100%;
      min-height: 100%;
    }
    .extra-padding-bottom {
      @include max(lg) {
        padding-bottom: rem(67);
      }
    }
  }

  @include min(lg) {
    top: 0;
    height: 100vh;
    display: grid;
    grid-template-columns: 17.25rem 1fr;
    grid-template-rows: auto 1fr;
    padding-right: var(--wp--style--root--padding-right);

    &__back {
      display: none;
    }

    &__sidebar {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      width: auto;
      overflow: initial;

      &:before {
        content: '';
        position: absolute;
        left: calc(100% - 1px);
        top: var(--arrow-top);
        border-style: solid;
        border-width: rem(27) 0 rem(27) rem(16);
        border-color: transparent transparent transparent var(--wp--preset--color--gray-one);
        pointer-events: none;
      }

      &__logo {
        cursor: pointer;
        margin: rem(50) auto rem(50);
        text-align: center;
        display: block;

        svg {

          * {
            fill: var(--wp--preset--color--white);
          }
        }
      }

      &__menu {
        position: relative;
        display: none;
        left: auto;
        top: auto;
        width: auto;
        max-height: 100%;
        margin: 0 1rem 0 0;

        &.active {
          display: block;
        }

        &--main {
          display: none;
        }

        &__item {
          position: relative;
          margin: rem(6) 0;
          padding: rem(14) 0 rem(14) rem(40);
          height: rem(54);
          cursor: pointer;
          border: 0;

          &:not(.active):hover {
            > a {
              text-decoration: underline;
            }
          }

          &.active {

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 0.25rem;
              background: var(--wp--preset--color--white);
            }
          }

          &.cloned {
            display: none;
          }

          a {
            cursor: pointer;
            color: var(--wp--preset--color--white);

            i {
              display: none;
            }
          }
        }
      }
    }

    &__main-nav {
      display: flex;
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      list-style-type: none;
      justify-content: flex-end;
      margin: rem(25) 0;
      position: relative;

      &__item {
        padding: rem(10) rem(24);
        flex-shrink: 0;

        &:last-child{
          padding-right: 0;
        }

        &.active {

          a {
            text-decoration: underline;
          }
        }

        a {
          cursor: pointer;
          font-weight: 600;
          text-transform: uppercase;
          color: var(--wp--preset--color--base);
          font-size: rem(14);

          @include theme-dark {

            svg {

              path {
                fill: var(--wp--preset--color--white-static);
              }
            }
          }
        }
      }
    }

    &__content {
      width: 100%;
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      margin: 0 0 0 calc(var(--wp--style--root--padding-left) / 2);
      position: static;
    }
  }

	@include min(xxxl) {
		grid-template-columns: 17.25rem 1fr 17.25rem;

		&__main-nav {
			grid-column: 2 / 4;
		}
	}
}
