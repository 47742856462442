.timepicker {
	color: var(--wp--preset--color--base);
	letter-spacing: 0.05em;
	position: relative;
	cursor: pointer;

	&__value {
		width: rem(100);
		background: var(--wp--preset--color--white);
		border-radius: rem(24);
		padding: rem(0 20);
		position: relative;
		display: inline-flex;
		align-items: center;
		height: 100%;
		transition: border-color .3s;
		line-height: 2.25;

		@include min(md) {
			min-height: rem(40);
			line-height: 1;
		}
	}

	&__wrap {
		max-height: rem(204);
		background: var(--wp--preset--color--white);
		border-radius: rem(10);
		padding: rem(8 10);
		box-shadow: rem(0 0 20) rgba(0, 0, 0, 0.2);
		position: absolute;
		top: calc(100% + rem(10));
		right: 0;
		display: none;
		z-index: 9999;

		@include max(md) {
			top: auto;
			bottom: calc(100% + rem(10));
		}

		&.active {
			display: block;
			animation: timeIn 0.3s forwards;
		}
	}

	&__options {
		width: fit-content;
		min-width: rem(180);
		max-height: rem(188);
		overflow: auto;

		@include max(md) {
			width: rem(154);
			min-width: rem(154);
		}

		&::-webkit-scrollbar {
			width: 3px;
			position: absolute;
			right: 0;
		}

		&::-webkit-scrollbar-track {
			background: var(--wp--preset--color--stroke);
			border-radius: rem(10);
		}

		&::-webkit-scrollbar-thumb {
			background: var(--wp--preset--color--primary);
			border-radius: rem(10);
		}

		&::-webkit-scrollbar-thumb:hover {
			filter: brightness(0.9);
		}
	}

	&__option {
		font-size: rem(14);
		font-weight: 500;
		line-height: rem(38);
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: rem(10);
		border-radius: rem(10);
		padding: rem(0 14 0 20);
		margin: 0 rem(4) 0 0;

		&.selected {
			background-color: var(--wp--preset--color--light-bg);
		}

		@media screen and (min-width: 768px) and (max-width: 1024px) {
			margin-right: 0;
		}

		@include max(md) {
			padding: rem(0 10 0 4);
		}

		&:hover {
			background: var(--wp--preset--color--light-bg);
		}

		&.active {
			background: var(--wp--preset--color--light-bg);
		}

		span {
			font-size: rem(10);
			color: var(--wp--preset--color--gray-two);
			text-transform: capitalize;
		}
	}

	.searchform-block-element.datetime & {

		.timepicker {

			&__value {
				border-radius: rem(0 24 24 0);

				&::before {
					content: "\e016";
					font-family: 'campeasy';
					position: absolute;
					top: 50%;
					right: rem(16);
					transform: translateY(-50%);
				}

				&::after {
					content: '';
					width: 1px;
					height: rem(28);
					background: var(--wp--preset--color--gray-three);
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}
		}
	}

	&__label {
		font-size: rem(16);
		color: var(--wp--preset--color--base);
		line-height: 1.5;
		margin: 0 0 rem(10);
		display: none;
	}
}

.modal {

	.timepicker {

		&__wrap {
			top: auto;
			bottom: calc(100% + rem(10));
			@media screen and (min-width: 768px) and (max-width: 1024px) {
				width: 100%;
				min-width: rem(180);
				padding-left: 0;
			}
		}
	}
}

.searchform-mainwrapper {

	&.active {

		.timepicker {

			&__wrap {

				@include max(md) {
					top: auto;
					left: 0;
					right: auto;
					bottom: calc(100% + rem(10));
				}
			}

			&__label {

				@include max(md) {
					display: block;
				}
			}

			&__value {

				@include max(md) {
					width: 100%;
					background-position: top 50% right rem(16);
					border: 1px solid var(--wp--preset--color--stroke);
					border-radius: rem(24) !important;
				}

				&.active {

					@include max(md) {
						color: var(--wp--preset--color--primary);
						border-color: var(--wp--preset--color--primary);
						transition: 0.3s;
					}
				}

				&::after {
					display: none;
				}
			}
		}
	}
}

@keyframes timeIn {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
