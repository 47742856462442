@import "../../node_modules/@fancyapps/ui/src/Fancybox/Fancybox";

// Custom styling:
[data-fancybox] {
  cursor: pointer;
}

.carousel__button {
  svg {
    path {
      fill: none !important;
    }
  }
}
