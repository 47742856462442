.page-contact-us {
  &__cta {
    .wp-block-columns {
      @include max(1200) {
        flex-wrap: wrap !important;
      }
    }

    .wp-block-column {
      @include max(1200) {
        flex-basis: 100% !important;
      }
    }

    a {
      @include animated-underline(2px);
    }
  }

  &__contacts {
    .iceland-time {
      @extend %copy2;
      color: var(--wp--preset--color--gray-one);
    }

    .wp-block-image {
      position: relative;

      figcaption {
        color: $color-white;
        display: inline-block;
        font-size: rem(12);
        font-weight: 500;
        letter-spacing: 0.05em;
        margin: 0;
        padding: rem(14);
        text-align: left;
        position: absolute;
        bottom: 0;
        right: 0;

        @include min(lg) {
          font-size: rem(14);
          letter-spacing: 0.1em;
          line-height: 2;
          padding: rem(20);

          strong {
            font-size: clamp-rem(18, 20);
            letter-spacing: 0.05em;
          }
        }

        strong {
          display: block;
        }
      }
    }
  }

  &__form {
    .gform_wrapper.gravity-theme {
      .gform_heading {
        padding: 0;
        text-align: left;
      }

      .gform_title {
        @extend %h3;
        margin: rem(0 0 20);
      }

      form {
        .gform_fields {
          gap: clamp-rem(20, 24);
        }

        .gform_footer {
          justify-content: flex-start;
          margin-top: clamp-rem(24, 32);

          .gform_button {
            @include max(md) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__map {
    + .exclusives {
      margin-top: 0;
    }

    .leaflet-container {
      z-index: 1 !important;
    }
  }
}
