// Call to Action
.itm-cta {
  padding: rem(80 16);
  position: relative;

  @include min(xxl) {
    min-height: rem(700) !important;
  }

  .wp-block-group {

    &.is-content-justification-right {
      max-width: rem(480);
      align-items: flex-start;
      row-gap: rem(30);
      margin: 0 0 0 auto;

      @include max(lg) {
        margin: 0 auto 0 0;
      }

      @include min(xxl) {
        max-width: rem(780);
      }
    }

    &.is-content-justification-left {
      max-width: rem(420);
      align-items: flex-start;
      row-gap: rem(30);

      @include max(lg) {
        margin: 0;
      }

      @include min(xxl) {
        max-width: rem(600);
      }

      @include max(md) {
        margin: 0 0 rem(-58);
      }
    }
  }

  .wp-block-heading {
    letter-spacing: 0.05em;

    @include min(xxl) {
      font-size: rem(62);
    }
  }

  .wp-block-button__link {

    @include min(xxl) {
      line-height: rem(32);
    }
  }

  .wp-block-cover__inner-container {
    align-self: center;

    @include max(md) {
      align-self: flex-end;
      padding: 0;
    }
  }

  a:not([class]):where(:not(.wp-element-button)) {
    @include animated-underline(2px, 0px);
    color: var(--wp--preset--color--primary);
    pointer-events: initial;

    &:after {
      display: none;
    }
  }

  .global-ratings__items {
    padding: rem(0 16);
    position: absolute;
    left: 50%;
    bottom: rem(24);
    transform: translateX(-50%);

    @include max(md) {
      padding-top: rem(32);
    }
  }
}

.editor-styles-wrapper {

  .itm-cta {

    .block-editor-block-list__layout {
      position: unset;
    }

    .block-editor-block-list__block:not(.wp-block-buttons) {

      &.is-content-justification-right {
        max-width: rem(480);
        align-items: flex-start;
        row-gap: rem(30);
        margin: 0 0 0 auto;
      }

      &.is-content-justification-left {
        margin: 0 0 0 rem(180);
      }
    }

    .wp-block-itm-ratings {
      position: absolute;
      left: 50%;
      bottom: rem(24);
      transform: translateX(-50%);
    }

    .global-ratings__items {
      min-height: rem(46);
      position: unset;
      transform: none;

      .itm-add-rating {
        top: rem(20);
      }
    }
  }
}

// pattern: Hover Animation
.itm-cta-hover {
  background-color: $color-base;
  min-height: rem(290);
  position: relative;

  @include hover-responsive {
    .itm-cta-hover__bg {
      opacity: 1;
    }
  }

  a:where(:not(.wp-element-button)) {
    @include animated-underline(2px, 0px);
    color: var(--wp--preset--color--primary);
    pointer-events: initial;

    &:after {
      display: none;
    }
  }

  .wp-block-cover__inner-container {
    pointer-events: none;
    z-index: 2;

    .wp-block-buttons {
      > * {
        pointer-events: initial;
      }
    }
  }

  &__bg {
    --x: 0px; // set via JS
    --y: 0px; // set via JS
    --size: 0vw; // set via JS
    --mask: radial-gradient(ellipse calc(var(--size) * 0.12) calc(var(--size) * 0.12) at var(--x) var(--y), var(--wp--preset--color--base) calc(var(--size) * 0.03), transparent 90%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity $tr;
    z-index: 1;

    @include max(lg) {
      opacity: 0.1;
    }

    @include min(lg) {
      mask-image: var(--mask);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}
