// Accordion
.js-accordion {
  &-item {
    &.is-open {
      .js-accordion-title {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-title {
    cursor: pointer;

    svg {
      width: rem(21);
      height: rem(21);
      fill: $primary;
      transition: all 0.4s;
    }
  }

  &-content {
    display: none;
  }
}

.itm-faq {
  &.layout-simplified {
    .container {
      @include min(md) {
        display: flex;
        gap: 8.3%;
      }
    }

    .faq__group {
      @include min(md) {
        flex-direction: column;
        padding-top: 0;
      }
    }

    .itm-faq__groups {
      width: 100%;

      @include min(md) {
        width: 50%;
        flex-shrink: 0;
      }
    }
  }

  &__header {
    flex-grow: 1;
  }

  &__groups {
    width: 100%;
  }
}

.faq {

  &__head {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding: rem(72 0 32);

    @include max(md) {
      flex-wrap: wrap;
      gap: rem(16);
      padding: rem(30 0 20);
    }
  }

  &__navs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: auto;
    row-gap: rem(20);
    column-gap: rem(32);
    padding: rem(12) var(--wp--preset--spacing--l) 0 0;

    @include max(md) {
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow: auto;
    }
  }

  &__nav {
    color: var(--wp--preset--color--gray-one);
    font-weight: 500;
    position: relative;
    transition: 0.4s;

    &:hover {
      color: var(--wp--preset--color--link);
    }

    &:first-child {
      color: var(--wp--preset--color--link);
    }
  }

  &__search {
    display: flex;
    align-items: center;
    gap: rem(6);
    border: 1px solid var(--wp--preset--color--stroke);
    border-radius: rem(24);
    box-shadow: rem(0 0 10) rgba(0, 0, 0, 0.1);
    padding: rem(0 20);
    position: relative;

    &:hover {
      border-color: var(--wp--preset--color--gray-two);
    }

    @include max(lg) {
      width: 50%;
    }

    @include max(md) {
      width: 100%;
    }

    input {
      width: rem(58);
      color: var(--wp--preset--color--base);
      line-height: rem(46);
      text-overflow: ellipsis;
      background: var(--wp--preset--color--white);
      border: none;
      padding: 0;
      transition: 0.3s;

      @include min(lg) {

        &:focus {
          width: rem(208);

          @include placeholder {
            opacity: 0;
          }
        }
      }

      @include max(md) {
        flex: auto;
      }
    }
  }

  &__search-btn {
    width: rem(20);
    height: rem(20);
    cursor: pointer;
  }

  &__group {
    display: flex;
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding: rem(50 0);

    &:last-child {
      border: none;
    }

    &:nth-last-child(2) {
      border: none;
    }

    @include max(md) {
      flex-direction: column;
      border: none;
      padding: rem(40 0 0);
    }

    &--empty {
      display: none !important;

      &.active {
        display: flex !important;
      }

      span {
        font-weight: 600;
      }
    }

    .gform_wrapper.gravity-theme {
      background: var(--wp--preset--color--light-bg);
      border-radius: rem(3 0 0 3);
      box-shadow:
              rem(0 0 0 50) var(--wp--preset--color--light-bg),
              rem(300 0 0 50) var(--wp--preset--color--light-bg),
              rem(-50 0 0 50) var(--wp--preset--color--light-bg)
    ;

      @include max(md) {
        border-radius: rem(10 0 0 10);
        box-shadow: rem(50 0 0) var(--wp--preset--color--light-bg);
        padding: rem(30 20);
      }

      .gform_heading {
        display: none !important;
      }

      .gform-body {

        .gform_fields {
          row-gap: rem(30);
          column-gap: rem(20);
        }

        label {
          font-size: rem(14);
          color: var(--wp--preset--color--gray-one);
          font-weight: 400;
          line-height: rem(22);
          margin: 0 0 rem(4);
        }

        textarea {
          height: rem(164);
        }
      }

      .gform_footer {
        justify-content: flex-start !important;
        margin-top: rem(30);
      }

      form {
        .gfield_checkbox {
          padding-left: 0;
        }

        .gfield_radio {
          @include border;
          display: inline-flex;
          background-color: var(--wp--preset--color--light-bg);
          overflow: hidden;

          .gchoice:has(input:checked) label {
            background-color: rgba($dark-link, 0.5);

            &:not(:last-child) {
              border-right: 1px solid var(--wp--preset--color--stroke);
            }
          }

          label {
            min-width: rem(53);
            height: rem(44);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--wp--preset--color--base);
            font-size: rem(16);
            line-height: 1;
            margin: 0;
            transition: $tr;

            @include hover-responsive {
              background-color: var(--wp--preset--color--gray-three);
            }
          }

          input[type="radio"],
          input[type="checkbox"] {
            display: none !important;
          }
        }
      }
    }
  }

  &__heading {
    flex: 0 0 50%;
    padding: 0 rem(24) 0 0;

    @include max(md) {
      flex: auto;
      padding: 0 0 rem(24);
    }

    h2 {
      position: sticky;
      top: var(--header-height);
    }

    h3 {
      @include min(md) {
        margin-bottom: rem(30);
      }
    }
  }

  &__items {
    flex: 0 0 50%;
  }

  &__item {
    border-top: 1px solid var(--wp--preset--color--base);
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    transition: border-color $tr;
    margin: 0;

    &.is-open,
    &:hover {
      .faq__item-icon {
        &::after {
          transform: translate(-50%, -50%) rotate(0deg) scale(1.1);
        }
      }
    }

    &:last-child,
    &:last-of-type {
      border-bottom: 1px solid var(--wp--preset--color--base);
    }

    &-title {
      display: flex;
      justify-content: space-between;
      gap: rem(16);
      padding: rem(24 0);
      margin: 0;
      position: relative;
      transition: color $tr, padding $tr;
    }

    &-icon {
      height: rem(24);
      flex: 0 0 rem(24);
      border-radius: 50%;
      box-shadow: rem(0 0 4) rgba(0, 0, 0, 0.25);
      margin: rem(4 0 0);
      position: relative;

      &::before {
        content: '';
        width: rem(12);
        height: 1px;
        background: var(--wp--preset--color--base);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: '';
        width: rem(12);
        height: 1px;
        background: var(--wp--preset--color--base);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: $tr;
      }
    }

    &-content {
      padding: rem(0 0 24);
    }

    &.active {

      .faq {

        &__answer {
		  @include max(md) {
			max-height: 300rem;
		  }
          max-height: 200rem;
          transition: 1s;
        }

        &__expander {

          &::after {
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }
      }
    }
  }

  &__question {
    font-size: rem(22);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    gap: rem(24);
    padding: rem(24 0);
    cursor: pointer;

    @include max(md) {
      font-size: rem(20);
      padding: rem(20 0);
    }
  }

  &__expander {
    height: rem(24);
    flex: 0 0 rem(24);
    border-radius: 50%;
    box-shadow: rem(0 0 4) rgba(0, 0, 0, 0.25);
    margin: rem(4 0 0);
    position: relative;

    &::before {
      content: '';
      width: rem(12);
      height: 1px;
      background: var(--wp--preset--color--base);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      width: rem(12);
      height: 1px;
      background: var(--wp--preset--color--base);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: 0.3s;
    }
  }

  &__answer {
    max-height: 0;
    transition: 0.8s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;

    p {

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.tpicker {
  width: calc(50% - rem(24));
  color: var(--wp--preset--color--base);
  letter-spacing: 0.05em;
  margin: 0 rem(24) 0 0;
  position: relative;
  cursor: pointer;

  @include max(md) {
    width: 100%;
    margin: 0;
  }

  &__value {
    line-height: rem(44);
    background: url(../../assets/img/chevron-down-outline.svg) no-repeat top 50% right rem(20) #fff;
    background-size: rem(20);
    border: 1px solid var(--wp--preset--color--stroke);
    border-radius: rem(24);
    box-shadow: rem(0 0 10) rgba(0, 0, 0, 0.1);
    padding: rem(0 20);
    position: relative;
  }

  &__wrap {
    width: 100%;
    background: #fff;
    border-radius: rem(10);
    padding: rem(8 10);
    box-shadow: rem(0 0 10) rgba(0, 0, 0, 0.1);
    position: absolute;
    top: calc(100% + rem(10));
    right: 0;
    display: none;
    z-index: 9999;

    &.active {
      display: block;
      animation: tIn 0.3s forwards;
    }
  }

  &__options {
    width: 100%;
    min-width: rem(180);
    max-height: rem(320);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      background: var(--wp--preset--color--stroke);
      border-radius: rem(10);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--wp--preset--color--primary);
      border-radius: rem(10);
    }

    &::-webkit-scrollbar-thumb:hover {
      filter: brightness(0.9);
    }
  }

  &__option {
    color: var(--wp--preset--color--base);
    line-height: rem(42);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);
    border-radius: rem(10);
    padding: rem(0 14 0 20);
    margin: 0 rem(4) 0 0;

    @include max(md) {
      padding: rem(0 10 0 4);
    }

    &:hover {
      background: var(--wp--preset--color--light-bg);
    }

    &.active {
      background: var(--wp--preset--color--light-bg);
    }

    span {
      font-size: rem(10);
      color: var(--wp--preset--color--gray-two);
      text-transform: capitalize;
    }
  }

}

.faq-nav {

  &__link {
    color: var(--wp--preset--color--base);

    &:hover {

      .icon-circle {
        background: var(--wp--preset--color--primary);
      }
    }

    label {
      display: inline-block;
    }
  }

  .filters-nav {

    &__item {

      &:hover {

        .icon-circle {
          background: var(--wp--preset--color--primary);
        }
      }
    }
  }

  .campers-filters-nav {

    &__item {

      &:hover {

        .icon-circle {
          background: var(--wp--preset--color--primary);
        }
      }
    }
  }
}

.faq-side {

  .filter-group--radio {

    label {
      color: var(--wp--preset--color--base);

      &:hover {
        background: #9580EB80;
      }
    }
  }
}

@keyframes tIn {
  0% {
    opacity: 0;
    transform: translateY(rem(-10));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
