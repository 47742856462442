.wp-block-single-navigation {

    &-title {
        font-size: clamp-rem(16, 18);
        color: var(--wp--preset--color--base);
        font-weight: 500;
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        padding-bottom: 0.75rem;
        margin-bottom: 1.5rem;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: rem(16);
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 95%;
            color: var(--wp--preset--color--gray-one);
            font-weight: 500;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                color: var(--wp--preset--color--link);

                @include max(lg) {
                    color: var(--wp--preset--color--gray-one);
                }
            }

            &.active {
                color: var(--wp--preset--color--link);
                padding-left: rem(10);
            }
        }
    }
}
