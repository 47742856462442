.wp-block-heading{

  @include max(md) {

    br{
      display: none;
    }
  }

  a {
    color: var(--wp--preset--color--link);
  }
}
