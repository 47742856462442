// User Login / Register
.modal-user-login {
  .modal__content {
    max-width: rem(440);
    padding-top: clamp-rem(24, 50);

    &-inner {
      padding: 0 clamp-rem(24, 47) clamp-rem(24, 50);
    }
  }
}

.has-message {
  .modal__content-inner {
    padding-bottom: rem(26);
  }    
}


.campeasy-user-form {
  background: var(--wp--preset--color--light-bg);
  border-radius: $radius;
  padding: 2rem;

	.modal__content-inner & {
		padding: 0;
		background: transparent;
		border-radius: 0;
	}

  &__container {
    display: none;

    &.active {
      display: block;
    }

    &[data-form_name="register"] {

      @media (min-width: 1800px) {
        // .campeasy-user-form__form {
        //   display: grid;
        //   grid-template-columns: 1fr 1fr;
        //   grid-column-gap: 2rem;
        // }

        .wp-block-buttons {
          display: grid;
          grid-column: 1/3;
        }
      }

      @media (min-width: 640px) and (max-width: 782px) {
        .campeasy-user-form__form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 2rem;
        }

        .wp-block-buttons {
          display: grid;
          grid-column: 1/3;
        }
      }
    }
  }

  &__title {
    margin: 0 !important;
    text-align: center;
  }

  &__form {
    margin-top: 2.5rem;
  }

  &__row {
    margin-bottom: 1rem;

    .checkin & {
      &.single {
        margin-bottom: rem(30);
      }
    }

    label {
      display: block;
      margin-bottom: rem(6);
      color: var(--wp--preset--color--gray-one);
    }

    input {
      width: 100%;
      line-height: 1;
      border: 1px solid var(--wp--preset--color--gray-three);
      border-radius: $radius;
      background-color: var(--wp--preset--color--white);
      color: var(--wp--preset--color--base);
      padding: rem(13 20) !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: border-color $tr;

      &.error {
        border-color: var(--wp--preset--color--error) !important;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: var(--wp--preset--color--gray-two);
      }
    }

    .password-wrapper {
      position: relative;

      .icon {
        position: absolute;
        padding: 0.75rem;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 1.5rem;
        color: var(--wp--preset--color--base);
        cursor: pointer;

        &.active {

          &:after {
            width: 50%;
          }
        }

        &:after{
          content: '';
          position: absolute;
          top: calc(50% - 1px);
          left: 50%;
          transform: translate(-50%, -50%) rotate(-35deg);
          width: 0;
          height: 2px;
          background: var(--wp--preset--color--base);
          transition: width $tr;
        }
      }

      input {
        padding-right: 3rem !important;
      }
    }
  }

  &__action-link{
    font-size: var(--wp--preset--font-size--medium);
    color: var(--wp--preset--color--gray-one) !important;
    @include animated-underline(1px, -1px);
  }

  .wp-block-buttons {
    margin-top: 1rem;
  }

  &__message {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.125rem;

    .modal-user-login & {
      margin-bottom: 0;
    }

    &.hidden {
      display: none;
    }

    &.success {
      color: var(--wp--preset--color--success);
    }

    &.error {
      color: var(--wp--preset--color--error);
    }
  }
}




/*
.user-login {
  &__title {
    text-align: center;
    margin-bottom: clamp-rem(20, 30);

    [data-form="lostpassword"] & {
      font-size: rem(38);
    }
  }

  &__heading {
    text-align: center;
  }

  &__switcher {
    @include border;
    border-radius: rem(25);
    display: flex;
    background-color: var(--wp--preset--color--white);
    margin-bottom: clamp-rem(24, 30);
    position: relative;

    &:has(.user-login__switcher-option + .user-login__switcher-option.is-active) {
      &:before {
        transform: translateX(100%);
      }
    }

    &-option {
      width: 50%;
      line-height: rem(43);
      margin: 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      z-index: 3;

      &.is-active {
        color: $w;
        pointer-events: none;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      border-radius: rem(25);
      background-color: var(--wp--preset--color--primary);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transition: transform $tr;
    }
  }

  &__form {
    .btn {
      width: 100%;
    }
  }

  &__row {
    margin-bottom: rem(16);

    label {
      display: block;
      color: var(--wp--preset--color--gray-one);
      margin-bottom: rem(4);
    }

    &:has(input[type="checkbox"]) {
      display: flex;
      align-items: center;
      gap: rem(10);
      margin: rem(20 0 34);

      label {
        margin: 0;
      }
    }
  }

  .login-password,
  .password-wrapper {
    position: relative;

    &:after {
      content: '';
      width: rem(45);
      height: rem(45);
      // icon: -io-eye-empty
      background: url("data:image/svg+xml,%3Csvg width='36' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.875 18a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM18 16.125a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z' fill='%2321201F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.893 18.07c1.307 1.91 3.025 3.775 4.99 5.203 2.195 1.596 4.635 2.602 7.117 2.602 2.483 0 4.922-1.006 7.118-2.602 1.964-1.428 3.682-3.292 4.99-5.203-3.364-4.112-6.977-7.945-12.108-7.945-5.13 0-8.743 3.833-12.107 7.945Zm-2.268-.777C7.007 13.11 11.37 7.875 18 7.875s10.993 5.236 14.375 9.418c.303.374.334.9.076 1.308-1.482 2.347-3.556 4.707-6.01 6.491-2.452 1.783-5.346 3.033-8.441 3.033-3.095 0-5.989-1.25-8.44-3.033-2.455-1.784-4.53-4.144-6.011-6.491a1.125 1.125 0 0 1 .076-1.308Z' fill='%2321201F'/%3E%3C/svg%3E") no-repeat 50% / rem(24 24);
      position: absolute;
      right: 0;
      bottom: 0;
    }

    input {
      padding-right: rem(45) !important;
    }

    &:has(input[type="text"]) {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5c-.96.003-1.91.203-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457.62.624 1.176 1.308 1.66 2.043-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709Z' fill='%2321272A'/%3E%3Cpath d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.83l.822.821Zm-2.943 1.3.822.821a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.83Z' fill='%2321272A'/%3E%3Cpath d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755 1.289 1.29 3.049 2.458 5.168 2.458.716 0 1.39-.133 2.02-.36l.77.771c-.88.385-1.83.585-2.79.588C3 13.5 0 8 0 8s.939-1.72 2.641-3.238l.708.71.001-.002Zm10.296 8.884-12-12 .708-.708 12 12-.708.708Z' fill='%2321272A'/%3E%3C/svg%3E");
        background-size: rem(18 18);
      }
    }
  }

  &__action {
    margin: rem(24 0);
    text-align: center;

    &-link {
      @extend %copy-md;
      @include animated-underline;
      color: var(--wp--preset--color--gray-one);
    }
  }
}

body:not(#id) {
  #loginform,
  #registerform,
  #lostpasswordform {
    background-color: var(--wp--preset--color--light-bg);

    > p {
      margin: rem(0 0 16);

      label {
        display: block;
        color: var(--wp--preset--color--gray-one);
        margin-bottom: rem(4);

        &:has(input[type="checkbox"]) {
          display: flex;
          align-items: center;
          gap: rem(10);
          margin: rem(20 0 34);
        }
      }

      &:has(input[type="checkbox"] + label) {
        display: flex;
        align-items: center;
        gap: rem(10);
        margin: rem(20 0 34px);

        input[type="checkbox"],
        label {
          margin: 0;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      margin: 0;
    }

    input[type="submit"] {
      width: 100%;
      font-size: rem(14);
      line-height: 20px;
      letter-spacing: 0.05em;
      padding: 12px 20px;
      text-transform: uppercase;

      &:hover {
        background-color: var(--wp--preset--color--primary);
      }
    }

    .lost-password {
      text-align: center;

      &__link {
        @extend %copy-md;
        @include animated-underline;
        color: var(--wp--preset--color--gray-one);
      }
    }

    .login-remember {
      display: none;
    }
  }
}
*/