.itm-intro {
  min-height: rem(552);
  padding: rem(160 80 30);
  overflow: visible;

  @include max(md) {
    min-height: rem(608) !important;
    height: auto;
    padding: rem(136 16 16 16) !important;
    transition: .3s;
  }

  .single-events & {
    @include min(lg) {
      min-height: rem(784) !important;
    }
  }

  &--collided {

    @include max(md) {
      padding-bottom: rem(116);
    }
  }

  .wp-block-cover {

    &__inner-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      padding: 0;
    }
  }

  .wp-block-buttons {
    margin-top: rem(10);

    @include max(md) {
      margin-top: 0;
    }
  }

  .yoast-breadcrumbs {
    width: 100%;
    max-width: 100%;
	  color: var(--wp--preset--color--white-static);
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-top: rem(94);
    padding-right: 50%;
    margin-top: auto;

    @include max(md) {
      text-align: center;
      padding-top: rem(48);
      padding-right: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    a {
      @include animated-underline;
      color: #fff;
    }

    .breadcrumb_last {
      font-weight: 700;
    }
  }

  p {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0 0 rem(10);

    @include max(md) {
      font-size: rem(14);
      margin: 0 0 rem(16);
    }
  }

  .wp-block-heading {
    font-size: rem(64);
    color: #fff;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0.05em;
    margin: 0 0 rem(20);

    @include max(md) {
      font-size: rem(38);
      line-height: 1.2;
    }

    br {

      @include max(md) {
        display: block;
      }
    }
  }

  .wp-block-gallery {

    @include max(md) {
      margin-top: 0;
    }
  }

}

.home {
  .itm-intro {
    min-height: 94vh !important;
    @include max(md) {
      min-height: 70vh !important;
    }
    @include max(lg) {
      min-height: 70vh !important;
    }
    @media screen and (min-height: 1024px) and (max-width: 1380px) {
      min-height: 60vh !important;
    }
  }
}