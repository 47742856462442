// Extras
.checkout-extras {
	&__filters {
		display: flex;
		gap: clamp-rem(24, 44);
		margin-bottom: rem(24);
		padding-bottom: rem(20);
		position: relative;

		&.sticky {
			position: fixed;
			width: 100%;
			border-bottom: 1px solid var(--wp--preset--color--stroke);
			top: calc(var(--sticky-header-height) - rem(1));
			left: 0;
			z-index: 10;
			background-color: var(--wp--preset--color--white);
			justify-content: center;
			padding-top: rem(22);
			opacity: 1;
			transition: opacity .3s ease;
			height: rem(65);

			&::after {
				display: none;
			}
		}

		&:after {
			content: '';
			display: block;
			width: 100%;
			border-bottom: 1px solid var(--wp--preset--color--gray-two);
			box-shadow: rem(0 1 4 0) rgba($b, 0.25);
			position: absolute;
			top: 100%;
			left: 0;
		}
		&-wrap {
			min-height: rem(66);
		}
	}

	&__filter {
		@extend %copy-md;
		color: var(--wp--preset--color--link);
		display: inline-flex;
		align-items: center;
		font-weight: 600;
		text-transform: uppercase;
		position: relative;
		transition: $tr;

		&:before {
			content: '';
			flex-shrink: 0;
			display: block;
			width: rem(10);
			height: 100%;
			background: url("data:image/svg+xml,%3Csvg width='10' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 .5a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 1 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 1 1 .708-.708L4.5 12.793V1A.5.5 0 0 1 5 .5Z' fill='%2321272A'/%3E%3C/svg%3E") no-repeat 50% / rem(10 15);
			transform: scaleX(0);
			transition: $tr;
			position: absolute;
			top: 0;
			left: 0;
		}

		.dark & {
			&:before {
				filter: invert(1);
			}
		}

		&.is-active {
			pointer-events: none;
			padding-left: rem(23);

			&:before {
				transform: scaleX(1);
			}
		}
	}

	&__group {
		&:not(:last-child) {
			margin-bottom: rem(40);
		}

		&-title {
			margin-bottom: rem(20);
		}
	}

	&__grid {
		display: grid;
		grid-gap: clamp-rem(16, 20);
		grid-template-columns: repeat(2, 1fr);
		.checkin & {
			@include min(lg) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

// Mini Cart (fixed on bottom)
.cart-extras,
.cart-comparison {
	width: 100%;
	background-color: var(--wp--preset--color--white);
	display: flex;
	align-items: center;
	margin: 0;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 90;
	transition: transform $tr;

	@include max(lg) {
		border-radius: $radius $radius 0 0;
		box-shadow: 0 -1px 1px 0 var(--wp--preset--color--stroke);
	}

	@include min(lg) {
		min-height: rem(80);
		border-top: 1px solid var(--wp--preset--color--stroke);
	}

	.checkout-steps[data-current="1"] + &,
	.checkout-steps[data-current="3"] + &,
	.checkout-steps[data-current="4"] + &,
	.checkout-steps[data-current="5"] + &,
	.checkout-steps[data-current="6"] + & {
		display: none !important;
		@include limit-text()
	}

	&.is-empty {
		transform: translateY(100%);
	}

	&.is-minimized {
		transform: translateY(100%);

		.cart-extras__toggle,
		.cart-comparison__toggle {
			height: rem(40);
			background-color: var(--wp--preset--color--primary);
			transform: translateY(-100%);
			border-radius: $radius $radius 0 0;
			box-shadow: rem(0 0 5 0) rgba($b, 0.09);
			transition: transform 0.5s $tr, background-color $tr, border-radius $tr;

			@include min(lg) {
				height: rem(50);
			}

			.icon {
				color: $w;
				&:not(.icon--io-cart) {
					transform: rotate(180deg);
				}
			}

			@include hover-responsive {
				background-color: var(--wp--preset--color--link);
			}
		}
	}

	&:not(.is-items-visible) {
		.cart-extras__items,
		.cart-comparison__items {
			@include max(lg) {
				max-height: 0;
				border: none;
				padding-top: 0;
				padding-bottom: 0;
				overflow: hidden;
			}
		}
	}

	&.is-items-visible {
		.cart-extras__items-added,
		.cart-comparison__items-added {
			grid-column: span 2;
		}

		.cart-extras__items-toggle,
		.cart-comparison__items-toggle {
			&:before {
				content: attr(data-hide);
			}
		}

		.cart-extras__toggle,
		.cart-comparison__toggle {
			display: none;
		}
	}

	.container {
		display: grid;
		grid-template-columns: 1fr auto auto auto;
		grid-column-gap: clamp-rem(12, 24, 400, 800);
		align-items: center;

		@include max(lg) {
			padding: rem(0 16);
		}

		@include min(lg) {
			grid-template-columns: 1fr auto auto;
			grid-gap: clamp-rem(16, 32);
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		gap: rem(10 14);
		padding: rem(10 0);
		transition: max-height $tr, padding $tr;

		@include max(lg) {
			overflow-y: auto;
			width: calc(100% + rem(32));
			max-height: rem(241);
			gap: rem(12);
			grid-column: span 4;
			border-bottom: 1px solid var(--wp--preset--color--stroke);
			margin: rem(0 -16);
			padding: rem(16 16 10);
		}

		> * {
			flex-shrink: 0;

			@include min(600) {
				max-width: rem(345);
			}

			@include min(lg) {
				width: rem(240);
			}
		}

		&-added {
			color: var(--wp--preset--color--base);
			margin: 0;

			&:before {
				content: attr(data-value);
				margin-right: rem(5);
			}

			&:after {
				content: attr(data-plural);
			}
		}
	}

	&__items-toggle,
	&__clear {
		font-size: rem(12);
		font-weight: 600;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		white-space: nowrap;

		@include max(lg) {
			line-height: rem(50);
		}

		@include min(lg) {
			font-size: rem(14);
			font-weight: 500;
		}
	}

	&__items-toggle {
		&:before {
			content: attr(data-show);
		}
	}

	&__clear {
		color: $b;

		@include max(lg) {
			&:before {
				display: none;
			}
		}

		@include theme-dark {
			color: $w;
		}
	}

	&__toggle {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--wp--preset--color--light-bg);
		transition: $tr;
		position: absolute;
		top: 0;
		left: 0;

		&-wrapper {
			width: rem(34);
			height: 100%;
			position: relative;

			@include min(md) {
				width: rem(56);
			}
		}

		@include hover-responsive {
			background-color: var(--wp--preset--color--gray-three);
		}

		.icon {
			color: var(--wp--preset--color--black);
			font-size: rem(20);
			transition: $tr;
		}
	}
}

.cart-extras {
	.icon--io-cart {
		display: none;
	}
	&.is-minimized {
		.icon {
			transform: none;
			&.icon--io-chevron-down {
				display: none;
			}
			&.icon--io-cart {
				display: block;
			}
		}
	}
}

.cart-comparison {
	z-index: 99;
	.container {
		@include min(lg) {
			grid-template-columns: 1fr auto auto auto;
			grid-gap: clamp-rem(16, 24);
		}
	}

	.icon--io-cart {
		display: none;
	}

//	.cart-comparison__btn, .js-comparison-clear {
//		@include max(lg) {
//			padding: rem(9) rem(16);
//			height: fit-content;
//			span {
//				font-size: rem(12);
//				line-height: rem(14);
//				white-space: nowrap;
//			}
//		}
//	}

	&__items {

		@include min(xxl) {
			flex-wrap: nowrap;

			> * {
				max-width: 100%;
				flex-shrink: 1;
			}
		}
	}
}
