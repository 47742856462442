.datepicker {
	letter-spacing: 0.05em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	&__row {
		width: 100%;
		display: flex;
		align-items: center;

		&--main {
			gap: rem(64);
			margin: rem(30 0);

			@include max(lg){
				gap: rem(32);
			}

			@include max(md) {
				gap: rem(8);
				padding: rem(0 16);
				margin: rem(10 0 12);
			}

			> .datepicker {

				&__col {

					> .datepicker {

						&__row {

							@include max(md) {
								flex-direction: column;
								gap: rem(10);
							}
						}
					}
				}
			}
		}
	}

	&__col {
		width: 50%;
		display: flex;

		@include max(md) {
			width: 100%;
		}
	}

	&__footer {
		width: 100%;
	}

	&__title {
		line-height: rem(22);
		display: flex;
		flex-direction: column;
		gap: rem(6);

		@include max(md) {
			letter-spacing: normal;
		}

		span {
			font-size: rem(14);
			line-height: rem(22);
			color: var(--wp--preset--color--primary);
			display: inline-block;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: rem(16);
		margin-top: rem(10);

		input[type=reset] {
			color: var(--wp--preset--color--main);
			background: none;
			border: 2px solid var(--wp--preset--color--gray-two);
			transition: 0.3s;

			&:hover {
				color: var(--wp--preset--color--white);
				background: var(--wp--preset--color--gray-two);
			}

			&::before {
				content: '';
				position: absolute;
				left: var(--x);
				top: var(--y);
				width: calc(var(--wp--preset--font-size--medium) * 8);
				height: calc(var(--wp--preset--font-size--medium) * 8);
				background: radial-gradient(circle closest-side, rgba(74, 52, 166, 0.5), transparent);
				transform: translate(-50%, -50%) scale(0);
				will-change: transform;
				transition: transform 0.2s ease;
				display: block;
			}
		}
	}

	.timepicker {
		width: 100%;

		&__wrap {
			width: 100%;
		}

		&__options {
			width: 100%;
		}

		&__value {
			width: 100%;
			border: 1px solid var(--wp--preset--color--primary);

			&::before {
				content: $icon--io-chevron-down;
				font-family: "campeasy";
				position: absolute;
				top: 50%;
				right: 1rem;
				transform: translateY(-50%);
			}
		}
	}

	&__legend {
		display: flex;
		margin: rem(20 0 42);

		@include max(md) {
			padding-left: var(--wp--style--root--padding-right);
			padding-right: var(--wp--style--root--padding-right);
			flex-wrap: wrap;
			padding: 0;
			gap: 1rem;
		}

		.datecell {
			font-size: rem(14);
			color: var(--wp--preset--color--gray-one);
			letter-spacing: 0.05em;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 1 0;
			gap: rem(8);

			@include max(md) {
				width: 100%;
				flex-shrink: 0;
				justify-content: flex-start;
				gap: 1rem;
				flex: none;
			}

			&__value {
				width: rem(38);
				height: rem(38);
				font-size: rem(12);
				font-weight: 500;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 rem(38);
				background: var(--wp--preset--color--white);
				border-radius: 50%;

				&.unavailable {
					color: var(--wp--preset--color--gray-three);
					text-decoration: line-through;
				}
			}

			&__title {

				@include max(md) {
					font-size: rem(12);
				}
			}
		}
	}
}

.modal-datepicker {

	.easepick-wrapper {
		width: 100%;
		height: 100%;
		padding: 1.5rem 0 0;
		display: flex;
		flex-direction: column;
	}

	@include max(md) {
		padding: 1.5rem 1rem;

		.easepick-wrapper {
			padding: 0;
		}
	}

	.modal {

		&__inner {
			max-height: 100dvh;

			@include max(md) {
				height: calc(100dvh - 3rem);
				padding: 0;
				margin: 0;
			}
		}

		&__content {
			width: auto;
			padding-top: rem(30);

			@include max(md) {
				width: 100%;
				height: 100vh;
				border: none;
				padding: 0;
				margin: 0;
			}
		}

		&__content-inner {

			@include max(md) {
				height: 100%;
				max-height: calc(100dvh - 3rem);
				padding: 0;
				transform: none;
				overflow: hidden;
			}
		}
	}

	.datepicker {
		height: inherit;

		&__buttons {
			padding: rem(0 24);

			@include max(md) {
				padding: rem(0 16);
			}

			.wp-block-button {
				@include max(md) {
					width: 100%;
				}

				button {
					color: var(--wp--preset--color--white-static);

					@include max(md) {
						width: 100%;
						line-height: 1.125rem;
						font-size: 0.75rem;
						padding: 0.625rem !important;
					}
				}
			}
		}

		&__title {

			@include max(md) {

				span {
					display: none;
				}
			}
		}

		&__footer {
			padding-bottom: 1rem;
		}
	}

	.timepicker {

		&__wrap {

			@include max(md) {
				width: auto;
				right: auto;
				left: 0;
			}
		}

		&__value {

			@include max(md) {
				border: 1px solid var(--wp--preset--color--stroke);
			}

			&.active {

				@include max(md) {
					color: var(--wp--preset--color--primary);
					border: 1px solid var(--wp--preset--color--primary);
				}
			}
		}
	}
}

.availability-datepicker {
	width: fit-content;
	margin: 0 auto;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include max(400) {
		margin-left: calc(var(--wp--style--root--padding-right) * -1);
		margin-right: calc(var(--wp--style--root--padding-right) * -1);
	}

	&.loading {
		opacity: 0.5;
		transition: 0s;
	}

	button[type=submit] {

		&.locked {
			background: var(--wp--preset--color--gray-two) !important;
			pointer-events: none !important;
		}
	}
}
