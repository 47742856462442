// Booking detail card
.booking-detail {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: rem(24 40);
  margin-bottom: rem(30);
  position: relative;
  @include max(md) {
    grid-template-columns: 1fr;
  }

  &__image {
    width: rem(80);
    margin: 0;

    @include min(480) {
      width: rem(100);
    }

    @include min(md) {
      width: rem(150);
    }

    @include max(1299) {
      grid-row: 1/3;
    }

    img {
      width: 100%;
      aspect-ratio: 160/120;
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__vehicle {
    @extend %copy-md;
    color: var(--wp--preset--color--gray-one);
    margin-bottom: rem(20);
  }

  &__details {
    @include unstyled-list;
    display: flex;
    gap: rem(10 20);
    flex-wrap: wrap;
    margin-bottom: rem(24);

    li {
      &:before {
        content: '•';
        margin-right: rem(7);
      }
    }
  }

  &__pricing {
    display: flex;
    align-items: center;
    gap: rem(20);

    @include max(1300) {
      grid-column: 2/3;
      justify-content: flex-end;
    }
  }

  &__price {
    flex-shrink: 0;
    @extend %h4;
    white-space: nowrap;
  }

  &__actions {
    flex-shrink: 0;
    position: relative;

    .btn.is-open {
      background-color: var(--wp--preset--color--primary);
      color: $w;
    }

    &-list {
      display: none;
      background-color: var(--wp--preset--color--white);
      border-radius: $radius;
      padding: rem(16);
      box-shadow: rem(2 2 10 0) rgba($b, 0.15);
      position: absolute;
      top: calc(100% + rem(16));
      right: 0;

      a {
        @include animated-underline;
        color: var(--wp--preset--color--base);
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: rem(5);
        }
      }
    }
  }

  &__footer {
    text-align: right;
  }

  &__info {
    @extend %copy2;
    display: inline-flex;
    align-items: center;
    gap: rem(10);
    color: var(--wp--preset--color--gray-one);

    &[data-checkin="available"] {
      color: var(--wp--preset--color--link);
    }

    .icon--io-warning {
      color: var(--wp--preset--color--warning);
    }

    .icon--io-star {
      color: var(--wp--preset--color--gray-two);
    }
  }
}
