// Checkout page
.checkout {
  background-color: var(--wp--preset--color--light-bg);

  &-wrapper {
    display: flex;
    flex-direction: column-reverse;
    row-gap: rem(40);

    @include min(lg) {
      flex-direction: row;
      gap: clamp-rem(24, 38);
    }

	  &__container {
		  padding-top: clamp-rem(25, 80);
		  padding-bottom: clamp-rem(80, 120);
      .checkin & {
        padding-top: clamp-rem(25, 40);
		  padding-bottom: clamp-rem(40, 80);
		  padding-left: rem(20);
		  padding-right: rem(20);
      }
	  }
  }

  &.checkin {
    background-color: var(--wp--preset--color--white);
    [data-current-step-key="driver_license"] {
		background-color: var(--wp--preset--color--light-bg);
      .checkout-step {
		  background-color: var(--wp--preset--color--light-bg);
		  padding-top: clamp-rem(25, 40);
		  padding-bottom: 0;
      }
      .exclusives {
        background-color: var(--wp--preset--color--white);
      }
		[type="submit"] {
			display: none;
		}
    }
  }

  &-title {
    margin-bottom: clamp-rem(24, 40);
  }

  &-form {
    max-width: rem(725);
    .checkin & {
      [type="submit"] {
        background-color: var(--wp--preset--color--gray-two);
        &:hover {
          background-color: var(--wp--preset--color--primary);
        }
      }
      h4 {
        margin-top: 0;
        margin-bottom: rem(30);
      }
    }
    &.checkout-payment__form {
      max-width: 100%;
    }
  }

  // Sidebar: Summary
  &-sidebar {
	  will-change: min-height;

    @include max(lg) {
      max-width: rem(375);
      max-height: calc(100dvh - var(--sticky-header-height) - #{rem(72)});
      overflow-y: auto;
      position: fixed;
      top: calc(var(--sticky-header-height) + #{rem(40)} + rem(32));
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity $tr;
      width: .1rem;
      height: .1rem;

      body:has(.step-actions-mobile.is-visible.is-open) & {
        opacity: 1;
        visibility: visible;
        z-index: 100;
        width: 100%;
        height: auto;
      }

      .page-checkout .checkout-steps[data-current="4"] &,
      .page-checkout-event .checkout-steps[data-current="2"] & {
        position: static;
        opacity: 1;
        visibility: visible;
      }
    }

    @include max(440) {
      max-width: unset;
    }

    @include max(md) {
      top: calc(var(--sticky-header-height) + #{rem(72)});
    }

    @include min(lg) {
      width: rem(384);
      flex-shrink: 0;
    }

    &__inner {
	    transform: translate3d(0, 0, 0);
	    will-change: position, transform;

      &.step-2 {
        top: rem(234);
        transition: .3s ease;
        &.step-2-down {
          top: rem(-220);
        }
      }

      @include max(lg) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &-content {
    flex-grow: 1;
  }

  // Login form
  &-login {
    @include border;
    background-color: var(--wp--preset--color--white);
    padding: clamp-rem(20, 26) clamp-rem(16, 26);
    margin-bottom: rem(40);

    &__title {
      font-size: clamp-rem(16, 22);
      letter-spacing: 0.02em;
      line-height: 1.4;
      margin-bottom: rem(16);
    }

    &__form {
      &-fields {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: clamp-rem(16, 24);

        @include min(xl) {
          gap: clamp-rem(24, 44);
        }

        @include min(md) {
          flex-direction: row;
        }
      }

      &-text {
        @include max(md) {
          font-size: rem(14);
          letter-spacing: 0.05em;
        }

        a {
          @include animated-underline;
        }
      }
    }

    .form-row {
      display: flex;
      flex-direction: column;
      gap: rem(16 24);
      margin-bottom: clamp-rem(16, 20);

      @include min(md) {
        align-items: flex-end;
        flex-direction: row;
      }

      @include min(lg) {
        align-items: initial;
        flex-direction: column;
      }

      @include min(1200) {
        align-items: flex-end;
        flex-direction: row;
      }
    }

    .form-field {
      flex-grow: 1;

      label {
        @extend %copy-md;
        display: block;
        margin-bottom: rem(2);
      }
    }

    .btn {
      flex-shrink: 0;

      @include max(md) {
        height: rem(34);
        align-self: flex-start;
        font-size: rem(12) !important;
        padding: rem(0 22) !important;
      }
    }
  }

  &-login-cta {
    @include border;
    display: inline-flex;
    gap: rem(10);
    padding: rem(12 20);
    margin-bottom: rem(40);

    @include min(lg) {
      padding: rem(20);
    }

    .icon {
      font-size: rem(24);
    }

    a {
      @include animated-underline;
    }
  }

  // Insurance
  &-insurance {
    margin-bottom: clamp-rem(40, 100);

    &__title {
      margin-bottom: clamp-rem(16, 27);
    }

    &__subtitle {
      margin-bottom: rem(24);
    }

    &__card {
      @include border;
      display: grid;
      grid-template-columns: 1fr;
      gap: rem(16 30);
      background-color: var(--wp--preset--color--white);
      padding: clamp-rem(20, 35) clamp-rem(16, 20) clamp-rem(20, 24);

      @include min(md) {
        grid-template-columns: 4fr 5fr;
        row-gap: rem(18);
      }

      &-title {
        font-weight: 500;
        letter-spacing: 0.02em;
        margin-bottom: clamp-rem(12, 18);

        span {
          @include max(lg) {
            display: block;
            color: var(--wp--preset--color--primary);
            margin-bottom: rem(8);
          }
        }

        strong {
          font-weight: 700;

          @include max(lg) {
            color: var(--wp--preset--color--primary);
          }
        }

        small {
          font-size: rem(16);
          font-weight: 400;
        }
      }

      &-subtitle {
        margin-bottom: rem(14);
      }

      &-table {
        @extend %copy-md;
        width: 100%;

        @include min(md) {
          font-size: rem(12);
        }

        tr {
          &:not(:last-child) {
            @include min(md) {
              border-bottom: 1px solid var(--wp--preset--color--gray-three);
            }
          }
        }

        td {
          padding: rem(5 16 5 0);

          @include min(md) {
            padding: rem(6 12 8 0);
          }

          &:nth-child(1) {
            color: $b;

            @include theme-dark {
              color: $w;
            }
          }

          &:nth-child(2) {
            color: var(--wp--preset--color--gray-one);

            @include max(md) {
              min-width: rem(108);
              padding-right: 0;
            }

            @include min(md) {
              text-align: right;
            }

            .check-icon {
              margin-right: rem(5);
            }
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      &-list {
        @include unstyled-list;
        @extend %copy-md;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(rem(250), 1fr));
        grid-gap: rem(6 12);

        @include min(md) {
          font-size: rem(12);
        }

        li {
          display: flex;
          align-items: baseline;
          gap: clamp-rem(8, 12);
        }
      }

      &-footer {
        display: flex;
        flex-direction: column-reverse;
        gap: rem(26 30);

        @include min(md) {
          display: grid;
          align-items: center;
          grid-template-columns: 4fr 5fr;
          grid-column: span 2;
        }
      }

      &-btn {
        > span {
          @extend .btn;
          pointer-events: none;
          text-transform: initial;

          @include max(md) {
            height: rem(36);
            font-size: rem(12);
            padding: rem(0 22);
          }

          .icon {
            font-size: rem(16);
          }
        }
      }

      &-more {
        display: inline-block;
        text-decoration: underline;
        font-size: clamp-rem(14, 16);
        letter-spacing: 0.05em;

        @include hover-responsive {
          text-decoration: none;
        }
      }
    }
  }

  // Insurances
  &-insurances {
    margin-bottom: rem(30);

    @include min(md) {
      margin-bottom: rem(40);
    }

    &__title {
      margin-bottom: clamp-rem(24, 34);
	    padding-right: 1.5rem;
      .checkin & {
        font-size: rem(30);
        font-weight: 600;
        line-height: rem(38);
        letter-spacing: 0.05em;
        margin-bottom: clamp-rem(24, 45);
      }

      .checkin & {
        margin-bottom: clamp-rem(24, 45);
      }
    }
  }

  // Insurance Comparison
  &-insurance-comparison {
    margin-bottom: rem(60);

    &__title {
      margin-bottom: rem(20);
    }
  }

  // Extra Coverage
  &-extra-coverage {
    .checkout-title {
      @include max(lg) {
        font-size: rem(20);
        font-weight: 500;
        margin-bottom: rem(30);
      }
    }
  }

  // FAQ
  &-faq {
	  .checkin & {
		  display: none;
	  }
    margin-top: clamp-rem(50, 80);

    &__title {
      font-size: clamp-rem(26, 30);
      margin-bottom: rem(30);
    }
  }

  // Rental Details
  &-rental-details {
    + .checkout-rental-details {
      margin-top: clamp-rem(40, 60);
    }

	  + .checkout-rental-details__form {
		  margin-top: rem(30);
	  }

    .field-switch {
      @include min(md) {
        min-height: rem(44);
      }
    }

    button[type="reset"] {
      font-size: rem(12);
      font-weight: 600;
      letter-spacing: 0.05em;
    }
  }

  // Shuttle
  &-shuttle {
    border-top: 1px solid var(--wp--preset--color--stroke);
    margin-top: clamp-rem(16, 60);
    padding-top: clamp-rem(30, 40);
    .checkin & {
      display: flex;
      flex-wrap: wrap;
      gap: rem(20);
      margin: 0;
      border-top: 0;
      padding-top: 0;
    }

    &__title {
      font-size: clamp-rem(20, 22);
      margin: 0 0 clamp-rem(30, 60);
      .checkin & {
        width: 100%;
        font-size: rem(30);
        font-weight: 600;
        line-height: rem(38);
        letter-spacing: 0.05em;
        margin-bottom: rem(10);
      }
    }

    &__subtitle {
      font-size: clamp-rem(16, 18);
      margin: 0;
      .checkin & {
        width: 100%;
      }

      &-warning {
        @extend %copy-sm;
        margin-left: rem(15);
        .checkin & {
          width: 100%;
        }

        @include max(xs) {
          display: block;
          margin: rem(10 0 0);
        }

        .icon {
          font-size: rem(20);
          margin-right: rem(10);
          position: relative;
          top: rem(4);
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: clamp-rem(16, 20);
      position: relative;
      .checkin & {
        flex: 1;
        border-radius: rem(9);
        @include min(xs) {
          background-color: var(--wp--preset--color--light-bg);
          padding: rem(30);
        }
      }

      + .checkout-shuttle__form {
        margin-top: clamp-rem(40, 80);
        .checkin & {
          margin-top: 0;
        }
      }

      // State: shuttle time has been confirmed
      &.is-confirmed {
        .form-card__inner {
          filter: blur(2px);
        }
      }

      &:not(.is-confirmed) {
        .checkout-shuttle__confirmation {
          display: none;
        }
      }
    }

    &__confirmation {
      @include border;
      width: calc(100% + #{rem(10)});
      height: calc(100% + #{rem(10)});
      background-color: rgba($w, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: rem(24);
      position: absolute;
      top: rem(-5);
      left: rem(-5);
      z-index: 10;

      &-title {
        display: inline-flex;
        gap: 0.4em;
        font-size: clamp-rem(20, 30);
        font-weight: 600;
        letter-spacing: 0.05em;
        padding: rem(10);
        .dark & {
            color: var(--wp--preset--color--white);
        }

        @include max(480) {
          max-width: rem(230);
        }

        .icon {
          font-size: 1.5em;
        }
      }
    }
  }

  // Payment
  &-payment {
    &__promo-code {
      width: 100%;
      max-width: rem(450);
      display: flex;
      flex-direction: column;
      gap: clamp-rem(16, 26);

      @include min(480) {
        flex-direction: row;
        align-items: center;
      }

      input[type="text"] {
        flex-grow: 1;
      }

      .btn {
        flex-shrink: 0;
      }
    }

    &__options {
      margin-bottom: clamp-rem(30, 40);

      &-title {
        margin-top: clamp-rem(40, 80);
        margin-bottom: clamp-rem(24, 30);
      }

      &-list {
        @include unstyled-list;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: rem(20);

        @include min(md) {
          flex-direction: row;
        }
      }

      .is-loading {
        width: 100%;
        height: 400px;
      }
    }

    &__option {
      @include border;
      background-color: var(--wp--preset--color--white);
      display: flex;
      flex-direction: column;
      position: relative;

      @include min(md) {
        width: calc(50% - #{rem(10)});
        max-width: rem(413);
      }
      @include min(lg) {
        width: calc(33.33333% - #{rem(14)});
        max-width: rem(413);
      }

      &-title {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: rem(16);
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        padding: rem(30 16 20);
        position: relative;

        h4 {
          font-weight: 500;
          margin: 0;
        }
      }

      input[type="radio"] {
        background-position: 50% !important;

        &:checked {
          // icon check mark black
          background: url("data:image/svg+xml,%3Csvg width='12' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.568 8.647.7 4.856 2.283 3.23l2.285 2.244L9.718.372 11.3 1.985l-6.732 6.66v.002Z' fill='%2321201F'/%3E%3C/svg%3E") no-repeat 50% / rem(12 9) !important;

          @include theme-dark {
            // icon check mark white
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.568 8.647.7 4.856 2.283 3.23l2.285 2.244L9.718.372 11.3 1.985l-6.732 6.66v.002Z' fill='%23FFFFFF'/%3E%3C/svg%3E") !important;
          }
        }
      }

      .tooltip {
        margin-left: rem(-10);
        @include max(md) {
          position: initial;
        }
        &__content {
          z-index: 9;
          @include max(md) {
            width: calc(100vw - rem(32));
            margin: auto;
          }
          h1, h2, h3, h4, h5, h6 {
            .dark & {
              color: $color-base;
            }
          }
          &-inner {
            max-height: 40vh;
            @include max(lg) {
              max-height: 25vh;
            }
            @include max(md) {
              max-height: 30vh;
            }
            .tab-content {
              overflow-y: scroll;
              scrollbar-color: var(--wp--preset--color--primary) transparent;
              scrollbar-width: rem(2);
              max-height: calc(40vh - rem(52));
              @include max(lg) {
                max-height: calc(25vh - rem(52));
              }
              @include max(md) {
                max-height: calc(30vh - rem(52));
              }
              &::-webkit-scrollbar {
                width: rem(2);
              }
            }
          }
        }
      }

      &-discount {
        display: inline-block;
        background-color: var(--wp--preset--color--success);
        border-radius: 0 $radius 0 $radius;
        color: $w;
        font-size: rem(10);
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: rem(12);
        padding: rem(8 10);
        text-align: center;
        position: absolute;
        top: -1px;
        right: -1px;
      }

      &-list {
        @include unstyled-list;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: clamp-rem(20, 24);
        padding: rem(32 16);

        .list-item {
          display: flex;
          align-items: center;
          gap: rem(7);

          .icon {
            font-size: rem(24);
            flex-shrink: 0;
          }
        }
      }

      &-footer {
        border-top: 1px solid var(--wp--preset--color--stroke);
        padding: rem(20 16);

        @include min(md) {
          padding-bottom: rem(30);
        }

        > span {
          @extend %copy-md;
          margin-bottom: rem(12);
        }
      }

      &-price {
        margin: 0;
      }
    }

    &__providers {
      margin-bottom: clamp-rem(30, 40);

      &-list {
        @include unstyled-list;
        @include border;
        background-color: var(--wp--preset--color--white);
        display: flex;
        flex-direction: column;
        gap: clamp-rem(20, 26);
        padding: rem(20 16);

        li {
          label {
            display: flex;
            align-items: center;
            gap: rem(10);
          }
        }
      }
    }

    &:not(:has(input[name="payment_option"]:checked)) {
      .checkout-payment__providers-list {
        background-color: var(--wp--preset--color--gray-three);
        pointer-events: none;
      }
    }

    .field-checkbox {
      a {
        @include animated-underline;
      }
    }
  }
}

.electric-disclaimer {
  @extend %copy-md;
  @include border;
  background-color: var(--wp--preset--color--white);
  display: flex;
  flex-direction: column;
  gap: rem(16);
  margin-bottom: rem(40);
  padding: rem(16);

  @include min(480) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 clamp-rem(24, 32);
    align-items: flex-start;
    padding: rem(28 26);
  }

  @include min(md) {
    align-items: center;
  }

  &__image {
    flex-shrink: 0;
    margin: 0;

    @include max(479) {
      width: calc(100% + #{rem(32)});
      background-color: var(--wp--preset--color--light-bg);
      margin: 0 rem(-16);
      padding: rem(5);
      text-align: center;
    }

    @include min(480) {
      grid-row: span 4;
    }

    img {
      width: clamp-rem(108, 128);
    }
  }

  &__title {
    margin: rem(0 0 10);

    @include max(479) {
      order: -2;
    }
  }

  &__text {
    @include min(480) {
      margin-bottom: rem(14);
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    gap: rem(5);
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-bottom: rem(14);

    @include max(479) {
      order: -1;
      margin-top: rem(-4);
      margin-bottom: 0;
    }

    .icon {
      font-size: rem(24);
    }
  }

  &__prices {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12);

    @include min(576) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: rem(16);
    }

    > span {
      display: inline-flex;
      gap: rem(10);
    }

    label {
      @include max(479) {
        min-width: rem(85);
      }
    }

    .btn.disabled {
      @include theme-dark {
        background-color: $color-gray-two !important;
        border-color: $color-gray-two !important;

        span {
          gap: rem(4);
        }
      }
    }
  }
}

[name="phone_flag"] {
  font-family: "Twemoji Country Flags";
  letter-spacing: rem(10);
}
