// Campers (funnel and archive)
.campers {
  &-content {
	padding: 0;
	margin: 0;
  }
  &-wrapper {
    margin-top: rem(20);
    margin-bottom: var(--wp--preset--spacing--xxl) !important;
  }

  &-header {
    padding: rem(110) 0 clamp-rem(35, 90);
    text-align: center;

    &__dates {
      letter-spacing: 0.05em;
      text-transform: uppercase;

      strong {
        color: var(--wp--preset--color--link);
      }
    }
  }

  &-filters {
	  margin: 0;

	  .page-campers & {
	    margin-top: var(--wp--preset--spacing--xxl);
	  }

    .wp-admin & {
      display: none !important;
    }

    &__inner {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: rem(16);
      align-items: center;
      margin-bottom: rem(16);

      @include min(lg) {
        row-gap: 0;
      }
    }

    &__features {
      justify-self: center;
      position: relative;

      @include max(md) {
        margin-right: auto;

        &:not(.is-open) {
          .campers-filters__features-inner {
            display: none;
          }
        }
      }

      &-inner {
        @include max(md) {
          display: none;
          position: absolute;
          top: calc(100% + rem(10));
          left: 50%;
          z-index: 2;
	        transform: translateX(-50%);
        }

        @include min(md) {
          display: block !important;
        }
      }

      &-fields {
        @extend %field-select;
        background-color: var(--wp--preset--color--white);
        flex-wrap: wrap;
        gap: rem(12) clamp-rem(40, 62) !important;

        @include max(md) {
          height: auto !important;
          flex-direction: column;
        }
      }

      &-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: rem(16);

        @include min(md) {
          width: auto;
          align-items: center;
          flex-direction: row;
          gap: clamp-rem(20, 32);

          &:not(:last-child) {
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 1px;
              height: rem(32);
              background: var(--wp--preset--color--stroke);
              position: absolute;
              top: calc(50% - rem(16));
              right: calc(clamp-rem(20, 32) * -1);
            }
          }
        }

        label {
          @extend %label-radio;

          @include max(md) {
            justify-content: space-between;
          }
        }
      }

      &-toggle {
        @extend %field-select;

        &.is-open {
          border-color: var(--wp--preset--color--primary) !important;
        }
      }
    }

    &__all {
      &-icon {
        @extend %field-select;
      }

      .btn-icon {
        width: rem(46);
        height: rem(46);
        font-size: rem(22);
      }
    }

    &__layout {
      min-width: rem(102);

      .custom-select__list {
        .icon {
          pointer-events: none;
        }
      }
    }

    &__divider {
      grid-column: -1/1;
      margin: rem(30 0 16);
      border-bottom: 1px solid var(--wp--preset--color--stroke);
    }

    &__more {
      @extend %copy-sm;
      grid-column: -1/1;
      color: var(--wp--preset--color--gray-one);

      @include min(lg) {
        grid-column: 1/3;
        width: calc(100% - rem(250));
      }

      &:empty {
        display: none;
      }

      a {
        @include animated-underline();
      }
    }

    &__sorting {
      grid-column: 3/4;

      @include max(lg) {
        grid-row: 1/1;
      }

      @include min(lg) {
        height: rem(48);
        position: relative;
      }

      .custom-select {
        display: flex;
        align-items: center;
        gap: rem(8);
        transition: filter $tr;

        @include min(lg) {
          position: absolute !important;
          top: 0;
          right: 0;

          &:not(.active):not(:hover) {
            filter: none;

            .custom-select__current {
              border-color: transparent;
            }
          }
        }

        .custom-select__current {
          padding-right: rem(40) !important;

          @include max(lg) {
            font-size: 0;
            gap: 0;

            &:before {
              content: attr(data-mobile);
              font-size: rem(16);
            }
          }
        }

        > .icon {
          font-size: rem(20);
          position: absolute;
          top: rem(14);
          right: rem(22);
          pointer-events: none;

          @include min(lg) {
            right: rem(14);
          }
        }

        @include max(lg) {

          &__list {
            left: auto !important;
            right: 0;
            border-top-left-radius: rem(24) !important;
            width: auto !important;
          }
        }
      }
    }

    &__search {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: relative;

      @include max(md) {
        grid-column: -1/1;
        grid-row: -2/-1;
      }

      @include min(md) {
        width: rem(210);
      }

      .campers-filters__search-input {
        text-overflow: ellipsis;
        background: var(--wp--preset--color--white);
        border-radius: $radius-lg !important;
        padding-right: rem(40);

        body:not(.wp-admin) & {
          box-shadow: rem(0 0 10) rgba(0, 0, 0, 0.1);
          transition: $tr;

          @include min(md) {
            width: rem(125);

            &:focus {
              width: rem(210);
            }
          }
        }
      }

      &-btn {
        width: rem(40);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &-filters-side {
    .offcanvas__content {
      padding-top: 0;
      padding-bottom: rem(30);
    }

    &__group {
      margin-bottom: rem(20);

      &:first-child {
        .campers-filters-side__group-header {
          border-top: none;
        }
      }

      &-header {
        display: flex;
        align-items: center;
        gap: rem(7);
        background-color: var(--wp--preset--color--light-bg);
        border-top: 1px solid var(--wp--preset--color--stroke);
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        padding: rem(16) var(--offcanvas-padding);
        margin: 0 calc(var(--offcanvas-padding) * -1) rem(20);

        .icon {
          font-size: rem(24);
        }
      }

      &-more {
        display: inline-flex;
        align-items: center;
        gap: rem(4);
        margin-top: rem(10);
        cursor: pointer;

        span {
          &:after {
            content: attr(data-more);
          }
        }

        &.is-open {
          span {
            &:after {
              content: attr(data-less);
            }
          }

          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__actions {
      margin-top: rem(30);

      .btn {
	      font-size: rem(12) !important;
	      border-radius: rem(30) !important;
	      padding-left: rem(20) !important;
	      padding-right: rem(20) !important;
      }
    }
  }
}

.filter-group--count {
  @include border;
  display: inline-flex;
  background-color: var(--wp--preset--color--light-bg);
  overflow: hidden;

  label {
    width: rem(53);
    height: rem(44);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $tr;

    &:has(input:checked) {
      background-color: rgba($dark-link, 0.5);

      &:not(:last-child) {
        border-right: 1px solid var(--wp--preset--color--stroke);
      }
    }

    @include hover-responsive {
      background-color: var(--wp--preset--color--gray-three) !important;
    }

    input[type="radio"],
    input[type="checkbox"] {
      display: none !important;
    }
  }
}

.filter-group--radio {
  display: flex;
  flex-direction: column;
  gap: rem(10);

  label {
    @include border;
    min-height: rem(44);
    display: flex;
    align-items: center;
    gap: rem(7);
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(8 20);
    transition: $tr;

    &:has(input:checked) {
      background-color: rgba($dark-link, 0.5);
    }

    &:has(.icon--io-star) {
      gap: rem(2);

      input {
        margin-right: rem(8);
      }
    }

    @include hover-responsive {
      background-color: var(--wp--preset--color--gray-three);
    }

    .icon {
      font-size: rem(24);
    }

    .icon--io-star,
    .icon--io-star-outline {
      font-size: rem(16);
    }
  }
}

.filters-nav {
  min-width: rem(50);
  background-color: var(--wp--preset--color--campeasy-white);
  border: 1px solid var(--wp--preset--color--stroke);
  border-radius: 0 $radius $radius 0;
  margin: 0;
  padding: rem(16 5 20);
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(-101%, -50%);
  transition: transform $tr;
  z-index: 90;

  @include min(1300) {
    min-width: rem(70);
    padding: rem(16 12 26);
  }

  @include hover-responsive {
    .icon__title {
      max-width: rem(150);
      padding-left: rem(10);

      @include max(1299) {
        padding-right: rem(5);
      }
    }
  }

  &.is-active {
    transform: translate(0, -50%);
  }

  &__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: rem(16);
    border-top: 1px solid var(--wp--preset--color--stroke);
    margin-top: rem(15);
    padding-top: rem(14);

    @include min(1300) {
      gap: rem(24);
      margin-top: rem(24);
      padding-top: rem(24);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    &:hover {
      .icon-circle {
        background-color: var(--wp--preset--color--primary);
      }
    }

    &.is-active {
      .icon-circle {
        background-color: var(--wp--preset--color--primary);
      }
    }
  }

  .icon__title {
    max-width: 0;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
    transition: $tr;

    @include min(xxl) {

      max-width: rem(150);
      padding-left: rem(10);
    }
  }

  .icon-circle {
    background-color: var(--wp--preset--color--gray-three);

    @include max(1299) {
      width: rem(36);
      height: rem(36);
    }

    @include hover-responsive {
      text-decoration: none;

      .icon {
        transform: scale(1.1);
      }
    }

    .icon {

      @include theme-dark {
        color: var(--wp--preset--color--white-static)
      }
    }
  }

  .js-offcanvas-open {
    cursor: pointer;

    @include hover-responsive {
      .icon-circle .icon {
        transform: scale(1.1);
      }
    }
  }

  label {
    @include hover-responsive {
      .icon-circle .icon {
        transform: scale(1.2);
      }
    }
  }
}

.dark {
  .campers-filters-side__actions {
    [type="submit"] {
      color: $w;
    }
  }
}
