// Event Card
$container-card-small-width: 550px;
$container-campers-width: 620px;
$translate-z: translateZ(40px) scale(0.98);

.event-card {
  width: 100%;
  position: relative;
  --event-color: var(--wp--preset--color--primary);

  &.category-vanlife-iceland {
    --event-color: var(--wp--preset--color--secondary-two);

    .event-card__actions .btn,
    .event-camper__btn {
      &:before {
        background: radial-gradient(circle closest-side, rgba(#274f5b, 0.5), transparent);
      }

      &.btn-outline:not(:hover),
      &.btn-outline:not(:focus),
      &.btn-outline:not(:active) {
        &:before {
          background-color: transparent;
        }
      }
    }

    .event-card__category {
      &:before {
        background: radial-gradient(circle closest-side, rgba(#274f5b, 0.5), transparent);
      }
    }
  }

  &.is-animating {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  &.is-hovered {
    @include hover-responsive {
      .event-card__content,
      .event-card__overlay-overview,
      .event-card-small__back {
        border-color: var(--event-color);
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--wp--preset--color--campeasy-white);
    border-radius: $radius;

    @include min(lg) {
      flex-direction: row;
    }
  }

  .btn-icon {
    @include min(xs) {
      width: rem(45);
      height: rem(45);
    }
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overview {
    width: 100%;
    flex-shrink: 0;
    aspect-ratio: 760 / 500;
    border-radius: $radius $radius 0 0;
    overflow: hidden;
    position: relative;
    transition: border-color $tr;

    @include min(lg) {
      width: 60%;
      min-height: rem(500);
      display: flex;
      align-items: center;
      border-radius: 0 $radius $radius 0;
    }

    @include min(xl) {
      aspect-ratio: initial;
      max-height: rem(500);
    }
  }

  &__meta {
    display: inline-flex;
    align-items: center;
    gap: rem(14);
    position: relative;

    &-date {
      background-color: var(--event-color);
      color: $color-white;
      font-size: rem(14);
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: rem(44);
      border-radius: 0 $radius $radius 0;
      padding: rem(0 16);
      white-space: nowrap;
	    text-transform: uppercase;

      @include min(lg) {
        font-size: clamp-rem(16, 20);
        line-height: clamp-rem(46, 48);
        padding: rem(0 30);
      }
    }

    &-photo,
    &-location {
      width: rem(50);
      height: rem(50);
      background-color: #fff;
      border: 2px solid var(--event-color);
      border-radius: 50%;
      overflow: hidden;

      @include hover-responsive {
        img {
          transform: scale(1.1);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $tr;
      }
    }

    &-location {
      img {
        object-fit: contain;
      }
    }
  }

  &__content {
    background-color: var(--wp--preset--color--light-bg);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--wp--preset--color--light-bg);
    border-radius: 0 0 $radius $radius;
    padding: rem(30 20 30);
    transition: border-color $tr;

    @include min(lg) {
      background-image: url("data:image/svg+xml,%3Csvg width='337' height='280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.726 0C79.4 187.083 186.19 213.809 186.19 213.809s78.184-40.09 135.393-181.356c54.348 114.54-52.441 212.854-52.441 212.854L313 264.397c-78.446 21.954-181.159 14.318-181.159 14.318S-77.923 203.309 31.726 0Z' fill='%23fff'/%3E%3C/svg%3E");
      background-position: calc(100% - rem(14)) calc(100% - rem(14));
      background-size: 65%;
      border-radius: $radius 0 0 $radius;
      position: relative;
      padding: rem(100 30 55);

      @include theme-dark {
        background-image: url("data:image/svg+xml,%3Csvg width='337' height='280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.726 0C79.4 187.083 186.19 213.809 186.19 213.809s78.184-40.09 135.393-181.356c54.348 114.54-52.441 212.854-52.441 212.854L313 264.397c-78.446 21.954-181.159 14.318-181.159 14.318S-77.923 203.309 31.726 0Z' fill='%23181818'/%3E%3C/svg%3E");
      }
    }

    &-favorite {
      height: clamp-rem(36, 46);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--event-color);
      border-radius: 50%;
    }
  }

  &__category {
    height: rem(37);
    background-color: var(--event-color);
    border-color: var(--event-color);
    border-radius: $radius 0 $radius 0;
    padding: rem(0 21);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__title {
    @include limit-text(2);
    letter-spacing: 0.05em;
    margin: rem(0 0 20);
  }

  &__text {
    @extend %copy-md;
    @include limit-text(8);
    color: var(--wp--preset--color--gray-one);
    margin-bottom: rem(30);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: rem(8);
    z-index: 2;

    @include min(xs) {
      flex-wrap: wrap;
      gap: rem(20);
    }

    .btn {
      border-color: var(--event-color);
    }

    .btn:not(.btn-outline) {
      background-color: var(--event-color);
    }

    .btn-outline {
      &:hover,
      &:focus,
      &:active {
        background-color: var(--event-color);
      }
    }

    .btn:not(.btn-icon) {
      @extend .btn-responsive;

      @include max(xs) {
        padding: rem(0 16);
      }
    }

    .btn-icon {
      @extend .btn-icon-responsive;
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: $radius;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    @include min(lg) {
      flex-direction: row;
    }

    &-overview {
      width: 100%;
      background-color: var(--wp--preset--color--white-static);
      border: 1px solid var(--wp--preset--color--stroke);
      border-radius: 0 0 $radius $radius;
      padding: rem(10);
      overflow: hidden;
      transition: border-color $tr;

      @include min(lg) {
        width: 60%;
        flex-shrink: 0;
        border-radius: 0 $radius $radius 0;
        padding: rem(50 30 40);
      }

      @include min(xl) {
        padding: rem(55 60);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-content {
      background-color: var(--wp--preset--color--light-bg);
      flex-grow: 1;
      padding: rem(30 16);

      @include min(md) {
        @include scrollbar(6px, var(--event-color));
        overflow-y: auto;
        border-radius: $radius 0 0 $radius;
        padding: rem(40);
      }

      @include min(lg) {
        background-image: url("data:image/svg+xml,%3Csvg width='337' height='280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.726 0C79.4 187.083 186.19 213.809 186.19 213.809s78.184-40.09 135.393-181.356c54.348 114.54-52.441 212.854-52.441 212.854L313 264.397c-78.446 21.954-181.159 14.318-181.159 14.318S-77.923 203.309 31.726 0Z' fill='%23fff'/%3E%3C/svg%3E");
        background-position: calc(100% - rem(14)) calc(100% - rem(14));
        background-size: 65%;
        padding: rem(55 40 55 30);

        @include theme-dark {
          background-image: url("data:image/svg+xml,%3Csvg width='337' height='280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.726 0C79.4 187.083 186.19 213.809 186.19 213.809s78.184-40.09 135.393-181.356c54.348 114.54-52.441 212.854-52.441 212.854L313 264.397c-78.446 21.954-181.159 14.318-181.159 14.318S-77.923 203.309 31.726 0Z' fill='%23181818'/%3E%3C/svg%3E");
        }
      }
    }

    .sold-out {
      display: flex;
      width: 100%;
      height: -webkit-fill-available;
      justify-content: center;
      align-items: center;
    }
  }

  &__host {
    &-photo {
      flex-shrink: 0;
      line-height: 0;
      margin: 0 0 rem(16);

      img {
        width: rem(82);
        height: rem(82);
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: rem(12 16);
      margin-bottom: rem(16);
    }

    &-name {
      letter-spacing: 0.05em;
      margin: 0;
    }

    &-languages {
      display: inline-flex;
      align-items: center;
      gap: rem(16);

      img {
        width: rem(24);
        height: rem(24);
        flex-shrink: 0;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &-position {
      @extend %h5;
      width: 100%;
      color: var(--wp--preset--color--gray-two);
    }

    &-instagram {
      display: inline-flex;
      align-items: center;
      gap: rem(6);
      margin-bottom: rem(24);

      svg {
        width: rem(22);
        height: rem(22);
      }

      span {
        @include animated-underline;
      }
    }

    &-bio {
      @extend %copy-md;
      color: var(--wp--preset--color--gray-one);
      margin-bottom: rem(30);
    }
  }

  &__clip-layer {
    width: 170%;
    height: 180%;
    background-color: var(--wp--preset--color--light-bg);
    clip-path: polygon(
                    75% 0%,
                    0% 0%,
                    0% 12.281%,
                    0% 12.281%,
                    0.655% 26.507%,
                    2.549% 40.004%,
                    5.582% 52.589%,
                    9.648% 64.083%,
                    14.646% 74.305%,
                    20.472% 83.073%,
                    27.024% 90.208%,
                    34.198% 95.527%,
                    41.891% 98.852%,
                    50% 100%,
                    100% 100%,
                    100% 27.895%,
                    100% 27.895%,
                    95.944% 27.53%,
                    92.097% 26.472%,
                    88.51% 24.781%,
                    85.234% 22.512%,
                    82.321% 19.724%,
                    79.823% 16.473%,
                    77.79% 12.818%,
                    76.274% 8.816%,
                    75.327% 4.524%,
                    75% 0%
    );
    position: absolute;
    top: -130%;
    left: 80%;
    z-index: 5;
  }

  &__more,
  &__back {
    z-index: 10;
  }

  .js-view-toggle {
    cursor: pointer;
  }

  // view: Default
  &:not([data-view="default"]) {
    .event-card__content,
    .event-card__overview {
      opacity: 0;
    }
  }

  // view: Details
  &:not([data-view="details"]) {
    .event-card__overlay--details {
      display: none;
    }
  }

  // view: Campers
  &:not([data-view="campers"]) {
    .event-card__overlay--campers {
      display: none;
    }
  }

  &__overlay--campers {
    overflow-x: hidden;

    .event-card__overlay-content {
      height: 100%;
      display: flex;
      padding: 0;

      @include max(lg) {
        flex-direction: column;
        padding-top: rem(45);
      }
    }
  }
}

/**
* Event Card (large) specific styles
 */
.event-card-large {
  margin: 0 auto;
  overflow: hidden;

  &.is-hovered {
    @include hover-responsive {
      .camper__inner {
        border-color: var(--wp--preset--color--primary);
      }
    }
  }

  .event-card__category {
    @include min(lg) {
      top: -1px;
      left: -1px;
    }
  }

  .event-card__meta {
    position: absolute;
    bottom: rem(50);
    left: 0;

    @include min(md) {
      bottom: rem(60);
    }
  }

  .event-card__actions {
    margin-top: auto;
  }

  .event-card__text {
    @include min(lg) {
      width: 74%;
    }
  }

  // view: Default
  &[data-view="default"] {
    .event-card__back {
      display: none;
    }
  }

  &:not([data-view="default"]) {
    .event-card__more {
      display: none;
    }
  }
}

/**
* Event Card (small) specific styles
 */
.event-card-small {
  perspective: 3000px;
  container: card-small / inline-size;

  &.is-flipped {
    .event-card__inner {
      transform: rotateY(180deg);
    }
  }

  .event-card__inner {
    min-height: rem(500);
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1), border-color $tr;
    //transform: rotateY(70deg);
    @include max(md) {
      min-height: auto;
    }
  }

  &__front,
  &__back {
    width: 100%;
    backface-visibility: hidden;
    border-radius: $radius;
    transform-style: preserve-3d;
  }

  &__front {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: rem(175 40 40);
    position: relative;

    @container (max-width: #{$container-card-small-width}) {
      padding: rem(140 16 30);
    }

    @include min(lg) {
      padding-top: rem(70);
    }

    .event-card__title {
      color: var(--wp--preset--color--white-static);
      margin-bottom: rem(22);
      position: relative;
    }

    .event-card__actions {
      .btn-outline {
        color: var(--wp--preset--color--white-static);
      }
    }

    .event-card__text {
      @include limit-text(2);
      color: var(--wp--preset--color--white-static);
      margin-bottom: rem(32);
      position: relative;
    }
  }

  &__back {
    height: 100%;
    background-color: var(--wp--preset--color--campeasy-white);
    transform: rotateY(180deg);
    border: 1px solid var(--wp--preset--color--stroke);
    position: absolute;
    top: 0;
    left: 0;
    transition: border-color $tr;

    &-title {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      color: var(--wp--preset--color--primary);
      margin: 0;
      padding: rem(40 30 10);
      text-align: center;
    }
  }

  .event-card-small__front {
    transform-style: preserve-3d;

    > .event-card__title,
    > .event-card__text {
      transform: $translate-z;
      backface-visibility: hidden;
    }
  }

  .event-card__thumbnail {
    border-radius: $radius;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.13%, rgba(0, 0, 0, 0.6) 72.2%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .event-card__meta {
    margin-bottom: rem(30);
    margin-left: rem(-40);

    @container (max-width: #{$container-card-small-width}) {
      margin-left: rem(-16);
    }
  }

  &__overlay {
    @include scrollbar(6px, var(--event-color));
    overflow-y: auto;
    height: 100%;
    background-color: var(--wp--preset--color--light-bg);
    border-radius: $radius;

    &-content {
      padding: rem(65 40 30);

      @include min(575) {
        padding-top: rem(40);
      }

	    > .sold-out {
		    display: flex;
		    width: 100%;
		    height: -webkit-fill-available;
		    justify-content: center;
		    align-items: center;
	    }
    }

    .event-card__title {
      margin-bottom: rem(16);

      &:first-child {
        margin-top: 2rem;
      }
    }

    .event-card__text {
      @include limit-text(4);
    }
  }

  &__host-header {
    display: flex;
    flex-direction: column;
    gap: rem(12);
    margin-bottom: rem(16);

    @include min(575) {
      align-items: flex-end;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .event-card__host-photo {
      margin: 0;

      img {
        width: rem(82);
        height: rem(82);

        @include min(575) {
          width: rem(180);
          height: rem(180);
        }
      }
    }

    .event-card__host-heading {
      margin: 0;
    }

    .event-card__host-languages {
      img {
        width: rem(18);
        height: rem(18);
      }
    }

    .event-card__host-bio {
      margin-bottom: rem(18);
    }
  }

  &__overlay {
    &-location {
      max-width: rem(230);
      max-height: rem(160);
      display: block;
      margin-bottom: rem(20);
	    margin-left: auto;
      object-fit: contain;

      @include theme-dark {
        background-color: var(--wp--preset--color--white-static);
      }
    }
  }

  // view: Details
  &:not([data-view="details"]) {
    .event-card-small__overlay--details {
      display: none;
    }
  }

  // view: Host
  &:not([data-view="host"]) {
    .event-card-small__overlay--host {
      display: none;
    }
  }

  // view: Campers
  &:not([data-view="campers"]) {
    .event-card-small__overlay--campers {
      display: none;
    }
  }

  &__overlay--campers {
    container: campers / inline-size;
    overflow-x: hidden;

    .event-card-small__overlay-content {
      height: 100%;
      display: flex;
      padding: 0;

      @include max(630) {
        flex-direction: column;
        padding-top: rem(45);
      }

      .event-card-small & {
        @container campers (max-width: #{$container-campers-width}) {
          flex-direction: column;
          padding-top: rem(45);
        }
      }
    }
  }
}

.event-camper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: rem(65 12 30);

  &:not(:last-child) {
    border-right: 1px solid var(--wp--preset--color--stroke);
  }

  @include max(630) {
    padding: rem(20 15);

    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid var(--wp--preset--color--stroke);
    }
  }

  .event-card-small & {
    @container campers (max-width: #{$container-campers-width}) {
      padding: rem(20 15);

      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid var(--wp--preset--color--stroke);
      }
    }
  }

  &__inner {
    width: 100%;
    max-width: rem(180);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include max(lg) {
      max-width: rem(410);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(0 30);
      grid-template-areas:
    "title image"
    "price image"
    "button image";
      align-items: center;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        max-width: rem(410);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem(0 30);
        grid-template-areas:
    "title image"
    "price image"
    "button image";
        align-items: center;
      }
    }
  }

  &__image {
    grid-area: image;

    img {
      width: 100%;
      aspect-ratio: 180/135;
      object-fit: contain;
    }
  }

  &__title {
    @include limit-text(2);
    grid-area: title;
    flex-shrink: 0;
    letter-spacing: 0.02em;
    margin: rem(0 0 12);

    @include max(lg) {
      margin: 0;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        margin: 0;
      }
    }
  }

  &__capacity {
    @extend %copy-md;
    display: block;
    color: var(--wp--preset--color--gray-one);
    margin-bottom: rem(16);

    @include max(lg) {
      display: none;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        display: none;
      }
    }
  }

  &__list {
    @include unstyled-list;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(10);
    margin-bottom: rem(20);

    @include max(lg) {
      display: none;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        display: none;
      }
    }

    .icon {
      color: var(--wp--preset--color--base);
      font-size: rem(24);
    }
  }

  &__guests {
    @extend %copy-md;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(12);
    margin-top: auto;
    margin-bottom: rem(20);

    @include max(lg) {
      display: none;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        display: none;
      }
    }
  }

  &__price {
    @extend %h6;
    grid-area: price;
    letter-spacing: 0.02em;
    margin-bottom: rem(24);

    @include max(lg) {
      margin: 0;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        margin: 0;
      }
    }
  }

  &__btn {
    grid-area: button;
    background-color: var(--event-color);
    border-color: var(--event-color);
    flex-shrink: 0;

    @include max(lg) {
      margin: 0;
    }

    .event-card-small & {
      @container campers (max-width: #{$container-campers-width}) {
        margin: 0;
      }
    }
  }
}
