// Tabs
.js-tab-title {

  &:not(.is-active) {
    cursor: pointer;
  }
}

.js-tab-content {
  display: none;

  &.is-active {
    display: block;
  }
}
