@import "custom-colors";

/*
 * Global CSS Variables:
 */
:root {
  --header-height: 3.5rem;
  --sticky-header-height: 3.5rem;
  --countdown-height: 5rem;

  @media (min-width: 782px) {
    --header-height: 5.875rem;
    --sticky-header-height: 4rem;
  }

  @media (min-width: 1025px) {
    --countdown-height: 7rem;
  }
}

@media (prefers-color-scheme: dark) {
  :root:not(.light) body {
    --wp--preset--color--white: #{$dark-white};
    --wp--preset--color--campeasy-white: #{$dark-white};
    --wp--preset--color--base: #{$dark-base};
    --wp--preset--color--black: #{$dark-base};
    --wp--preset--color--light-bg: #{$dark-light-bg};
    --wp--preset--color--gray-one: #{$dark-gray-one};
    --wp--preset--color--gray-two: #{$dark-gray-two};
    --wp--preset--color--stroke: #{$dark-stroke};
    --wp--preset--color--link: #{$dark-link};
    --wp--preset--color--primary-lighter: #{$dark-primary-lighter};
  }
}

:root.dark body {
  --wp--preset--color--white: #{$dark-white};
  --wp--preset--color--campeasy-white: #{$dark-white};
  --wp--preset--color--base: #{$dark-base};
  --wp--preset--color--black: #{$dark-base};
  --wp--preset--color--light-bg: #{$dark-light-bg};
  --wp--preset--color--gray-one: #{$dark-gray-one};
  --wp--preset--color--gray-two: #{$dark-gray-two};
  --wp--preset--color--gray-three: #{$dark-primary-lighter};
  --wp--preset--color--stroke: #{$dark-stroke};
  --wp--preset--color--link: #{$dark-link};
  --wp--preset--color--primary-lighter: #{$dark-primary-lighter};
}
