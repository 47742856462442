.insurance {

  &__items {
    display: flex;
    .checkin & {
      flex-wrap: wrap;
    }

    @include max(lg) {
      flex-direction: column;
      gap: rem(24);
    }

	  .insurance__item {
		  display: grid;
		  grid-template-rows: rem(148) 4rem 1fr auto;
	  }

	  .checkout-insurances & {
		  .insurance__item {
		    grid-template-rows: rem(170) minmax(0, 4rem) 1fr auto;
		  }
	  }

    .checkin & {
      .insurance__item {
        grid-template-rows: minmax(rem(170), rem(240)) minmax(0, 5rem) 1fr;
        @include min(lg) {
          max-width: 33.33333%;
          width: 100%;
          flex: auto;
        }
      }

    }

	  @include min-max(lg, xl) {
		  .insurance__item {
		    grid-template-rows: rem(170) 6rem 1fr auto;
		  }
	  }

	  @include max(lg) {

		  .checkout-insurances & {
			  display: flex;
			  flex-direction: column;
		  }
	  }
  }

  &__item {
	  display: flex;
	  flex-direction: column;
	  flex: 1 1 0;
    background: var(--wp--preset--color--white);
    border: 1px solid var(--wp--preset--color--stroke);
    position: relative;

    @include max(lg) {
      background: var(--wp--preset--color--light-bg);
      border-radius: rem(8);

      .wp-block-itm-insurance & {
        background: var(--wp--preset--color--white);
      }
    }

    &:first-child {
      border-radius: rem(8 0 0 0);
    }

    &:not(:last-child) {
      border-right: 1px solid var(--wp--preset--color--stroke);

      @include min(lg) {
        border-right: none;
      }
    }

    &:nth-child(3n) {
      border-right: 1px solid var(--wp--preset--color--stroke);
    }

    &:last-child {
      border-radius: rem(0 8 0 0);
      .checkin & {
        border-radius: 0;
      }
    }

    &.recommended {
      border: 1px solid var(--wp--preset--color--primary);
      box-shadow: 0 0 0 1px var(--wp--preset--color--primary) inset;
      .checkin & {
        position: relative;
        border: 1px solid var(--wp--preset--color--stroke);
        box-shadow: none;
        &:before {
          content: "Recommended";
          width: rem(162);
          height: rem(38);
          background-color: var(--wp--preset--color--primary);
          border-top-left-radius: rem(9);
          border-top-right-radius: rem(9);
          color: var(--wp--preset--color--white);
          font-size: rem(16);
          font-weight: 500;
          line-height: rem(26);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: rem(-38);
          left: 0;
          right: 0;
          margin: auto;
        }
      }&.with-button {
      .checkin & {
        padding-right: rem(70);
      }
    }

    .checkin & {
      display: grid;
      grid-template-areas:
      'image title'
      'image price'
      'button button';
      grid-template-columns: auto 1fr;
      padding-bottom: rem(30);
      img {
        grid-area: image;
        width: rem(52);
        height: rem(52);
        object-fit: contain;
        margin-right: rem(10);
      }

    }

      @include max(lg) {
        margin-top: rem(28);
      }
    }
  }

  &__head {
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    padding: rem(48 30 24);

    @include max(lg) {
      padding: rem(20 16);
    }

    &.with-button {
      .checkin & {
        padding-right: rem(70);
      }
    }

    .checkin & {
      display: grid;
      grid-template-areas:
      'image title'
      'image price'
      'button button';
      grid-template-columns: auto 1fr;
      padding-bottom: rem(30);
      img {
        grid-area: image;
        width: rem(52);
        height: rem(52);
        object-fit: contain;
        margin-right: rem(10);
      }

    }

    .page-checkout & {
      display: flex;
      flex-direction: column;
      padding-top: rem(14);
      padding-right: rem(50);

      @include min(lg) {
        height: rem(170);
        padding: rem(20);
      }
    }

    .page-checkout .insurance-comparison & {
      @include min(md) {
        @include max(lg) {
          height: rem(170);
          padding: rem(20);
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: rem(8);
    color: var(--wp--preset--color--link);
    margin: 0;

    .page-checkout & {
      @extend %h6;
      gap: rem(10);
    }

	.page-my-account & {
	  @extend %h6;
	  gap: rem(10);
	}
    img {
      width: rem(24);
      height: rem(24);
      flex: 0 0 rem(24);

	    @include min-max(lg, xl) {
		    width: rem(50);
		    height: rem(50);
	    }

      @include theme-dark {
        filter: invert(1);
      }
    }
  }

  &__price {
    width: 100%;
    min-height: rem(22);
    font-size: rem(14);
    line-height: rem(22);
    color: var(--wp--preset--color--base);
    font-weight: 400;
    margin-top: rem(10);
    white-space: nowrap;

    .checkin & {
      grid-area: price;
      margin: 0;
    }
	strong {
	  display: inline-block;
	}

    p {
      margin: 0;
    }

    small {
      font-size: rem(14);
      color: var(--wp--preset--color--gray-one);
      white-space: nowrap;
    }
  }

  &__status {
    margin-top: rem(10);

    @include min(lg) {
      margin-top: auto;
    }

    .checkin & {
      margin-top: rem(24);
      grid-area: button;
    }

    strong {
      font-size: rem(14);
      letter-spacing: 0.05em;
      line-height: rem(36);
      text-transform: uppercase;
    }

    .btn {
      border: 1px solid var(--wp--preset--color--stroke);
      gap: rem(10);
      padding: 0 rem(14);
      text-transform: capitalize;

      @include min(lg) {
        &:hover {
          input[type="radio"],
          input[type="checkbox"] {
            border-color: $w;
          }
        }
      }

      &:after {
        content: attr(data-default);
        z-index: 2;
      }

      &:not(:has(input[type="radio"]:checked)):not(:has(input[type="checkbox"]:checked)) {
        background-color: transparent;
        color: var(--wp--preset--color--base);
        border-color: var(--wp--preset--color--stroke);

        &:before {
          background-color: transparent;
        }

        @include min(lg) {
          &:hover {
            background-color: var(--wp--preset--color--primary);
            color: $w;
          }
        }

        @include theme-dark {
          border-color: var(--wp--preset--color--primary);
        }
      }

      &:has(input[type="checkbox"]:checked),
      &:has(input[type="radio"]:checked) {
        &:after {
          content: attr(data-checked);
        }

        input[type="checkbox"],
        input[type="radio"] {
          background: radial-gradient(circle, $w 0%, $w rem(5), var(--wp--preset--color--primary) rem(5));
          border-color: $w;
        }
      }

      input[type="checkbox"],
      input[type="radio"] {
        border-radius: 50%;
        transition: 0.1s;
      }

      .icon {
        font-size: rem(20);
        position: relative;
        top: -1px;
      }
    }

    &--fixed {
      pointer-events: none;

      input[type="checkbox"],
      input[type="radio"] {
        display: none !important;
      }
    }
  }

  &__description {
    @extend %copy-md;
    max-height: rem(96);
    font-weight: 500;
    padding: rem(0 30);
    overflow: hidden;
	  margin-top: rem(24);
	  margin-bottom: rem(16);

    @include max(lg) {
      padding: rem(0 16);
    }

    .checkin & {
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(24);
      color: var(--wp--preset--color--base);
      margin-top: rem(30);
	    margin-bottom: rem(28);
    }

    p {
      margin: 0;
    }
  }

  &__features {
    display: flex;
    flex-direction: column;

    .page-checkout & {
      padding: rem(14) 0;
    }

    .insurance-comparison & {
      padding: 0;
      border-bottom: 1px solid var(--wp--preset--color--stroke);
      border-top: 1px solid var(--wp--preset--color--stroke);
    }
  }

  &__feature {
    font-size: rem(14);
    color: var(--wp--preset--color--gray-one);
    display: flex;
    align-items: center;
    gap: rem(12);
    padding: rem(6 16);

    @include min(lg) {
      padding: rem(6 30);
    }

    .page-checkout & {
      @include min(lg) {
        @include max(xl) {
          padding: rem(6 20);
        }
      }
    }

    .icon {
      width: rem(16);
      height: rem(16);
      background-color: var(--wp--preset--color--gray-two);
      color: var(--wp--preset--color--white);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 rem(16);
      border-radius: 50%;

      &.green {
        background-color: var(--wp--preset--color--success);
      }

      &.yellow {
        background-color: var(--wp--preset--color--warning);
      }

      &.red {
        background-color: #F17B16;
      }

      &.error {
        background-color: var(--wp--preset--color--error);

        &::before {
          content: $icon--io-warning-mark;
        }
      }

      &.gray {
        &::before {
          content: $icon--io-close;
        }
      }

      &:before {
        content: $icon--io-check;
        width: rem(12);
        font-size: rem(12);
        text-align: center;
      }
    }

    &-more {
      @extend %copy-sm;
      color: var(--wp--preset--color--primary);
      display: inline-flex;
      align-items: center;
      gap: rem(5);
      font-weight: 600;

      &:before {
        content: '+';
        font-size: rem(18);
        font-weight: 500;
      }
    }
  }

  &__outro {
    font-size: rem(14);
    color: var(--wp--preset--color--gray-one);
    padding: rem(30 30 24);

    @include max(lg) {
      padding: rem(20 16);

      .page-checkout & {
        padding-top: rem(14);
      }
    }

    p {
      margin: 0;
    }
  }

  &__more {
    font-size: rem(42);
    color: var(--wp--preset--color--primary);
    background: transparent;
    display: flex;
    position: absolute;
    top: -2px;
    right: -2px;
    transition: transform 0.3s;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 rem(42) rem(42) 0;
      border-color: transparent var(--wp--preset--color--white) transparent transparent;
      pointer-events: none;

      .page-checkout & {
        border-color: transparent var(--wp--preset--color--light-bg) transparent transparent;
      }
    }

    &:hover {
      text-decoration: none;
      transform: scale(1.1);
      transform-origin: 100% 0%;
    }

    .icon {
      &:after {
        content: '';
        display: block;
        width: rem(30);
        height: rem(11);
        background-color: $w;
        transform: rotate(45deg);
        position: absolute;
        top: rem(21);
        left: 0;
      }

      &:before {
        position: relative;
        z-index: 2;
      }
    }
  }

  &__footer {
    background: var(--wp--preset--color--white);
    border: 1px solid var(--wp--preset--color--stroke);
    border-top: none;
    border-radius: rem(0 0 8 8);
    padding: rem(24 30);

    @include max(lg) {
      border: none;
      padding: 0;
      margin: rem(24 0 0);

      .wp-block-itm-insurance & {
        background: none;
      }
    }

    .page-checkout & {

      @include max(lg) {
        display: flex;
        flex-direction: column;
        border-radius: rem(8);
        padding: rem(24 30);
        margin-top: rem(30);
      }

      @include min(lg) {
        padding: rem(20 30);
      }
    }

    .insurance__feature {
      padding: 0;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: rem(48);
    row-gap: rem(16);
    margin: rem(0 0 20);

    &:last-child {
      margin-block-end: 0;
    }

    .page-checkout & {
      margin-bottom: rem(10);

      @include max(lg) {
        order: 3;
        margin-top: rem(30);
        margin-bottom: 0;
      }
    }

    @include max(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__hint {
    font-size: rem(12);
    color: var(--wp--preset--color--gray-one);
    margin: rem(16 0 20);

    .page-checkout & {
      margin-bottom: rem(10);

      @include max(lg) {
        font-size: rem(14);
        letter-spacing: 0.05em;
        line-height: 1.6;
        margin-bottom: rem(16);
      }
    }
  }

  &__download {
    @extend %copy-sm;

    .page-checkout & {
      @include max(lg) {
        font-size: rem(14);
      }
    }
  }

  &__button {
    a {
      color: var(--wp--preset--color--white-static) !important;
    }
  }

  &__recommended {
    font-weight: 500;
    color: var(--wp--preset--color--white-static);
    line-height: rem(38);
    letter-spacing: normal;
    white-space: nowrap;
    text-transform: uppercase;
    background: var(--wp--preset--color--primary);
    border-radius: rem(8 8 0 0);
    box-shadow: rem(0 -4 4) rgba(0, 0, 0, 0.15) inset;
    padding: rem(0 20);
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);

    @include max(1300) {
      font-size: rem(12);
      line-height: rem(28);
    }
  }

  &__more {
    color: var(--wp--preset--color--primary) !important;
  }
}

.insurance__title {
  .with-button & {
    padding-right: rem(24);
  }
}
