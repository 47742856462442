.pagination {
  margin-top: rem(40);

  &.hidden {
    display: none;
  }

  .wp-block-button {
    justify-content: center;
  }

  ul {
    @include unstyled-list;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(8);
    border: none;

    li {
      border: none;
      a, span {
        @extend %copy-md;
        min-width: rem(36);
        height: rem(36);
        color: var(--wp--preset--color--base);
        line-height: 1;
        padding: rem(0 8);

        &.current {
          &:not(.dots) {
            text-shadow: 0.02em 0 .03em var(--wp--preset--color--base), -0.02em 0 .03em var(--wp--preset--color--base);
          }
        }

        &:not(.dots):hover {
          text-shadow: 0.02em 0 .03em var(--wp--preset--color--base), -0.02em 0 .03em var(--wp--preset--color--base);
        }
      }
    }
  }
}
