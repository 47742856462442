// Checkout Summary (in sidebar)
.checkout-summary {
  @include border;
  width: 100%;
  background-color: var(--wp--preset--color--white);
  font-size: rem(14);
  overflow: hidden;

  @include max(lg) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow-y: auto;

    .checkout .checkout-steps:not([data-current="4"]) &,
    .checkout-event .checkout-steps:not([data-current="2"]) & {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .checkout-event .checkout-steps[data-current="1"] & {
      border-radius: 0;
    }

    &:has(.checkout-summary__toggle-details input[type="checkbox"]:checked) {
      border-radius: 0;

      .checkout-summary__toggle-details .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__title {
    font-size: rem(22);
    font-weight: 700;
    padding: rem(20 20 16);
    margin: 0;
    letter-spacing: 0.05em;

    @include max(lg) {
      .checkout .checkout-steps:not([data-current="4"]) &,
      .checkout-event .checkout-steps:not([data-current="2"]) & {
        display: none;
      }
    }
  }

  &__dates {
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(16 20);
    border-top: 1px solid #eaeaea;

    @include max(lg) {
      .checkout .checkout-steps:not([data-current="4"]) &,
      .checkout-event .checkout-steps:not([data-current="2"]) & {
        padding-top: rem(20);
        padding-bottom: rem(20);
      }
    }

    &-item {
      padding: rem(0 16);

      @include min(lg) {
        padding: rem(0 20);
      }

      &:not(:first-child) {
        border-left: 1px solid var(--wp--preset--color--stroke);
      }

      label {
        @extend %copy-md;
        display: block;
        color: var(--wp--preset--color--gray-one);
        margin-bottom: rem(6);
      }

      span {
        @extend %copy-sm;
        font-weight: 500;
        display: block;
        max-width: 90%;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: rem(24);
    margin-top: rem(16);
  }

  &__next {
    width: 100%;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  &__back {
    @extend %copy-md;
    @include animated-underline();
    color: var(--wp--preset--color--base);
    font-weight: 600;
    text-transform: uppercase;
  }

  &__toggle-details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: rem(10);
    margin-top: rem(12);

    input[type="checkbox"] {
      display: none !important;
    }
  }
}

.summary-table {
  @extend %copy-md;
  padding: rem(16);

  @include min(lg) {
    padding: rem(20);
  }

  + .summary-table {
    border-top: 1px solid var(--wp--preset--color--stroke);
  }

  &__title {
    margin-bottom: rem(16);
  }

  table {
    width: 100%;
	strong {
	  display: inline-flex;
	}

    + table {
      margin-top: rem(6);
    }

    th {
      font-weight: 700;
    }

    th,
    td {
      text-align: left;
      padding: rem(3 0);

      &:not(:last-child) {
        padding-right: rem(10);
      }

      &:nth-child(2):last-child {
        width: 40%;
		text-align: right;
		vertical-align: bottom;
      }
    }
  }

  &--total {
    background-color: #F8F6FF;
    border-top: none !important;

    @include max(lg) {
      padding: rem(10 16);
    }

    @include min(lg) {
      margin-top: rem(10);
    }

    @include theme-dark {
      background-color: $dark-primary-lighter;
    }

    table {
      td {
        vertical-align: bottom;
      }

      h4 {
        white-space: nowrap;
        margin: 0;
        font-weight: 600;
      }

      del {
        display: block;
        margin-bottom: rem(3);
      }
    }
  }
}

.summary-camper {
  font-size: rem(14);
  padding: rem(16 20);

  @include max(lg) {
    .checkout .checkout-steps:not([data-current="4"]) &,
    .checkout-event .checkout-steps:not([data-current="2"]) & {
      background-color: var(--wp--preset--color--light-bg);
    }
  }

  @include min(lg) {
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(20);
  }

  &__title {
    color: var(--wp--preset--color--link);
    margin-bottom: rem(6);
    font-size: rem(16);
    font-weight: 600;

    @include min(lg) {
      font-size: rem(18);
      margin-bottom: rem(16);
    }
  }

  &__vehicle {
    @extend %copy-sm;
    color: var(--wp--preset--color--gray-one);
    margin-bottom: rem(16);

    @include min(lg) {
      font-size: rem(14);
    }
  }

  &__content {
    display: grid;
    gap: rem(16);
    grid-template-columns: rem(76) 1fr;
    position: relative;

    @include min(lg) {
      grid-template-columns: 1fr rem(108);
    }
  }

  &__image {
    margin: 0;

    img {
      aspect-ratio: 150/86;
      object-fit: contain;
      height: rem(86);
      width: auto;
    }
  }

  &__details {
    @include unstyled-list;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(100), 1fr));
    grid-column-gap: rem(5);
    flex-direction: column;
    @extend %copy-sm;

    li {
      &:before {
        content: '•';
        margin-right: rem(7);
      }
    }
  }

  &__back {
    @include max(lg) {
      font-size: rem(12);

      .icon {
        font-size: rem(14);
      }
    }

    .checkout .checkout-steps:not([data-current="4"]) &,
    .checkout-event .checkout-steps:not([data-current="2"]) & {
      display: none;
    }
  }
}

.summary-price-details {
  @include border;
  margin-top: rem(16);
  padding: rem(20) rem(34) rem(20) rem(20);
  transition: $tr;
  background-color: var(--wp--preset--color--white);

  @include max(lg) {
    max-height: rem(300);
    border-top: none;
    border-radius: 0 0 $radius $radius;
    overflow: hidden;
    margin: 0;

    .checkout-event & {
      border-radius: 0;
    }

    body:not(:has(.checkout-summary__toggle-details input[type="checkbox"]:checked)) & {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      border-width: 0;
    }
  }

  &__title {
    margin-bottom: rem(16);
  }

  &__content {
    font-size: clamp-rem(14, 16);
  }

  table {
    width: 100%;
    margin-bottom: rem(20);
    tr {
      display: flex;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: rem(12);
      }
    }
    td {
      font-size: rem(14);
      line-height: rem(22);
      letter-spacing: 0.05em;
      color: var(--wp--preset--color--gray-one);
      &:nth-child(2n) {
        text-align: right;
      }
    }
  }

  ul {
    @include unstyled-list;
    display: flex;
    flex-direction: column;
    gap: clamp-rem(10, 16);
    margin-top: rem(20);

    li {
      display: flex;
      gap: rem(10);
      font-size: rem(14);
      line-height: rem(22);
      letter-spacing: 0.05em;
      .icon {
        font-size: clamp-rem(16, 24);
      }
      .tooltip {
        &::after {
          border: none;
          border-bottom: rem(1) solid transparent;
          background-image: linear-gradient(to right, var(--wp--preset--color--base) 50%, transparent 50%);
          background-size: rem(10) rem(1);
          background-repeat: repeat-x;
          padding-bottom: rem(1);
        }
      }
    }
  }
}

// Checkout Event Summary
.summary-event {
  font-size: rem(14);

  @include max(lg) {
    .checkout .checkout-steps:not([data-current="4"]) &,
    .checkout-event .checkout-steps:not([data-current="2"]) & {
      background-color: var(--wp--preset--color--light-bg);
    }
  }

  @include min(lg) {
    background-color: var(--wp--preset--color--light-bg);
  }

  &__image {
    margin: 0;

    img {
      width: 100%;
      aspect-ratio: 305/135;
      object-fit: cover;
    }
  }

  &__content {
    padding: rem(20);
  }

  &__title {
    color: var(--wp--preset--color--link);
    margin-bottom: rem(16);
  }

  &__details {
    @include unstyled-list;
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }

  &__back {
    margin-top: rem(20);

    @include max(lg) {
      font-size: rem(12);

      .icon {
        font-size: rem(14);
      }
    }

    .checkout .checkout-steps:not([data-current="4"]) &,
    .checkout-event .checkout-steps:not([data-current="2"]) & {
      display: none;
    }
  }
}

.summary-cancellation {
  @include border;
  background-color: var(--wp--preset--color--white);
  font-size: rem(14);
  padding: rem(12 16);
  border-radius: 0 0 $radius $radius;

  @include max(lg) {
    border-top: none;
    order: 3;
  }

  @include min(lg) {
    font-size: rem(16);
    margin-top: rem(16);
    padding: rem(20);
    border-radius: $radius;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: rem(8);
    margin-bottom: rem(8);
  }

  &__text {
    color: var(--wp--preset--color--gray-one);
  }
}

.checkout-summary-list {
  margin: rem(16 0);
  padding: 0;
  list-style-type: none;
  position: relative;

  &:before {
    position: absolute;
    top: rem(10);
    bottom: rem(10);
    border-left: 1px solid var(--wp--preset--color--base);
    content: '';
    left: 2px;
  }

  &__item {
    position: relative;
    color: var(--wp--preset--color--gray-one);
    @extend %copy-sm;
    font-weight: 500;
    padding-left: rem(16);
    margin-bottom: rem(6);

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      top: rem(8);
      left: 0;
      display: block;
      background-color: var(--wp--preset--color--base);
      border-radius: 50%;
    }

    &--duration {
      font-size: rem(12);
      font-weight: 700;
      line-height: 1.57;
      list-style-type: 0.05em;
      color: var(--wp--preset--color--gray-one);
    }
  }
}

.list-styled-item {
  position: relative;
  padding-left: rem(16);
  &:before {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    top: rem(11);
    left: 0;
    display: block;
    background-color: var(--wp--preset--color--base);
    border-radius: 50%;
  }

  .summary-table & {
    padding-left: rem(16);
  }
}

.summary-breakdown-toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(10);
  padding: rem(16 0);
  margin-left: rem(16);
  margin-right: rem(16);
  border-bottom: 1px solid var(--wp--preset--color--stroke);
  flex-wrap: wrap;

  input[type="checkbox"]:checked  {
    + .field-switch__slider {
      &:before {
        background: var(--wp--preset--color--success);
      }
    }
  }

  .summary-table__title {
    margin-bottom: 0;
  }

  label {
    @extend %copy-sm;
  }
}

tr.js-sub-total-items {
  visibility: collapse;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  td {
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    line-height: 0;
    transition: all 0.4s ease-in-out;
  }

  td > div {
    transform: translateY(-10px);
    opacity: 0;
    transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  }

  &.is-active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0.05s;

    td {
      padding-top: 1px;
      padding-bottom: 1px;
      border-top-width: 1px;
      border-bottom-width: 1px;
      line-height: 1.5;
      transition: all 0.5s ease-in-out;
    }

    td > div {
      transform: translateY(0);
      opacity: 1;
      transition: transform 0.5s ease-out 0.15s, opacity 0.5s ease-out 0.15s;
    }
  }
}

@supports (-webkit-appearance:none) {
  tr.js-sub-total-items td {
    max-height: 0;
    overflow: hidden;
  }

  tr.js-sub-total-items.is-active td {
    max-height: 100px;
  }
}
@supports (-webkit-appearance:none) {
  tr.js-sub-total-items td {
    max-height: 0;
    overflow: hidden;
  }

  tr.js-sub-total-items.is-active td {
    max-height: 100px;
  }
}

