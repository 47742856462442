.review-item {
  background-color: var(--wp--preset--color--light-bg);
  font-size: rem(14, 16);
  line-height: 1.5;
  padding: clamp-rem(24, 60) clamp-rem(24, 40);

  &__original {
    display: flex;
    align-items: center;
    gap: rem(10);

    &:hover {

        .review-item__social {
          filter: grayscale(0);

          @include theme-dark {
            filter: grayscale(0) brightness(1);
          }
        }
    }
  }

  &:not(:last-child) {
    position: relative;

    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid var(--wp--preset--color--campeasy-white);
      position: absolute;
      top: 100%;
      left: clamp-rem(24, 40);
      right: clamp-rem(24, 40);
      z-index: 2;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 rem(24);

    @include max(md) {
      flex-wrap: wrap;
      gap: rem(16);
      margin: 0 0 rem(16);
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    gap: rem(10);
  }

  &__social {
    width: rem(26);
    height: rem(26);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    filter: grayscale(1);
    transition: 0.3s;

    [data-layout="color"] & {
      filter: none !important;
    }

    @include theme-dark {
      filter: grayscale(1) brightness(10);
      transition: none;
    }
  }

  &__rating {
    display: flex;

    .icon {
      font-size: rem(16);

      &.active {

        @include theme-dark {
          color: var(--wp--preset--color--white-static);
        }
      }

      &:not(.active) {

        @include theme-dark {
          opacity: 0.5;
        }
      }
    }
  }

  &__content {
    color: var(--wp--preset--color--gray-one);
    margin: 0 0 rem(20);
    overflow: hidden;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: rem(8);

    &-item {
      color: var(--wp--preset--color--gray-one);
      display: flex;
      align-items: center;
      gap: rem(8);

      .icon {
        height: rem(16);
        font-size: rem(16);
      }
    }
  }

  &__gallery {
    display: flex;
    gap: rem(16);
    margin: rem(30) 0 0;
    position: relative;
    overflow: auto;

    @include max(lg) {
      overflow: hidden;
    }

    &.has-extra {

      @include max(lg) {
        &::after {
          content: attr(data-extra);
          width: calc(33.33% - rem(11));
          height: 100%;
          font-size: rem(16);
          color: #fff;
          text-align: center;
          line-height: 1.25;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 2px;
          padding: rem(8);
          position: absolute;
          top: 0;
          right: rem(1);
          pointer-events: none;
        }
      }
    }
  }

  &__thumbnail {
    max-width: rem(80);

    @include max(lg) {
      max-width: unset;
      flex: 0 0 calc(33.33% - rem(11));
    }

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 2px;
      cursor: pointer;

      @include max(lg) {
        width: 100%;
      }
    }
  }

  &__author {
    &-placeholder {
      width: rem(48);
      height: rem(48);
      font-size: rem(22);
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(200, 187, 250);
      border-radius: 50%;
    }

    &-preview {
      font-size: rem(16);
      line-height: 1.5;
      display: flex;
      align-items: center;
      gap: rem(16);

      img {
        width: rem(48);
        height: rem(48);
        background: #EAEAEA;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &-img {
      border-radius: 50%;
    }

    &-name {
      @extend %copy2;
      color: var(--wp--preset--color--base);
      display: flex;
      align-items: center;
      gap: rem(6);

      img {
        width: rem(16);
        height: rem(16);
        object-fit: cover;
      }
    }

    &-location {
      color: var(--wp--preset--color--gray-one);
      text-transform: capitalize;
    }
  }
}

// inside Reviews (company block)
.itm-reviews__grid {
  .review-item {
    padding-top: clamp-rem(32, 45);
    padding-bottom: clamp-rem(32, 45);
    border: 1px solid var(--wp--preset--color--stroke);
    border-radius: $radius;

    &:after {
      display: none !important;
    }
  }
}

.itm-reviews-camper {
  .itm-reviews__grid {
    .review-item {
      background-color: var(--wp--preset--color--white);
    }
  }
}
