.exclusives {
  background-color: var(--wp--preset--color--light-bg);
  margin-top: 0 !important;
  padding-bottom: clamp-rem(20, 30);
  text-align: center;
  position: relative;

  &.is-open {
    .exclusives__toggle:after {
      transform: rotate(90deg);
      opacity: 0;
    }
  }

  &__title {
    cursor: pointer;
    letter-spacing: 0.05em;
    padding: clamp-rem(30, 50) 0 rem(20);
    margin: 0 !important;
    transition: $tr;
  }

  &__toggle {
    width: rem(44);
    height: rem(44);
    background-color: var(--wp--preset--color--campeasy-white);
    border-radius: 50%;
    position: absolute;
    bottom: rem(-22);
    left: calc(50% - rem(22));
    transition: transform $tr;
    cursor: pointer;
    z-index: 2;

    &:before,
    &:after {
      content: '';
      display: block;
      width: rem(18);
      height: 1px;
      background-color: var(--wp--preset--color--base);
      position: absolute;
      top: 50%;
      left: calc(50% - rem(9));
      transition: $tr;
    }

    &:after {
      transform: rotate(90deg);
    }

    @include hover-responsive {
      &:before,
      &:after {
        background-color: var(--wp--preset--color--primary);
      }
    }
  }

  &__arrow {
    width: rem(44);
    height: rem(44);
    position: absolute;
    bottom: rem(-22);
    left: calc(50% - rem(22));
    transition: transform $tr;
    cursor: pointer;
    z-index: 2;
  }

  &__subtitle {
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-bottom: clamp-rem(32, 50);
  }

  &__content {
    padding: 0 0 clamp-rem(20, 80);
    display: none;
  }

  &__items {
    @include unstyled-list;
    display: grid;
    gap: rem(20);
    grid-template-columns: repeat(auto-fill, minmax(rem(300), 1fr));

    @include min(lg) {
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-template-columns: initial;
    }
  }
}

.exclusive {
  flex: 1 0 auto;
  background-color: var(--wp--preset--color--campeasy-white);
  padding: clamp-rem(32, 50) rem(20) clamp-rem(28, 46);

  &__icon {
    img {
      width: clamp-rem(54, 72);
      height: clamp-rem(54, 72);
      object-fit: contain;
    }
  }

  &__title {
    font-size: rem(18);
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: rem(16);
  }
}
