.summary-promo-code {
 padding: rem(20);
 background-color: var(--wp--preset--color--white);
 margin-top: rem(16);
 @include border;
 transition: $tr;

 &__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:rem(10);
  cursor: pointer;

  .summary-promo-code--expanded & {
   margin-bottom: rem(12);

   .icon--io-chevron-down {
    transform: rotate(180deg);
   }
  }
 }

 &__title {
  margin-bottom: 0;
 }

 &__note {
  color: var(--wp--preset--color--gray-one);
  @extend %copy-md;
  margin-bottom: rem(2);
   @include max(md) {
	 font-size: rem(12);

   }
 }

	&__apply-btn {
  margin-top: rem(16);
 }

 &__result {
  display: flex;
  align-items: center;
  gap: rem(10);
  @extend %copy1;

  i {
   font-size: rem(24);
   color: var(--wp--preset--color--success);
  }

  &--error {
   color: var(--wp--preset--color--error);

   i {
    color: var(--wp--preset--color--error);
   }
  }

  &--success {
   .success-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--wp--preset--color--success);
    border-radius: 50%;
    width: rem(24);
    height: rem(24);
   }
   i {
    color: $w;
    font-size: rem(12);
   }
  }
 }

 &__input-wrapper {
  position: relative;
  margin-bottom: rem(10);

  &--error {
   .summary-promo-code__input {
      border: 1px solid var(--wp--preset--color--error) !important;
    }
  }
 }

 &__clear {
  @extend %copy1;
  position: absolute;
  top: 50%;
  right: rem(16);
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--wp--preset--color--gray-one);
 }

 &__code-display {
  @extend %copy1;
  @include border;
  width: 100%;
  height: rem(44);
  display: inline-flex;
  align-items: center;
  background-color: var(--wp--preset--color--white);
  color: var(--wp--preset--color--base);
  font-size: rem(16);
  font-weight: 400;
  text-align: left;
  line-height: rem(16);
  box-shadow: none;
  padding: rem(13 20);
  position: relative;
  margin-bottom: rem(10);
 }

 &__delete {
  position: absolute;
  top: 50%;
  right: rem(16);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: none;
  color: var(--wp--preset--color--error);
  @extend %copy1;
 }
}
