$w: #FFF;
$b: #000;
$primary: #9580EB;
$primary-darker: #4A34A6;

// Base static colors (same for Light and Dark themes):
$color-white: #FFF;
$color-base: #21201F;
$color-gray-one: #545A5C;
$color-gray-two: #9CA3AF;
$color-light-bg: #F9F9F9; 
$color-stroke: #EAEAEA;
$color-link: #553FB1;
$color-primary-lighter: #EFECFC;

// Dark theme colors:
$dark-white: #2a2928;
$dark-base: #FFF; // main black
$dark-light-bg: #1e1d1c;
$dark-gray-one: #FFF;
$dark-gray-two: #FFF;
$dark-stroke: #373636;
$dark-link: #9580eb;
$dark-primary-lighter: #403f47;
