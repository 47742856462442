div.gform_wrapper.gravity-theme {
  .gform_heading {
    padding: rem(0 16);
    text-align: center;
  }

  .gform_description {
    font-size: clamp-rem(20, 22);
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: clamp-rem(26, 30);
    margin: rem(16 0);
  }

  .gform_confirmation_message {
    font-size: rem(18);
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  div.gform_validation_errors {
    display: none !important;
  }

  form {
    position: relative;

    .gform_body {
      .gfield {
        position: relative;
	      +.gfield {
		      margin-top: 0;
	      }
      }

      fieldset.gfield {
        .gfield_label {
          margin-bottom: rem(20);
        }
      }
    }

    .gform_fields {
      gap: rem(10);
    }

    .gfield_label {
      font-size: 1em;
      font-weight: 400;
      margin-bottom: 1em;
    }

    input[type=color],
    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
      @extend %input;
    }

    .gfield--type-textarea {
      resize: none !important;
    }

    .gfield_checkbox {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      padding-left: rem(20);

      @include min(md) {
        padding-left: rem(40);
      }

      label {
        font-size: 1em;
      }
    }

    .gchoice {
      display: flex;
      align-items: center;
      gap: rem(20);

      input {
        flex-shrink: 0;
      }
    }

    .gfield_validation_message,
    .validation_message {
      background: transparent;
      border: none;
      font-size: rem(12);
      line-height: 1;
      margin-top: rem(5);
      padding: 0;
    }

    .nice-select .current {
      font-weight: 400;
    }

    .gform_footer {
      justify-content: center;
      margin: rem(10 0 0);
      padding: 0;

      input[type="submit"],
      .gform_button {
        @extend .btn;
        margin-bottom: 0;
      }
    }
  }

  .gform_ajax_spinner {
    position: absolute;
    top: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);

	  .modal-contact & {
		  top: auto;
		  transform: none;
		  left: auto;
		  right: 0;
		  bottom: calc((rem(45) - 1.5rem) / 2);
	  }
  }
}

.gform_confirmation_wrapper.gravity-theme {
  .gform_confirmation_message {
    font-size: clamp-rem(20, 22);
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: clamp-rem(26, 30);
    margin: rem(16 0 0);
    text-align: center;
  }
}

// Spinner
.gform_ajax_spinner {
  margin-left: rem(16);
  border: 3px solid var(--wp--preset--color--gray-three);
  border-left: 3px solid var(--wp--preset--color--primary);
  animation: spinner 1.1s infinite linear;
  border-radius: 50%;
  width: rem(24);
  height: rem(24);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
