.js-compare-camper {

  .in-cart & {
	background-color: var(--wp--preset--color--primary);

	.icon {
	  color: $w !important;
	}
  }
}

.page-comparison {
  padding-top: var(--header-height);

  .page-title {
	margin: rem(20 0);
  }

  @media print {
	.site-header,
	.comparison-header--fixed,
	.comparison-actions,
	.comparison-actions-mobile,
	.exclusives,
	.site-footer,
	.comparison-header__camper-remove,
	.comparison-header__camper .btn,
	.comparison-header__form {
	  display: none;
	}
  }
}

.comparison {

  &-container {
	padding-top: var(--wp--preset--spacing--xl);
	padding-bottom: var(--wp--preset--spacing--xxl);
  }

  &-actions {
	&__inner {
	  display: flex;
	  align-items: center;
	  gap: rem(24);
	  padding: rem(11 0);

	  @include min(lg) {
		justify-content: flex-end;
		padding: rem(21 0);
	  }
	}

	&__checkboxes {
	  display: flex;
	  align-items: center;
	  gap: rem(24);
	  margin-right: auto;

	  &-label {
		margin: rem(0 16 0 0);

		@include max(1280) {
		  display: none;
		}
	  }
	}

	&__item {
	  display: inline-flex;
	  align-items: center;
	  gap: rem(12);
	  position: relative;

	  .icon {
		font-size: rem(20);
	  }
	}

	&__dropdown {
	  display: none;
	  width: calc(100% + #{rem(28)});
	  background-color: var(--wp--preset--color--white);
	  border-radius: $radius;
	  box-shadow: 0 4px 12.5px 0 rgba($b, 0.25);
	  position: absolute;
	  top: calc(100% + 10px);
	  left: rem(-14);
	  z-index: 11;

	  &-item {
		display: flex;
		align-items: center;
		gap: rem(10);
		padding: rem(14);

		+ .comparison-actions__dropdown-item {
		  border-top: 1px solid var(--wp--preset--color--stroke);
		}

		.icon {
		  font-size: rem(16);
		}

		span {
		  @extend %copy-md;
		}
	  }
	}
  }

  &-actions-mobile {
	display: flex;
	flex-wrap: wrap;
	gap: rem(12 24);
	padding: rem(13 20);
	border: 1px solid var(--wp--preset--color--stroke);
	border-top: none;
  }

  &-header {
	background-color: var(--wp--preset--color--white);
	position: relative;
	z-index: 10;

	&__inner {
	  display: flex;
	  border: 1px solid var(--wp--preset--color--stroke);

	  > * {
		flex: 1 0 25%;
		padding: rem(28) clamp-rem(10, 30) clamp-rem(14, 24);

		@include min(md) {
		  padding-top: clamp-rem(14, 24);
		}

		&:first-child {
		  @include max(lg) {
			display: none;
		  }
		}

		&:not(:last-child) {
		  border-right: 1px solid var(--wp--preset--color--stroke);
		}
	  }

	  > h6 {
		display: flex;
		align-items: center;
		margin: 0;
	  }
	}

	&__camper {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  text-align: center;
	  position: relative;

	  &-title {
		flex-grow: 1;
		color: var(--wp--preset--color--primary);
		padding: rem(0 20);
		margin-bottom: clamp-rem(4, 24);

		@include max(md) {
		  font-size: clamp-rem(15, 18, 360, 480);
		  padding: 0;
		}

		a {
		  color: inherit !important;
		}
	  }

	  img {
		flex-shrink: 0;
		margin-bottom: rem(30);
	  }

	  > .btn {
		@include max(md) {
		  height: rem(32);
		  font-size: clamp-rem(11, 12, 360, 480);
		  padding: 0 clamp-rem(8, 12, 360, 480);
		}
	  }

	  &-remove {
		flex-shrink: 0;
		position: absolute;
		top: rem(4);
		right: rem(4);
		font-size: rem(20);
		color: var(--wp--preset--color--gray-two);
		cursor: pointer;
		transition: color 0.1s;

		@include min(md) {
		  top: rem(25);
		  right: clamp-rem(10, 30);
		}

		&:hover {
		  color: var(--wp--preset--color--primary);
		}
	  }

	  &-add {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: rem(20);
		max-width: rem(260);
		margin: auto;

		@include max(md) {
		  font-size: rem(14);
		}

		&-inner {
		  width: 100%;

		  .btn-icon {
			font-size: rem(28);
			font-weight: 300;
			font-style: normal;
			cursor: pointer;
		  }
		}
	  }

	  .custom-select {
		width: 100%;
		font-size: rem(14);
		line-height: rem(16);
		margin: auto;

		.icon {
		  font-size: rem(18);
		  position: absolute;
		  top: calc(50% - #{rem(9)});
		  right: rem(12);

		  @include max(md) {
			top: calc(50% + #{rem(16)});
		  }
		}

		.option {
		  gap: rem(7);

		  label {
			text-align: left;
			white-space: normal;
		  }

		  img {
			width: rem(40);
			margin: 0;
		  }
		}

		.custom-select__current {
		  padding-right: rem(27) !important;

		  @include max(md) {
			width: rem(175) !important;
			position: absolute;
			top: 0;
			right: 0;
		  }
		}

		&__list {
		  max-height: 240px;
		  overflow-y: auto !important;
		  border-radius: 0 !important;

		  @include max(md) {
			width: rem(175) !important;
			right: 0 !important;
			left: auto !important;
			top: rem(47) !important;
		  }
		}
	  }
	}

	@include max(xs) {

	  .container {
		padding: 0;
	  }
	}
  }

  &-header--fixed {
	width: 100%;
	background-color: var(--wp--preset--color--white);
	transform: translateY(-100%);
	position: fixed;
	top: var(--sticky-header-height);
	z-index: 10;
	transition: transform $tr;

	&.is-active {
	  transform: translateY(0);
	}

	.comparison-header__inner {
	  border-top: none;

	  > * {
		height: rem(64);
		padding-top: rem(6);
		padding-bottom: rem(6);
	  }
	}

	.comparison-header__camper {
	  justify-content: center;

	  &-title {
		color: var(--wp--preset--color--black);
		font-size: clamp-rem(14, 16);
		flex-grow: 0;
		padding: 0;
	  }

	  &-add {
		.btn-icon {
		  width: rem(32);
		  height: rem(32);
		  font-size: rem(22);
		}
	  }
	}
  }

  &-body {
	margin-bottom: var(--wp--preset--spacing--xxl);

	&__inner {
	  border: 1px solid var(--wp--preset--color--stroke);
	  border-top: none;
	}

	@include max(xs) {

	  .container {
		padding: 0;
	  }
	}
  }

  &-group {
	+ .comparison-group {
	  border-top: 1px solid var(--wp--preset--color--stroke);
	}

	&__title {
	  display: flex;
	  align-items: center;
	  gap: rem(10);
	  padding: rem(21 30);
	  cursor: pointer;
	  margin: 0;

	  @include max(lg) {
		flex-direction: row-reverse;
		justify-content: center;
		gap: rem(20);
	  }

	  &.is-open {
		.icon {
		  transform: rotate(180deg);
		}
	  }
	}

	&__row {
	  display: flex;

	  @include max(lg) {
		flex-wrap: wrap;
	  }

	  &:nth-child(2n+1) {
		@include min(lg) {
		  background-color: var(--wp--preset--color--light-bg);
		}
	  }

	  &.has-differences {
		.comparison-group__cell:not(:empty):not(:first-child) {
		  background-color: rgba(#B5A6F1, 0.15) !important;
		}
	  }
	}

	&__cell {
	  flex: 1 0 25%;
	  height: rem(54);
	  display: flex;
	  align-items: center;
	  padding: rem(0 30);

	  @include max(lg) {
		padding: rem(0 15);
	  }

	  &:empty {
		&:after {
		  content: '-';
		}
	  }

	  &:first-child {
		@include max(lg) {
		  flex-basis: 100%;
		  height: auto;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  gap: rem(8);
		  font-size: rem(14);
		  padding: rem(12 0);
		}

		@include max(xs) {
		  flex-direction: column;
		}

		.icon {
		  font-size: rem(24);

		  @include min(lg) {
			display: none;
		  }
		}
	  }

	  &:not(:first-child) {
		justify-content: center;
		text-align: center;
		font-weight: 600;
		border-left: 1px solid var(--wp--preset--color--stroke);

		@include max(lg) {
		  background-color: var(--wp--preset--color--light-bg);
		  flex-basis: 33.3%;
		  height: rem(46);
		  font-size: rem(14);
		}
	  }

	  .insurance__feature {
		padding: 0;

		.icon {
		  min-width: rem(18);
		  height: rem(18);
		  font-size: rem(18);
		  flex-basis: rem(18);
		}
	  }
	}
  }
}

// Form
.gform_wrapper.gravity-theme {
  .gform_heading {
	padding: 0;
	text-align: left;
  }

  .gform_title {
	@extend .h6;
	margin-bottom: rem(24);
  }

  .gform_required_legend {
	display: none;
  }

  form {
	.gfield + .gfield {
	  margin-top: rem(12);
	}

	.gform_footer {
	  justify-content: flex-start;
	  margin-top: rem(14);

	  .gform_button {
		@extend .btn-outline;
	  }
	}

	textarea {
	  height: rem(112) !important;
	}
  }
}

// Fixed Mini-cart in the bottom
.cart-comparison {
  // &__count {
  // 	&:after {
  // 		content: attr(data-count);
  // 	}
  // }
  bottom: rem(-1000);
  transition: $tr;

  @include max(lg) {
	border-radius: 0;
  }


  &.loaded {
	bottom: 0;
  }

  &__btn {
	span {
	  display: inline;
	}
  }
}

.camper-card__mini {
  display: flex !important;
}
