.fixed-links {
  width: rem(74);
  background-color: var(--wp--preset--color--white);
  border: 1px solid var(--wp--preset--color--gray-three);
  border-radius: rem(74);
  padding: rem(14 5);
  text-align: center;
  overflow: hidden;
  transition: $tr;
  position: fixed;
  right: rem(6);
  z-index: 98;

  @include min(lg) {
    bottom: rem(64);
  }

  @include min(xl) {
    bottom: rem(16);
  }

  body:has(#modal-contact.is-open) & {
    display: none;
  }

  &:has(input:not(:checked)) {
    background-color: transparent;
    border-color: transparent;

    .fixed-links__items {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }

    .fixed-link {
      transform: scale(0.5);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    margin-bottom: rem(24);
    transition: $tr;
  }

  &__toggle {
    width: rem(46);
    height: rem(46);
    position: relative;

    input {
      display: none !important;
    }

    &:has(input:not(:checked)) {
      .fixed-links__toggle--close {
        display: none;
      }
    }

    &:has(input:checked) {
      .fixed-links__toggle--open {
        display: none;
      }
    }

    &--close {
      background-color: var(--wp--preset--color--white);
      border: 1px solid var(--wp--preset--color--gray-three);

      .icon {
        color: var(--wp--preset--color--gray-one);
      }
    }

    &--open {
      .icon {
        color: $w;
      }
    }
  }

  .icon-circle {
    cursor: pointer;

    @include hover-responsive {
      .icon,
      img {
        transform: scale(1.1);
      }
    }

    .icon,
    img {
      transition: transform $tr;
    }
  }
}

.fixed-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(5);
  transition: $tr;

  &__title {
    display: inline-block;
    color: var(--wp--preset--color--base);
    font-size: rem(12);
    letter-spacing: 0.05em;
    line-height: 1.3;
  }
}
