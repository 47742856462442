.single-campers {
  .itm-intro {
    @include max(lg) {
      padding-bottom: rem(130);
    }

    @include min(lg) {
      height: 55vw;
      max-height: rem(785);
    }

    .yoast-breadcrumbs {
      @include min(xl) {
        padding-top: rem(140);
      }
    }
  }

  .fancybox__content {
    padding: 0;
    width: 100% !important;
    height: auto !important;
    justify-content: center;
    background: transparent;
    @include min(lg) {
      max-width: 60vw !important;
    }

    @include max(lg) {
      max-width: 70vw !important;
    }

    @include max(md) {
      max-width: 80vw !important;
    }

    > iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    .is-close-btn {
      position: absolute;
      top: rem(-40);
      right: rem(-40);
      visibility: hidden;
      cursor: pointer;
      transition: 0.15s;

      &::before {
        content: "\e01d";
        display: block;
        line-height: 1;
        font-family: "campeasy";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        text-decoration: none;
        text-transform: none;
        color: $color-gray-two;
        visibility: visible;
      }
      &:hover {

        &::before {
          color: $primary;
        }
      }
    }

  }

  .wp-block-itm-availability-table {

    @media screen and (min-width: 768px) and (max-width: 1024px){
      .searchform-mainwrapper {
        max-width: 100% !important;
        .searchform-block {
          flex-direction: row !important;
        }
      }
    }
  }
}

.single {
  &-summary {
    padding-bottom: var(--wp--preset--spacing--xxl);
    margin: 0;

    &__inner {
      display: grid;
      margin-top: rem(-100);
      position: relative;
      z-index: 2;

      @include min(lg) {
        column-gap: rem(20);
        grid-template-columns: 1fr 1fr;
	      max-width: var(--wp--style--global--wide-size);
        margin: rem(-140) auto 0;
        pointer-events: none; // needed for breadcrumbs to be clickable
      }

      @include min(xl) {
        margin-top: rem(-190);
      }
    }
  }

  &-dates {
    background-color: var(--wp--preset--color--stroke);
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.2;
    border-radius: $radius 0 $radius 0;
    padding: rem(10 21);
    position: absolute;
    top: 0;
    left: 0;
  }

  &-gallery {
    grid-row: 2;

    @include min(lg) {
      padding-top: rem(190); // 50 + 140
      grid-row: span 10;
    }

    @include min(xl) {
      padding-top: rem(240); // 50 + 190
    }

    .gallery-grid {
      @include min(lg) {
        position: sticky;
        top: 0;
        pointer-events: all;
      }
    }
  }

  &-overview {
    background-color: var(--wp--preset--color--campeasy-white);
    border-radius: $radius $radius 0 0;
    padding: rem(48 16 40);
    position: relative;

    @include min(lg) {
      padding: rem(69 50 0);
      pointer-events: all;
    }

    &:has(.single-camper-dates) {
      padding-top: rem(50);
    }

    &__heading {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: rem(8 16);
      align-items: center;
      margin-bottom: rem(16);

      .rating {
        @include min(md) {
          grid-column: span 2;
        }
      }

      &-icons {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        gap: clamp-rem(10, 16);
        line-height: 0;

        @include max(md) {
          grid-row: 1;
          grid-column: 2;
        }

        .icon {
          color: var(--wp--preset--color--primary);
          flex-shrink: 0;
          font-size: rem(24);

          @include min(420) {
            font-size: clamp-rem(32, 42);
          }
        }
      }
    }

    &__title {
      margin: 0;

      @include max(md) {
        grid-column: -1/1;
      }
    }

    &__text {
      margin-bottom: rem(30);
    }

    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: clamp-rem(16, 20);

      @include max(575) {
        display: grid;
        grid-template-areas: "book favorites"
        "all compare";
      }

      .button--book {
        grid-area: book;
      }

      .button--all {
        grid-area: all;
      }

      .button--favorites {
        grid-area: favorites;
      }

      .js-compare-camper {
        grid-area: compare;
      }
    }
  }
}

.single-camper {
  &-pricing {
    border-bottom: 1px solid var(--wp--preset--color--stroke);
    margin-bottom: clamp-rem(32, 40);
    padding-bottom: clamp-rem(32, 40);
    container: pricing/inline-size;

    &__table {
      width: 100%;
      margin: rem(0 -4 16);

      td {
        vertical-align: baseline;
        padding: rem(0 4);

        &:nth-child(1) {
          color: var(--wp--preset--color--link);
          font-size: clamp-rem(16, 18);
          font-weight: 500;
          letter-spacing: 0.02em;
          line-height: 1.4;
          white-space: nowrap;
        }

        &:nth-child(2) {
          width: rem(105);
          color: var(--wp--preset--color--gray-two);
          font-size: rem(14);
          line-height: rem(22);
          letter-spacing: 0.05em;
          padding-right: rem(15);
          white-space: nowrap;
        }

        &:nth-child(3) {
          color: var(--wp--preset--color--link);
          font-size: clamp-rem(18, 22);
          font-weight: 500;
          white-space: nowrap;

          span {
            padding-right: clamp-rem(14, 16);
            position: relative;
          }

          @include hover-responsive {
            .icon {
              transform: scale(1.2);
            }
          }

          .icon {
            color: currentColor;
            font-size: clamp-rem(12, 14);
            position: absolute;
            top: 0;
            right: 0;
            transition: transform $tr;
          }
        }
      }

      &-total {
        td {
          padding-top: rem(12);

          &:nth-child(1) {
            font-size: clamp-rem(18, 22);
          }

          &:nth-child(3) {
            font-size: clamp-rem(22, 30);
          }
        }
      }
    }

    @container pricing (max-width: 360px) {
      .single-camper-pricing__table {
        margin: 0;

        tr {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: rem(10);

          &.single-camper-pricing__table-total {
            margin-top: rem(7);
          }
        }

        td {
          display: block;
          padding: 0;

          &:nth-child(2) {
            order: 3;
            width: 100%;
            text-align: right;
          }
        }
      }

      .camper__dates-footer {
        margin-top: 0;
      }
    }

    &__footer {
      @extend %copy-sm;
    }
  }

  &-lists {

    @include min(md) {
      padding: 0;
    }

    @include min(lg) {
      padding: rem(0 50);
    }

    &__title {
      margin-bottom: clamp-rem(24, 30);
    }

    &--details {
      margin-top: rem(32);

      @include min(lg) {
        border-top: 1px solid var(--wp--preset--color--stroke);
        margin-top: rem(40);
        padding-top: rem(40);
      }

      .single-camper-list {
        @include unstyled-list;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        .list-item {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: rem(7);

          &__title {
            @extend %copy-sm;
          }
        }

        .icon {
          font-size: rem(56);
        }

        @include min(md) {
          grid-template-columns: repeat(auto-fit, rem(72));
          gap: rem(34 75);

          .icon {
            font-size: rem(72);
          }
        }
      }
    }

    &--highlights {
      border-top: 1px solid var(--wp--preset--color--stroke);
      margin-top: clamp-rem(24, 40);
      padding-top: clamp-rem(24, 40);

      .single-camper-list {
        @include unstyled-list;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(30);

        @include min(md) {
          grid-template-columns: repeat(auto-fit, minmax(rem(190), 1fr));
          gap: rem(30);
        }

        .list-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: rem(12);

          @include min(md) {
            gap: rem(8);
          }

          @include max(md) {

            &__title {
              line-height: 1.33;
            }
          }
        }

        .icon {
          font-size: rem(36);
        }
      }
    }
  }

  &-modal__features {
    @include unstyled-list;

    .list-item {
      display: grid;
      grid-template-columns: clamp-rem(54, 60) 1fr;
      column-gap: rem(16, 20);
      row-gap: rem(12);

      &:not(:last-child) {
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        margin-bottom: clamp-rem(16, 30);
        padding-bottom: clamp-rem(16, 30);
      }

      .icon {
        display: inline-block;
        grid-row: span 2;
        color: var(--wp--preset--color--primary);
        font-size: clamp-rem(54, 60);
        vertical-align: top;
      }

      .list-item__title {
        @extend %h5;
        margin: 0;
      }

      .list-item__text {
        @extend %copy1;
      }
    }
  }

  &__unavailable-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--wp--preset--color--light-bg);
    padding: clamp-rem(50, 80) rem(30);

    .camper__unavailable {
      text-align: center;

      &-title {
        max-width: unset;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Reviews
.offcanvas-reviews {
  &__header {
    height: rem(120);
    background-color: var(--wp--preset--color--light-bg);
    flex-shrink: 0;
    padding: clamp-rem(32, 40) clamp-rem(50, 70) rem(10) clamp-rem(24, 40);
    position: relative;

    @include min(lg) {
      height: rem(150);
    }

    > .icon {
      color: rgba($b, 0.02);
      font-size: clamp-rem(100, 160);
      position: absolute;
      top: 50%;
      left: 56%;
      transform: translate(-50%, -50%);
    }

    &-title {
      margin: rem(0 0 12);

      @include max(xs) {
        font-size: rem(26);
      }
    }

    &-subtitle {
      color: var(--wp--preset--color--gray-two);
      margin: 0;
    }
  }

  .campers-filters__sorting {
    position: absolute;
    bottom: rem(10);
    right: rem(6);

    @include min(md) {
      right: clamp-rem(8, 30);
    }

    .custom-select {
      @include max(lg) {
        .option {
          min-height: rem(36);
          padding-top: rem(5);
          padding-bottom: rem(5);
        }

        > .icon {
          top: rem(8);
        }

        &:not(.active):not(:hover) .custom-select__current {
          border-color: transparent;
        }
      }

      .custom-select__current {
        background-color: transparent !important;
      }
    }
  }
}

.reviews-stats {
  background-color: var(--wp--preset--color--campeasy-white);
  padding: clamp-rem(24, 32) clamp-rem(18, 40) clamp-rem(24, 32) clamp-rem(24, 40);
  display: flex;
  align-items: center;

  &__total {
    &-value {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: clamp-rem(8, 14);
      margin-bottom: rem(9);

      span {
        display: block;
        font-size: clamp-rem(20, 29);
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.27;
        white-space: nowrap;
        position: relative;
        top: rem(3);
      }

      .icon {
        font-size: clamp-rem(26, 43);
      }
    }

    &-reviews {
      font-size: clamp-rem(14, 18);
      line-height: 1.3;
    }
  }

  &__separated {
    flex-grow: 1;
    border-left: 1px solid var(--wp--preset--color--stroke);
    margin-left: clamp-rem(22, 43);
    padding: clamp-rem(4, 10) 0 clamp-rem(4, 10) clamp-rem(24, 50);
    @include max(md) {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
    }

    &-table {
      @extend %copy-sm;
      width: 100%;
      font-weight: 500;

      td {
        &:nth-child(1),
        &:nth-child(3) {
          white-space: nowrap;
        }

        &:nth-child(1) {
          width: rem(43);
        }

        &:nth-child(2) {
          padding: clamp-rem(5, 8) rem(10);
        }

        &:nth-child(3) {
          width: rem(33);
        }
      }
    }

    &-value {
      display: block;
      width: 100%;
      height: clamp-rem(16, 18);
      background: linear-gradient(to right, var(--wp--preset--color--primary) var(--review-value, 0), $w var(--review-value, 0));
      border: 1px solid var(--wp--preset--color--gray-two);
      border-radius: rem(9);
    }
  }

  // layout: vertical on mobiles
  &--vertical-mobile {
    @include max(md) {
      flex-direction: column;
      padding: rem(30 16);

      .reviews-stats__total {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;

        &-value {
          margin: 0;
        }
      }

      .reviews-stats__separated {
        width: 100%;
        border-left: none;
        border-top: 1px solid var(--wp--preset--color--stroke);
        padding: rem(20 0 0);
        margin: rem(20 0 0);
      }
    }
  }
}
