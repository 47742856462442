.wp-site-blocks {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100vh;
}

main {
	height: max-content;
	@supports (overflow-x: clip) {
		overflow-x: clip;
	}

	@supports not (overflow-x: clip) {
		overflow-x: hidden;
	}
}

.is-front,
.is-front * {
	@include scrollbar;
	@include scrollbar-x;
}

.entry-content {
	max-width: 100vw;
	margin-top: 0;
}

.container {
	width: 100%;
	padding-left: var(--wp--style--root--padding-left);
	padding-right: var(--wp--style--root--padding-right);
	max-width: calc(var(--wp--style--global--wide-size) + var(--wp--style--root--padding-left) + var(--wp--style--root--padding-right));
	margin-right: auto;
	margin-left: auto;
}

.itm-max-width {
	max-width: var(--wp--style--global--wide-size);
	margin-left: auto !important;
	margin-right: auto !important;

	&.has-global-padding {
		max-width: calc(var(--wp--style--global--wide-size) + var(--wp--style--root--padding-left) + var(--wp--style--root--padding-right));
	}
}

.is-style-sticky {

	@include min(md) {
		position: sticky;
		top: rem(32);
	}
}
