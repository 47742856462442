footer.wp-block-template-part {
  margin-top: 0;
}

.site-footer {
  background-color: $color-base;
  color: $color-white;
  letter-spacing: 0.05em;
  margin: 0;
  padding-top: rem(90);
  padding-bottom: rem(32);

  @include min(md) {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr auto;
    gap: rem(32 0);

    @include min(md) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__logo {
    grid-area: 1 / 1 / 1 / 3;

    @include min(md) {
      grid-area: 1 / 1 / 1 / 5;
    }

    @include min(lg) {
      grid-area: 1 / 1 / 1 / 4;
    }

    @include min(xl) {
      grid-area: 1 / 1 / 1 / 3;
    }

    @include min(xxl) {
      grid-area: 1 / 1 / 1 / 2;
    }

    img {
      width: rem(146);
    }
  }

  &__time {
    grid-area: 1 / 3 / 1 / 5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: rem(32);

    @include min(md) {
      grid-area: 2 / 5 / 2 / 9;
      justify-content: center;
      padding-right: 0;

      @include max(xl) {
        border-top: 1px solid var(--wp--preset--color--gray-three);
        border-bottom: 1px solid var(--wp--preset--color--gray-three);
      }
    }

    @include min(lg) {
      padding-right: 15%;
    }

    @include min(xl) {
      grid-area: 1 / 3 / 1 / 5;
      padding-right: 0;
    }

    @include min(xxl) {
      grid-area: 1 / 2 / 1 / 5;
      justify-content: flex-start;
      padding-left: 12%;
    }

    &-inner {
      @include max(lg) {
        max-width: rem(120);
      }

      @include min(xl) {
        position: relative;
        top: rem(-3);
      }
    }

    &-label {
      display: block;
      font-size: rem(14);
      line-height: rem(22);
    }

    &-value {
      display: block;
      font-size: clamp-rem(26, 30);
      font-weight: 500;
      letter-spacing: 0.05em;
      line-height: clamp-rem(34, 38);
      white-space: nowrap;

      @include min(lg) {
        font-weight: 600;
      }
    }
  }

  .socials {
    grid-area: 2 / 4 / 2 / 5;

    @include max(md) {
      align-items: flex-end;
      flex-direction: column;
      padding-right: rem(32);
    }

    @include min(md) {
      height: rem(116);
      grid-area: 2 / 1 / 2 / 5;
      border-top: 1px solid var(--wp--preset--color--gray-three);
      border-bottom: 1px solid var(--wp--preset--color--gray-three);
      padding: rem(5 0);
    }

    @include min(xl) {
      grid-area: 2 / 1 / 2 / 9;
    }
  }

  &__address {
    grid-area: 3 / 1 / 3 / 5;
    display: flex;
    align-items: center;
    font-size: rem(14);
    line-height: clamp-rem(22, 28);
    border-top: 1px solid var(--wp--preset--color--gray-three);
    border-bottom: 1px solid var(--wp--preset--color--gray-three);
    padding: rem(32 0);

    @include min(md) {
      grid-area: 2 / 9 / 2 / 13;
      height: rem(116);
      padding: rem(8 0);
    }

    @include min(xl) {
      justify-content: flex-end;
    }

    &-inner {
      > * {
        margin: rem(0 0 5);

        @include min(md) {
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: clamp-rem(24, 32);
    justify-content: space-between;
    padding-top: rem(32);
  }

  &__copyright {
    grid-area: 3 / 1 / 3 / 4;
    font-size: rem(14);
    line-height: rem(22);
  }

  &__awards {
    display: flex;
    flex-wrap: wrap;
    gap: clamp-rem(16, 28);

    @include max(md) {
      order: -1;
      margin-bottom: rem(6);
    }
  }

  &__award {
    flex-shrink: 0;
    display: block;
    line-height: 0;

    @include hover-responsive {
      img {
        filter: grayscale(0);
      }
    }

    img {
      width: auto;
      height: clamp-rem(42, 46);
      filter: grayscale(1);
      transition: filter $tr;
    }
  }

  &__links {
    @include unstyled-list;
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @include min(md) {
      align-items: center;
      flex-direction: row;
     }

    a {
      @include animated-underline;
      color: $color-white;
      font-size: rem(14);
      line-height: rem(22);
    }
  }
}

// Quick Links
.quick-links {
  grid-area: 2 / 1 / 2 / 4;
  display: flex;
  flex-direction: column;
  gap: rem(24);

  @include min(md) {
    grid-area: 1 / 5 / 1 / 13;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(16 24);
    grid-template-columns: repeat(2, 1fr);
  }

  @include min(lg) {
    grid-area: 1 / 4 / 1 / 13;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  @include min(xl) {
    grid-area: 1 / 5 / 1 / 13;
    gap: rem(32);
    margin-left: 6%;
  }
}

.quick-link {
  display: flex;
  align-items: center;
  gap: rem(10);
  color: $color-white;
  line-height: 1.3;

  @include min(md) {
    width: calc(50% - rem(12));
  }

  @include min(lg) {
    width: calc(25% - rem(12));
  }

  @include min(xl) {
    width: auto;
  }

  @include hover-responsive {
    text-decoration: none;

    .quick-link__icon img {
      transform: scale(1.2);
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    display: block;
    font-size: rem(16);
    font-weight: 500;
  }

  &__subtitle {
    min-width: rem(80);
    display: block;
    font-size: rem(10);
    margin-top: rem(3);
    word-break: keep-all;
  }
}
