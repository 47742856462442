// Icon
.icon {
  flex-shrink: 0;
}

// Icon inside a circle
.icon-circle {
  width: rem(46);
  height: rem(46);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--wp--preset--color--primary);
  border-radius: 50%;
  transition: background-color $tr;

  img {
    width: rem(22);
    transition: transform $tr;
  }

  .icon {
    color: var(--wp--preset--color--campeasy-white);
    font-size: rem(22);
    transition: color $tr, transform $tr;
  }
}

// Rating Stars
.stars {
  display: inline-flex;
  flex-wrap: wrap;
  line-height: 0;
  position: relative;

  .icon {
    color: var(--wp--preset--color--gray-two);
    font-size: rem(18);

    &.active {
      color: var(--wp--preset--color--accent);
      clip-path: polygon(0 0, var(--rating-value) 0, var(--rating-value) 100%, 0 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// Image Placeholder
.img-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wp--preset--color--white);
  color: var(--wp--preset--color--primary);
  font-size: rem(80);

  svg {
    width: rem(80);
    height: rem(80);
  }
}

// Input controls (plus and minus buttons, value) (is used on Event Card camper)
.input-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);

  &__btn {
    @include unstyled-button;
    flex-shrink: 0;
    width: rem(16);
    height: rem(16);
    background-color: var(--wp--preset--color--white-static);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 2.56px 0 rgba(#000, 0.25);
    cursor: pointer;
    transition: border-color 0.2s;

    @include hover-responsive {
      border-color: var(--wp--preset--color--primary);
      box-shadow: none;
    }

    svg {
      width: rem(10);
      height: rem(10);
      transition: transform $tr;
      fill: $b;
      color: $b;
    }

    // .dark & {
    //   svg {
    //     fill: $w;
    //     color: $w;
    //   }
    // }
  }
  &__btndisabled {
	cursor: not-allowed!important;
	&:hover {
		background: none;
		outline: none;
		padding: 0;
		flex-shrink: 0;
		width: 1rem;
		height: 1rem;
		background-color: var(--wp--preset--color--white-static);
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid transparent;
		border-radius: 50%;
		box-shadow: 0 0 2.56px 0 rgba(0, 0, 0, 0.25);
		cursor: pointer;
		transition: border-color 0.2s;
	}

	svg {
	  color: #9CA3AF !important;
	}
  }

  &__value {
    min-width: rem(10);
    text-align: center;

    &:after {
      content: attr(data-value);
    }
  }
}

// Card toggle icon (more, back) (is used on Camper Card, Event Card)
.card-toggle-right,
.card-toggle-left {
  width: rem(61);
  height: rem(61);
  background-color: var(--wp--preset--color--campeasy-white);
  color: var(--event-color) !important;
  cursor: pointer;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  transition: transform $tr;

  &:hover {
    transform: scale(1.07) translate(rem(-2), rem(2));
  }

  &:after {
    content: '';
    display: block;
    width: 102%;
    height: 102%;
    background-color: var(--wp--preset--color--campeasy-white);
    position: absolute;
    top: rem(-2);
  }

  .icon {
    display: inline-block;
    font-size: rem(61);
    position: relative;

    &:after {
      content: "";
      display: block;
      background-color: $w;
      position: absolute;
      inset: 0.5rem;
      z-index: -1;
    }
  }
}

.card-toggle-right {
  border-radius: 0 0 0 rem(9);
  right: 0;

  &:after {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 1px;
  }

  .icon {
    border-radius: 0 0 0 rem(9);
    filter: drop-shadow(-2px 2px 2px rgba(#000, .2));

    &:after {
      border-radius: 0 0 0 rem(10);
      clip-path: polygon(-5% 0%, 0 100%, 95% 100%);
    }
  }
}

.card-toggle-left {
  border-radius: 0 0 rem(9) 0;
  left: -1px;

  &:hover {
    transform: scale(1.1) translate(rem(2), rem(2));
  }

  &:after {
    clip-path: polygon(0 0, 100% 0, 0 100%);
    left: rem(-1);
  }

  .icon {
    border-radius: 0 0 rem(9) 0;
    filter: drop-shadow(-2px 2px 2px rgba(#000, .2));

    &:after {
      border-radius: 0 0 rem(10) 0;
      clip-path: polygon(0 105%, 105% 0, 100% 100%);
    }
  }
}

// Link stretched to full box
.stretched-link {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

// Link with animated underline on hover
.link-animated {
  @include animated-underline;
}

// Check Icon (is used for e.g. insurance features)
.check-icon {
  @extend .icon;
  width: rem(16);
  height: rem(16);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wp--preset--color--gray-two);
  color: $w;
  font-size: rem(12);
  border-radius: 50%;
}

// Conditional Display
.conditional-wrapper {
  &:not(:has(.conditional-checkbox:checked)) {
    .conditional-hidden {
      display: none;
    }
  }
}

// Print link (on Account, Thank You pages)
.print-link {
  display: inline-flex;
  align-items: center;
  gap: rem(7);
  font-size: rem(14);
  font-weight: 500;

  .icon {
    font-size: rem(22);
  }
}

// Loading state
.is-loading {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    background-color: var(--wp--preset--color--white);
    opacity: 0.9;
    width: 100%;
    height: 100%;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &:before {
    content: '';
    display: block;
    width: rem(60);
    height: rem(60);
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 118 122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='57.19' cy='61.107' r='56.683' stroke='%23545A5C'/%3E%3Cpath d='M57.19 4.424c31.305 0 56.683 25.378 56.683 56.683s-25.378 56.682-56.683 56.682c-15.02 0-28.677-5.842-38.82-15.379' stroke='%239580EB' stroke-width='7' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% / rem(60 60);
    animation: spinner 1.5s infinite linear;
    position: absolute;
    top: calc(50% - #{rem(30)});
    left: calc(50% - #{rem(30)});
    z-index: 10000;
  }
}

// Loader Icon (van)
.loader-van {
  width: 150px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: auto;

  svg {
    animation: loader-car 2.5s infinite linear;
    color: var(--wp--preset--color--base);
  }

  &__wheel {
    transform-box: fill-box;
    transform-origin: center;
    animation: loader-car-wheel 2.5s infinite linear;
  }
}

@keyframes loader-car {
  0% {
    transform: translateX(-150px);
  }
  40% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(0) scale(1.05);
  }
  60% {
    transform: translateX(0) scale(1);
  }
  100% {
    transform: translateX(150px);
  }
}

@keyframes loader-car-wheel {
  0% {
    transform: rotate(0);
  }
  40% {
    transform: rotate(360deg);
  }
  60% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
