// Camper Card
$price-container-min: 340px;
$translate-z: translateZ(40px) scale(0.98);

.cards-grid {
  display: grid;
  grid-gap: rem(30);

  @include min(lg) {
    grid-gap: rem(50);
  }

  + .cards-grid,
  + .cards-grid-simple {
    margin-top: clamp-rem(35, 50);
  }
}

.cards-grid-simple {
  @include max(479) {
    display: flex;
    flex-direction: column;
    gap: rem(30);
  }

  @include min(480) {
    display: grid;
    grid-gap: rem(30);
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(rem(410), 1fr));

    .single-campers & {
      grid-template-columns: repeat(3, minmax(rem(410), rem(500)));
      justify-content: center;
    }
  }

  @include min(lg) {
    grid-gap: rem(40 20);
  }

  @include min(2200) {
    grid-gap: rem(40 30);
  }
}

.camper {
  width: 100%;
  position: relative;

  &.is-animating {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  &__inner {
	  border: 2px solid var(--wp--preset--color--stroke);
	  border-radius: $radius;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--wp--preset--color--campeasy-white);
    transition: border-color $tr;
    padding-bottom: 1px;

    @include min(lg) {
      flex-direction: row;
    }
  }

  &__overview {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(16);
    padding: rem(60 30 32);
    transition: background-color $tr;
    container: camper-overview / inline-size;

    @include max(lg) {
      border-bottom: 2px solid var(--wp--preset--color--stroke);
    }

    @include min(lg) {
      width: 33%;
      flex-shrink: 0;
      border-right: 2px solid var(--wp--preset--color--stroke);
      padding-left: rem(50);
      position: relative;
    }
  }

  &__content {
    width: 100%;
    clip-path: polygon(0 0, calc(100% - 61px) 0, 100% 61px, 100% 100%, 0 100%);
    overflow: hidden;
    position: relative;
    @include max(lg) {
      clip-path: none;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid var(--wp--preset--color--primary);
      border-radius: 0 0 $radius 0;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: $tr;
      pointer-events: none;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;

      @include min(lg) {
        height: 100%;
        flex-direction: row;
      }
    }

    &-col {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      padding: rem(50 30 32);

      @include min(lg) {
        padding-left: rem(50);
      }

      &:nth-child(1):not(:only-child) {
        @include max(lg) {
          border-bottom: 2px solid var(--wp--preset--color--stroke);
        }

        @include min(lg) {
          width: 54%;
          border-right: 2px solid var(--wp--preset--color--stroke);
        }
      }

      &:nth-child(2) {
        @include min(lg) {
          width: 46%;
          padding-left: rem(40);
        }
      }
    }
  }

  &__clip-layer {
    width: 170%;
    height: 180%;
    background-color: var(--wp--preset--color--light-bg);
    clip-path: polygon(
                    75% 0%,
                    0% 0%,
                    0% 12.281%,
                    0% 12.281%,
                    0.655% 26.507%,
                    2.549% 40.004%,
                    5.582% 52.589%,
                    9.648% 64.083%,
                    14.646% 74.305%,
                    20.472% 83.073%,
                    27.024% 90.208%,
                    34.198% 95.527%,
                    41.891% 98.852%,
                    50% 100%,
                    100% 100%,
                    100% 27.895%,
                    100% 27.895%,
                    95.944% 27.53%,
                    92.097% 26.472%,
                    88.51% 24.781%,
                    85.234% 22.512%,
                    82.321% 19.724%,
                    79.823% 16.473%,
                    77.79% 12.818%,
                    76.274% 8.816%,
                    75.327% 4.524%,
                    75% 0%
    );
    position: absolute;
    top: -130%;
    left: 80%;
    z-index: 5;
  }

  &__more {
    color: var(--wp--preset--color--primary) !important;
    z-index: 10;

    .back-icon {
      display: none;
    }
  }

  &__header {
    min-height: rem(95);
    border-radius: $radius $radius 0 0;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(10);

      &-icons {
        display: flex;
        flex-shrink: 0;
        gap: rem(10);
        color: var(--wp--preset--color--primary);
        font-size: rem(28);
        line-height: 1;
        margin-right: -0.25rem;

        .js-view-toggle {
          cursor: pointer;
        }
      }
    }
  }

  &__rating {
    display: inline-flex;
    align-items: center;
    gap: rem(5);
    margin-bottom: rem(5);
    cursor: pointer;
  }

  &__tags {
    display: flex;
    gap: 2px;
    position: absolute;
    top: -1px;
    left: -1px;
  }

  &__tag {
    height: rem(37);
    border-radius: 0;
    padding: rem(0 18);

    @container camper-overview (max-width: 380px) {
      span:has(.icon) {
        font-size: 0;
        letter-spacing: 0;
      }

      .icon {
        margin: 0;
        line-height: normal;
      }
    }

    &:first-child {
      border-top-left-radius: $radius;
    }

    &:last-child {
      border-bottom-right-radius: $radius;
    }

    i {
      font-size: rem(18);
      margin-right: rem(7);
    }
  }

  &__title {
    @include animated-underline(2px, -2px);
    line-height: 1;
    margin: 0;
  }

  &__image {
    line-height: 0;
    position: relative;

    &-img,
    img {
      width: 100%;
      max-height: rem(400);
      aspect-ratio: 325/255;
      object-fit: contain;

      &--exterior {
        [data-look="interior"] & {
          display: none;
        }
      }

      &--interior {
        object-fit: cover;

        [data-look="exterior"] & {
          display: none;
        }
      }
    }
  }

  &__wishlist {
    background-color: var(--wp--preset--color--white);
    position: absolute;
    top: rem(-20);
    left: rem(-5);
    pointer-events: all;
    transform: translateZ(rem(40));

    .account-content & {
      display: none;
    }

    svg {
      color: transparent;
      pointer-events: none;
    }

    &.is-style-icon {
      border-radius: 50%;
      overflow: hidden;

      span {
        @include hover(rgba(#9580EB, .4));
      }

    }
  }

  &__gallery {
    width: rem(36);
    height: rem(36);
    background-color: var(--wp--preset--color--white);
    color: var(--wp--preset--color--gray-two);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(24);
    line-height: 1;
    border-radius: 50%;
    position: absolute;
    bottom: rem(10);
    right: rem(10);
    pointer-events: all;
    transform: translateZ(rem(40));
  }

  &__media {
    display: flex;
    justify-content: space-evenly;
    gap: rem(24);

    &-link {
      display: block;
      text-align: center;
      margin: 0 auto;

      @include hover-responsive {
        .icon {
          color: var(--wp--preset--color--primary);
        }
      }

      .icon {
        color: var(--wp--preset--color--gray-two);
        display: block;
        font-size: rem(70);
        margin-bottom: rem(5);
        line-height: normal;
      }

      &-title {
        @extend %copy1;
      }
    }
  }

  &__block {
    margin-bottom: rem(25);

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      @extend %h5;
      margin-bottom: rem(16);
    }

    &--details {
      margin-bottom: rem(30);
    }
  }

  &__list {
    @include unstyled-list;
    display: grid;
    gap: rem(20 10);

    .list-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: rem(5);

      i {
        color: currentColor;
        font-size: rem(24);
      }

      &__icon {
        flex-shrink: 0;
        color: transparent;
        width: rem(24);
        height: rem(24);

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        @extend %copy-md;
      }

      .tooltip {
        .tooltip__content {
          left: rem(-30);

          &:not(:has(a)) {
            pointer-events: none;
          }
        }
      }
    }

    // layout: Details
    &--details {
      grid-template-columns: repeat(auto-fit, minmax(rem(60), 1fr));
      text-align: center;
      gap: rem(20);
      @include max(370) {
        gap: rem(16);
      }

      .list-item {
        width: auto;
        flex-direction: column;
        gap: rem(4);

        .icon {
          font-size: rem(45);
          line-height: normal;
        }

        &__title {
          font-size: rem(12);
          @include max(md) {
            line-height: rem(18);
            letter-spacing: normal;
            margin-top: rem(5);
          }
        }
      }
    }

    // layout: 2 columns
    &--2-cols {
      grid-template-columns: repeat(auto-fit, minmax(rem(130), 1fr));

      @include min(md) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    // layout: Features
    &--features {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: clamp-rem(30, 60);
      font-size: 0;
      position: relative;

      .list-item {
        display: block;

        &:nth-of-type(4n+1) {
          margin-bottom: clamp-rem(35, 60);
          padding-bottom: clamp-rem(35, 60);

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--wp--preset--color--stroke);
            margin-top: clamp-rem(35, 60);
            position: absolute;
            left: 0;
          }
        }

        &:nth-of-type(4n+3):after {
          content: '';
          width: 100%;
          height: 2px;
          background-color: var(--wp--preset--color--stroke);
          display: block;
          position: absolute;
          left: 0;
        }

        &:nth-last-child(-n + 2):after {
          content: none;
        }

        .icon {
          display: inline-block;
          color: var(--wp--preset--color--primary);
          font-size: rem(62);
          margin-right: rem(24);
          vertical-align: top;
        }

        &__content {
          display: inline-block;
          width: calc(100% - rem(86));
        }

        .list-item__title {
          @extend %h5;
          margin-bottom: rem(16);
        }

        .list-item__text {
          @extend %copy1;
        }
      }
    }
  }

  &__description {
    flex-grow: 1;

    .editor {
      overflow-y: auto;
      @extend %copy1;
      max-height: rem(410);
      max-width: rem(285);
      padding-right: rem(15);

	    @include min(1280) {
		    max-width: calc(100% - #{rem(30)});
	    }
    }
  }

  &__actions {
    flex-shrink: 0;
    margin-top: rem(15);
    margin-bottom: rem(20);
    border-bottom-right-radius: $radius;
  }

  &__savings {
    flex-grow: 1;

    &-header {
      padding-left: rem(76);
      margin-bottom: rem(30);
      position: relative;
    }

    &-title {
      @extend %h5;
      text-transform: uppercase;
    }

    &-value {
      display: inline-flex;
      align-items: center;
      background-color: var(--wp--preset--color--success);
      color: var(--wp--preset--color--campeasy-white);
      font-size: rem(18);
      font-weight: 600;
      border-radius: rem(4);
      letter-spacing: 0.05em;
      line-height: 1.25;
      padding: rem(3 15);
      margin-bottom: rem(11);
    }

    &-icon {
      font-size: rem(64);
      position: absolute;
      top: calc(50% - rem(32));
      left: 0;
    }
  }

  &__dates {
    margin-bottom: rem(15);

    &-range {
      display: flex;
      align-items: center;
      gap: rem(7);
      margin-bottom: rem(12);

      .icon {
        font-size: rem(24);
        color: currentColor;
        position: relative;
        top: -1px;
      }

      span {
        display: block;
        font-size: rem(14);
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: rem(22);
        text-transform: uppercase;
      }
    }

    &-footer {
      @extend %copy-sm;
    }
  }

  &__price-table {
    width: 100%;
    margin: rem(0 -4);

    td {
      vertical-align: baseline;
      padding: rem(6 4);

      @include max(440) {
        padding: rem(6 2);
      }

      &:nth-child(1) {
        @extend %copy-md;
        letter-spacing: 0;
        line-height: 1.15;
        white-space: nowrap;

        @include max(440) {
          font-size: rem(13);
        }
      }

      &:nth-child(2) {
        color: var(--wp--preset--color--gray-two);
        font-size: rem(14);
        line-height: rem(22);
        letter-spacing: 0.05em;
        white-space: nowrap;

        @include max(440) {
          font-size: rem(12);
          letter-spacing: 0;
        }
      }

      &:nth-child(3) {
        @extend %h5;
        white-space: nowrap;

        @include max(440) {
          font-size: rem(16);
          letter-spacing: 0;
        }

        a {
          color: var(--wp--preset--color--link);
          padding-right: rem(10);
          position: relative;
	        display: inline-block;
        }

        @include hover-responsive {
          .icon {
            transform: scale(1.2);
          }
        }

        .icon {
          color: currentColor;
          font-size: rem(12);
          position: absolute;
          top: 0;
          right: rem(-5);
          transition: transform $tr;
        }
      }
    }

    tr.camper__price--total {
      td {
        &:nth-child(1) {
          font-size: rem(16);

          @include max(440) {
            font-size: rem(14);
          }
        }

        &:nth-child(2) {
          @include max(440) {
            font-size: rem(13);
            letter-spacing: 0;
          }
        }

        &:nth-child(3) {
          @extend %h4;

          @include max(440) {
            font-size: rem(17);
            letter-spacing: 0;
          }

          a {
            color: var(--wp--preset--color--link);
          }
        }
      }
    }
  }

  @container (max-width: #{$price-container-min}) {
    // container: .camper__dates
    .camper__price-table {
      margin: 0;

      tr {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: rem(10);

        &.camper__price--total {
          margin-top: rem(7);
        }
      }

      td {
        display: block;
        padding: 0;

        &:nth-child(2) {
          order: 3;
          width: 100%;
          padding-right: rem(15);
          text-align: right;
        }
      }
    }

    .camper__dates-footer {
      margin-top: 0;
    }
  }


  &__buttons {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: rem(8);

    @include min(400) {
      gap: rem(10);
    }

    .btn:not(.btn-icon) {
      @include max(399) {
        padding: rem(0 16);
      }
    }
  }

  &__unavailable {
    max-width: rem(300);

    .icon {
      display: inline-block;
      font-size: rem(84);
      margin-bottom: rem(24);
    }

    &-title {
      @extend %h5;
      max-width: rem(250);
      margin: 0 auto rem(12);

      a {
        font-weight: 700;

        &:before {
          height: 2px;
        }
      }
    }

    &-subtitle {
      @extend %copy-md;
      margin: rem(0 0 40);
    }

    .btn {
      min-width: rem(222);
      margin-bottom: rem(18);
    }

    .btn-outline {
      &:not(:hover) {
        background-color: var(--wp--preset--color--white);
      }
    }
  }

  &__overlay {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: $radius;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 2;

    &--features,
    &--savings,
    &--unavailable {
      padding: rem(40 30 32);

      @include min(md) {
        padding: rem(70 60 50);
      }
    }

    &--unavailable {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#f9f9f9, 0.75) !important;
      backdrop-filter: blur(1px);
      text-align: center;
      top: 0;
      left: 0;

      @include theme-dark {
        background-color: rgba(#2a2928, 0.75) !important;
      }
    }

    &--savings {
      padding-top: rem(40);
      padding-bottom: rem(30);

      .camper__list {
        @include min(576) {
          grid-template-columns: 1fr 1fr;
        }
      }

      .list-item {
        .list-item__title {
          @extend %copy1;
        }
      }

      .camper__content-col:nth-child(2) {
        width: 100%;
        height: 100%;
        background-color: var(--wp--preset--color--campeasy-white);
        padding: rem(40 40 30);
        position: absolute;
        top: 0;
        left: 0;

        @include min(xxl) {
          padding: rem(40 70 30 60);
        }
      }

      .camper__savings {
        &-header {
          display: flex;
          align-items: center;
          gap: rem(15);
          padding: 0 5% 0 0;
          margin-bottom: rem(16);
        }

        &-title {
          @extend %h3;
          margin: 0;
          order: -1;
        }

        &-value {
          font-size: rem(20);
          margin: 0;
          padding: rem(4 18);
        }

        &-icon {
          font-size: rem(84);
          margin-left: auto;
          position: static;
        }

        .camper__list {
          .list-item {
            flex-direction: column;
            gap: rem(12);
            align-items: flex-start;

            .icon {
              font-size: rem(40);
            }

            &__title {
              margin-bottom: rem(4);
            }

            &__text {
              @extend %copy-sm;
              color: var(--wp--preset--color--gray-one);
            }
          }
        }
      }

      .camper__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(50);
        margin: rem(50 0 0);

        > * {
          margin: 0;
        }
      }

      .camper__price-table {
        td {
          &:nth-child(1) {
            color: var(--wp--preset--color--base);
          }

          &:nth-child(3) {
            a {
              color: var(--wp--preset--color--base) !important;
              padding-right: 0;
            }

            .icon {
              display: none;
            }
          }
        }
      }
    }

    &--highlights {
      padding: rem(40 30 32);

      @include min(md) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: fit-content(50%);
        padding: rem(60 60 45);
      }

      .camper__block {
        margin-bottom: 0;

        &-title {
          margin-bottom: rem(23);
        }

        &--highlights {
          grid-area: 1 / 1 / 1 / 4;
        }

        &--comfort {
          grid-area: 2 / 1 / 2 / 2;
          padding-top: rem(30);
        }

        &--specs {
          grid-area: 2 / 2 / 2 / 4;
          padding-top: rem(30);
        }
      }
    }

    .camper__block--comfort {
      @include min(md) {
        padding-right: rem(40);
      }

      .camper__list {
        gap: rem(12);

        @include max(md) {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(rem(92), 1fr));
        }
      }
    }

    .camper__block--specs {
      @include min(md) {
        border-left: 2px solid var(--wp--preset--color--stroke);
        padding-left: rem(40);
      }

      .camper__list {
        gap: rem(12);
      }
    }
  }


  // layout: Archive
  &[data-layout="archive"] {
    .camper__savings,
    .camper__dates {
      display: none;
    }

    &[data-view="default"] {
      .camper__actions {
        background-color: var(--wp--preset--color--campeasy-white);
        border-top: none;
      }
    }
  }

  // layout: Funnel
  &[data-layout="funnel"] {
    .camper__description {
      display: none;
    }
  }

  // layout: Unavailable
  &[data-layout="unavailable"] {
    .camper__description {
      display: none;
    }

    .camper__more {
      z-index: 1;
    }

    .camper__overlay--unavailable,
    .camper-small__front {
      &:after {
        content: "";
        display: block;
        width: rem(62);
        height: rem(62);
        background-color: var(--wp--preset--color--campeasy-white);
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: 2;
      }
    }
  }

  &:not([data-layout="unavailable"]) {
    .camper__overlay--unavailable {
      display: none;
    }
  }

  // view: Features
  &[data-view="features"],
  &[data-view="highlights"],
  &[data-view="savings"] {
    .camper__more {
      .more-icon {
        display: none;
      }

      .back-icon {
        display: block;
      }
    }
  }

  &:not([data-view="features"]) {
    .camper__overlay--features {
      display: none;
    }
  }

  // view: Highlights
  &:not([data-view="highlights"]) {
    .camper__overlay--highlights {
      display: none;
    }
  }

  // view: Savings
  &:not([data-view="savings"]) {
    .camper__overlay--savings {
      display: none;
    }

    .camper__savings {
      .list-item__text {
        display: none;
      }
    }
  }
}

/**
* Camper Card (large) specific styles
 */
.camper-large {
  overflow: hidden;

  .rating__reviews {
    color: var(--wp--preset--color--link);
    @include animated-underline;
  }

  &.is-hovered {
    @include hover-responsive {
      .camper__content {
        &:after {
          opacity: 1;
        }
      }
      .camper__overlay {
        border-radius: 0;
      }
    }
  }

  .camper__dates {
    container-type: inline-size;
  }

  .camper__list--highlights {
    grid-template-columns: repeat(auto-fit, minmax(rem(92), 1fr));
    gap: rem(16 25);

    @include min(md) {
      border-bottom: 2px solid var(--wp--preset--color--stroke);
      padding-bottom: rem(20);
    }

    .list-item {
      flex-direction: column;
      text-align: center;

      &__icon {
        width: rem(32);
        height: rem(32);
      }

      .list-item__title {
        @extend %copy-sm;
      }
    }
  }

  .camper__overlay {
    background-color: var(--wp--preset--color--campeasy-white);
  }

  // view: Default
  &[data-view="default"] {
    .camper__actions {
      width: calc(100% + rem(70));
      background-color: var(--wp--preset--color--light-bg);
      border-top: 2px solid var(--wp--preset--color--stroke);
      margin: rem(20 -30 -32 -40);
      padding: rem(23 30 40 40);
    }
  }
}

/**
* Camper Card (small) specific styles
 */
.camper-small {
  perspective: 3000px;

  &[data-layout="unavailable"] {
    .camper__price-table {
      .js-view-toggle {
        pointer-events: none;
        color: var(--wp--preset--color--base);
      }
    }
  }

  &.is-hovered {
    @include hover-responsive {
      .camper__content {
        &:after {
          opacity: 1;
        }
      }

      .camper__overlay {
        border-radius: 0;
      }

      .camper__inner {
        border-color: var(--wp--preset--color--primary);
      }
    }
  }

  &.is-flipped {
    .camper__inner {
      transform: rotateY(180deg);
    }
  }

  .camper__inner {
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1), border-color $tr;
    //transform: rotateY(70deg);
  }

  &__front,
  &__back {
    width: 100%;
    backface-visibility: hidden;
    border-radius: $radius;
    transform-style: preserve-3d;
  }

  &__front {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .camper__more {
    top: -1px;
    right: -1px;
  }

  &__back {
    height: 100%;
    background-color: var(--wp--preset--color--campeasy-white);
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;

    &-title {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      color: var(--wp--preset--color--primary);
      margin: 0;
      padding: rem(40 30 10);
      text-align: center;
    }
  }

  .camper__title {
    color: var(--wp--preset--color--primary);
    font-size: rem(22);
    font-weight: 500;
  }

  .camper-small__overlay-header {
    transform-style: preserve-3d;
    pointer-events: none;

    * {
      pointer-events: auto;
    }

    > * {
      transform: $translate-z;
      backface-visibility: hidden;
    }
  }

  .camper-small__overlay {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    overflow: auto;
  }

  .camper__header {
    min-height: rem(135);
    background-color: var(--wp--preset--color--light-bg);
    margin: 0;
    padding: rem(60 30 20);
  }

  .camper__image {
    height: rem(285);
    text-align: center;
    transform-style: preserve-3d;
    pointer-events: none;

    &-img {
      height: 100%;
      max-height: unset;
      object-fit: contain;
      pointer-events: auto;
      backface-visibility: hidden;
      transform: $translate-z;

      &--exterior {
        padding: rem(16 30);
      }

      &--interior {
        width: 100%;
        height: 100%;
        padding: 0;
        object-fit: cover;
        transform: none;
      }
    }
  }

  .camper__wishlist {
    top: rem(20);
    left: rem(30);

    [data-look="interior"] & {
      transform: none;
    }
  }

  .camper__gallery {
    [data-look="interior"] & {
      transform: none;
    }
    .icon {
      line-height: normal;
    }
  }

  &__overview-footer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--wp--preset--color--light-bg);
    padding: rem(20 30 30);
    border-radius: 0 0 $radius $radius;
    @include max(md) {
      padding: rem(20 16 30 16);
    }
  }

  .camper__block--details {
    margin-bottom: rem(20);

    .camper__block-title {
      @extend %screen-reader-text;
    }
  }

  .camper__actions {
    width: 100%;
	  background-color: transparent !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;

    .camper__buttons {
      margin-top: auto;
    }
  }

  .camper__back {
    color: var(--wp--preset--color--primary) !important;
    top: -2px;
    left: -2px;
    transform-style: preserve-3d;
    z-index: 2;
  }

  &__overlay {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;

    &-header {
      padding: rem(40 60 15);
      border-radius: $radius $radius 0 0;
    }

    &-title {
      text-align: center;
      margin: 0;
      color: var(--wp--preset--color--primary);
    }

    &-container {
      overscroll-behavior: contain;
      position: relative;
    }

    &-content {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: rem(15 30 35);

      &:has(>*:only-child) {
        flex-direction: row;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-evenly;
      background-color: var(--wp--preset--color--light-bg);
      border-top: 2px solid var(--wp--preset--color--stroke);
      border-radius: 0 0 $radius $radius;
      padding: rem(20 30);
      backface-visibility: hidden;

      .camper__buttons {
        translate: 0 rem(-7);
      }
    }
  }

  // view: Savings
  &:not([data-view="savings"]) {
    .camper-small__overlay--savings {
      display: none;
    }
  }

  &[data-view="savings"] {
    .camper-small__back-title {
      display: none;
    }
  }

  &__savings {
    flex-grow: 1;
    pointer-events: none !important;

    &-days {
      @extend %h5;
      color: var(--wp--preset--color--gray-one);
      text-align: right;
      text-transform: uppercase;
      margin-bottom: rem(30);
      pointer-events: none !important;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      gap: rem(16);

      .camper__price {
        text-align: right;

        &-label {
          @extend %h4;
          text-transform: uppercase;
        }

        &-current {
          @extend %h3;
          display: block;
          color: var(--wp--preset--color--base);
        }

        del {
          color: var(--wp--preset--color--gray-one);
        }
      }
    }

    &-icon {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: rem(10);

      .icon {
        font-size: rem(72);
      }

      span {
        @extend %h5;
        display: block;
        text-transform: uppercase;

      }
    }

    .camper__list {
      gap: rem(24);

      .list-item {
        @extend %copy-md;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: rem(7);

        .icon {
          font-size: rem(24);
        }
      }
    }
  }

  &__overlay--savings {
    .camper-small__overlay-header {
      padding-left: rem(30);
      padding-right: rem(30);
    }
  }

  // view: Highlights
  &:not([data-view="highlights"]) {
    .camper-small__overlay--highlights {
      display: none;
    }
  }

  &__overlay--highlights {
    .camper-small__block {
      &:not(:last-child) {
        margin-bottom: rem(30);
      }

      &-title {
        margin-bottom: rem(24);
      }

      .camper__list {
        gap: rem(20);
      }

      .list-item {
        flex-direction: row;
        text-align: left;

        &__title {
          font-size: rem(14);

          br {
            display: none;
          }
        }
      }
    }

    .camper__media {
      .icon {
        font-size: rem(34);
        margin-bottom: rem(10);
      }

      &-link-title {
        font-size: rem(12);
      }
    }
  }

  .camper__list--features {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    .list-item {
      width: 100%;
      padding: 0;
      margin: 0;

      &:after {
        display: none;
      }

      &__title {
        margin-bottom: rem(6);
      }

      .icon {
        font-size: rem(56);
        margin-right: rem(20);
      }
    }
  }

  // layout: Unavailable
  &:not([data-layout="unavailable"]) {
    .camper__more:not(.js-view-toggle) {
      display: none;
    }
  }

  &[data-layout="unavailable"] {
    .camper-small__front {
      *:not(.camper__more) {
        transform: none !important;
      }
    }

    .camper__overlay--unavailable {
      margin: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: $radius;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.in-cart {
    .js-compare-camper {
      background-color: var(--wp--preset--color--primary);

      .icon {
        color: $w !important;
      }
    }
  }
  &.is-flipped-finished {
    .camper-small__front {
      display: none;
    }
    .camper-small__back {
      position: initial;
    }
    .camper-small__overlay-container, .camper-small__overlay-footer, .icon--io-back-left {
      transform: none;
    }
    i {
      line-height: normal;
    }
    @supports (-webkit-touch-callout: none) {
      .icon--io-back-left {
        filter: none;
        background-color: $w;
      }
    }
  }
}

.camper__tag i {
  line-height: normal !important;
}

.cards-grid-funnel {
  .camper-large {
    h3 {
      color: var(--wp--preset--color--primary);
    }
  }
  .icon--io-more {
    top: -2px;
    position: relative;
    right: -2px;
  }
}
