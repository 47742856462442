// Self Checkin
.checkin {
	background-color: var(--wp--preset--color--light-bg);

	&-header {
		background-color: var(--wp--preset--color--white);
		text-align: center;
		padding-top: clamp-rem(30, 80);
		padding-bottom: rem(15);
		margin-top: var(--header-height) !important;
	}

	&-title {
		margin-bottom: rem(20);
	}

	.insurance__more .icon:before {
		background-color: var(--wp--preset--color--light-bg);
	}
}

.checkout-agreement {
	.signature-pad {
		width: 100%;
		background-color: var(--wp--preset--color--campeasy-white);
		border: 2px solid var(--wp--preset--color--stroke);
		border-radius: $radius;
		aspect-ratio: 16/9;

		@include min(lg) {
			width: 50%;
		}
	}

	.buttons {
		margin-top: rem(30);

		.btn {
			cursor: pointer;
		}
	}
}

.checkin-driver-licence-heading {
	@include min(lg) {
		margin-bottom: rem(30);
	}
}

.form-field--file {
	label {
		display: inline-flex;
	}
}
