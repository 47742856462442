.btn {
	height: rem(45);
	display: inline-flex;
	align-items: center;
	gap: rem(7);
	justify-content: center;
	flex-shrink: 0;
	background-color: var(--wp--preset--color--primary);
	color: $color-white;
	font-family: inherit;
	font-size: rem(14);
	font-weight: 500;
	border-radius: rem(30);
	border: 2px solid var(--wp--preset--color--primary);
	letter-spacing: 0.05em;
	line-height: 1.2;
	padding: rem(0 28);
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color $tr, border-color $tr, color 0.1s;
	@include hover();

	span {
		display: inline-flex;
		align-items: center;
		gap: rem(7);
		pointer-events: none !important;
	}

	&.disabled,
	&:disabled {
		background-color: var(--wp--preset--color--gray-two) !important;
		border-color: var(--wp--preset--color--gray-two) !important;
		pointer-events: none !important;

		@include theme-dark {
			background-color: var(--wp--preset--color--gray-three) !important;
			border-color: var(--wp--preset--color--gray-three) !important;
		}
	}
}

.btn-responsive {
	@include max(xs) {
		height: rem(36);
		font-size: rem(12);
		padding: rem(0 22);
	}
}

.btn-sm {
	height: rem(36);
	font-size: rem(12);
	padding: rem(0 22);
}

.btn-icon {
	width: clamp-rem(36, 45);
	height: clamp-rem(36, 45);
	font-style: normal;
	padding: 0;

	&.active {
		background-color: var(--wp--preset--color--primary);

		.icon {
			color: var(--wp--preset--color--white-static);
		}
	}

	.icon {
		color: currentColor !important;
		font-size: clamp-rem(18, 22);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		max-width: clamp-rem(18, 22);
		max-height: clamp-rem(18, 22);
	}

	&.btn-sm {
		width: rem(36);
		height: rem(36);

		.icon {
			font-size: rem(18);
		}

		svg {
			max-width: rem(18);
			max-height: rem(18);
		}
	}

	&-responsive {
		@include max(xs) {
			width: rem(36);
			height: rem(36);

			.icon {
				font-size: rem(18);
			}

			svg {
				max-width: rem(18);
				max-height: rem(18);
			}
		}
	}
}

.btn-outline {
	background-color: transparent;
	color: var(--wp--preset--color--base);
	@include hover();

	&:before {
		background-color: transparent;
	}

	&:hover {
		background-color: var(--wp--preset--color--primary);
		color: $w;
	}
}

.btn-white {
	background-color: $w;
	color: var(--wp--preset--color--base-static);
	border-color: $w;

	&:hover {
		background-color: var(--wp--preset--color--primary);
		color: $w;
	}
}

.btn-link {
	@include unstyled-button;
	display: inline-flex;
	align-items: center;
	gap: rem(4);
	font-size: rem(14);
	font-weight: 600;
	letter-spacing: 0.05em;
	line-height: 1.2;
	text-transform: uppercase;
	cursor: pointer;

	svg {
		width: 1em;
		height: 1em;
	}

	.icon {
		font-size: rem(16);
	}

	span {
		@include animated-underline(2px, 0);
	}
}

// for Modal, Offcanvas
%btn-close {
	cursor: pointer;
	font-size: rem(20);
	color: var(--wp--preset--color--gray-two);
	text-decoration: none;
	transition: color $tr;

	@include hover-responsive {
		color: var(--wp--preset--color--primary);
	}
}

.btn-close {
	@extend %btn-close;
}

// Button group
.buttons {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: rem(10);
}
