.wp-block-group {

    &.is-collapsed-on-mobile {
        position: relative;
    }

    &.is-style-megamenu-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;

        &:not(.is-selected) {
            > *:last-child{
                &:not(.megamenu-bottom-section-faq) {
                    margin-top: auto !important;

                    @include min(1381) {
                        padding-bottom: var(--wp--preset--spacing--s) !important;
                    }
                }
            }
        }

        > *:last-child{
            position: sticky;
            bottom: 0;
            z-index: 1;
        }
    }

    @include min(md) {

        &.is-style-stretch-group{

            display: flex;
            height: 100%;
            flex-direction: column;

            &.is-style-last-element-to-bottom{

                &:not(.is-selected) {
                    > *:last-child{
                        margin-top: auto !important;
                    }
                }

                &.is-selected {
                    > *:nth-last-child(2){
                        margin-top: auto !important;
                    }
                }

            }
        }
    }
}

@include max(md) {

    .wp-block-group {

        &.is-collapsed-on-mobile {
            max-height: rem(48);
            margin: rem(20) 0 0 !important;
            overflow: hidden;

            &::after {
                content: '+';
                width: rem(16);
                height: rem(16);
                font-size: rem(15);
                font-weight: 300;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--wp--preset--color--white);
                border: 1px solid var(--wp--preset--color--stroke);
                border-radius: 50%;
                position: absolute;
                top: rem(26);
                right: 0;
                pointer-events: none;
            }

            &.active {
                max-height: 100%;
                padding: 0 0 rem(10);

                &::after {
                    content: '-';
                }
            }

            .wp-block-separator {
                margin: 0 0 rem(20) !important;
            }

            .wp-block-heading {
                margin: 0 !important;
            }

            .wp-block-group {
                text-align: center;
            }
        }

        &.is-child-auto-width-on-responsive{

            > .wp-block-group{
                flex-basis: auto;
            }
        }

        &-is-layout-grid {

            &.mobile-100 {
                grid-template-columns: 1fr;
            }
        }
    }
}
