@font-face {
	font-family: "campeasy";
	src: url('../fonts/campeasy.woff2') format('woff2'),
		url('../fonts/campeasy.woff') format('woff');
}

@function icon-char($filename) {
	$char: "";

	@if $filename == -io-age {
		$char: "\E001";
	}
	@if $filename == -io-airplane {
		$char: "\E002";
	}
	@if $filename == -io-arrow-next {
		$char: "\E003";
	}
	@if $filename == -io-arrow-right-circle {
		$char: "\E004";
	}
	@if $filename == -io-back-left {
		$char: "\E005";
	}
	@if $filename == -io-back-right {
		$char: "\E006";
	}
	@if $filename == -io-basic-alarm {
		$char: "\E007";
	}
	@if $filename == -io-bonfire {
		$char: "\E008";
	}
	@if $filename == -io-brand-location-pin {
		$char: "\E009";
	}
	@if $filename == -io-calendar-2 {
		$char: "\E00A";
	}
	@if $filename == -io-calendar-event {
		$char: "\E00B";
	}
	@if $filename == -io-calendar {
		$char: "\E00C";
	}
	@if $filename == -io-camera {
		$char: "\E00D";
	}
	@if $filename == -io-cancellation {
		$char: "\E00E";
	}
	@if $filename == -io-cart {
		$char: "\E00F";
	}
	@if $filename == -io-category {
		$char: "\E010";
	}
	@if $filename == -io-check-out {
		$char: "\E011";
	}
	@if $filename == -io-check {
		$char: "\E012";
	}
	@if $filename == -io-checkbox-checked {
		$char: "\E013";
	}
	@if $filename == -io-checkbox-radiobutton-unchcked {
		$char: "\E014";
	}
	@if $filename == -io-checkbox-unchecked {
		$char: "\E015";
	}
	@if $filename == -io-chevron-down {
		$char: "\E016";
	}
	@if $filename == -io-chevron-left {
		$char: "\E017";
	}
	@if $filename == -io-chevron-right {
		$char: "\E018";
	}
	@if $filename == -io-chevron-up {
		$char: "\E019";
	}
	@if $filename == -io-chips-checked {
		$char: "\E01A";
	}
	@if $filename == -io-city-hall {
		$char: "\E01B";
	}
	@if $filename == -io-clock-outline {
		$char: "\E01C";
	}
	@if $filename == -io-close-circle {
		$char: "\E01D";
	}
	@if $filename == -io-close {
		$char: "\E01E";
	}
	@if $filename == -io-compass {
		$char: "\E01F";
	}
	@if $filename == -io-current-location {
		$char: "\E020";
	}
	@if $filename == -io-directions {
		$char: "\E021";
	}
	@if $filename == -io-distance {
		$char: "\E022";
	}
	@if $filename == -io-document {
		$char: "\E023";
	}
	@if $filename == -io-dots {
		$char: "\E024";
	}
	@if $filename == -io-download {
		$char: "\E025";
	}
	@if $filename == -io-drag-and-drop {
		$char: "\E026";
	}
	@if $filename == -io-edit {
		$char: "\E027";
	}
	@if $filename == -io-envelope {
		$char: "\E028";
	}
	@if $filename == -io-eye-empty {
		$char: "\E029";
	}
	@if $filename == -io-favorites {
		$char: "\E02A";
	}
	@if $filename == -io-filter-outline {
		$char: "\E02B";
	}
	@if $filename == -io-flower {
		$char: "\E02C";
	}
	@if $filename == -io-forbidden {
		$char: "\E02D";
	}
	@if $filename == -io-gallery {
		$char: "\E02E";
	}
	@if $filename == -io-gender-fluid {
		$char: "\E02F";
	}
	@if $filename == -io-google-logo {
		$char: "\E030";
	}
	@if $filename == -io-grid {
		$char: "\E031";
	}
	@if $filename == -io-group {
		$char: "\E032";
	}
	@if $filename == -io-gym {
		$char: "\E033";
	}
	@if $filename == -io-handshake {
		$char: "\E034";
	}
	@if $filename == -io-heart-count {
		$char: "\E035";
	}
	@if $filename == -io-heart-handshake {
		$char: "\E036";
	}
	@if $filename == -io-heart-outline {
		$char: "\E037";
	}
	@if $filename == -io-heart {
		$char: "\E038";
	}
	@if $filename == -io-image-list {
		$char: "\E039";
	}
	@if $filename == -io-info-empty {
		$char: "\E03A";
	}
	@if $filename == -io-information-circle {
		$char: "\E03B";
	}
	@if $filename == -io-language {
		$char: "\E03C";
	}
	@if $filename == -io-layout {
		$char: "\E03D";
	}
	@if $filename == -io-leaves {
		$char: "\E03E";
	}
	@if $filename == -io-level {
		$char: "\E03F";
	}
	@if $filename == -io-light-theme {
		$char: "\E040";
	}
	@if $filename == -io-lightning {
		$char: "\E041";
	}
	@if $filename == -io-line {
		$char: "\E042";
	}
	@if $filename == -io-link {
		$char: "\E043";
	}
	@if $filename == -io-location {
		$char: "\E044";
	}
	@if $filename == -io-lock-open {
		$char: "\E045";
	}
	@if $filename == -io-menu-burger {
		$char: "\E046";
	}
	@if $filename == -io-message-writing {
		$char: "\E047";
	}
	@if $filename == -io-messenger {
		$char: "\E048";
	}
	@if $filename == -io-moon-theme {
		$char: "\E049";
	}
	@if $filename == -io-moon {
		$char: "\E04A";
	}
	@if $filename == -io-more {
		$char: "\E04B";
	}
	@if $filename == -io-mountain {
		$char: "\E04C";
	}
	@if $filename == -io-navigator-alt {
		$char: "\E04D";
	}
	@if $filename == -io-new {
		$char: "\E04E";
	}
	@if $filename == -io-office-building {
		$char: "\E04F";
	}
	@if $filename == -io-open {
		$char: "\E050";
	}
	@if $filename == -io-planet {
		$char: "\E051";
	}
	@if $filename == -io-play {
		$char: "\E052";
	}
	@if $filename == -io-pricetag {
		$char: "\E053";
	}
	@if $filename == -io-printing-page {
		$char: "\E054";
	}
	@if $filename == -io-profile-user {
		$char: "\E055";
	}
	@if $filename == -io-question {
		$char: "\E056";
	}
	@if $filename == -io-quote {
		$char: "\E057";
	}
	@if $filename == -io-radiobutton-checked {
		$char: "\E058";
	}
	@if $filename == -io-restart {
		$char: "\E059";
	}
	@if $filename == -io-review {
		$char: "\E05A";
	}
	@if $filename == -io-river {
		$char: "\E05B";
	}
	@if $filename == -io-road {
		$char: "\E05C";
	}
	@if $filename == -io-scales {
		$char: "\E05D";
	}
	@if $filename == -io-search {
		$char: "\E05E";
	}
	@if $filename == -io-settings {
		$char: "\E05F";
	}
	@if $filename == -io-share {
		$char: "\E060";
	}
	@if $filename == -io-sort {
		$char: "\E061";
	}
	@if $filename == -io-stack-of-books {
		$char: "\E062";
	}
	@if $filename == -io-star-half {
		$char: "\E063";
	}
	@if $filename == -io-star-outline {
		$char: "\E064";
	}
	@if $filename == -io-star {
		$char: "\E065";
	}
	@if $filename == -io-stars {
		$char: "\E066";
	}
	@if $filename == -io-start {
		$char: "\E067";
	}
	@if $filename == -io-steering-wheel {
		$char: "\E068";
	}
	@if $filename == -io-suitcase {
		$char: "\E069";
	}
	@if $filename == -io-sun {
		$char: "\E06A";
	}
	@if $filename == -io-swimming {
		$char: "\E06B";
	}
	@if $filename == -io-transmission {
		$char: "\E06C";
	}
	@if $filename == -io-trash {
		$char: "\E06D";
	}
	@if $filename == -io-upload {
		$char: "\E06E";
	}
	@if $filename == -io-user-square {
		$char: "\E06F";
	}
	@if $filename == -io-van {
		$char: "\E070";
	}
	@if $filename == -io-verify {
		$char: "\E071";
	}
	@if $filename == -io-video-gallery {
		$char: "\E072";
	}
	@if $filename == -io-view {
		$char: "\E073";
	}
	@if $filename == -io-volcano {
		$char: "\E074";
	}
	@if $filename == -io-vote-yes {
		$char: "\E075";
	}
	@if $filename == -io-warning-mark {
		$char: "\E076";
	}
	@if $filename == -io-warning {
		$char: "\E077";
	}
	@if $filename == -io-whatsapp {
		$char: "\E078";
	}
	@if $filename == -io-writing {
		$char: "\E079";
	}
	@if $filename == -io-сamera {
		$char: "\E07A";
	}
	@if $filename == 0-3y {
		$char: "\E07B";
	}
	@if $filename == 0-3y1 {
		$char: "\E07C";
	}
	@if $filename == 1-bed {
		$char: "\E07D";
	}
	@if $filename == 1-large-bag {
		$char: "\E07E";
	}
	@if $filename == 1-seat {
		$char: "\E07F";
	}
	@if $filename == 1-small-bag {
		$char: "\E080";
	}
	@if $filename == 2-5y {
		$char: "\E081";
	}
	@if $filename == 2-camper-special {
		$char: "\E082";
	}
	@if $filename == 2-large-bag {
		$char: "\E083";
	}
	@if $filename == 2-small-bag {
		$char: "\E084";
	}
	@if $filename == 220v-sockets-b {
		$char: "\E085";
	}
	@if $filename == 230v-socket {
		$char: "\E086";
	}
	@if $filename == 24-7-assistance {
		$char: "\E087";
	}
	@if $filename == 24-7-calls-on-the-road {
		$char: "\E088";
	}
	@if $filename == 24h-email-answering-b {
		$char: "\E089";
	}
	@if $filename == 24h-email-answering {
		$char: "\E08A";
	}
	@if $filename == 3-6y {
		$char: "\E08B";
	}
	@if $filename == 3-6y1 {
		$char: "\E08C";
	}
	@if $filename == 3-batteries {
		$char: "\E08D";
	}
	@if $filename == 3-day-minimum {
		$char: "\E08E";
	}
	@if $filename == 3-large-bag {
		$char: "\E08F";
	}
	@if $filename == 3-small-bag {
		$char: "\E090";
	}
	@if $filename == 3000_watt_sinus_inverter {
		$char: "\E091";
	}
	@if $filename == 360-parking-camera {
		$char: "\E092";
	}
	@if $filename == 4-beds-twinbed {
		$char: "\E093";
	}
	@if $filename == 4-doors {
		$char: "\E094";
	}
	@if $filename == 4-large-bags {
		$char: "\E095";
	}
	@if $filename == 4-small-bags {
		$char: "\E096";
	}
	@if $filename == 4-speakers {
		$char: "\E097";
	}
	@if $filename == 4x4-outlined {
		$char: "\E098";
	}
	@if $filename == 4x4 {
		$char: "\E099";
	}
	@if $filename == 5-day-minimum {
		$char: "\E09A";
	}
	@if $filename == 5-doors {
		$char: "\E09B";
	}
	@if $filename == 5-large-bags {
		$char: "\E09C";
	}
	@if $filename == 5-speed {
		$char: "\E09D";
	}
	@if $filename == 6-speed {
		$char: "\E09E";
	}
	@if $filename == 7-day-minimum {
		$char: "\E09F";
	}
	@if $filename == 7-speed {
		$char: "\E0A0";
	}
	@if $filename == 8-inch-screen {
		$char: "\E0A1";
	}
	@if $filename == 8-speed {
		$char: "\E0A2";
	}
	@if $filename == abs-brakes {
		$char: "\E0A3";
	}
	@if $filename == accident-report {
		$char: "\E0A4";
	}
	@if $filename == acoustic-guitar {
		$char: "\E0A5";
	}
	@if $filename == activities {
		$char: "\E0A6";
	}
	@if $filename == adaptive-cruise {
		$char: "\E0A7";
	}
	@if $filename == adjustable-driver-seat {
		$char: "\E0A8";
	}
	@if $filename == adjustable-seat-electric {
		$char: "\E0A9";
	}
	@if $filename == agent-portal-a-letter {
		$char: "\E0AA";
	}
	@if $filename == agentportal-tag {
		$char: "\E0AB";
	}
	@if $filename == agm-batteries {
		$char: "\E0AC";
	}
	@if $filename == air-conditioning {
		$char: "\E0AD";
	}
	@if $filename == airbag-deactivation {
		$char: "\E0AE";
	}
	@if $filename == airbags {
		$char: "\E0AF";
	}
	@if $filename == airport-transfer {
		$char: "\E0B0";
	}
	@if $filename == airport {
		$char: "\E0B1";
	}
	@if $filename == all-year {
		$char: "\E0B2";
	}
	@if $filename == amendments {
		$char: "\E0B3";
	}
	@if $filename == arrow-circle {
		$char: "\E0B4";
	}
	@if $filename == arrow-down {
		$char: "\E0B5";
	}
	@if $filename == arrow-next {
		$char: "\E0B6";
	}
	@if $filename == atv-trailer {
		$char: "\E0B7";
	}
	@if $filename == atv {
		$char: "\E0B8";
	}
	@if $filename == aurora-hunting {
		$char: "\E0B9";
	}
	@if $filename == auto-emergency-braking {
		$char: "\E0BA";
	}
	@if $filename == automatic-gearbox {
		$char: "\E0BB";
	}
	@if $filename == automatic-stop-start {
		$char: "\E0BC";
	}
	@if $filename == autopilot {
		$char: "\E0BD";
	}
	@if $filename == average-rain {
		$char: "\E0BE";
	}
	@if $filename == average-sunlight {
		$char: "\E0BF";
	}
	@if $filename == average-temperature {
		$char: "\E0C0";
	}
	@if $filename == awd {
		$char: "\E0C1";
	}
	@if $filename == bandana-buff {
		$char: "\E0C2";
	}
	@if $filename == basket {
		$char: "\E0C3";
	}
	@if $filename == battery-hour {
		$char: "\E0C4";
	}
	@if $filename == battery-kwhr {
		$char: "\E0C5";
	}
	@if $filename == battery-last {
		$char: "\E0C6";
	}
	@if $filename == battery {
		$char: "\E0C7";
	}
	@if $filename == battery_capacity {
		$char: "\E0C8";
	}
	@if $filename == battery_kwh {
		$char: "\E0C9";
	}
	@if $filename == battery_monitor {
		$char: "\E0CA";
	}
	@if $filename == bbq-tong {
		$char: "\E0CB";
	}
	@if $filename == bed-length {
		$char: "\E0CC";
	}
	@if $filename == bed-width {
		$char: "\E0CD";
	}
	@if $filename == bedding {
		$char: "\E0CE";
	}
	@if $filename == beds-2 {
		$char: "\E0CF";
	}
	@if $filename == beds-3 {
		$char: "\E0D0";
	}
	@if $filename == beds-5 {
		$char: "\E0D1";
	}
	@if $filename == bench-sitting-area {
		$char: "\E0D2";
	}
	@if $filename == bench {
		$char: "\E0D3";
	}
	@if $filename == bicycle-helmet {
		$char: "\E0D4";
	}
	@if $filename == bicycle-rack {
		$char: "\E0D5";
	}
	@if $filename == bicycle {
		$char: "\E0D6";
	}
	@if $filename == big-bag {
		$char: "\E0D7";
	}
	@if $filename == big-plates {
		$char: "\E0D8";
	}
	@if $filename == bird-outline {
		$char: "\E0D9";
	}
	@if $filename == bird {
		$char: "\E0DA";
	}
	@if $filename == birdwatchng {
		$char: "\E0DB";
	}
	@if $filename == blanket {
		$char: "\E0DC";
	}
	@if $filename == bluetooth {
		$char: "\E0DD";
	}
	@if $filename == bottle-opener {
		$char: "\E0DE";
	}
	@if $filename == bottom-mounted-extra-tyre {
		$char: "\E0DF";
	}
	@if $filename == bowls {
		$char: "\E0E0";
	}
	@if $filename == brake-pads {
		$char: "\E0E1";
	}
	@if $filename == broom-and-shovel {
		$char: "\E0E2";
	}
	@if $filename == buggy {
		$char: "\E0E3";
	}
	@if $filename == buggy_trailer {
		$char: "\E0E4";
	}
	@if $filename == buggying {
		$char: "\E0E5";
	}
	@if $filename == built-in-messaging-2 {
		$char: "\E0E6";
	}
	@if $filename == built-in-messaging {
		$char: "\E0E7";
	}
	@if $filename == built_in_stove {
		$char: "\E0E8";
	}
	@if $filename == cabin-round-table {
		$char: "\E0E9";
	}
	@if $filename == cabin-system-heating {
		$char: "\E0EA";
	}
	@if $filename == calendar-2 {
		$char: "\E0EB";
	}
	@if $filename == calendar-3 {
		$char: "\E0EC";
	}
	@if $filename == calendar {
		$char: "\E0ED";
	}
	@if $filename == camper-bicycle {
		$char: "\E0EE";
	}
	@if $filename == camper-box {
		$char: "\E0EF";
	}
	@if $filename == camper-kayak {
		$char: "\E0F0";
	}
	@if $filename == camper-specification {
		$char: "\E0F1";
	}
	@if $filename == campsite-battery-charging {
		$char: "\E0F2";
	}
	@if $filename == campsite-fees {
		$char: "\E0F3";
	}
	@if $filename == campsite-power-plug {
		$char: "\E0F4";
	}
	@if $filename == campsite-van {
		$char: "\E0F5";
	}
	@if $filename == campsite_power_plug {
		$char: "\E0F6";
	}
	@if $filename == can-opener {
		$char: "\E0F7";
	}
	@if $filename == cart {
		$char: "\E0F8";
	}
	@if $filename == cave {
		$char: "\E0F9";
	}
	@if $filename == caving {
		$char: "\E0FA";
	}
	@if $filename == cdw {
		$char: "\E0FB";
	}
	@if $filename == charging-station {
		$char: "\E0FC";
	}
	@if $filename == check-in {
		$char: "\E0FD";
	}
	@if $filename == check-out {
		$char: "\E0FE";
	}
	@if $filename == cleaning-included {
		$char: "\E0FF";
	}
	@if $filename == clicgear {
		$char: "\E100";
	}
	@if $filename == clock {
		$char: "\E101";
	}
	@if $filename == close-circle {
		$char: "\E102";
	}
	@if $filename == coffee_machine {
		$char: "\E103";
	}
	@if $filename == comfort-items-all-together-x {
		$char: "\E104";
	}
	@if $filename == compressor-fridge {
		$char: "\E105";
	}
	@if $filename == cook-and-kitchenware {
		$char: "\E106";
	}
	@if $filename == covid-protection {
		$char: "\E107";
	}
	@if $filename == crater {
		$char: "\E108";
	}
	@if $filename == create-account {
		$char: "\E109";
	}
	@if $filename == crosscountry-skiing {
		$char: "\E10A";
	}
	@if $filename == crosswind_assist {
		$char: "\E10B";
	}
	@if $filename == cruise-control {
		$char: "\E10C";
	}
	@if $filename == cups {
		$char: "\E10D";
	}
	@if $filename == curtains {
		$char: "\E10E";
	}
	@if $filename == custom-navigation {
		$char: "\E10F";
	}
	@if $filename == cutting-board {
		$char: "\E110";
	}
	@if $filename == diesel-engine {
		$char: "\E111";
	}
	@if $filename == diesel {
		$char: "\E112";
	}
	@if $filename == dimmer-1 {
		$char: "\E113";
	}
	@if $filename == dinig-spoons {
		$char: "\E114";
	}
	@if $filename == dining-forks {
		$char: "\E115";
	}
	@if $filename == dining-knives {
		$char: "\E116";
	}
	@if $filename == direct-drive {
		$char: "\E117";
	}
	@if $filename == dirt-biking {
		$char: "\E118";
	}
	@if $filename == dirtbike-helmet {
		$char: "\E119";
	}
	@if $filename == dirtbike-rack-2 {
		$char: "\E11A";
	}
	@if $filename == dirtbike-rack {
		$char: "\E11B";
	}
	@if $filename == dirtbike-traler {
		$char: "\E11C";
	}
	@if $filename == dirtbike {
		$char: "\E11D";
	}
	@if $filename == dish-cloth {
		$char: "\E11E";
	}
	@if $filename == dishwasher-brush {
		$char: "\E11F";
	}
	@if $filename == dishwashing-machine {
		$char: "\E120";
	}
	@if $filename == distance {
		$char: "\E121";
	}
	@if $filename == diving {
		$char: "\E122";
	}
	@if $filename == dog-sledding {
		$char: "\E123";
	}
	@if $filename == dogs-allowed {
		$char: "\E124";
	}
	@if $filename == door {
		$char: "\E125";
	}
	@if $filename == downhill-bicycling {
		$char: "\E126";
	}
	@if $filename == dressing-room {
		$char: "\E127";
	}
	@if $filename == driver-arm-rest {
		$char: "\E128";
	}
	@if $filename == driver-guide {
		$char: "\E129";
	}
	@if $filename == driver-lumbar-support {
		$char: "\E12A";
	}
	@if $filename == drysuit {
		$char: "\E12B";
	}
	@if $filename == dsg-gearbox {
		$char: "\E12C";
	}
	@if $filename == dual-motor {
		$char: "\E12D";
	}
	@if $filename == duvet-bed {
		$char: "\E12E";
	}
	@if $filename == duvet {
		$char: "\E12F";
	}
	@if $filename == e-charging {
		$char: "\E130";
	}
	@if $filename == early-bird-spring {
		$char: "\E131";
	}
	@if $filename == earlybird {
		$char: "\E132";
	}
	@if $filename == easy-auto-bubble {
		$char: "\E133";
	}
	@if $filename == easy-auto-exp-bubble {
		$char: "\E134";
	}
	@if $filename == easy-auto-x-bubble {
		$char: "\E135";
	}
	@if $filename == easy-auto-x {
		$char: "\E136";
	}
	@if $filename == easy-auto {
		$char: "\E137";
	}
	@if $filename == easy-big-bubble {
		$char: "\E138";
	}
	@if $filename == easy-big-exp-bubble {
		$char: "\E139";
	}
	@if $filename == easy-big-x-bubble-2 {
		$char: "\E13A";
	}
	@if $filename == easy-big-x-bubble {
		$char: "\E13B";
	}
	@if $filename == easy-big-x {
		$char: "\E13C";
	}
	@if $filename == easy-big {
		$char: "\E13D";
	}
	@if $filename == easy-clever-bubble {
		$char: "\E13E";
	}
	@if $filename == easy-clever-exp-bubble {
		$char: "\E13F";
	}
	@if $filename == easy-clever-x-bubble {
		$char: "\E140";
	}
	@if $filename == easy-clever-x {
		$char: "\E141";
	}
	@if $filename == easy-clever {
		$char: "\E142";
	}
	@if $filename == easy-fun-bubble {
		$char: "\E143";
	}
	@if $filename == easy-fun-exp-bubble {
		$char: "\E144";
	}
	@if $filename == easy-fun-x-bubble {
		$char: "\E145";
	}
	@if $filename == easy-fun-x {
		$char: "\E146";
	}
	@if $filename == easy-fun {
		$char: "\E147";
	}
	@if $filename == easy-guide-tablet-abc {
		$char: "\E148";
	}
	@if $filename == easy-guide-tablet-navigation {
		$char: "\E149";
	}
	@if $filename == easy-guide-tablet-plain {
		$char: "\E14A";
	}
	@if $filename == easy-guide-tablet {
		$char: "\E14B";
	}
	@if $filename == easy-luxury-bubble {
		$char: "\E14C";
	}
	@if $filename == easy-luxury-exp-bubble {
		$char: "\E14D";
	}
	@if $filename == easy-monster-bubble {
		$char: "\E14E";
	}
	@if $filename == easy-monster-exp-bubble {
		$char: "\E14F";
	}
	@if $filename == easy-ranger-bubble {
		$char: "\E150";
	}
	@if $filename == easy-ranger-exp-bubble {
		$char: "\E151";
	}
	@if $filename == easy-ranger-x-bubble {
		$char: "\E152";
	}
	@if $filename == easy-ranger-x {
		$char: "\E153";
	}
	@if $filename == easy-ranger {
		$char: "\E154";
	}
	@if $filename == easy-small-bubble {
		$char: "\E155";
	}
	@if $filename == easy-small-exp-bubble {
		$char: "\E156";
	}
	@if $filename == easy-small-x-bubble {
		$char: "\E157";
	}
	@if $filename == easy-small-x {
		$char: "\E158";
	}
	@if $filename == easy-small {
		$char: "\E159";
	}
	@if $filename == easy-tablet-modify {
		$char: "\E15A";
	}
	@if $filename == easy-viking-bubble {
		$char: "\E15B";
	}
	@if $filename == easy-viking-exp-bubble {
		$char: "\E15C";
	}
	@if $filename == easy-viking-x-bubble {
		$char: "\E15D";
	}
	@if $filename == easy-viking-x {
		$char: "\E15E";
	}
	@if $filename == easy-viking {
		$char: "\E15F";
	}
	@if $filename == electric-adjustable-driver-seat {
		$char: "\E160";
	}
	@if $filename == electric-dirtbike {
		$char: "\E161";
	}
	@if $filename == electric-fatbike {
		$char: "\E162";
	}
	@if $filename == electric-moped {
		$char: "\E163";
	}
	@if $filename == electric-scooter {
		$char: "\E164";
	}
	@if $filename == electric-streetbike {
		$char: "\E165";
	}
	@if $filename == electric-vespa {
		$char: "\E166";
	}
	@if $filename == electric {
		$char: "\E167";
	}
	@if $filename == electricity {
		$char: "\E168";
	}
	@if $filename == emergency-service {
		$char: "\E169";
	}
	@if $filename == emission {
		$char: "\E16A";
	}
	@if $filename == engine-bottom-shield {
		$char: "\E16B";
	}
	@if $filename == environmental {
		$char: "\E16C";
	}
	@if $filename == exp {
		$char: "\E16D";
	}
	@if $filename == exp1 {
		$char: "\E16E";
	}
	@if $filename == exp2 {
		$char: "\E16F";
	}
	@if $filename == experienced {
		$char: "\E170";
	}
	@if $filename == expert-made-itineraries {
		$char: "\E171";
	}
	@if $filename == extensive-modification {
		$char: "\E172";
	}
	@if $filename == extra-duvet {
		$char: "\E173";
	}
	@if $filename == extra-gas {
		$char: "\E174";
	}
	@if $filename == extra-light {
		$char: "\E175";
	}
	@if $filename == extra-pan {
		$char: "\E176";
	}
	@if $filename == extra-pillow {
		$char: "\E177";
	}
	@if $filename == extra-pot {
		$char: "\E178";
	}
	@if $filename == extra-rags {
		$char: "\E179";
	}
	@if $filename == extra-set-of-bedlinen {
		$char: "\E17A";
	}
	@if $filename == extra-stove {
		$char: "\E17B";
	}
	@if $filename == extra-thick-bed {
		$char: "\E17C";
	}
	@if $filename == f-road-2 {
		$char: "\E17D";
	}
	@if $filename == f-road-mountain {
		$char: "\E17E";
	}
	@if $filename == f-road {
		$char: "\E17F";
	}
	@if $filename == facebook {
		$char: "\E180";
	}
	@if $filename == factory-mileage-in-lmk {
		$char: "\E181";
	}
	@if $filename == factory-mileage-in-mpg {
		$char: "\E182";
	}
	@if $filename == fall {
		$char: "\E183";
	}
	@if $filename == family-buggy {
		$char: "\E184";
	}
	@if $filename == fast-charging {
		$char: "\E185";
	}
	@if $filename == fire-extinguisher {
		$char: "\E186";
	}
	@if $filename == first-aid-kit {
		$char: "\E187";
	}
	@if $filename == fishing-gear {
		$char: "\E188";
	}
	@if $filename == fishing {
		$char: "\E189";
	}
	@if $filename == fjord {
		$char: "\E18A";
	}
	@if $filename == flashlight {
		$char: "\E18B";
	}
	@if $filename == floormat {
		$char: "\E18C";
	}
	@if $filename == fog-cornering-lights {
		$char: "\E18D";
	}
	@if $filename == fog-lights {
		$char: "\E18E";
	}
	@if $filename == food {
		$char: "\E18F";
	}
	@if $filename == forbidden {
		$char: "\E190";
	}
	@if $filename == frisbee {
		$char: "\E191";
	}
	@if $filename == front-assist {
		$char: "\E192";
	}
	@if $filename == fuel-tank {
		$char: "\E193";
	}
	@if $filename == fullpayment {
		$char: "\E194";
	}
	@if $filename == fwd {
		$char: "\E195";
	}
	@if $filename == gallery-int {
		$char: "\E196";
	}
	@if $filename == gallery {
		$char: "\E197";
	}
	@if $filename == gas {
		$char: "\E198";
	}
	@if $filename == gift {
		$char: "\E199";
	}
	@if $filename == glacier {
		$char: "\E19A";
	}
	@if $filename == golf-set {
		$char: "\E19B";
	}
	@if $filename == golfing {
		$char: "\E19C";
	}
	@if $filename == google-logo {
		$char: "\E19D";
	}
	@if $filename == gps-navigation {
		$char: "\E19E";
	}
	@if $filename == ground_clearance_center {
		$char: "\E19F";
	}
	@if $filename == ground_clearance_front {
		$char: "\E1A0";
	}
	@if $filename == ground_clearance_rear {
		$char: "\E1A1";
	}
	@if $filename == group {
		$char: "\E1A2";
	}
	@if $filename == halogen {
		$char: "\E1A3";
	}
	@if $filename == heart-outline {
		$char: "\E1A4";
	}
	@if $filename == heart {
		$char: "\E1A5";
	}
	@if $filename == heat-insulating-glass {
		$char: "\E1A6";
	}
	@if $filename == heated-driver-seat {
		$char: "\E1A7";
	}
	@if $filename == heated-mirrors {
		$char: "\E1A8";
	}
	@if $filename == heated-rear-window {
		$char: "\E1A9";
	}
	@if $filename == heated-seats-2 {
		$char: "\E1AA";
	}
	@if $filename == heated-seats {
		$char: "\E1AB";
	}
	@if $filename == heated-steering-wheel {
		$char: "\E1AC";
	}
	@if $filename == heated-window-wiper {
		$char: "\E1AD";
	}
	@if $filename == heated-windscreen {
		$char: "\E1AE";
	}
	@if $filename == heating {
		$char: "\E1AF";
	}
	@if $filename == height-adjustable-seat {
		$char: "\E1B0";
	}
	@if $filename == hello {
		$char: "\E1B1";
	}
	@if $filename == helmet-included {
		$char: "\E1B2";
	}
	@if $filename == highlands-summer {
		$char: "\E1B3";
	}
	@if $filename == highlands {
		$char: "\E1B4";
	}
	@if $filename == hiking-gear {
		$char: "\E1B5";
	}
	@if $filename == hiking-trail {
		$char: "\E1B6";
	}
	@if $filename == hiking {
		$char: "\E1B7";
	}
	@if $filename == honest-mileage-lkm {
		$char: "\E1B8";
	}
	@if $filename == honest-mileage-mpg {
		$char: "\E1B9";
	}
	@if $filename == horseback-riding {
		$char: "\E1BA";
	}
	@if $filename == horsepower {
		$char: "\E1BB";
	}
	@if $filename == host-or-guide-with-frame {
		$char: "\E1BC";
	}
	@if $filename == host-or-guide {
		$char: "\E1BD";
	}
	@if $filename == hot-pot-pad {
		$char: "\E1BE";
	}
	@if $filename == hot-water {
		$char: "\E1BF";
	}
	@if $filename == hunting {
		$char: "\E1C0";
	}
	@if $filename == ice-sar-connection {
		$char: "\E1C1";
	}
	@if $filename == indoor-cooking-facilities {
		$char: "\E1C2";
	}
	@if $filename == info {
		$char: "\E1C3";
	}
	@if $filename == information-tablet {
		$char: "\E1C4";
	}
	@if $filename == inside-camper {
		$char: "\E1C5";
	}
	@if $filename == instagram {
		$char: "\E1C6";
	}
	@if $filename == insurance-cancellation-protection {
		$char: "\E1C7";
	}
	@if $filename == insurance-free {
		$char: "\E1C8";
	}
	@if $filename == insurance-premium {
		$char: "\E1C9";
	}
	@if $filename == insurance-super-service {
		$char: "\E1CA";
	}
	@if $filename == jumper-cables {
		$char: "\E1CB";
	}
	@if $filename == kayak-canoe {
		$char: "\E1CC";
	}
	@if $filename == kayak {
		$char: "\E1CD";
	}
	@if $filename == kettle {
		$char: "\E1CE";
	}
	@if $filename == keyless-start {
		$char: "\E1CF";
	}
	@if $filename == kitchen-knife {
		$char: "\E1D0";
	}
	@if $filename == kwh {
		$char: "\E1D1";
	}
	@if $filename == ladle {
		$char: "\E1D2";
	}
	@if $filename == lagoon {
		$char: "\E1D3";
	}
	@if $filename == lake {
		$char: "\E1D4";
	}
	@if $filename == large-bike-rack {
		$char: "\E1D5";
	}
	@if $filename == large-trailer {
		$char: "\E1D6";
	}
	@if $filename == larger-tyres-and-wheels-1 {
		$char: "\E1D7";
	}
	@if $filename == larger-tyres-and-wheels {
		$char: "\E1D8";
	}
	@if $filename == laundry-rack {
		$char: "\E1D9";
	}
	@if $filename == layout-int {
		$char: "\E1DA";
	}
	@if $filename == leather-steering-wheel {
		$char: "\E1DB";
	}
	@if $filename == led-headlights {
		$char: "\E1DC";
	}
	@if $filename == level-0 {
		$char: "\E1DD";
	}
	@if $filename == level {
		$char: "\E1DE";
	}
	@if $filename == level1-easy {
		$char: "\E1DF";
	}
	@if $filename == level2-moderate {
		$char: "\E1E0";
	}
	@if $filename == level3-hard {
		$char: "\E1E1";
	}
	@if $filename == life-vest {
		$char: "\E1E2";
	}
	@if $filename == lighter {
		$char: "\E1E3";
	}
	@if $filename == linkedin {
		$char: "\E1E4";
	}
	@if $filename == lithium-batteries {
		$char: "\E1E5";
	}
	@if $filename == live-chat {
		$char: "\E1E6";
	}
	@if $filename == live-fleet-management {
		$char: "\E1E7";
	}
	@if $filename == live-fleet {
		$char: "\E1E8";
	}
	@if $filename == live-warnings {
		$char: "\E1E9";
	}
	@if $filename == locked-differential {
		$char: "\E1EA";
	}
	@if $filename == long-rental {
		$char: "\E1EB";
	}
	@if $filename == long-weekend {
		$char: "\E1EC";
	}
	@if $filename == lte_roof_antena {
		$char: "\E1ED";
	}
	@if $filename == manage-booking {
		$char: "\E1EE";
	}
	@if $filename == manual-gearbox {
		$char: "\E1EF";
	}
	@if $filename == maximum_range-1 {
		$char: "\E1F0";
	}
	@if $filename == mileage {
		$char: "\E1F1";
	}
	@if $filename == minimum-age-fill-in-number {
		$char: "\E1F2";
	}
	@if $filename == mobile-phone-interface {
		$char: "\E1F3";
	}
	@if $filename == mood-lighting {
		$char: "\E1F4";
	}
	@if $filename == moon {
		$char: "\E1F5";
	}
	@if $filename == mostbooks {
		$char: "\E1F6";
	}
	@if $filename == motorcycle-licence-a {
		$char: "\E1F7";
	}
	@if $filename == motorcycle-licence-a1 {
		$char: "\E1F8";
	}
	@if $filename == motorcycle-licence-a2 {
		$char: "\E1F9";
	}
	@if $filename == motorcycle-license {
		$char: "\E1FA";
	}
	@if $filename == mountain-bicycle {
		$char: "\E1FB";
	}
	@if $filename == mountain-bicycling-2 {
		$char: "\E1FC";
	}
	@if $filename == mountain-bicycling {
		$char: "\E1FD";
	}
	@if $filename == mountain {
		$char: "\E1FE";
	}
	@if $filename == mounted-spare-tyre {
		$char: "\E1FF";
	}
	@if $filename == mudflaps {
		$char: "\E200";
	}
	@if $filename == multifunction-display {
		$char: "\E201";
	}
	@if $filename == multifunctional-steering-wheel {
		$char: "\E202";
	}
	@if $filename == multiple-payments {
		$char: "\E203";
	}
	@if $filename == muscle-powered {
		$char: "\E204";
	}
	@if $filename == museum {
		$char: "\E205";
	}
	@if $filename == new-2 {
		$char: "\E206";
	}
	@if $filename == new-3 {
		$char: "\E207";
	}
	@if $filename == new {
		$char: "\E208";
	}
	@if $filename == next-level-gps {
		$char: "\E209";
	}
	@if $filename == no-false-damage {
		$char: "\E20A";
	}
	@if $filename == no-license-required {
		$char: "\E20B";
	}
	@if $filename == normal-license {
		$char: "\E20C";
	}
	@if $filename == northern-lights {
		$char: "\E20D";
	}
	@if $filename == ocean-swimming {
		$char: "\E20E";
	}
	@if $filename == open {
		$char: "\E20F";
	}
	@if $filename == paddleboard {
		$char: "\E210";
	}
	@if $filename == paddles {
		$char: "\E211";
	}
	@if $filename == pai {
		$char: "\E212";
	}
	@if $filename == pan {
		$char: "\E213";
	}
	@if $filename == pantry-unused-food {
		$char: "\E214";
	}
	@if $filename == park-distance-control {
		$char: "\E215";
	}
	@if $filename == parking-sensors {
		$char: "\E216";
	}
	@if $filename == petrol-engine-2 {
		$char: "\E217";
	}
	@if $filename == petrol-engine {
		$char: "\E218";
	}
	@if $filename == pillows {
		$char: "\E219";
	}
	@if $filename == post-collision-braking {
		$char: "\E21A";
	}
	@if $filename == pot {
		$char: "\E21B";
	}
	@if $filename == powered-mirrors {
		$char: "\E21C";
	}
	@if $filename == powered-ventilation {
		$char: "\E21D";
	}
	@if $filename == powered-windows {
		$char: "\E21E";
	}
	@if $filename == price-per-day {
		$char: "\E21F";
	}
	@if $filename == protective-helmet {
		$char: "\E220";
	}
	@if $filename == quad-motor {
		$char: "\E221";
	}
	@if $filename == quality-camper {
		$char: "\E222";
	}
	@if $filename == quality-stove {
		$char: "\E223";
	}
	@if $filename == quote {
		$char: "\E224";
	}
	@if $filename == rags {
		$char: "\E225";
	}
	@if $filename == rain-sensing-wipers {
		$char: "\E226";
	}
	@if $filename == raised-chassis {
		$char: "\E227";
	}
	@if $filename == ranked-1 {
		$char: "\E228";
	}
	@if $filename == rear-box {
		$char: "\E229";
	}
	@if $filename == rear-glass-heat-insulated {
		$char: "\E22A";
	}
	@if $filename == rear-mirror {
		$char: "\E22B";
	}
	@if $filename == rear-space {
		$char: "\E22C";
	}
	@if $filename == rear_wiper_washer {
		$char: "\E22D";
	}
	@if $filename == reflective-vest {
		$char: "\E22E";
	}
	@if $filename == remote-lock {
		$char: "\E22F";
	}
	@if $filename == repeating-customer {
		$char: "\E230";
	}
	@if $filename == restaurant {
		$char: "\E231";
	}
	@if $filename == reverse-camera {
		$char: "\E232";
	}
	@if $filename == reviews {
		$char: "\E233";
	}
	@if $filename == ride_height-2 {
		$char: "\E234";
	}
	@if $filename == river-2 {
		$char: "\E235";
	}
	@if $filename == river-crosser {
		$char: "\E236";
	}
	@if $filename == river-rafting {
		$char: "\E237";
	}
	@if $filename == river {
		$char: "\E238";
	}
	@if $filename == road-monitor {
		$char: "\E239";
	}
	@if $filename == rock-climbing {
		$char: "\E23A";
	}
	@if $filename == roof-box {
		$char: "\E23B";
	}
	@if $filename == roof-ladder {
		$char: "\E23C";
	}
	@if $filename == roof-rack {
		$char: "\E23D";
	}
	@if $filename == roofbox-closeup {
		$char: "\E23E";
	}
	@if $filename == rope {
		$char: "\E23F";
	}
	@if $filename == router {
		$char: "\E240";
	}
	@if $filename == running-water {
		$char: "\E241";
	}
	@if $filename == rwd {
		$char: "\E242";
	}
	@if $filename == safest-country {
		$char: "\E243";
	}
	@if $filename == safety-tracking {
		$char: "\E244";
	}
	@if $filename == sand-ash {
		$char: "\E245";
	}
	@if $filename == save-big {
		$char: "\E246";
	}
	@if $filename == scale {
		$char: "\E247";
	}
	@if $filename == scales-new {
		$char: "\E248";
	}
	@if $filename == scooter {
		$char: "\E249";
	}
	@if $filename == search {
		$char: "\E24A";
	}
	@if $filename == seats-2 {
		$char: "\E24B";
	}
	@if $filename == seats-3 {
		$char: "\E24C";
	}
	@if $filename == seats-5 {
		$char: "\E24D";
	}
	@if $filename == selfservice-required {
		$char: "\E24E";
	}
	@if $filename == set-of-bedlinen {
		$char: "\E24F";
	}
	@if $filename == sheets {
		$char: "\E250";
	}
	@if $filename == shovel {
		$char: "\E251";
	}
	@if $filename == shower {
		$char: "\E252";
	}
	@if $filename == ski-area {
		$char: "\E253";
	}
	@if $filename == small-bag {
		$char: "\E254";
	}
	@if $filename == small-bike-rack {
		$char: "\E255";
	}
	@if $filename == small-bikerack {
		$char: "\E256";
	}
	@if $filename == small-plates {
		$char: "\E257";
	}
	@if $filename == small-trailer {
		$char: "\E258";
	}
	@if $filename == smart-steering-wheel {
		$char: "\E259";
	}
	@if $filename == snorkel {
		$char: "\E25A";
	}
	@if $filename == snorkeling {
		$char: "\E25B";
	}
	@if $filename == snowboard {
		$char: "\E25C";
	}
	@if $filename == snowboarding {
		$char: "\E25D";
	}
	@if $filename == softer-suspension {
		$char: "\E25E";
	}
	@if $filename == solar-powered-ventilation {
		$char: "\E25F";
	}
	@if $filename == songbook-1 {
		$char: "\E260";
	}
	@if $filename == songbook-2 {
		$char: "\E261";
	}
	@if $filename == songbook {
		$char: "\E262";
	}
	@if $filename == souvenir-shop {
		$char: "\E263";
	}
	@if $filename == spade {
		$char: "\E264";
	}
	@if $filename == spare {
		$char: "\E265";
	}
	@if $filename == speakers {
		$char: "\E266";
	}
	@if $filename == special-offer {
		$char: "\E267";
	}
	@if $filename == speed-limiter {
		$char: "\E268";
	}
	@if $filename == speed-transmission-general {
		$char: "\E269";
	}
	@if $filename == speed-transmission {
		$char: "\E26A";
	}
	@if $filename == speedometer {
		$char: "\E26B";
	}
	@if $filename == standup-paddle-board {
		$char: "\E26C";
	}
	@if $filename == start {
		$char: "\E26D";
	}
	@if $filename == steering-wheel {
		$char: "\E26E";
	}
	@if $filename == storage-above-driver {
		$char: "\E26F";
	}
	@if $filename == stove {
		$char: "\E270";
	}
	@if $filename == street-legal {
		$char: "\E271";
	}
	@if $filename == street-motorcycle {
		$char: "\E272";
	}
	@if $filename == street-racer-bicycle {
		$char: "\E273";
	}
	@if $filename == stronger-main-battery {
		$char: "\E274";
	}
	@if $filename == summer {
		$char: "\E275";
	}
	@if $filename == sunglasses {
		$char: "\E276";
	}
	@if $filename == support-24-7-round {
		$char: "\E277";
	}
	@if $filename == surfboard {
		$char: "\E278";
	}
	@if $filename == surfing {
		$char: "\E279";
	}
	@if $filename == suspended_driver_seat {
		$char: "\E27A";
	}
	@if $filename == swimming {
		$char: "\E27B";
	}
	@if $filename == tandem-kayak {
		$char: "\E27C";
	}
	@if $filename == tap-trawler {
		$char: "\E27D";
	}
	@if $filename == tesla-camper-y-bubble {
		$char: "\E27E";
	}
	@if $filename == tesla-camper-y {
		$char: "\E27F";
	}
	@if $filename == theft-insurance {
		$char: "\E280";
	}
	@if $filename == thick-duvets {
		$char: "\E281";
	}
	@if $filename == tinted-glass-roof {
		$char: "\E282";
	}
	@if $filename == tinted-windows {
		$char: "\E283";
	}
	@if $filename == touch-screen {
		$char: "\E284";
	}
	@if $filename == touring-bike {
		$char: "\E285";
	}
	@if $filename == tow-hitch {
		$char: "\E286";
	}
	@if $filename == town {
		$char: "\E287";
	}
	@if $filename == toy-insurance {
		$char: "\E288";
	}
	@if $filename == toy {
		$char: "\E289";
	}
	@if $filename == toy1 {
		$char: "\E28A";
	}
	@if $filename == toys {
		$char: "\E28B";
	}
	@if $filename == tpl {
		$char: "\E28C";
	}
	@if $filename == traction-control {
		$char: "\E28D";
	}
	@if $filename == traffic-aware-cruise-control {
		$char: "\E28E";
	}
	@if $filename == transmission-bottom-shield {
		$char: "\E28F";
	}
	@if $filename == tripplanner-desktop {
		$char: "\E290";
	}
	@if $filename == tronic_gearbox-1 {
		$char: "\E291";
	}
	@if $filename == tv_and_media {
		$char: "\E292";
	}
	@if $filename == twitter {
		$char: "\E293";
	}
	@if $filename == two-batteries {
		$char: "\E294";
	}
	@if $filename == tyre-changing-tools {
		$char: "\E295";
	}
	@if $filename == tyre-pressure-censor {
		$char: "\E296";
	}
	@if $filename == tyre-size {
		$char: "\E297";
	}
	@if $filename == unavailable {
		$char: "\E298";
	}
	@if $filename == unlimited-mileage {
		$char: "\E299";
	}
	@if $filename == upper-bed-height {
		$char: "\E29A";
	}
	@if $filename == upperbed-length {
		$char: "\E29B";
	}
	@if $filename == upperbed-width {
		$char: "\E29C";
	}
	@if $filename == usb-and-aux {
		$char: "\E29D";
	}
	@if $filename == usb {
		$char: "\E29E";
	}
	@if $filename == van {
		$char: "\E29F";
	}
	@if $filename == vehicle-height {
		$char: "\E2A0";
	}
	@if $filename == vehicle-length {
		$char: "\E2A1";
	}
	@if $filename == vehicle-width {
		$char: "\E2A2";
	}
	@if $filename == veteran {
		$char: "\E2A3";
	}
	@if $filename == video-int {
		$char: "\E2A4";
	}
	@if $filename == volcano {
		$char: "\E2A5";
	}
	@if $filename == warning-no-expenses {
		$char: "\E2A6";
	}
	@if $filename == warning-triangle {
		$char: "\E2A7";
	}
	@if $filename == washing-liquid {
		$char: "\E2A8";
	}
	@if $filename == washing-machine {
		$char: "\E2A9";
	}
	@if $filename == waterfall {
		$char: "\E2AA";
	}
	@if $filename == wc {
		$char: "\E2AB";
	}
	@if $filename == weather-monitor {
		$char: "\E2AC";
	}
	@if $filename == wetsuite {
		$char: "\E2AD";
	}
	@if $filename == whale-watching {
		$char: "\E2AE";
	}
	@if $filename == whisk {
		$char: "\E2AF";
	}
	@if $filename == wifi {
		$char: "\E2B0";
	}
	@if $filename == wine-beer {
		$char: "\E2B1";
	}
	@if $filename == winter-tyres {
		$char: "\E2B2";
	}
	@if $filename == winter {
		$char: "\E2B3";
	}
	@if $filename == wireless-phone-charger {
		$char: "\E2B4";
	}
	@if $filename == wondow-rake {
		$char: "\E2B5";
	}
	@if $filename == wool-insulation {
		$char: "\E2B6";
	}
	@if $filename == writer {
		$char: "\E2B7";
	}
	@if $filename == youtube {
		$char: "\E2B8";
	}
	@if $filename == zero-risk-2 {
		$char: "\E2B9";
	}
	@if $filename == zero-risk {
		$char: "\E2BA";
	}
	@if $filename == zoo {
		$char: "\E2BB";
	}

	@return $char;
}

.icon {
  font-family: "campeasy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
  line-height: 0;

  &:before {
    line-height: 1em;
  }
}

@mixin icon($filename) {
	&:before {
		content: icon-char($filename);
	}
}

.icon--io-age {
	@include icon(-io-age);
}
.icon--io-airplane {
	@include icon(-io-airplane);
}
.icon--io-arrow-next {
	@include icon(-io-arrow-next);
}
.icon--io-arrow-right-circle {
	@include icon(-io-arrow-right-circle);
}
.icon--io-back-left {
	@include icon(-io-back-left);
}
.icon--io-back-right {
	@include icon(-io-back-right);
}
.icon--io-basic-alarm {
	@include icon(-io-basic-alarm);
}
.icon--io-bonfire {
	@include icon(-io-bonfire);
}
.icon--io-brand-location-pin {
	@include icon(-io-brand-location-pin);
}
.icon--io-calendar-2 {
	@include icon(-io-calendar-2);
}
.icon--io-calendar-event {
	@include icon(-io-calendar-event);
}
.icon--io-calendar {
	@include icon(-io-calendar);
}
.icon--io-camera {
	@include icon(-io-camera);
}
.icon--io-cancellation {
	@include icon(-io-cancellation);
}
.icon--io-cart {
	@include icon(-io-cart);
}
.icon--io-category {
	@include icon(-io-category);
}
.icon--io-check-out {
	@include icon(-io-check-out);
}
.icon--io-check {
	@include icon(-io-check);
}
.icon--io-checkbox-checked {
	@include icon(-io-checkbox-checked);
}
.icon--io-checkbox-radiobutton-unchcked {
	@include icon(-io-checkbox-radiobutton-unchcked);
}
.icon--io-checkbox-unchecked {
	@include icon(-io-checkbox-unchecked);
}
.icon--io-chevron-down {
	@include icon(-io-chevron-down);
}
.icon--io-chevron-left {
	@include icon(-io-chevron-left);
}
.icon--io-chevron-right {
	@include icon(-io-chevron-right);
}
.icon--io-chevron-up {
	@include icon(-io-chevron-up);
}
.icon--io-chips-checked {
	@include icon(-io-chips-checked);
}
.icon--io-city-hall {
	@include icon(-io-city-hall);
}
.icon--io-clock-outline {
	@include icon(-io-clock-outline);
}
.icon--io-close-circle {
	@include icon(-io-close-circle);
}
.icon--io-close {
	@include icon(-io-close);
}
.icon--io-compass {
	@include icon(-io-compass);
}
.icon--io-current-location {
	@include icon(-io-current-location);
}
.icon--io-directions {
	@include icon(-io-directions);
}
.icon--io-distance {
	@include icon(-io-distance);
}
.icon--io-document {
	@include icon(-io-document);
}
.icon--io-dots {
	@include icon(-io-dots);
}
.icon--io-download {
	@include icon(-io-download);
}
.icon--io-drag-and-drop {
	@include icon(-io-drag-and-drop);
}
.icon--io-edit {
	@include icon(-io-edit);
}
.icon--io-envelope {
	@include icon(-io-envelope);
}
.icon--io-eye-empty {
	@include icon(-io-eye-empty);
}
.icon--io-favorites {
	@include icon(-io-favorites);
}
.icon--io-filter-outline {
	@include icon(-io-filter-outline);
}
.icon--io-flower {
	@include icon(-io-flower);
}
.icon--io-forbidden {
	@include icon(-io-forbidden);
}
.icon--io-gallery {
	@include icon(-io-gallery);
}
.icon--io-gender-fluid {
	@include icon(-io-gender-fluid);
}
.icon--io-google-logo {
	@include icon(-io-google-logo);
}
.icon--io-grid {
	@include icon(-io-grid);
}
.icon--io-group {
	@include icon(-io-group);
}
.icon--io-gym {
	@include icon(-io-gym);
}
.icon--io-handshake {
	@include icon(-io-handshake);
}
.icon--io-heart-count {
	@include icon(-io-heart-count);
}
.icon--io-heart-handshake {
	@include icon(-io-heart-handshake);
}
.icon--io-heart-outline {
	@include icon(-io-heart-outline);
}
.icon--io-heart {
	@include icon(-io-heart);
}
.icon--io-image-list {
	@include icon(-io-image-list);
}
.icon--io-info-empty {
	@include icon(-io-info-empty);
}
.icon--io-information-circle {
	@include icon(-io-information-circle);
}
.icon--io-language {
	@include icon(-io-language);
}
.icon--io-layout {
	@include icon(-io-layout);
}
.icon--io-leaves {
	@include icon(-io-leaves);
}
.icon--io-level {
	@include icon(-io-level);
}
.icon--io-light-theme {
	@include icon(-io-light-theme);
}
.icon--io-lightning {
	@include icon(-io-lightning);
}
.icon--io-line {
	@include icon(-io-line);
}
.icon--io-link {
	@include icon(-io-link);
}
.icon--io-location {
	@include icon(-io-location);
}
.icon--io-lock-open {
	@include icon(-io-lock-open);
}
.icon--io-menu-burger {
	@include icon(-io-menu-burger);
}
.icon--io-message-writing {
	@include icon(-io-message-writing);
}
.icon--io-messenger {
	@include icon(-io-messenger);
}
.icon--io-moon-theme {
	@include icon(-io-moon-theme);
}
.icon--io-moon {
	@include icon(-io-moon);
}
.icon--io-more {
	@include icon(-io-more);
}
.icon--io-mountain {
	@include icon(-io-mountain);
}
.icon--io-navigator-alt {
	@include icon(-io-navigator-alt);
}
.icon--io-new {
	@include icon(-io-new);
}
.icon--io-office-building {
	@include icon(-io-office-building);
}
.icon--io-open {
	@include icon(-io-open);
}
.icon--io-planet {
	@include icon(-io-planet);
}
.icon--io-play {
	@include icon(-io-play);
}
.icon--io-pricetag {
	@include icon(-io-pricetag);
}
.icon--io-printing-page {
	@include icon(-io-printing-page);
}
.icon--io-profile-user {
	@include icon(-io-profile-user);
}
.icon--io-question {
	@include icon(-io-question);
}
.icon--io-quote {
	@include icon(-io-quote);
}
.icon--io-radiobutton-checked {
	@include icon(-io-radiobutton-checked);
}
.icon--io-restart {
	@include icon(-io-restart);
}
.icon--io-review {
	@include icon(-io-review);
}
.icon--io-river {
	@include icon(-io-river);
}
.icon--io-road {
	@include icon(-io-road);
}
.icon--io-scales {
	@include icon(-io-scales);
}
.icon--io-search {
	@include icon(-io-search);
}
.icon--io-settings {
	@include icon(-io-settings);
}
.icon--io-share {
	@include icon(-io-share);
}
.icon--io-sort {
	@include icon(-io-sort);
}
.icon--io-stack-of-books {
	@include icon(-io-stack-of-books);
}
.icon--io-star-half {
	@include icon(-io-star-half);
}
.icon--io-star-outline {
	@include icon(-io-star-outline);
}
.icon--io-star {
	@include icon(-io-star);
}
.icon--io-stars {
	@include icon(-io-stars);
}
.icon--io-start {
	@include icon(-io-start);
}
.icon--io-steering-wheel {
	@include icon(-io-steering-wheel);
}
.icon--io-suitcase {
	@include icon(-io-suitcase);
}
.icon--io-sun {
	@include icon(-io-sun);
}
.icon--io-swimming {
	@include icon(-io-swimming);
}
.icon--io-transmission {
	@include icon(-io-transmission);
}
.icon--io-trash {
	@include icon(-io-trash);
}
.icon--io-upload {
	@include icon(-io-upload);
}
.icon--io-user-square {
	@include icon(-io-user-square);
}
.icon--io-van {
	@include icon(-io-van);
}
.icon--io-verify {
	@include icon(-io-verify);
}
.icon--io-video-gallery {
	@include icon(-io-video-gallery);
}
.icon--io-view {
	@include icon(-io-view);
}
.icon--io-volcano {
	@include icon(-io-volcano);
}
.icon--io-vote-yes {
	@include icon(-io-vote-yes);
}
.icon--io-warning-mark {
	@include icon(-io-warning-mark);
}
.icon--io-warning {
	@include icon(-io-warning);
}
.icon--io-whatsapp {
	@include icon(-io-whatsapp);
}
.icon--io-writing {
	@include icon(-io-writing);
}
.icon--io-сamera {
	@include icon(-io-сamera);
}
.icon-0-3y {
	@include icon(0-3y);
}
.icon-0-3y1 {
	@include icon(0-3y1);
}
.icon-1-bed {
	@include icon(1-bed);
}
.icon-1-large-bag {
	@include icon(1-large-bag);
}
.icon-1-seat {
	@include icon(1-seat);
}
.icon-1-small-bag {
	@include icon(1-small-bag);
}
.icon-2-5y {
	@include icon(2-5y);
}
.icon-2-camper-special {
	@include icon(2-camper-special);
}
.icon-2-large-bag {
	@include icon(2-large-bag);
}
.icon-2-small-bag {
	@include icon(2-small-bag);
}
.icon-220v-sockets-b {
	@include icon(220v-sockets-b);
}
.icon-230v-socket {
	@include icon(230v-socket);
}
.icon-24-7-assistance {
	@include icon(24-7-assistance);
}
.icon-24-7-calls-on-the-road {
	@include icon(24-7-calls-on-the-road);
}
.icon-24h-email-answering-b {
	@include icon(24h-email-answering-b);
}
.icon-24h-email-answering {
	@include icon(24h-email-answering);
}
.icon-3-6y {
	@include icon(3-6y);
}
.icon-3-6y1 {
	@include icon(3-6y1);
}
.icon-3-batteries {
	@include icon(3-batteries);
}
.icon-3-day-minimum {
	@include icon(3-day-minimum);
}
.icon-3-large-bag {
	@include icon(3-large-bag);
}
.icon-3-small-bag {
	@include icon(3-small-bag);
}
.icon-3000_watt_sinus_inverter {
	@include icon(3000_watt_sinus_inverter);
}
.icon-360-parking-camera {
	@include icon(360-parking-camera);
}
.icon-4-beds-twinbed {
	@include icon(4-beds-twinbed);
}
.icon-4-doors {
	@include icon(4-doors);
}
.icon-4-large-bags {
	@include icon(4-large-bags);
}
.icon-4-small-bags {
	@include icon(4-small-bags);
}
.icon-4-speakers {
	@include icon(4-speakers);
}
.icon-4x4-outlined {
	@include icon(4x4-outlined);
}
.icon-4x4 {
	@include icon(4x4);
}
.icon-5-day-minimum {
	@include icon(5-day-minimum);
}
.icon-5-doors {
	@include icon(5-doors);
}
.icon-5-large-bags {
	@include icon(5-large-bags);
}
.icon-5-speed {
	@include icon(5-speed);
}
.icon-6-speed {
	@include icon(6-speed);
}
.icon-7-day-minimum {
	@include icon(7-day-minimum);
}
.icon-7-speed {
	@include icon(7-speed);
}
.icon-8-inch-screen {
	@include icon(8-inch-screen);
}
.icon-8-speed {
	@include icon(8-speed);
}
.icon-abs-brakes {
	@include icon(abs-brakes);
}
.icon-accident-report {
	@include icon(accident-report);
}
.icon-acoustic-guitar {
	@include icon(acoustic-guitar);
}
.icon-activities {
	@include icon(activities);
}
.icon-adaptive-cruise {
	@include icon(adaptive-cruise);
}
.icon-adjustable-driver-seat {
	@include icon(adjustable-driver-seat);
}
.icon-adjustable-seat-electric {
	@include icon(adjustable-seat-electric);
}
.icon-agent-portal-a-letter {
	@include icon(agent-portal-a-letter);
}
.icon-agentportal-tag {
	@include icon(agentportal-tag);
}
.icon-agm-batteries {
	@include icon(agm-batteries);
}
.icon-air-conditioning {
	@include icon(air-conditioning);
}
.icon-airbag-deactivation {
	@include icon(airbag-deactivation);
}
.icon-airbags {
	@include icon(airbags);
}
.icon-airport-transfer {
	@include icon(airport-transfer);
}
.icon-airport {
	@include icon(airport);
}
.icon-all-year {
	@include icon(all-year);
}
.icon-amendments {
	@include icon(amendments);
}
.icon-arrow-circle {
	@include icon(arrow-circle);
}
.icon-arrow-down {
	@include icon(arrow-down);
}
.icon-arrow-next {
	@include icon(arrow-next);
}
.icon-atv-trailer {
	@include icon(atv-trailer);
}
.icon-atv {
	@include icon(atv);
}
.icon-aurora-hunting {
	@include icon(aurora-hunting);
}
.icon-auto-emergency-braking {
	@include icon(auto-emergency-braking);
}
.icon-automatic-gearbox {
	@include icon(automatic-gearbox);
}
.icon-automatic-stop-start {
	@include icon(automatic-stop-start);
}
.icon-autopilot {
	@include icon(autopilot);
}
.icon-average-rain {
	@include icon(average-rain);
}
.icon-average-sunlight {
	@include icon(average-sunlight);
}
.icon-average-temperature {
	@include icon(average-temperature);
}
.icon-awd {
	@include icon(awd);
}
.icon-bandana-buff {
	@include icon(bandana-buff);
}
.icon-basket {
	@include icon(basket);
}
.icon-battery-hour {
	@include icon(battery-hour);
}
.icon-battery-kwhr {
	@include icon(battery-kwhr);
}
.icon-battery-last {
	@include icon(battery-last);
}
.icon-battery {
	@include icon(battery);
}
.icon-battery_capacity {
	@include icon(battery_capacity);
}
.icon-battery_kwh {
	@include icon(battery_kwh);
}
.icon-battery_monitor {
	@include icon(battery_monitor);
}
.icon-bbq-tong {
	@include icon(bbq-tong);
}
.icon-bed-length {
	@include icon(bed-length);
}
.icon-bed-width {
	@include icon(bed-width);
}
.icon-bedding {
	@include icon(bedding);
}
.icon-beds-2 {
	@include icon(beds-2);
}
.icon-beds-3 {
	@include icon(beds-3);
}
.icon-beds-5 {
	@include icon(beds-5);
}
.icon-bench-sitting-area {
	@include icon(bench-sitting-area);
}
.icon-bench {
	@include icon(bench);
}
.icon-bicycle-helmet {
	@include icon(bicycle-helmet);
}
.icon-bicycle-rack {
	@include icon(bicycle-rack);
}
.icon-bicycle {
	@include icon(bicycle);
}
.icon-big-bag {
	@include icon(big-bag);
}
.icon-big-plates {
	@include icon(big-plates);
}
.icon-bird-outline {
	@include icon(bird-outline);
}
.icon-bird {
	@include icon(bird);
}
.icon-birdwatchng {
	@include icon(birdwatchng);
}
.icon-blanket {
	@include icon(blanket);
}
.icon-bluetooth {
	@include icon(bluetooth);
}
.icon-bottle-opener {
	@include icon(bottle-opener);
}
.icon-bottom-mounted-extra-tyre {
	@include icon(bottom-mounted-extra-tyre);
}
.icon-bowls {
	@include icon(bowls);
}
.icon-brake-pads {
	@include icon(brake-pads);
}
.icon-broom-and-shovel {
	@include icon(broom-and-shovel);
}
.icon-buggy {
	@include icon(buggy);
}
.icon-buggy_trailer {
	@include icon(buggy_trailer);
}
.icon-buggying {
	@include icon(buggying);
}
.icon-built-in-messaging-2 {
	@include icon(built-in-messaging-2);
}
.icon-built-in-messaging {
	@include icon(built-in-messaging);
}
.icon-built_in_stove {
	@include icon(built_in_stove);
}
.icon-cabin-round-table {
	@include icon(cabin-round-table);
}
.icon-cabin-system-heating {
	@include icon(cabin-system-heating);
}
.icon-calendar-2 {
	@include icon(calendar-2);
}
.icon-calendar-3 {
	@include icon(calendar-3);
}
.icon-calendar {
	@include icon(calendar);
}
.icon-camper-bicycle {
	@include icon(camper-bicycle);
}
.icon-camper-box {
	@include icon(camper-box);
}
.icon-camper-kayak {
	@include icon(camper-kayak);
}
.icon-camper-specification {
	@include icon(camper-specification);
}
.icon-campsite-battery-charging {
	@include icon(campsite-battery-charging);
}
.icon-campsite-fees {
	@include icon(campsite-fees);
}
.icon-campsite-power-plug {
	@include icon(campsite-power-plug);
}
.icon-campsite-van {
	@include icon(campsite-van);
}
.icon-campsite_power_plug {
	@include icon(campsite_power_plug);
}
.icon-can-opener {
	@include icon(can-opener);
}
.icon-cart {
	@include icon(cart);
}
.icon-cave {
	@include icon(cave);
}
.icon-caving {
	@include icon(caving);
}
.icon-cdw {
	@include icon(cdw);
}
.icon-charging-station {
	@include icon(charging-station);
}
.icon-check-in {
	@include icon(check-in);
}
.icon-check-out {
	@include icon(check-out);
}
.icon-cleaning-included {
	@include icon(cleaning-included);
}
.icon-clicgear {
	@include icon(clicgear);
}
.icon-clock {
	@include icon(clock);
}
.icon-close-circle {
	@include icon(close-circle);
}
.icon-coffee_machine {
	@include icon(coffee_machine);
}
.icon-comfort-items-all-together-x {
	@include icon(comfort-items-all-together-x);
}
.icon-compressor-fridge {
	@include icon(compressor-fridge);
}
.icon-cook-and-kitchenware {
	@include icon(cook-and-kitchenware);
}
.icon-covid-protection {
	@include icon(covid-protection);
}
.icon-crater {
	@include icon(crater);
}
.icon-create-account {
	@include icon(create-account);
}
.icon-crosscountry-skiing {
	@include icon(crosscountry-skiing);
}
.icon-crosswind_assist {
	@include icon(crosswind_assist);
}
.icon-cruise-control {
	@include icon(cruise-control);
}
.icon-cups {
	@include icon(cups);
}
.icon-curtains {
	@include icon(curtains);
}
.icon-custom-navigation {
	@include icon(custom-navigation);
}
.icon-cutting-board {
	@include icon(cutting-board);
}
.icon-diesel-engine {
	@include icon(diesel-engine);
}
.icon-diesel {
	@include icon(diesel);
}
.icon-dimmer-1 {
	@include icon(dimmer-1);
}
.icon-dinig-spoons {
	@include icon(dinig-spoons);
}
.icon-dining-forks {
	@include icon(dining-forks);
}
.icon-dining-knives {
	@include icon(dining-knives);
}
.icon-direct-drive {
	@include icon(direct-drive);
}
.icon-dirt-biking {
	@include icon(dirt-biking);
}
.icon-dirtbike-helmet {
	@include icon(dirtbike-helmet);
}
.icon-dirtbike-rack-2 {
	@include icon(dirtbike-rack-2);
}
.icon-dirtbike-rack {
	@include icon(dirtbike-rack);
}
.icon-dirtbike-traler {
	@include icon(dirtbike-traler);
}
.icon-dirtbike {
	@include icon(dirtbike);
}
.icon-dish-cloth {
	@include icon(dish-cloth);
}
.icon-dishwasher-brush {
	@include icon(dishwasher-brush);
}
.icon-dishwashing-machine {
	@include icon(dishwashing-machine);
}
.icon-distance {
	@include icon(distance);
}
.icon-diving {
	@include icon(diving);
}
.icon-dog-sledding {
	@include icon(dog-sledding);
}
.icon-dogs-allowed {
	@include icon(dogs-allowed);
}
.icon-door {
	@include icon(door);
}
.icon-downhill-bicycling {
	@include icon(downhill-bicycling);
}
.icon-dressing-room {
	@include icon(dressing-room);
}
.icon-driver-arm-rest {
	@include icon(driver-arm-rest);
}
.icon-driver-guide {
	@include icon(driver-guide);
}
.icon-driver-lumbar-support {
	@include icon(driver-lumbar-support);
}
.icon-drysuit {
	@include icon(drysuit);
}
.icon-dsg-gearbox {
	@include icon(dsg-gearbox);
}
.icon-dual-motor {
	@include icon(dual-motor);
}
.icon-duvet-bed {
	@include icon(duvet-bed);
}
.icon-duvet {
	@include icon(duvet);
}
.icon-e-charging {
	@include icon(e-charging);
}
.icon-early-bird-spring {
	@include icon(early-bird-spring);
}
.icon-earlybird {
	@include icon(earlybird);
}
.icon-easy-auto-bubble {
	@include icon(easy-auto-bubble);
}
.icon-easy-auto-exp-bubble {
	@include icon(easy-auto-exp-bubble);
}
.icon-easy-auto-x-bubble {
	@include icon(easy-auto-x-bubble);
}
.icon-easy-auto-x {
	@include icon(easy-auto-x);
}
.icon-easy-auto {
	@include icon(easy-auto);
}
.icon-easy-big-bubble {
	@include icon(easy-big-bubble);
}
.icon-easy-big-exp-bubble {
	@include icon(easy-big-exp-bubble);
}
.icon-easy-big-x-bubble-2 {
	@include icon(easy-big-x-bubble-2);
}
.icon-easy-big-x-bubble {
	@include icon(easy-big-x-bubble);
}
.icon-easy-big-x {
	@include icon(easy-big-x);
}
.icon-easy-big {
	@include icon(easy-big);
}
.icon-easy-clever-bubble {
	@include icon(easy-clever-bubble);
}
.icon-easy-clever-exp-bubble {
	@include icon(easy-clever-exp-bubble);
}
.icon-easy-clever-x-bubble {
	@include icon(easy-clever-x-bubble);
}
.icon-easy-clever-x {
	@include icon(easy-clever-x);
}
.icon-easy-clever {
	@include icon(easy-clever);
}
.icon-easy-fun-bubble {
	@include icon(easy-fun-bubble);
}
.icon-easy-fun-exp-bubble {
	@include icon(easy-fun-exp-bubble);
}
.icon-easy-fun-x-bubble {
	@include icon(easy-fun-x-bubble);
}
.icon-easy-fun-x {
	@include icon(easy-fun-x);
}
.icon-easy-fun {
	@include icon(easy-fun);
}
.icon-easy-guide-tablet-abc {
	@include icon(easy-guide-tablet-abc);
}
.icon-easy-guide-tablet-navigation {
	@include icon(easy-guide-tablet-navigation);
}
.icon-easy-guide-tablet-plain {
	@include icon(easy-guide-tablet-plain);
}
.icon-easy-guide-tablet {
	@include icon(easy-guide-tablet);
}
.icon-easy-luxury-bubble {
	@include icon(easy-luxury-bubble);
}
.icon-easy-luxury-exp-bubble {
	@include icon(easy-luxury-exp-bubble);
}
.icon-easy-monster-bubble {
	@include icon(easy-monster-bubble);
}
.icon-easy-monster-exp-bubble {
	@include icon(easy-monster-exp-bubble);
}
.icon-easy-ranger-bubble {
	@include icon(easy-ranger-bubble);
}
.icon-easy-ranger-exp-bubble {
	@include icon(easy-ranger-exp-bubble);
}
.icon-easy-ranger-x-bubble {
	@include icon(easy-ranger-x-bubble);
}
.icon-easy-ranger-x {
	@include icon(easy-ranger-x);
}
.icon-easy-ranger {
	@include icon(easy-ranger);
}
.icon-easy-small-bubble {
	@include icon(easy-small-bubble);
}
.icon-easy-small-exp-bubble {
	@include icon(easy-small-exp-bubble);
}
.icon-easy-small-x-bubble {
	@include icon(easy-small-x-bubble);
}
.icon-easy-small-x {
	@include icon(easy-small-x);
}
.icon-easy-small {
	@include icon(easy-small);
}
.icon-easy-tablet-modify {
	@include icon(easy-tablet-modify);
}
.icon-easy-viking-bubble {
	@include icon(easy-viking-bubble);
}
.icon-easy-viking-exp-bubble {
	@include icon(easy-viking-exp-bubble);
}
.icon-easy-viking-x-bubble {
	@include icon(easy-viking-x-bubble);
}
.icon-easy-viking-x {
	@include icon(easy-viking-x);
}
.icon-easy-viking {
	@include icon(easy-viking);
}
.icon-electric-adjustable-driver-seat {
	@include icon(electric-adjustable-driver-seat);
}
.icon-electric-dirtbike {
	@include icon(electric-dirtbike);
}
.icon-electric-fatbike {
	@include icon(electric-fatbike);
}
.icon-electric-moped {
	@include icon(electric-moped);
}
.icon-electric-scooter {
	@include icon(electric-scooter);
}
.icon-electric-streetbike {
	@include icon(electric-streetbike);
}
.icon-electric-vespa {
	@include icon(electric-vespa);
}
.icon-electric {
	@include icon(electric);
}
.icon-electricity {
	@include icon(electricity);
}
.icon-emergency-service {
	@include icon(emergency-service);
}
.icon-emission {
	@include icon(emission);
}
.icon-engine-bottom-shield {
	@include icon(engine-bottom-shield);
}
.icon-environmental {
	@include icon(environmental);
}
.icon-exp {
	@include icon(exp);
}
.icon-exp1 {
	@include icon(exp1);
}
.icon-exp2 {
	@include icon(exp2);
}
.icon-experienced {
	@include icon(experienced);
}
.icon-expert-made-itineraries {
	@include icon(expert-made-itineraries);
}
.icon-extensive-modification {
	@include icon(extensive-modification);
}
.icon-extra-duvet {
	@include icon(extra-duvet);
}
.icon-extra-gas {
	@include icon(extra-gas);
}
.icon-extra-light {
	@include icon(extra-light);
}
.icon-extra-pan {
	@include icon(extra-pan);
}
.icon-extra-pillow {
	@include icon(extra-pillow);
}
.icon-extra-pot {
	@include icon(extra-pot);
}
.icon-extra-rags {
	@include icon(extra-rags);
}
.icon-extra-set-of-bedlinen {
	@include icon(extra-set-of-bedlinen);
}
.icon-extra-stove {
	@include icon(extra-stove);
}
.icon-extra-thick-bed {
	@include icon(extra-thick-bed);
}
.icon-f-road-2 {
	@include icon(f-road-2);
}
.icon-f-road-mountain {
	@include icon(f-road-mountain);
}
.icon-f-road {
	@include icon(f-road);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-factory-mileage-in-lmk {
	@include icon(factory-mileage-in-lmk);
}
.icon-factory-mileage-in-mpg {
	@include icon(factory-mileage-in-mpg);
}
.icon-fall {
	@include icon(fall);
}
.icon-family-buggy {
	@include icon(family-buggy);
}
.icon-fast-charging {
	@include icon(fast-charging);
}
.icon-fire-extinguisher {
	@include icon(fire-extinguisher);
}
.icon-first-aid-kit {
	@include icon(first-aid-kit);
}
.icon-fishing-gear {
	@include icon(fishing-gear);
}
.icon-fishing {
	@include icon(fishing);
}
.icon-fjord {
	@include icon(fjord);
}
.icon-flashlight {
	@include icon(flashlight);
}
.icon-floormat {
	@include icon(floormat);
}
.icon-fog-cornering-lights {
	@include icon(fog-cornering-lights);
}
.icon-fog-lights {
	@include icon(fog-lights);
}
.icon-food {
	@include icon(food);
}
.icon-forbidden {
	@include icon(forbidden);
}
.icon-frisbee {
	@include icon(frisbee);
}
.icon-front-assist {
	@include icon(front-assist);
}
.icon-fuel-tank {
	@include icon(fuel-tank);
}
.icon-fullpayment {
	@include icon(fullpayment);
}
.icon-fwd {
	@include icon(fwd);
}
.icon-gallery-int {
	@include icon(gallery-int);
}
.icon-gallery {
	@include icon(gallery);
}
.icon-gas {
	@include icon(gas);
}
.icon-gift {
	@include icon(gift);
}
.icon-glacier {
	@include icon(glacier);
}
.icon-golf-set {
	@include icon(golf-set);
}
.icon-golfing {
	@include icon(golfing);
}
.icon-google-logo {
	@include icon(google-logo);
}
.icon-gps-navigation {
	@include icon(gps-navigation);
}
.icon-ground_clearance_center {
	@include icon(ground_clearance_center);
}
.icon-ground_clearance_front {
	@include icon(ground_clearance_front);
}
.icon-ground_clearance_rear {
	@include icon(ground_clearance_rear);
}
.icon-group {
	@include icon(group);
}
.icon-halogen {
	@include icon(halogen);
}
.icon-heart-outline {
	@include icon(heart-outline);
}
.icon-heart {
	@include icon(heart);
}
.icon-heat-insulating-glass {
	@include icon(heat-insulating-glass);
}
.icon-heated-driver-seat {
	@include icon(heated-driver-seat);
}
.icon-heated-mirrors {
	@include icon(heated-mirrors);
}
.icon-heated-rear-window {
	@include icon(heated-rear-window);
}
.icon-heated-seats-2 {
	@include icon(heated-seats-2);
}
.icon-heated-seats {
	@include icon(heated-seats);
}
.icon-heated-steering-wheel {
	@include icon(heated-steering-wheel);
}
.icon-heated-window-wiper {
	@include icon(heated-window-wiper);
}
.icon-heated-windscreen {
	@include icon(heated-windscreen);
}
.icon-heating {
	@include icon(heating);
}
.icon-height-adjustable-seat {
	@include icon(height-adjustable-seat);
}
.icon-hello {
	@include icon(hello);
}
.icon-helmet-included {
	@include icon(helmet-included);
}
.icon-highlands-summer {
	@include icon(highlands-summer);
}
.icon-highlands {
	@include icon(highlands);
}
.icon-hiking-gear {
	@include icon(hiking-gear);
}
.icon-hiking-trail {
	@include icon(hiking-trail);
}
.icon-hiking {
	@include icon(hiking);
}
.icon-honest-mileage-lkm {
	@include icon(honest-mileage-lkm);
}
.icon-honest-mileage-mpg {
	@include icon(honest-mileage-mpg);
}
.icon-horseback-riding {
	@include icon(horseback-riding);
}
.icon-horsepower {
	@include icon(horsepower);
}
.icon-host-or-guide-with-frame {
	@include icon(host-or-guide-with-frame);
}
.icon-host-or-guide {
	@include icon(host-or-guide);
}
.icon-hot-pot-pad {
	@include icon(hot-pot-pad);
}
.icon-hot-water {
	@include icon(hot-water);
}
.icon-hunting {
	@include icon(hunting);
}
.icon-ice-sar-connection {
	@include icon(ice-sar-connection);
}
.icon-indoor-cooking-facilities {
	@include icon(indoor-cooking-facilities);
}
.icon-info {
	@include icon(info);
}
.icon-information-tablet {
	@include icon(information-tablet);
}
.icon-inside-camper {
	@include icon(inside-camper);
}
.icon-instagram {
	@include icon(instagram);
}
.icon-insurance-cancellation-protection {
	@include icon(insurance-cancellation-protection);
}
.icon-insurance-free {
	@include icon(insurance-free);
}
.icon-insurance-premium {
	@include icon(insurance-premium);
}
.icon-insurance-super-service {
	@include icon(insurance-super-service);
}
.icon-jumper-cables {
	@include icon(jumper-cables);
}
.icon-kayak-canoe {
	@include icon(kayak-canoe);
}
.icon-kayak {
	@include icon(kayak);
}
.icon-kettle {
	@include icon(kettle);
}
.icon-keyless-start {
	@include icon(keyless-start);
}
.icon-kitchen-knife {
	@include icon(kitchen-knife);
}
.icon-kwh {
	@include icon(kwh);
}
.icon-ladle {
	@include icon(ladle);
}
.icon-lagoon {
	@include icon(lagoon);
}
.icon-lake {
	@include icon(lake);
}
.icon-large-bike-rack {
	@include icon(large-bike-rack);
}
.icon-large-trailer {
	@include icon(large-trailer);
}
.icon-larger-tyres-and-wheels-1 {
	@include icon(larger-tyres-and-wheels-1);
}
.icon-larger-tyres-and-wheels {
	@include icon(larger-tyres-and-wheels);
}
.icon-laundry-rack {
	@include icon(laundry-rack);
}
.icon-layout-int {
	@include icon(layout-int);
}
.icon-leather-steering-wheel {
	@include icon(leather-steering-wheel);
}
.icon-led-headlights {
	@include icon(led-headlights);
}
.icon-level-0 {
	@include icon(level-0);
}
.icon-level {
	@include icon(level);
}
.icon-level1-easy {
	@include icon(level1-easy);
}
.icon-level2-moderate {
	@include icon(level2-moderate);
}
.icon-level3-hard {
	@include icon(level3-hard);
}
.icon-life-vest {
	@include icon(life-vest);
}
.icon-lighter {
	@include icon(lighter);
}
.icon-linkedin {
	@include icon(linkedin);
}
.icon-lithium-batteries {
	@include icon(lithium-batteries);
}
.icon-live-chat {
	@include icon(live-chat);
}
.icon-live-fleet-management {
	@include icon(live-fleet-management);
}
.icon-live-fleet {
	@include icon(live-fleet);
}
.icon-live-warnings {
	@include icon(live-warnings);
}
.icon-locked-differential {
	@include icon(locked-differential);
}
.icon-long-rental {
	@include icon(long-rental);
}
.icon-long-weekend {
	@include icon(long-weekend);
}
.icon-lte_roof_antena {
	@include icon(lte_roof_antena);
}
.icon-manage-booking {
	@include icon(manage-booking);
}
.icon-manual-gearbox {
	@include icon(manual-gearbox);
}
.icon-maximum_range-1 {
	@include icon(maximum_range-1);
}
.icon-mileage {
	@include icon(mileage);
}
.icon-minimum-age-fill-in-number {
	@include icon(minimum-age-fill-in-number);
}
.icon-mobile-phone-interface {
	@include icon(mobile-phone-interface);
}
.icon-mood-lighting {
	@include icon(mood-lighting);
}
.icon-moon {
	@include icon(moon);
}
.icon-mostbooks {
	@include icon(mostbooks);
}
.icon-motorcycle-licence-a {
	@include icon(motorcycle-licence-a);
}
.icon-motorcycle-licence-a1 {
	@include icon(motorcycle-licence-a1);
}
.icon-motorcycle-licence-a2 {
	@include icon(motorcycle-licence-a2);
}
.icon-motorcycle-license {
	@include icon(motorcycle-license);
}
.icon-mountain-bicycle {
	@include icon(mountain-bicycle);
}
.icon-mountain-bicycling-2 {
	@include icon(mountain-bicycling-2);
}
.icon-mountain-bicycling {
	@include icon(mountain-bicycling);
}
.icon-mountain {
	@include icon(mountain);
}
.icon-mounted-spare-tyre {
	@include icon(mounted-spare-tyre);
}
.icon-mudflaps {
	@include icon(mudflaps);
}
.icon-multifunction-display {
	@include icon(multifunction-display);
}
.icon-multifunctional-steering-wheel {
	@include icon(multifunctional-steering-wheel);
}
.icon-multiple-payments {
	@include icon(multiple-payments);
}
.icon-muscle-powered {
	@include icon(muscle-powered);
}
.icon-museum {
	@include icon(museum);
}
.icon-new-2 {
	@include icon(new-2);
}
.icon-new-3 {
	@include icon(new-3);
}
.icon-new {
	@include icon(new);
}
.icon-next-level-gps {
	@include icon(next-level-gps);
}
.icon-no-false-damage {
	@include icon(no-false-damage);
}
.icon-no-license-required {
	@include icon(no-license-required);
}
.icon-normal-license {
	@include icon(normal-license);
}
.icon-northern-lights {
	@include icon(northern-lights);
}
.icon-ocean-swimming {
	@include icon(ocean-swimming);
}
.icon-open {
	@include icon(open);
}
.icon-paddleboard {
	@include icon(paddleboard);
}
.icon-paddles {
	@include icon(paddles);
}
.icon-pai {
	@include icon(pai);
}
.icon-pan {
	@include icon(pan);
}
.icon-pantry-unused-food {
	@include icon(pantry-unused-food);
}
.icon-park-distance-control {
	@include icon(park-distance-control);
}
.icon-parking-sensors {
	@include icon(parking-sensors);
}
.icon-petrol-engine-2 {
	@include icon(petrol-engine-2);
}
.icon-petrol-engine {
	@include icon(petrol-engine);
}
.icon-pillows {
	@include icon(pillows);
}
.icon-post-collision-braking {
	@include icon(post-collision-braking);
}
.icon-pot {
	@include icon(pot);
}
.icon-powered-mirrors {
	@include icon(powered-mirrors);
}
.icon-powered-ventilation {
	@include icon(powered-ventilation);
}
.icon-powered-windows {
	@include icon(powered-windows);
}
.icon-price-per-day {
	@include icon(price-per-day);
}
.icon-protective-helmet {
	@include icon(protective-helmet);
}
.icon-quad-motor {
	@include icon(quad-motor);
}
.icon-quality-camper {
	@include icon(quality-camper);
}
.icon-quality-stove {
	@include icon(quality-stove);
}
.icon-quote {
	@include icon(quote);
}
.icon-rags {
	@include icon(rags);
}
.icon-rain-sensing-wipers {
	@include icon(rain-sensing-wipers);
}
.icon-raised-chassis {
	@include icon(raised-chassis);
}
.icon-ranked-1 {
	@include icon(ranked-1);
}
.icon-rear-box {
	@include icon(rear-box);
}
.icon-rear-glass-heat-insulated {
	@include icon(rear-glass-heat-insulated);
}
.icon-rear-mirror {
	@include icon(rear-mirror);
}
.icon-rear-space {
	@include icon(rear-space);
}
.icon-rear_wiper_washer {
	@include icon(rear_wiper_washer);
}
.icon-reflective-vest {
	@include icon(reflective-vest);
}
.icon-remote-lock {
	@include icon(remote-lock);
}
.icon-repeating-customer {
	@include icon(repeating-customer);
}
.icon-restaurant {
	@include icon(restaurant);
}
.icon-reverse-camera {
	@include icon(reverse-camera);
}
.icon-reviews {
	@include icon(reviews);
}
.icon-ride_height-2 {
	@include icon(ride_height-2);
}
.icon-river-2 {
	@include icon(river-2);
}
.icon-river-crosser {
	@include icon(river-crosser);
}
.icon-river-rafting {
	@include icon(river-rafting);
}
.icon-river {
	@include icon(river);
}
.icon-road-monitor {
	@include icon(road-monitor);
}
.icon-rock-climbing {
	@include icon(rock-climbing);
}
.icon-roof-box {
	@include icon(roof-box);
}
.icon-roof-ladder {
	@include icon(roof-ladder);
}
.icon-roof-rack {
	@include icon(roof-rack);
}
.icon-roofbox-closeup {
	@include icon(roofbox-closeup);
}
.icon-rope {
	@include icon(rope);
}
.icon-router {
	@include icon(router);
}
.icon-running-water {
	@include icon(running-water);
}
.icon-rwd {
	@include icon(rwd);
}
.icon-safest-country {
	@include icon(safest-country);
}
.icon-safety-tracking {
	@include icon(safety-tracking);
}
.icon-sand-ash {
	@include icon(sand-ash);
}
.icon-save-big {
	@include icon(save-big);
}
.icon-scale {
	@include icon(scale);
}
.icon-scales-new {
	@include icon(scales-new);
}
.icon-scooter {
	@include icon(scooter);
}
.icon-search {
	@include icon(search);
}
.icon-seats-2 {
	@include icon(seats-2);
}
.icon-seats-3 {
	@include icon(seats-3);
}
.icon-seats-5 {
	@include icon(seats-5);
}
.icon-selfservice-required {
	@include icon(selfservice-required);
}
.icon-set-of-bedlinen {
	@include icon(set-of-bedlinen);
}
.icon-sheets {
	@include icon(sheets);
}
.icon-shovel {
	@include icon(shovel);
}
.icon-shower {
	@include icon(shower);
}
.icon-ski-area {
	@include icon(ski-area);
}
.icon-small-bag {
	@include icon(small-bag);
}
.icon-small-bike-rack {
	@include icon(small-bike-rack);
}
.icon-small-bikerack {
	@include icon(small-bikerack);
}
.icon-small-plates {
	@include icon(small-plates);
}
.icon-small-trailer {
	@include icon(small-trailer);
}
.icon-smart-steering-wheel {
	@include icon(smart-steering-wheel);
}
.icon-snorkel {
	@include icon(snorkel);
}
.icon-snorkeling {
	@include icon(snorkeling);
}
.icon-snowboard {
	@include icon(snowboard);
}
.icon-snowboarding {
	@include icon(snowboarding);
}
.icon-softer-suspension {
	@include icon(softer-suspension);
}
.icon-solar-powered-ventilation {
	@include icon(solar-powered-ventilation);
}
.icon-songbook-1 {
	@include icon(songbook-1);
}
.icon-songbook-2 {
	@include icon(songbook-2);
}
.icon-songbook {
	@include icon(songbook);
}
.icon-souvenir-shop {
	@include icon(souvenir-shop);
}
.icon-spade {
	@include icon(spade);
}
.icon-spare {
	@include icon(spare);
}
.icon-speakers {
	@include icon(speakers);
}
.icon-special-offer {
	@include icon(special-offer);
}
.icon-speed-limiter {
	@include icon(speed-limiter);
}
.icon-speed-transmission-general {
	@include icon(speed-transmission-general);
}
.icon-speed-transmission {
	@include icon(speed-transmission);
}
.icon-speedometer {
	@include icon(speedometer);
}
.icon-standup-paddle-board {
	@include icon(standup-paddle-board);
}
.icon-start {
	@include icon(start);
}
.icon-steering-wheel {
	@include icon(steering-wheel);
}
.icon-storage-above-driver {
	@include icon(storage-above-driver);
}
.icon-stove {
	@include icon(stove);
}
.icon-street-legal {
	@include icon(street-legal);
}
.icon-street-motorcycle {
	@include icon(street-motorcycle);
}
.icon-street-racer-bicycle {
	@include icon(street-racer-bicycle);
}
.icon-stronger-main-battery {
	@include icon(stronger-main-battery);
}
.icon-summer {
	@include icon(summer);
}
.icon-sunglasses {
	@include icon(sunglasses);
}
.icon-support-24-7-round {
	@include icon(support-24-7-round);
}
.icon-surfboard {
	@include icon(surfboard);
}
.icon-surfing {
	@include icon(surfing);
}
.icon-suspended_driver_seat {
	@include icon(suspended_driver_seat);
}
.icon-swimming {
	@include icon(swimming);
}
.icon-tandem-kayak {
	@include icon(tandem-kayak);
}
.icon-tap-trawler {
	@include icon(tap-trawler);
}
.icon-tesla-camper-y-bubble {
	@include icon(tesla-camper-y-bubble);
}
.icon-tesla-camper-y {
	@include icon(tesla-camper-y);
}
.icon-theft-insurance {
	@include icon(theft-insurance);
}
.icon-thick-duvets {
	@include icon(thick-duvets);
}
.icon-tinted-glass-roof {
	@include icon(tinted-glass-roof);
}
.icon-tinted-windows {
	@include icon(tinted-windows);
}
.icon-touch-screen {
	@include icon(touch-screen);
}
.icon-touring-bike {
	@include icon(touring-bike);
}
.icon-tow-hitch {
	@include icon(tow-hitch);
}
.icon-town {
	@include icon(town);
}
.icon-toy-insurance {
	@include icon(toy-insurance);
}
.icon-toy {
	@include icon(toy);
}
.icon-toy1 {
	@include icon(toy1);
}
.icon-toys {
	@include icon(toys);
}
.icon-tpl {
	@include icon(tpl);
}
.icon-traction-control {
	@include icon(traction-control);
}
.icon-traffic-aware-cruise-control {
	@include icon(traffic-aware-cruise-control);
}
.icon-transmission-bottom-shield {
	@include icon(transmission-bottom-shield);
}
.icon-tripplanner-desktop {
	@include icon(tripplanner-desktop);
}
.icon-tronic_gearbox-1 {
	@include icon(tronic_gearbox-1);
}
.icon-tv_and_media {
	@include icon(tv_and_media);
}
.icon-twitter {
	@include icon(twitter);
}
.icon-two-batteries {
	@include icon(two-batteries);
}
.icon-tyre-changing-tools {
	@include icon(tyre-changing-tools);
}
.icon-tyre-pressure-censor {
	@include icon(tyre-pressure-censor);
}
.icon-tyre-size {
	@include icon(tyre-size);
}
.icon-unavailable {
	@include icon(unavailable);
}
.icon-unlimited-mileage {
	@include icon(unlimited-mileage);
}
.icon-upper-bed-height {
	@include icon(upper-bed-height);
}
.icon-upperbed-length {
	@include icon(upperbed-length);
}
.icon-upperbed-width {
	@include icon(upperbed-width);
}
.icon-usb-and-aux {
	@include icon(usb-and-aux);
}
.icon-usb {
	@include icon(usb);
}
.icon-van {
	@include icon(van);
}
.icon-vehicle-height {
	@include icon(vehicle-height);
}
.icon-vehicle-length {
	@include icon(vehicle-length);
}
.icon-vehicle-width {
	@include icon(vehicle-width);
}
.icon-veteran {
	@include icon(veteran);
}
.icon-video-int {
	@include icon(video-int);
}
.icon-volcano {
	@include icon(volcano);
}
.icon-warning-no-expenses {
	@include icon(warning-no-expenses);
}
.icon-warning-triangle {
	@include icon(warning-triangle);
}
.icon-washing-liquid {
	@include icon(washing-liquid);
}
.icon-washing-machine {
	@include icon(washing-machine);
}
.icon-waterfall {
	@include icon(waterfall);
}
.icon-wc {
	@include icon(wc);
}
.icon-weather-monitor {
	@include icon(weather-monitor);
}
.icon-wetsuite {
	@include icon(wetsuite);
}
.icon-whale-watching {
	@include icon(whale-watching);
}
.icon-whisk {
	@include icon(whisk);
}
.icon-wifi {
	@include icon(wifi);
}
.icon-wine-beer {
	@include icon(wine-beer);
}
.icon-winter-tyres {
	@include icon(winter-tyres);
}
.icon-winter {
	@include icon(winter);
}
.icon-wireless-phone-charger {
	@include icon(wireless-phone-charger);
}
.icon-wondow-rake {
	@include icon(wondow-rake);
}
.icon-wool-insulation {
	@include icon(wool-insulation);
}
.icon-writer {
	@include icon(writer);
}
.icon-youtube {
	@include icon(youtube);
}
.icon-zero-risk-2 {
	@include icon(zero-risk-2);
}
.icon-zero-risk {
	@include icon(zero-risk);
}
.icon-zoo {
	@include icon(zoo);
}
