body:not(.wp-admin) {

	.search-form {
		display: inline-flex;
		position: relative;

		@include hover-responsive {
			.search-form__input {
				border-color: var(--wp--preset--color--gray-two);
			}
		}

		.search-form__input {
			height: rem(46);
			box-shadow: rem(0 0 10 0) rgba(#000, 0.1);
			padding: rem(14 40 14 20) !important;
			text-overflow: ellipsis;
			transition: $tr;
		}

		.search-form__submit {
			width: rem(46);
			height: rem(46);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			color: var(--wp--preset--color--base);
			border-radius: 0 $radius-lg $radius-lg 0;
			padding: 0 rem(10) 0 0;
			position: absolute;
			top: 0;
			right: 0;

			&:before {
				display: none !important;
			}

			svg {
				width: rem(14);
				height: rem(14);
			}

			.icon {
				font-size: rem(16);
			}
		}

		&.locked {
			overflow: hidden;
		}
	}

	.itm-intro {
		position: relative;
		z-index: 2;
	}

	.searchform {

		&__head {
			display: flex;
			gap: rem(16);

			@include max(md) {
				display: none;
			}
		}

		&__apply {
			display: none;
		}

		&__book {
			display: none;
		}
	}

	.searchform-close {
		width: rem(36);
		height: rem(36);
		font-size: rem(20);
		color: var(--wp--preset--color--gray-two);
		padding: rem(8);
		display: none;
		position: fixed;
		top: rem(48);
		right: rem(24);
		z-index: 9999;
	}

	.searchform-mainwrapper {
		max-width: fit-content;
		background: rgba(255, 255, 255, 0.4);
		border-radius: rem(10);
		padding: rem(20 30);
		margin: rem(20) auto;

		@include max(lg) {
			max-width: rem(328) !important;
			padding: rem(30 24);
		}

		@include max(md) {
			width: 100%;
			margin-bottom: rem(62);
		}

		&.active {
			padding: rem(16);

			@include max(md) {
				width: 100vw;
				max-width: 100vw !important;
				height: rem(154);
				background: var(--wp--preset--color--white);
				border-radius: 0;
				margin: 0;
				position: fixed;
				left: 0;
				bottom: 0;
				z-index: 3;
			}

			.searchform {

				&__search {

					@include max(md) {
						display: none;
					}
				}

				&__apply {

					@include max(md) {
						display: block;
					}

					button {

						@include max(md) {
							font-size: rem(12);
							color: var(--wp--preset--color--white-static);
							line-height: rem(25);
						}

						@media (max-width: 540px) {
							line-height: rem(18);
						}

						@include theme-dark {
							background: var(--wp--preset--color--stroke) !important;
						}
					}
				}

				&__book {

					@include max(md) {
						display: block;
					}

					button {

						@include max(md) {
							width: 100%;
							font-size: rem(12);
							color: var(--wp--preset--color--base);
							font-weight: 500;
							text-transform: uppercase;
							background: #ffffff;
							border: 2px solid var(--wp--preset--color--primary) !important;
						}

						@include theme-dark {
							background: var(--wp--preset--color--stroke);
						}
					}
				}
			}

			.searchform-block {

				@include max(md) {
					flex-direction: row;
					justify-content: space-between;
					flex-wrap: wrap;
					gap: 0;
					row-gap: rem(12);
				}
			}

			.searchform-block-element {

				@include max(md) {
					width: 100%;
					flex-wrap: wrap;
					row-gap: rem(12);
				}

				&.datetime {

					@include max(md) {
						width: 49%;
						flex-direction: column;
						row-gap: 0;
					}
				}
			}

			.searchform-block-element-dateinput {

				@include max(md) {
					display: none;
				}
			}

			.searchform-close {

				@include max(md) {
					display: flex;
					right: 1rem;
					top: 1.5rem;
				}
			}
		}

		.searchform-mainwrapper-selector-item {
			font-size: rem(12);
			color: var(--wp--preset--color--base-static);
			line-height: rem(30);
			letter-spacing: 0.1em;
			display: flex;
			align-items: center;
			gap: rem(8);
			background-color: #e0e0e0;
			border-radius: rem(4);
			padding: rem(0 16);
			margin: 0 0 rem(20);
			cursor: pointer;

			@include max(lg) {
				margin: 0 0 rem(16);
			}

			&:hover {
				background-color: #d6d1ea;
			}

			&.active {
				background-color: #d6d1ea;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
			}

			&:first-child:last-child {
				display: none;
			}

			.icon {
				height: rem(16);
				font-size: rem(16);
				transition: none;

				&.icon--io-van {
					margin-top: 0.05vw;
				}

				&.icon-calendar-3 {
					margin-top: -1px;
				}
			}
		}
	}

	.searchform-block {
		display: flex;
		gap: rem(10);

		@include max(lg) {
			flex-direction: column;
			gap: rem(16);
		}

		.searchform-block-element {
			white-space: nowrap;
			display: flex;

			.wp-block-button {
				width: 100%;
			}

			&.highlighted {

				.searchform-block-element-dateinput {
					border-color: var(--wp--preset--color--primary) !important;
				}

				.timepicker__value {
					border-color: var(--wp--preset--color--primary) !important;
				}
			}

			.searchform-block-element-dateinput {
				border-width: 2px 0 2px 2px;
				border-style: solid;
				border-color: var(--wp--preset--color--white) !important;
			}

			.timepicker__value {
			}

			@include min(md) {

				.timepicker__value {
					border-width: 2px 2px 2px 0;
					border-style: solid;
					border-color: var(--wp--preset--color--white) !important;
				}
			}

			button {
				color: var(--wp--preset--color--white-static);
				font-weight: 500;
				line-height: rem(21);
				letter-spacing: 0.05em;
				text-transform: uppercase;
				border-radius: rem(24) !important;
				padding: rem(12 28);

				@include max(lg) {
					width: 100%;
				}

				@include max(md) {
					line-height: rem(20);
					font-size: rem(12);
					font-weight: 500;
					padding: rem(10) !important;
				}

				@include max(540) {
					line-height: rem(14);
				}
			}

			.searchform-block-element-dateinput {
				width: rem(180);
				height: rem(45);
				line-height: rem(45);
				border-radius: rem(24 0 0 24);
				padding: rem(0 20) !important;

				&:hover,
				&:focus {
					border-color: transparent;
				}
			}

			.searchform-block-element-timeinput {
				width: rem(100);
				border-radius: rem(0 24 24 0);

				&:hover,
				&:focus {
					border-color: transparent;
				}
			}

			select {
				border-radius: rem(24);
			}
		}
	}

	.searchform-mainwrapper.searchform-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: rem(10);
		margin: 0 auto var(--wp--preset--spacing--l);

		.searchform-block {
			align-items: center;
			cursor: pointer;
		}

		.searchform-block-element-dateinput {
			background: var(--wp--preset--color--light-bg);
			pointer-events: none;
		}

		.timepicker {
			pointer-events: none;
		}
	}
}
