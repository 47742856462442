// Hidden sidebar
.offcanvas {
	// local variables:
	--offcanvas-width: #{rem(415)};
	--offcanvas-padding: #{clamp-rem(24, 50)};
	--offcanvas-transition: 0.4s ease-in-out;

	width: 100%;
	margin: 0;
	outline: 0;
	position: fixed;
	top: 0;
	left: 0;
	visibility: hidden;
	z-index: 110;

	@supports (height: 100dvh) {
		height: 100dvh;
	}

	@supports not (height: 100dvh) {
		height: 100vh;
	}

	&.is-open {
		visibility: visible;

		.offcanvas__inner {
			transform: translateX(0);
		}

		.offcanvas__overlay {
			opacity: 1;
		}
	}

	&__overlay {
		width: 100vw;
		height: 100vh;
		background-color: rgba($w, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;

		@include theme-dark {
			background-color: rgba($b, 0.5);
		}
	}

	&__inner {
		width: var(--offcanvas-width);
		max-width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: var(--wp--preset--color--white);
		background-clip: padding-box;
		box-shadow: rem(0 0 15 0) rgba($b, 0.15);
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(-100%);
		transition: var(--offcanvas-transition);
	}

	&__header {
		flex-shrink: 0;
		padding: var(--offcanvas-padding);
	}

	&__content {
		overflow-y: auto;
		flex-grow: 1;
		padding: var(--offcanvas-padding);
	}

	.btn-close {
		position: absolute;
		top: rem(12);
		right: rem(12);
		z-index: 2;
	}
}

// Camper Reviews
.offcanvas-reviews {
	--offcanvas-width: #{rem(710)};
	--offcanvas-padding: 0;
	right: 0;
	left: auto;

	.offcanvas__inner {
		transform: translateX(100%);
		left: auto;
		right: 0;
	}

	.offcanvas__overlay {
		background: none;
	}

	.btn-close {
		top: rem(40);
		right: clamp-rem(24, 40);
	}

	.offcanvas__content {
		background-color: var(--wp--preset--color--light-bg);
		border: none;
		display: flex;
		flex-direction: column;
	}
}
