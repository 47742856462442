html {
  box-sizing: border-box; // _sanitize.scss already applied this for all other elements
}

@include min(xl) {
  html {
    font-size: clamp-rem(16, 18, 1440, 1920);
  }

  :root {
    --wp--style--global--wide-size: 1440px;
  }
}

@include min(xxl) {
  :root {
    --wp--style--global--wide-size: 1440px;
  }
}

@include min(2200) {
  html {
    font-size: clamp-rem(18, 20, 2200, 2560);
  }

  :root {
    --wp--style--global--wide-size: 70.375vw;
  }
}

*,
*:active,
*:focus {
  outline: 0 !important; // remove outlines from all elements
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  min-width: 360px;

  &.locked {
    overflow: hidden;
  }

  &:has(.countdown) {
    padding-top: var(--countdown-height);
  }

  &.is-loading {
    &:before,
    &:after {
      position: fixed;
    }
  }
}

img {
  height: auto; // Make sure images are scaled correctly.
  max-width: 100%; // Adhere to container width.
}

.wp-block-image,
figure {

  &:last-child {
    margin-bottom: 0 !important;
  }
}

strong {
  font-weight: 600;
}

iframe {
  width: 100%;
}

pre {
  max-width: 100%;
  background: #eff1f2;
  font-size: rem(14);
  margin: rem(16 0);
  padding: rem(16);
  overflow: auto;
}

code, kbd, tt, var {
  background: rgba(0,0,0,.07);
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  border-radius: rem(4);
  padding: rem(3 6);
}

code var {
  padding: 0;
}

.editor-styles-wrapper {
  a:where(:not(.wp-element-button)) {
    &:hover {
      text-decoration: underline;
    }
  }

  @include headings {
    &:not(:first-child) {
      margin-block-start: 0.75em;

      .post-type-post & {
        margin-block-start: clamp-rem(32, 40);
      }
    }

    &:not(:last-child) {
      margin-block-end: 0.25em;

      .post-type-post & {
        margin-block-end: rem(20);
      }
    }
  }

  .is-layout-flex {
    > .content-type-h1,
    > .content-type-h2,
    > .content-type-h3,
    > .content-type-h4,
    > .content-type-h5,
    > .content-type-h6 {
      margin: 0;
    }
  }
}

@include headings {
  margin-block-start: 0.75em;
  margin-block-end: 0.25em;

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  a {
    color: inherit;
  }
}

%h1, %h2, %h3, %h4, %h5, %h6 {
  font-weight: 600;
  margin: 0;

  a {
    color: inherit;
  }
}

%h1 {
  font-size: clamp-rem(38, 64);
  letter-spacing: 0.05em;
  line-height: 1.1;
}
.h1 {
  @extend %h1;
}

%h2 {
  font-size: clamp-rem(30, 42);
  letter-spacing: 0.05em;
  line-height: 1.2;
	font-weight: 700;
}
.h2 {
  @extend %h2;
}

%h3 {
  font-size: clamp-rem(26, 30);
  letter-spacing: 0.05em;
  line-height: 1.25;
}
.h3 {
  @extend %h3;
}

%h4 {
  font-size: clamp-rem(20, 22);
  font-weight: 500;
  line-height: 1.4;
}
.h4 {
  @extend %h4;
}

%h5 {
  font-size: rem(18);
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.4;
}
.h5 {
  @extend %h5;
}

%h6 {
  font-size: rem(16);
  letter-spacing: 0.02em;
}
.h6 {
  @extend %h6;
}

%subtitle {
  font-size: rem(14);
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: rem(24);

  @include min(md) {
    font-size: rem(20);
  }
}

%copy1 {
  font-size: rem(16);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

%copy2 {
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.5;
}

%copy-sm {
  font-size: rem(12);
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: rem(20);
}

%copy-md { // desktop/footer in design
  font-size: rem(14);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

%figcaption {
  @extend %copy-sm;
  background: none;
  color: var(--wp--preset--color--gray-one);
  margin-top: rem(12);
  padding: 0;
  text-align: right;
  position: static;
}

.content-type-sub {
  @extend %subtitle;
}

.content-type-p2 {
  @extend %copy2;
}

.editor {
  p {
    margin-bottom: rem(24);
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  a:not([class]) {
    @include animated-underline;
  }
}

.icon {
  transition: $tr;
}

body > svg > symbol > path {
  transition: fill $header-tr;
}
