/* Additional styles for core/columns block. */
.wp-block-columns.wp-block-columns {

	 @include max(md) {

		&.mobile-gap-0 { gap: 0; }
		&.mobile-gap-1 { gap: var(--wp--preset--spacing--xs ); }
		&.mobile-gap-2 { gap: var(--wp--preset--spacing--s ); }
		&.mobile-gap-3 { gap: var(--wp--preset--spacing--m ); }
		&.mobile-gap-4 { gap: var(--wp--preset--spacing--l ); }
		&.mobile-gap-5 { gap: var(--wp--preset--spacing--xl ); }
		&.mobile-gap-6 { gap: var(--wp--preset--spacing--xxl ); }
	}

	@include max(md) {
		&.mobile-column-gap-0 { column-gap: 0; }
		&.mobile-column-gap-1 { column-gap: var(--wp--preset--spacing--xs ); }
		&.mobile-column-gap-2 { column-gap: var(--wp--preset--spacing--s ); }
		&.mobile-column-gap-3 { column-gap: var(--wp--preset--spacing--m ); }
		&.mobile-column-gap-4 { column-gap: var(--wp--preset--spacing--l ); }
		&.mobile-column-gap-5 { column-gap: var(--wp--preset--spacing--xl ); }
		&.mobile-column-gap-6 { column-gap: var(--wp--preset--spacing--xxl ); }
	}

	.wp-block-column{

		 @include max(md) {

			&.mobile-order--10 { order: -10; }
			&.mobile-order--9 { order: -9; }
			&.mobile-order--8 { order: -8; }
			&.mobile-order--7 { order: -7; }
			&.mobile-order--6 { order: -6; }
			&.mobile-order--5 { order: -5; }
			&.mobile-order--4 { order: -4; }
			&.mobile-order--3 { order: -3; }
			&.mobile-order--2 { order: -2; }
			&.mobile-order--1 { order: -1; }
			&.mobile-order-0 { order: 0; }
			&.mobile-order-1 { order: 1; }
			&.mobile-order-2 { order: 2; }
			&.mobile-order-3 { order: 3; }
			&.mobile-order-4 { order: 4; }
			&.mobile-order-5 { order: 5; }
			&.mobile-order-6 { order: 6; }
			&.mobile-order-7 { order: 7; }
			&.mobile-order-8 { order: 8; }
			&.mobile-order-9 { order: 9; }
			&.mobile-order-10 { order: 10; }
		}
	}
}
