.itm-megamenu-campers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(rem(288), 1fr));
  grid-column-gap: rem(20);
  grid-row-gap: rem(16);

  &__card {

    &:hover {

      .itm-megamenu-campers__card__info{
        border-color: var(--wp--preset--color--primary);
      }

      .itm-megamenu-campers__card__icons{

        .list-item{

          &:before{
            border-color: var(--wp--preset--color--primary);
          }
        }
      }
    }

    &__info {
      background: var(--wp--preset--color--light-bg);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(20);
      padding: rem(20) rem(32) rem(32);
      border-radius: rem(9);
      border: 1px solid var(--wp--preset--color--stroke);
      transition: border-color $tr;
      color: var(--wp--preset--color--black);

      img {
        max-width: rem(134);
      }
    }

    &__icons {
      position: relative;
      display: flex;
      justify-content: center;
      gap: rem(34);
      transform: translateY(-50%);
      @include unstyled-list;

      .list-item {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          inset: rem(-5);
          background: var(--wp--preset--color--white);
          border: 1px solid var(--wp--preset--color--stroke);
          border-radius: 2rem;
          z-index: -2;
          clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
          transition: border-color $tr;
        }
      }

      .icon {
        display: inline-block;
        font-size: rem(20);
        padding: rem(8);
        background: var(--wp--preset--color--light-bg);
        border: 1px solid var(--wp--preset--color--stroke);
        border-radius: 50%;
        color: var(--wp--preset--color--black);
      }

      .icon-state{
        padding: 0;
        font-size: rem(36);
        color: var(--wp--preset--color--primary);
        background: transparent;
      }
    }
  }
}
