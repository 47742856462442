// Reviews (company)
.itm-reviews {
  &__heading {
    text-align: center;
    margin-bottom: rem(38);
  }

  &__title {
    margin: 0;
  }

  &__subtitle {
    @extend %copy2;
    color: var(--wp--preset--color--gray-one);
    margin-top: rem(20);
  }

  &__grid {
    max-width: rem(850);
    display: flex;
    flex-direction: column;
    gap: clamp-rem(32, 40);
    margin: 0 auto;

    &-more {
      margin-top: clamp-rem(35, 50);
      margin-bottom: clamp-rem(35, 50);
      text-align: center;
    }
  }

  .campers-filters {
    &__inner {
      margin-bottom: rem(30);

      @include max(lg) {
        border-bottom: 1px solid var(--wp--preset--color--stroke);
        padding-bottom: rem(20);
      }
    }

    &__sorting {
      @include min(lg) {
        height: rem(65);
        grid-column: -1/1;
        border-top: 1px solid var(--wp--preset--color--stroke);
        margin-top: rem(30);
        padding-top: rem(30);
      }

      .custom-select {
        @include min(lg) {
          margin-top: rem(15);
        }
      }
    }
  }
}

// Reviews (camper)
.itm-reviews-camper {
  .campers-filters__sorting .custom-select .custom-select__current {
    background-color: var(--wp--preset--color--light-bg) !important;
  }

  &__all {
    text-align: center;
  }

  &__slider {
    border-top: 1px solid var(--wp--preset--color--stroke);
    margin: rem(50 0 0);

    .swiper-slide {
      width: rem(88);
      padding: rem(20 14 70);

      &:after {
        content: '';
        display: block;
        width: rem(60);
        border-bottom: 2px solid transparent;
        transition: border-color $tr;
        position: absolute;
        bottom: rem(50);
        left: rem(14);
      }

      &.is-active {
        pointer-events: none;

        &:after {
          border-bottom-color: var(--wp--preset--color--primary);
        }
      }

      &:hover {
        cursor: pointer;
      }

      img,
      .img-placeholder {
        width: rem(60);
        height: rem(40);
        object-fit: contain;
      }

      .icon {
        font-size: rem(32);
      }

      .tooltip {
        &:after {
          display: none;
        }
      }

      .tooltip__content {
        width: rem(88);
        left: calc(50% - #{rem(44)});

        &-inner {
          font-size: rem(11);
          padding: rem(8 4);
          text-align: center;

          &:after {
            border-width: rem(0 4 6 4);
            border-color: transparent transparent var(--wp--preset--color--gray-one) transparent;
            top: auto;
            bottom: 100%;
            left: calc(50% - #{rem(44)});
          }
        }
      }
    }
  }

  &__selected {
    display: grid;
    grid-gap: rem(20);
    grid-template-areas: "image"
      "header"
      "stats";
    margin-bottom: rem(50);

    @include min(md) {
      grid-gap: rem(0 20);
      grid-template-areas: "image header"
        "image stats";
      grid-template-columns: 40% 1fr;
    }

    &-image {
      @include border;
      grid-area: image;
      background-color: var(--wp--preset--color--white);
      padding: clamp-rem(35, 50);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include max(md) {
          aspect-ratio: 275/205;
          height: auto;
        }
      }
    }

    &-header {
      grid-area: header;

      @include min(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid var(--wp--preset--color--stroke);
        border-bottom: none;
        padding: rem(15) rem(15) rem(15) clamp-rem(32, 40);
        border-radius: $radius $radius 0 0;
        position: relative;
      }

      > .icon {
        color: rgba($b, 0.02);
        font-size: clamp-rem(100, 160);
        position: absolute;
        top: 50%;
        left: 56%;
        transform: translate(-50%, -50%);

        @include max(md) {
          display: none;
        }
      }
    }

    &-title {
      width: 100%;
      margin: rem(0 0 12);

      @include max(xs) {
        font-size: rem(26);
      }
    }

    &-subtitle {
      color: var(--wp--preset--color--gray-one);
      margin: 0;
    }

    .reviews-stats {
      @include border;
      grid-area: stats;
      background-color: var(--wp--preset--color--white);

      @include max(md) {
        flex-direction: column;
      }

      @include min(md) {
        background-color: var(--wp--preset--color--light-bg);
        border-radius: 0 0 $radius $radius;
      }
    }
  }
}
