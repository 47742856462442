.countdown {
  width: 100%;
  height: var(--countdown-height);
  background-color: #E3DCFF;
  color: var(--wp--preset--color--base);
  padding: rem(8 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: rem(12);
    max-width: 100vw;
  }

  &__label {
    width: 37%;
    font-size: rem(14);

    @include min(lg) {
      font-size: clamp-rem(16, 22, 1024, 1440);
    }

    strong {
      font-weight: 500;
    }
  }

  &__btn {
    @include max(lg) {
      padding: rem(0 16);
    }

    @include max(xs) {
      padding: rem(0 8);
    }
  }

  &__inner {
    flex-shrink: 0;
    width: rem(160);
    text-align: center;
    position: relative;

    @include min(xs) {
      width: rem(200);
    }

    @include min(lg) {
      width: rem(380);
    }
  }

  &__tiles {
    width: 100%;
    height: rem(28);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include min(lg) {
      height: rem(48);
    }

    > span,
    > strong {
      display: inline-block;
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(28);

      @include min(lg) {
        font-size: rem(30);
        line-height: rem(48);
      }
    }

    > span {
      width: rem(36);
      text-align: center;
      background: url("data:image/svg+xml,%3Csvg width='65' height='48' viewBox='0 0 65 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' width='30' height='48' rx='2' fill='white'/%3E%3Crect x='34.5' width='30' height='48' rx='2' fill='white'/%3E%3C/svg%3E%0A") no-repeat 50% / contain;
      position: relative;
      letter-spacing: 0.2em;
      text-indent: 0.1em;
      white-space: nowrap;

      @include min(lg) {
        width: rem(64);
        letter-spacing: 0.4em;
        text-indent: 0.2em;
      }
    }

    > strong {
      @include max(xs) {
        display: none;
      }
    }
  }

  &__labels {
    @include unstyled-list;
    width: 100%;
    color: var(--wp--preset--color--base);
    font-size: rem(11);
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 1.4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: rem(3);

    @include min(lg) {
      font-size: rem(16);
    }

    li {
      width: rem(36);
      white-space: nowrap;

      @include min(lg) {
        width: rem(64);
      }
    }
  }
}
