.form-card {
  @include border;
  width: 100%;
  background-color: var(--wp--preset--color--white);
  padding: rem(14 16);
  position: relative;

  @include min(640) {
    padding-right: rem(75);
  }

  @include min(md) {
    padding: rem(16 75 16 20);
  }

  .checkin & {
    padding: rem(20);
  }

  &:has(.form-card__subitem input[type="radio"]:checked),
  &:has(input[type="radio"]:checked) {
    .form-card__toggler {
      background-color: var(--wp--preset--color--primary);
      border-color: var(--wp--preset--color--primary);
      color: $w;

      &:after {
        content: attr(data-selected);
      }
    }

    .radio-like {
      background: radial-gradient(circle, $w 0%, $w rem(4), var(--wp--preset--color--primary) rem(4));
      border-color: $w;
      .checkin & {
        background: radial-gradient(circle, $color-base 0%, $color-base rem(4), var(--wp--preset--color--primary) rem(4));
        border-color: $color-base;
      }
    }
  }

  &:has(.form-card__subitem input[type="radio"]:checked) {
    .form-card {
      background-color: var(--wp--preset--color--white);

      @include min(md) {
        background-color: var(--wp--preset--color--light-bg);
      }
    }
  }

  &:not(:has(.form-card__subitem input[type="radio"]:checked)) {
    .form-card__toggle {
      display: none;
    }
  }

  &:has(.form-card__subitem-link) {
    .form-card__subitem-inner {
      @include max(xs) {
        padding-bottom: rem(40);
        position: relative;
      }
    }

    .form-card__subitem-link {
      @include max(xs) {
        position: absolute;
        bottom: rem(10);
        left: rem(10);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: rem(14);


    @include min(480) {
      display: grid;
      grid-column-gap: clamp-rem(16, 24);
      grid-template-columns: auto auto 1fr;
      grid-template-areas:
        "image title title"
        "image text text"
        "image button new";
    }

    .btn-sm {
      font-size: rem(14);
    }
  }

  &__title {
    grid-area: title;
    font-size: clamp-rem(16, 18);
    margin: 0;

    @include min(md) {
      padding-right: rem(50);
    }

    @include min(md) {
      padding-right: 0;
    }
  }

  &__thumbnail {
    grid-area: image;
    width: calc(100% + rem(32));
    flex-shrink: 0;
    margin: rem(0 -16);

    @include min(480) {
      width: rem(120);
      margin: 0;
    }

    @include min(md) {
      width: rem(168);
    }

    &:has(img[src*="watch.png"]) {
      @include max(479) {
        width: rem(100);
      }

      @include min(480) {
        background-color: #F7F7F7;
        border-radius: $radius;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 340/160;
      object-fit: cover;

      @include min(480) {
        aspect-ratio: 1/1;
        border-radius: $radius;
      }

      &[src*="watch.png"] {
        height: rem(71);
        aspect-ratio: initial;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__text {
    @extend %copy-md;
    grid-area: text;

    @include min(480) {
      margin-bottom: rem(6);
    }
  }

  &__radio {
    grid-area: button;
    width: rem(120);
    gap: rem(10);
    font-weight: 400;
    padding-left: rem(14);
    padding-right: rem(14);
    text-transform: initial;

    &:after {
      content: attr(data-label);
      white-space: nowrap;
      z-index: 2;
    }

    .radio-like {
      display: inline-block;
      border: 2px solid var(--wp--preset--color--base);
      position: relative;
      z-index: 2;
    }

    input[type="radio"],
    .radio-like {
      width: rem(16) !important;
      height: rem(16) !important;
      border-radius: 50% !important;
      transition: none !important;
    }

    &:hover {
      input[type="radio"],
      .radio-like {
        border-color: $w;
      }
    }

    &:not(:hover) {
      border-color: var(--wp--preset--color--stroke);
    }

    &:has(.radio-like) {
      justify-content: flex-start;
      font-weight: 400;
      letter-spacing: 0.05em;
      text-transform: initial;
    }

    &:has(input[type="radio"]:checked) {
      background-color: var(--wp--preset--color--primary);
      border-color: var(--wp--preset--color--primary);
      color: $w;

      &:after {
        content: attr(data-selected);
      }

      input[type="radio"] {
        background: radial-gradient(circle, $w 0%, $w rem(4), var(--wp--preset--color--primary) rem(4));
        border-color: $w;
      }
    }
  }

  &__toggle {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding-top: clamp-rem(20, 30);

    @include min(md) {
      gap: rem(20);
    }
  }

  // Sub item
  &__subitem {
    @extend %copy-md;
    width: 100%;
    display: flex;
    align-items: center;
    gap: clamp-rem(10, 18);

    @include hover-responsive {
      .form-card__subitem-inner {
        border-color: var(--wp--preset--color--primary);
      }
    }

    > input[type="radio"] {
      flex-shrink: 0;
    }

    &-inner {
      @include border;
      flex-grow: 1;
      background-color: var(--wp--preset--color--light-bg);
      padding: rem(10);
      transition: border-color $tr;

      @include min(md) {
        background-color: var(--wp--preset--color--white);
      }

      @include min(480) {
        padding: rem(16);
      }

      @include min(md) {
        padding: rem(20 20 24);
      }

      input[type="radio"]:checked + & {
        border-color: var(--wp--preset--color--primary);
      }
    }

    &-header {
      display: grid;
      grid-gap: rem(14 20);
      grid-template-areas: "image title"
      "image link"
      "image text";
      grid-template-columns: auto 1fr;
      margin-bottom: clamp-rem(16, 30);

      @include max(479) {
        grid-gap: rem(8);
      }

      @include min(md) {
        grid-template-areas: "image title link"
      "image text text";
        grid-template-columns: auto 1fr auto;
        .checkin & {
          grid-template-rows: auto auto;
          grid-template-areas: "image title link"
          "image text text";
        }
      }
    }

    &-image {
      grid-area: image;
      width: rem(65);
      margin: 0;

      @include min(480) {
        width: rem(83);
      }

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    &-title {
      margin: 0;

      &:after {
        display: none !important;
      }
    }

    &-link {
      grid-area: link;
      font-weight: 600;
      .checkin & {
        height: fit-content;
      }

      @include max(479) {
        font-size: rem(12);
      }
    }

    &-label {
      @include max(479) {
        font-size: rem(12);
        letter-spacing: 0.03em;
      }

      @include min(480) {
        margin-bottom: rem(9);
      }
    }

    &-text {
      .page-checkout & {
        @extend %copy-md;
        margin-bottom: rem(14);
        font-family: 'Poppins', sans-serif !important;
        color: var(--wp--preset--color--base) !important;
        span {
          @extend %copy-md;
          font-size: rem(14) !important;
          font-family: 'Poppins', sans-serif !important;
          color: var(--wp--preset--color--base) !important;
        }
      }
      @include max(479) {
        font-size: rem(12);
        letter-spacing: 0.03em;
      }
      .checkin & {
        grid-column: span 2;
      }
    }

    &-row {
      display: flex;
      gap: clamp-rem(10, 14);
    }

    &-select {
      body:not(.wp-admin) & {
        width: rem(175);
        height: rem(36);
        background-size: rem(11 6);
        background-position-x: calc(100% - rem(16));
        font-size: rem(13);
        line-height: rem(34);
        border-radius: rem(20);
        padding: rem(0 40 0 16) !important;
      }
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12);
    font-size: clamp-rem(12, 14);
    letter-spacing: 0.05em;
    margin-top: clamp-rem(20, 30);

    @include min(480) {
      letter-spacing: 0.03em;
    }

    label {
      display: flex;
      align-items: center;
      gap: rem(10);

      input[type="checkbox"] {
        width: rem(22) !important;
        height: rem(22) !important;
      }
    }

    a {
      text-decoration: underline;

      @include hover-responsive {
        text-decoration: none;
      }
    }

    .btn {
      margin-top: rem(10);
    }
  }

  &--simple {
    .form-card__content {
      display: flex;
      flex-direction: row;
      grid-template-columns: auto 1fr;
      gap: clamp-rem(14, 24);
      align-items: center;
    }

    .form-card__title {
      margin-bottom: rem(14);

      @include min(480) {
        margin-bottom: rem(11);
      }
    }
  }
}
